import React, { useState } from 'react';
import './Cbu.css';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { CascadeSelect } from 'primereact/cascadeselect';
import flag from '../../assets/images/flag/flag_placeholder.png';

const Cbu = ({ onLanguageSelect, onSelect }) => {
  const [displayMaximizable, setDisplayMaximizable] = useState(false);
  const [submitDialog, setSubmitDialog] = useState(false);
  const [position, setPosition] = useState('center');
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedNLP, setSelectedNLP] = useState(null);
  const [selectedDataSource, setSelectedDataSource] = useState(null);
  const [selectedItem, setSelectedItem] = useState(-1);
  const countriesGroup = [
    {
      name: 'Australia',
      code: 'AU',
      states: [
        {
          name: 'New South Wales',
          cities: [
            { cname: 'Sydney', code: 'A-SY' },
            { cname: 'Newcastle', code: 'A-NE' },
            { cname: 'Wollongong', code: 'A-WO' },
          ],
        },
        {
          name: 'Queensland',
          cities: [
            { cname: 'Brisbane', code: 'A-BR' },
            { cname: 'Townsville', code: 'A-TO' },
          ],
        },
      ],
    },
    {
      name: 'Canada',
      code: 'CA',
      states: [
        {
          name: 'Quebec',
          cities: [
            { cname: 'Montreal', code: 'C-MO' },
            { cname: 'Quebec City', code: 'C-QU' },
          ],
        },
        {
          name: 'Ontario',
          cities: [
            { cname: 'Ottawa', code: 'C-OT' },
            { cname: 'Toronto', code: 'C-TO' },
          ],
        },
      ],
    },
    {
      name: 'United States',
      code: 'US',
      states: [
        {
          name: 'California',
          cities: [
            { cname: 'Los Angeles', code: 'US-LA' },
            { cname: 'San Diego', code: 'US-SD' },
            { cname: 'San Francisco', code: 'US-SF' },
          ],
        },
        {
          name: 'Florida',
          cities: [
            { cname: 'Jacksonville', code: 'US-JA' },
            { cname: 'Miami', code: 'US-MI' },
            { cname: 'Tampa', code: 'US-TA' },
            { cname: 'Orlando', code: 'US-OR' },
          ],
        },
        {
          name: 'Texas',
          cities: [
            { cname: 'Austin', code: 'US-AU' },
            { cname: 'Dallas', code: 'US-DA' },
            { cname: 'Houston', code: 'US-HO' },
          ],
        },
      ],
    },
  ];
  const countries = [
    { name: 'Australia', code: 'AU' },
    { name: 'Brazil', code: 'BR' },
    { name: 'China', code: 'CN' },
    { name: 'Egypt', code: 'EG' },
    { name: 'France', code: 'FR' },
    { name: 'Germany', code: 'DE' },
    { name: 'India', code: 'IN' },
    { name: 'Japan', code: 'JP' },
    { name: 'Spain', code: 'ES' },
    { name: 'United States', code: 'US' },
  ];
  const names = [];
  const onCountryChange = (e) => {
    setSelectedCountry(e.value);
  };

  const onNLPChange = (e) => {
    setSelectedNLP(e.value);
  };
  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="country-item country-item-value">
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };
  const selectedNLPTemplate = (option, props) => {
    if (option) {
      return (
        <div className="country-item country-item-value">
          <div>{option.name}</div>
        </div>
      );
    }
    return <span>{props.placeholder}</span>;
  };
  const countryOptionTemplate = (option) => {
    return (
      <div className="country-item">
        <div>{option.name}</div>
      </div>
    );
  };

  const countryOptionGroupTemplate = (option) => {
    return (
      <div className="country-item">
        {option.cities && <i className="pi pi-compass mr-2" />}
        {option.cname && <i className="pi pi-map-marker mr-2" />}
        <span>{option.cname || option.name}</span>
      </div>
    );
  };
  const languageArray = [
    {
      language: 'Turkish',
      activeLanguage: ['Turkish', 'English'],
      admins: ['Metehan Tan', 'Sergen Dönmez'],
     // url: 'https://upload.wikimedia.org/wikipedia/commons/b/bb/Turkey_flag_300.png',
    },
    {
      language: 'Australia',
      activeLanguage: ['English'],
      admins: ['Metehan Tan', 'Sergen Dönmez'],
      //url: 'https://upload.wikimedia.org/wikipedia/en/b/b9/Flag_of_Australia.svg',
    },
  ];
  const dialogFuncMap = {
    displayMaximizable: setDisplayMaximizable,
  };
  const submitDialogFuncMap = {
    submitDisplayMaximizable: setSubmitDialog,
  };

  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);

    if (position) {
      setPosition(position);
    }
    setSelectedItem(-1);
  };
  const onClickSubmitDialog = (name, position) => {
    submitDialogFuncMap[`${name}`](true);

    if (position) {
      setPosition(position);
    }
  };
  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  };
  const onHideSubmit = (name) => {
    submitDialogFuncMap[`${name}`](false);
  };
  const renderFooter = (name) => {
    return (
      <div>
        <Button
          label="Cancel"
          icon="pi pi-times"
          onClick={() => {
            onHide(name);
            setSelectedDataSource(null);
            setSelectedNLP(null);
            setSelectedCountry(null);
          }}
          className="p-button-text"
        />
        <Button
          label="Submit"
          icon="pi pi-check"
          onClick={() => {
            onHide(name);
            onClickSubmitDialog('submitDisplayMaximizable');
          }}
          autoFocus
          disabled={
            !(
              selectedDataSource !== null &&
              selectedCountry.name !== null &&
              selectedNLP !== null
            )
          }
        />
      </div>
    );
  };
  const renderFooterSubmit = (name) => {
    return (
      <div>
        <Button
          label="Take Me There!"
          onClick={() => {
            onHideSubmit(name);
            onLanguageSelect(true);
            names.push(selectedNLP.name);
            onSelect(
              {
                selectedCountry: {
                  name: selectedCountry.name,
                },
              },
              {
                selectedNLP: {
                  name: names,
                },
              }
            );
          }}
          autoFocus
        />
      </div>
    );
  };
  return (
    <div className="cbu-head">
      <div className="header">
        {languageArray.map((item, key) => (
          <div
            key={key}
            className="cbu-card"
            onClick={() => {
              setSelectedCountry(item.language);
              setSelectedNLP(item.activeLanguage);
              setSelectedItem(key);
            }}
            style={
              selectedItem === key
                ? { borderColor: 'blue' }
                : { borderColor: 'white' }
            }
          >
            <div className="card-header">
              <span className="card-header-language-span" >
                {item.language}
              </span>
              <img width={200} height={100} src={item.url} alt="new" />
              <div className="active-language-div" >
                <span className="active-language-span">
                  Active Languages:&nbsp;
                </span>
                {item.activeLanguage.map((language, key) => (
                  <span key={key}>{language},</span>
                ))}
              </div>
            </div>
            <div className="admin-div">
              <span className="admin-span">Admins:&nbsp;</span>
              {item.admins.map((admin, key) => (
                <span key={key}>{admin},</span>
              ))}
            </div>
          </div>
        ))}
        <div
          className="cbu-card-plus"
          onClick={() => onClick('displayMaximizable')}
        >
          <span>Create New CBU</span>
          <i className="pi pi-plus" style={{ fontSize: 50 }}></i>
        </div>
        <Dialog
          header="Create New CBU"
          visible={displayMaximizable}
          modal
          style={{ width: '50vw' }}
          footer={renderFooter('displayMaximizable')}
          onHide={() => onHide('displayMaximizable')}
        >
          <div className="dropdown-div">
            <Dropdown
              value={selectedCountry}
              options={countries}
              onChange={onCountryChange}
              optionLabel="name"
              filter
              filterBy="name"
              placeholder="Select a Country"
              valueTemplate={selectedCountryTemplate}
              itemTemplate={countryOptionTemplate}
              style={{ minWidth: '14rem' }}
            />
            <Dropdown
              value={selectedNLP}
              options={countries}
              onChange={onNLPChange}
              optionLabel="name"
              filter
              filterBy="name"
              placeholder="Select a Country"
              valueTemplate={selectedNLPTemplate}
              itemTemplate={countryOptionTemplate}
              style={{ minWidth: '14rem' }}
            />
            <CascadeSelect
              value={selectedDataSource}
              options={countriesGroup}
              optionLabel={'cname'}
              optionGroupLabel={'name'}
              optionGroupChildren={['states', 'cities']}
              style={{ minWidth: '14rem' }}
              placeholder={'Select Data Source'}
              onChange={(event) => setSelectedDataSource(event.value)}
              itemTemplate={countryOptionGroupTemplate}
            />
          </div>
        </Dialog>
        <Dialog
          header="Create New CBU"
          visible={submitDialog}
          modal
          className="create-dialog"
          footer={renderFooterSubmit('submitDisplayMaximizable')}
          onHide={() => onHideSubmit('submitDisplayMaximizable')}
        >
          <div className="createNewCBU-div">
            <span>
              You have created{' '}
              <span className="created-span">
                {selectedCountry !== null && selectedCountry.name}
              </span>{' '}
              country with{' '}
              <span className="created-span">
                {selectedNLP !== null && selectedNLP.name} NLP Engine
              </span>{' '}
              and flow data has been dublicated from Global Master
            </span>
            <span className="location-span">
              Please go to settings and complete localizations.
            </span>
          </div>
        </Dialog>
      </div>
      <div>
        <div>
          <Button
            label="Submit"
            icon="pi pi-check"
            autoFocus
            disabled={selectedItem === -1}
            onClick={() => {
              onLanguageSelect(true);
              onSelect(
                {
                  selectedCountry: {
                    name: selectedCountry,
                  },
                },
                {
                  selectedNLP: {
                    name: selectedNLP,
                  },
                }
              );
            }}
          />
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Cbu;
