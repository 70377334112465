import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/header/Header";
import "./Settings.css";
import { TabPanel, TabView } from "primereact/tabview";
import { ColorPicker } from "primereact/colorpicker";
import { InputText } from "primereact/inputtext";
import { FileUpload } from "primereact/fileupload";
import WidgetIntro from "../../components/Widget-Intro/Widget-intro";
import {
  getFlowList,
  getGeneralList,
  getInitialSettings,
  submitGreetingsSettings,
} from "../../API/helper";
import WidgetChat from "../../components/Widget-Chat/Widget-Chat";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import DeepDiff from "deep-diff";
import settingsAnimation from "../../assets/settingsAnimation.json";
import Lottie from "react-lottie-player";
let API_BASE_URL = "";
const DEV_MODE = process.env.REACT_APP_MODE;
const PRODUCT_URL = process.env.REACT_APP_PRODUCT_URL;
const DEV_URL = process.env.REACT_APP_STAGING_URL;

const Settings = () => {
  const toast = useRef(null);
  const [toggleInputBox, setToggleInputBox] = useState("button");
  const [templates, setTemplates] = useState([]);
  const [tempTemplates, setTempTemplates] = useState([]);
  const [translations, setTranslations] = useState();
  const [tempTranslations, setTempTranslations] = useState();
  const [defaultTemplatesData, setDefaultTemplatesData] = useState();
  const [defaultTranslationsData, setDefaultTranslationsData] = useState();
  const [differenceItem, setDifferenceItem] = useState([]);
  const [fontFamily, setFontFamily] = useState(null);
  const [loading, setLoading] = useState(true);
  const inputRef = useRef(null);
  const APIkeyInput = useRef(null);
  const [innerTabIndex, setInnerTabIndex] = useState(0);
  const [outerTabIndex, setOuterTabIndex] = useState(0);
  const [translationTabIndex, setTranslationTabIndex] = useState(0);
  const [privacyPath, setPrivacyPath] = useState("");
  const [termsPath, setTermsPath] = useState("");
  const onOuterTabChange = (event) => {
    setOuterTabIndex(event.index);
    localStorage.setItem("outerTabSelectedIndex", event.index);
  };
  const onTranslationTabChange = (event) => {
    setTranslationTabIndex(event.index);
    localStorage.setItem("translationTabSelectedIndex", event.index);
  };
  const onInnerTabChange = (event) => {
    setInnerTabIndex(event.index);
    localStorage.setItem("innerTabSelectedIndex", event.index);
  };
  const getURL = () => {
    if (DEV_MODE === "DEV") {
      console.log("APP STARTED WITH STAGING MODE");
      API_BASE_URL = DEV_URL;
    } else if (DEV_MODE === "PRODUCT") {
      console.log("APP STARTED WITH PRODUCT MODE");
      API_BASE_URL = PRODUCT_URL;
    }
  };

  useEffect(() => {
    const outerTabSelectedIndex = localStorage.getItem("outerTabSelectedIndex");
    if (outerTabSelectedIndex) {
      setOuterTabIndex(parseInt(outerTabSelectedIndex));
    }
    const innerTabSelectedIndex = localStorage.getItem("innerTabSelectedIndex");
    if (innerTabSelectedIndex) {
      setInnerTabIndex(parseInt(innerTabSelectedIndex));
    }
    const translationTabIndex = localStorage.getItem(
      "translationTabSelectedIndex"
    );
    if (translationTabIndex) {
      setTranslationTabIndex(parseInt(translationTabIndex));
    }
  }, []);

  useEffect(() => {
    setDifferenceItem(DeepDiff(templates, tempTemplates));
  }, [templates]);

  const getAllSettings = async () => {
    await getInitialSettings().then((resp) => {
      if (resp.success) {
        setTranslations(resp.data.detail.configs.message);
        setTempTranslations(resp.data.detail.configs.message);
        setTemplates(resp.data.detail.configs.template);
        setTempTemplates(resp.data.detail.configs.template);
        setDefaultTemplatesData(resp.data.original.configs.template);
        setDefaultTranslationsData(resp.data.original.configs.message);
        fetchData(resp.data.detail.configs.template);
        const timeout = setTimeout(() => {
          setLoading(false);
          clearTimeout(timeout);
        }, 2000);
      }
    });
  };
  const submitGreeting = async (settings) => {
    await submitGreetingsSettings(settings).then((response) =>
      response.success
        ? toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: response.message,
            life: 3000,
          })
        : toast.current.show({
            severity: "error",
            summary: "Error",
            detail: response.message,
            life: 3000,
          })
    );
  };
  const handleOnBeforeSend = (e, key) => {
    e.formData.append("key", key);
    e.xhr.setRequestHeader(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
  };

  const handleOnUpload = (e, key) => {
    const obj = JSON.parse(e.xhr.response);

    if (key === "brand") {
      setTranslations({ ...translations, brand: obj.data.detail.url });
    } else if (key === "businessIcon") {
      setTemplates({ ...templates, businessIcon: obj.data.detail.url });
    } else if (key === "customerIcon") {
      setTemplates({ ...templates, customerIcon: obj.data.detail.url });
    } else if (key == "privacyPolicyUrl") {
      const names = e.files.map((file) => file.name);
      setPrivacyPath(names[0]);
      setTranslations({
        ...translations,
        privacyPolicyUrl: obj.data.detail.url,
      });
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Privacy and policy URL saved successfully.",
        life: 3000,
      });
    } else if (key == "termsConditionsUrl") {
      const names = e.files.map((file) => file.name);
      setTermsPath(names[0]);
      setTranslations({
        ...translations,
        termsConditionsUrl: obj.data.detail.url,
      });
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Terms and conditions URL saved successfully.",
        life: 3000,
      });
    }
  };
  const handleChangesSubmit = () => {
    submitGreeting([
      {
        parameter: "template",
        key: "headerBgColor",
        value:
          templates.headerBgColor === ""
            ? tempTemplates.headerBgColor
            : templates.headerBgColor,
      },
      {
        parameter: "template",
        key: "headerFontColor",
        value:
          templates.headerFontColor === ""
            ? tempTemplates.headerFontColor
            : templates.headerFontColor,
      },
      {
        parameter: "template",
        key: "greetingBackground",
        value:
          templates.greetingBackground === ""
            ? tempTemplates.greetingBackground
            : templates.greetingBackground,
      },
      {
        parameter: "template",
        key: "agreeButtonBackgroundColor",
        value:
          templates.agreeButtonBackgroundColor === ""
            ? tempTemplates.agreeButtonBackgroundColor
            : templates.agreeButtonBackgroundColor,
      },
      {
        parameter: "template",
        key: "agreeButtonTextColor",
        value:
          templates.agreeButtonTextColor === ""
            ? tempTemplates.agreeButtonTextColor
            : templates.agreeButtonTextColor,
      },
      {
        parameter: "template",
        key: "fontFamily",
        value:
          templates.fontFamily === ""
            ? tempTemplates.fontFamily
            : templates.fontFamily,
      },
      {
        parameter: "message",
        key: "brand",
        value:
          translations.brand === ""
            ? tempTranslations.brand
            : translations.brand,
      },
      {
        parameter: "template",
        key: "botBubbleColor",
        value:
          templates.botBubbleColor === ""
            ? tempTemplates.botBubbleColor
            : templates.botBubbleColor,
      },
      {
        parameter: "template",
        key: "botFontColor",
        value:
          templates.botFontColor === ""
            ? tempTemplates.botFontColor
            : templates.botFontColor,
      },
      {
        parameter: "template",
        key: "userBubbleColor",
        value:
          templates.userBubbleColor === ""
            ? tempTemplates.userBubbleColor
            : templates.userBubbleColor,
      },
      {
        parameter: "template",
        key: "userFontColor",
        value:
          templates.userFontColor === ""
            ? tempTemplates.userFontColor
            : templates.userFontColor,
      },
      {
        parameter: "template",
        key: "button",
        value:
          templates.button === "" ? tempTemplates.button : templates.button,
      },
      {
        parameter: "template",
        key: "buttonColor",
        value:
          templates.buttonColor === ""
            ? tempTemplates.buttonColor
            : templates.buttonColor,
      },
      {
        parameter: "template",
        key: "businessIcon",
        value:
          templates.businessIcon === ""
            ? tempTemplates.businessIcon
            : templates.businessIcon,
      },
      {
        parameter: "template",
        key: "customerIcon",
        value:
          templates.customerIcon === ""
            ? tempTemplates.customerIcon
            : templates.customerIcon,
      },
      // {
      //   parameter: "message",
      //   key: "termsConditions",
      //   value:
      //     translations.termsConditions === ""
      //       ? tempTranslations.termsConditions
      //       : translations.termsConditions,
      // },
      // {
      //   parameter: "message",
      //   key: "privacyPolicy",
      //   value:
      //     translations.privacyPolicy === ""
      //       ? tempTranslations.privacyPolicy
      //       : translations.privacyPolicy,
      // },
      {
        parameter: "message",
        key: "windowTitle",
        value:
          translations.windowTitle === ""
            ? tempTranslations.windowTitle
            : translations.windowTitle,
      },
      {
        parameter: "message",
        key: "agreeButtonTitle",
        value:
          translations.agreeButtonTitle === ""
            ? tempTranslations.agreeButtonTitle
            : translations.agreeButtonTitle,
      },
      {
        parameter: "message",
        key: "welcomeMessage",
        value:
          translations.welcomeMessage === ""
            ? tempTranslations.welcomeMessage
            : translations.welcomeMessage,
      },
      {
        parameter: "message",
        key: "privacyPolicyUrl",
        value:
          translations.privacyPolicyUrl === ""
            ? tempTranslations.privacyPolicyUrl
            : translations.privacyPolicyUrl,
      },
      {
        parameter: "message",
        key: "termsConditionsUrl",
        value:
          translations.termsConditionsUrl === ""
            ? tempTranslations.termsConditionsUrl
            : translations.termsConditionsUrl,
      },
      {
        parameter: "message",
        key: "continueChat",
        value:
          translations.continueChat === ""
            ? tempTranslations.continueChat
            : translations.continueChat,
      },
      {
        parameter: "message",
        key: "inputPlaceholder",
        value:
          translations.inputPlaceholder === ""
            ? tempTranslations.inputPlaceholder
            : translations.inputPlaceholder,
      },
      {
        parameter: "message",
        key: "cardOverlay1",
        value:
          translations.cardOverlay1 === ""
            ? tempTranslations.cardOverlay1
            : translations.cardOverlay1,
      },
      {
        parameter: "message",
        key: "cardOverlay2",
        value:
          translations.cardOverlay2 === ""
            ? tempTranslations.cardOverlay2
            : translations.cardOverlay2,
      },
      {
        parameter: "message",
        key: "resetStore",
        value:
          translations.resetStore === ""
            ? tempTranslations.resetStore
            : translations.resetStore,
      },
      {
        parameter: "message",
        key: "yes",
        value:
          translations.yes === "" ? tempTranslations.yes : translations.yes,
      },
      {
        parameter: "message",
        key: "no",
        value: translations.no === "" ? tempTranslations.no : translations.no,
      },
      {
        parameter: "message",
        key: "about",
        value:
          translations.about === ""
            ? tempTranslations.about
            : translations.about,
      },
      {
        parameter: "message",
        key: "pollThanksMsg",
        value:
          translations.pollThanksMsg === ""
            ? tempTranslations.pollThanksMsg
            : translations.pollThanksMsg,
      },
    ]);
    setTempTemplates(templates);
    setTempTranslations(translations);
  };

  const handleSetDefaultValues = (key) => {
    if (key === "greetingTemplates") {
      const newFont = window.font_list.find(
        (item) => item.name === defaultTemplatesData.fontFamily
      );
      setFontFamily(newFont);
      setTemplates({
        ...templates,
        headerBgColor: defaultTemplatesData.headerBgColor,
        headerFontColor: defaultTemplatesData.headerFontColor,
        agreeButtonBackgroundColor:
          defaultTemplatesData.agreeButtonBackgroundColor,
        agreeButtonTextColor: defaultTemplatesData.agreeButtonTextColor,
        greetingBackground: defaultTemplatesData.greetingBackground,
        fontFamily: defaultTemplatesData.fontFamily,
      });
    } else if (key === "chatsTemplates") {
      setTemplates({
        ...templates,
        botBubbleColor: defaultTemplatesData.botBubbleColor,
        botFontColor: defaultTemplatesData.botFontColor,
        userBubbleColor: defaultTemplatesData.userBubbleColor,
        userFontColor: defaultTemplatesData.userFontColor,
        button: defaultTemplatesData.button,
        buttonColor: defaultTemplatesData.buttonColor,
      });
    } else if (key === "greetingsTranslations") {
      setTranslations({
        ...translations,
        termsConditions: defaultTranslationsData.termsConditions,
        privacyPolicy: defaultTranslationsData.privacyPolicy,
        windowTitle: defaultTranslationsData.windowTitle,
        agreeButtonTitle: defaultTranslationsData.agreeButtonTitle,
        privacyPolicyUrl: defaultTranslationsData.privacyPolicyUrl,
        termsConditionsUrl: defaultTranslationsData.termsConditionsUrl,
        welcomeMessage: defaultTranslationsData.welcomeMessage,
      });
    } else if (key === "chatsTranslations") {
      setTranslations({
        ...translations,
        continueChat: defaultTranslationsData.continueChat,
        inputPlaceholder: defaultTranslationsData.inputPlaceholder,
      });
    } else if (key === "overlayTranslations") {
      setTranslations({
        ...translations,
        cardOverlay1: defaultTranslationsData.cardOverlay1,
        cardOverlay2: defaultTranslationsData.cardOverlay2,
      });
    } else if (key === "resetTranslations") {
      setTranslations({
        ...translations,
        resetStore: defaultTranslationsData.resetStore,
        yes: defaultTranslationsData.yes,
        no: defaultTranslationsData.no,
      });
    } else if (key === "aboutTranslations") {
      setTranslations({
        ...translations,
        about: defaultTranslationsData.about,
      });
    } else if (key === "otherMessages") {
      setTranslations({
        ...translations,
        pollThanksMsg: defaultTranslationsData.pollThanksMsg,
      });
    }
  };
  const handleSetLogoDefault = () => {
    setTranslations({ ...translations, brand: defaultTranslationsData.brand });
  };
  const handleSetDefault = () => {
    setTemplates({
      ...templates,
      businessIcon: defaultTemplatesData.businessIcon,
    });
  };
  const handleSetDefaultCustomer = () => {
    setTemplates({
      ...templates,
      customerIcon: defaultTemplatesData.customerIcon,
    });
  };
  function handleCopy() {
    const inputValue = inputRef.current.value;
    navigator.clipboard.writeText(inputValue);
  }
  function handleAPICopy() {
    const inputValue = APIkeyInput.current.value;
    navigator.clipboard.writeText(inputValue);
  }
  const headerUserTemplate = (options, title) => {
    const { className, chooseButton, uploadButton } = options;
    return (
      <div
        className={className}
        style={{
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div style={{ marginRight: 20, fontWeight: "bold" }}>{title}</div>
        {chooseButton}
        {uploadButton}
        <button
          aria-label="Cancel"
          type="button"
          className="p-button p-component"
          onClick={handleSetDefaultCustomer}
          disabled=""
        >
          <span className="p-button-label p-c">Set Default</span>
        </button>
      </div>
    );
  };
  const headerBotTemplate = (options, title) => {
    const { className, chooseButton, uploadButton } = options;
    return (
      <div
        className={className}
        style={{
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div style={{ marginRight: 20, fontWeight: "bold" }}>{title}</div>
        {chooseButton}
        {uploadButton}
        <button
          aria-label="Cancel"
          type="button"
          className="p-button p-component"
          onClick={handleSetDefault}
          disabled=""
        >
          <span className="p-button-label p-c">Set Default</span>
        </button>
      </div>
    );
  };

  const headerLogoTemplate = (options, title) => {
    const { className, chooseButton, uploadButton } = options;
    return (
      <div
        className={className}
        style={{
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div style={{ marginRight: 20, fontWeight: "bold" }}>{title}</div>
        {chooseButton}
        {uploadButton}
        <button
          aria-label="Cancel"
          type="button"
          className="p-button p-component"
          onClick={handleSetLogoDefault}
          disabled=""
        >
          <span className="p-button-label p-c">Set Default</span>
        </button>
      </div>
    );
  };
  const getAllGeneralData = async () => {
    const resp = await getGeneralList();
    if (resp) {
      return resp.data;
    }
  };
  const fetchData = async (template) => {
    const data = await getAllGeneralData();
    const newFont = data.list?.widget["font.list"].find(
      (item) => item.name === template.fontFamily
    );
    setFontFamily(newFont);
  };

  useEffect(() => {
    getURL();
    getAllSettings();
  }, []);

  const fontItemTemplate = (option) => {
    return (
      <div className="country-item">
        <div style={{ fontFamily: `${option.name}` }}>{option.name}</div>
      </div>
    );
  };
  return (
    <div>
      <Header items={[{ label: "Widget Setting" }]} />
      <div className="tabview-wrapper">
        <Toast ref={toast}></Toast>
        <div>
          {loading ? (
            <div className="settings-loader">
              <Lottie
                loop
                animationData={settingsAnimation}
                play
                style={{ width: 400, height: 400 }}
              />
            </div>
          ) : (
            <TabView activeIndex={outerTabIndex} onTabChange={onOuterTabChange}>
              <TabPanel
                header="Appearance"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <div className="appearance-wrapper">
                  <TabView
                    activeIndex={innerTabIndex}
                    onTabChange={onInnerTabChange}
                  >
                    <TabPanel header="Greetings">
                      <FileUpload
                        name="image"
                        url={`${API_BASE_URL}/admin/v1/settings/api_keys/detail/image`}
                        accept="image/*"
                        maxFileSize={1000000}
                        chooseOptions={{
                          icon: (
                            <span
                              style={{ marginRight: 5 }}
                              className="pi pi-image"
                            ></span>
                          ),
                        }}
                        onBeforeSend={(e) => handleOnBeforeSend(e, "brand")}
                        onUpload={(e) => handleOnUpload(e, "brand")}
                        headerTemplate={(e) =>
                          headerLogoTemplate(e, "Change Logo")
                        }
                        emptyTemplate={
                          <p className="m-0">
                            Drag and drop files to here to upload.
                          </p>
                        }
                      />
                      <div className="column-wrapper">
                        <div style={{ display: "flex", gap: 50 }}>
                          <div className="color-column-wrapper">
                            <label
                              htmlFor="headerFontColor"
                              style={{ marginBottom: 20, fontWeight: "bold" }}
                            >
                              Header Background Color
                            </label>
                            <div className="color-wrapper">
                              <ColorPicker
                                inputId="cp-hex"
                                format="hex"
                                value={templates?.headerBgColor}
                                onChange={(e) =>
                                  setTemplates({
                                    ...templates,
                                    headerBgColor: `#${e.target.value}`,
                                  })
                                }
                              />
                              <div className="color-picker-wrapper">
                                <InputText
                                  id="headerFontColor"
                                  placeholder="Header Background Color"
                                  style={{ width: 250 }}
                                  value={templates?.headerBgColor?.toUpperCase()}
                                  onChange={(e) =>
                                    setTemplates({
                                      ...templates,
                                      headerBgColor: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="color-column-wrapper">
                            <label
                              htmlFor="headerFontColor"
                              style={{ marginBottom: 20, fontWeight: "bold" }}
                            >
                              Header Font Color
                            </label>
                            <div className="color-wrapper">
                              <ColorPicker
                                inputId="cp-hex"
                                format="hex"
                                value={templates?.headerFontColor}
                                onChange={(e) =>
                                  setTemplates({
                                    ...templates,
                                    headerFontColor: `#${e.target.value}`,
                                  })
                                }
                              />
                              <div className="color-picker-wrapper">
                                <InputText
                                  id="headerFontColor"
                                  placeholder="Header Font Color"
                                  style={{ width: 250 }}
                                  value={templates?.headerFontColor?.toUpperCase()}
                                  onChange={(e) =>
                                    setTemplates({
                                      ...templates,
                                      headerFontColor: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div style={{ display: "flex", gap: 50 }}>
                          <div className="color-column-wrapper">
                            <label
                              htmlFor="headerFontColor"
                              style={{ marginBottom: 20, fontWeight: "bold" }}
                            >
                              Background Color-1
                            </label>
                            <div className="color-wrapper">
                              <ColorPicker
                                inputId="cp-hex"
                                format="hex"
                                value={templates?.greetingBackground}
                                onChange={(e) =>
                                  setTemplates({
                                    ...templates,
                                    greetingBackground: `#${e.target.value}`,
                                  })
                                }
                              />
                              <div className="color-picker-wrapper">
                                <InputText
                                  id="greetingFontColor"
                                  value={templates?.greetingBackground?.toUpperCase()}
                                  placeholder="Greeting Background-2"
                                  style={{ width: 250 }}
                                  onChange={(e) =>
                                    setTemplates({
                                      ...templates,
                                      greetingBackground: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            className="color-column-wrapper"
                            style={{ width: 315 }}
                          >
                            <label
                              htmlFor="headerFontColor"
                              style={{ marginBottom: 20, fontWeight: "bold" }}
                            >
                              Font Family
                            </label>
                            <Dropdown
                              placeholder="Select Font Family"
                              optionLabel="name"
                              itemTemplate={fontItemTemplate}
                              options={window.font_list}
                              onChange={(e) => {
                                setTemplates({
                                  ...templates,
                                  fontFamily: e.value.name,
                                });
                                setFontFamily(e.value);
                              }}
                              value={fontFamily}
                            />
                          </div>
                        </div>
                        <div style={{ display: "flex", gap: 50 }}>
                          <div className="color-column-wrapper">
                            <label
                              htmlFor="headerFontColor"
                              style={{ marginBottom: 20, fontWeight: "bold" }}
                            >
                              Button Color
                            </label>
                            <div className="color-wrapper">
                              <ColorPicker
                                inputId="cp-hex"
                                format="hex"
                                value={templates?.agreeButtonBackgroundColor}
                                onChange={(e) =>
                                  setTemplates({
                                    ...templates,
                                    agreeButtonBackgroundColor: `#${e.target.value}`,
                                  })
                                }
                              />
                              <div className="color-picker-wrapper">
                                <InputText
                                  id="buttonColor"
                                  value={templates?.agreeButtonBackgroundColor?.toUpperCase()}
                                  placeholder="Button Color"
                                  style={{ width: 250 }}
                                  onChange={(e) =>
                                    setTemplates({
                                      ...templates,
                                      agreeButtonBackgroundColor:
                                        e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="color-column-wrapper">
                            <label
                              htmlFor="headerFontColor"
                              style={{ marginBottom: 20, fontWeight: "bold" }}
                            >
                              Button Text Color
                            </label>
                            <div className="color-wrapper">
                              <ColorPicker
                                inputId="cp-hex"
                                format="hex"
                                value={templates?.agreeButtonTextColor}
                                onChange={(e) =>
                                  setTemplates({
                                    ...templates,
                                    agreeButtonTextColor: `#${e.target.value}`,
                                  })
                                }
                              />
                              <div className="color-picker-wrapper">
                                <InputText
                                  id="greetingFontColor"
                                  value={templates?.agreeButtonTextColor?.toUpperCase()}
                                  placeholder="Greeting Background"
                                  style={{ width: 250 }}
                                  onChange={(e) =>
                                    setTemplates({
                                      ...templates,
                                      agreeButtonTextColor: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 20,
                            marginTop: 20,
                            marginBottom: 50,
                          }}
                        >
                          <div className="settings-default-wrapper">
                            <Button
                              label="Set Default Values"
                              severity="success"
                              onClick={() =>
                                handleSetDefaultValues("greetingTemplates")
                              }
                            />
                            <Button
                              style={{ marginLeft: 10 }}
                              label="Save"
                              severity="success"
                              onClick={handleChangesSubmit}
                            />
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel header="Chats">
                      <div className="column-wrapper">
                        <div className="chats-uploader-section">
                          <FileUpload
                            name="image"
                            url={`${API_BASE_URL}/admin/v1/settings/api_keys/detail/image`}
                            accept="image/*"
                            maxFileSize={1000000}
                            onBeforeSend={(e) =>
                              handleOnBeforeSend(e, "businessIcon")
                            }
                            chooseOptions={{
                              icon: (
                                <span
                                  style={{ marginRight: 5 }}
                                  className="pi pi-image"
                                ></span>
                              ),
                            }}
                            onUpload={(e) => handleOnUpload(e, "customerIcon")}
                            emptyTemplate={
                              <p className="m-0">
                                Drag and drop files to here to upload.
                              </p>
                            }
                            headerTemplate={(e) =>
                              headerUserTemplate(e, "User Avatar")
                            }
                          />
                          <FileUpload
                            name="image"
                            url={`${API_BASE_URL}/admin/v1/settings/api_keys/detail/image`}
                            accept="image/*"
                            maxFileSize={1000000}
                            onBeforeSend={(e) =>
                              handleOnBeforeSend(e, "customerIcon")
                            }
                            chooseOptions={{
                              icon: (
                                <span
                                  style={{ marginRight: 5 }}
                                  className="pi pi-image"
                                ></span>
                              ),
                            }}
                            onUpload={(e) => handleOnUpload(e, "businessIcon")}
                            emptyTemplate={
                              <p className="m-0">
                                Drag and drop files to here to upload.
                              </p>
                            }
                            headerTemplate={(e) =>
                              headerBotTemplate(e, "Bot Avatar")
                            }
                          />
                        </div>

                        <div style={{ display: "flex", gap: 50 }}>
                          <div className="color-column-wrapper">
                            <label
                              htmlFor="headerFontColor"
                              style={{ marginBottom: 20, fontWeight: "bold" }}
                            >
                              Bot Bubble Background Color
                            </label>
                            <div className="color-wrapper">
                              <ColorPicker
                                inputId="cp-hex"
                                format="hex"
                                value={templates?.botBubbleColor}
                                onChange={(e) =>
                                  setTemplates({
                                    ...templates,
                                    botBubbleColor: `#${e.target.value}`,
                                  })
                                }
                              />
                              <div className="color-picker-wrapper">
                                <InputText
                                  id="headerFontColor"
                                  placeholder="Header Background Color"
                                  style={{ width: 250 }}
                                  value={templates?.botBubbleColor?.toUpperCase()}
                                  onChange={(e) =>
                                    setTemplates({
                                      ...templates,
                                      botBubbleColor: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="color-column-wrapper">
                            <label
                              htmlFor="headerFontColor"
                              style={{ marginBottom: 20, fontWeight: "bold" }}
                            >
                              Bot Bubble Font Color
                            </label>
                            <div className="color-wrapper">
                              <ColorPicker
                                inputId="cp-hex"
                                format="hex"
                                value={templates?.botFontColor}
                                onChange={(e) =>
                                  setTemplates({
                                    ...templates,
                                    botFontColor: `#${e.target.value}`,
                                  })
                                }
                              />
                              <div className="color-picker-wrapper">
                                <InputText
                                  id="headerFontColor"
                                  placeholder="Header Font Color"
                                  style={{ width: 250 }}
                                  value={templates?.botFontColor?.toUpperCase()}
                                  onChange={(e) =>
                                    setTemplates({
                                      ...templates,
                                      botFontColor: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div style={{ display: "flex", gap: 50 }}>
                          <div className="color-column-wrapper">
                            <label
                              htmlFor="headerFontColor"
                              style={{ marginBottom: 20, fontWeight: "bold" }}
                            >
                              User Bubble Background Color
                            </label>
                            <div className="color-wrapper">
                              <ColorPicker
                                inputId="cp-hex"
                                format="hex"
                                value={templates?.userBubbleColor}
                                onChange={(e) =>
                                  setTemplates({
                                    ...templates,
                                    userBubbleColor: `#${e.target.value}`,
                                  })
                                }
                              />
                              <div className="color-picker-wrapper">
                                <InputText
                                  id="greetingFontColor"
                                  value={templates?.userBubbleColor?.toUpperCase()}
                                  placeholder="Greeting Background-2"
                                  style={{ width: 250 }}
                                  onChange={(e) =>
                                    setTemplates({
                                      ...templates,
                                      userBubbleColor: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="color-column-wrapper">
                            <label
                              htmlFor="headerFontColor"
                              style={{ marginBottom: 20, fontWeight: "bold" }}
                            >
                              User Bubble Font Color
                            </label>
                            <div className="color-wrapper">
                              <ColorPicker
                                inputId="cp-hex"
                                format="hex"
                                value={templates?.userFontColor}
                                onChange={(e) =>
                                  setTemplates({
                                    ...templates,
                                    userFontColor: `#${e.target.value}`,
                                  })
                                }
                              />
                              <div className="color-picker-wrapper">
                                <InputText
                                  id="greetingFontColor"
                                  value={templates?.userFontColor?.toUpperCase()}
                                  placeholder="Greeting Background"
                                  style={{ width: 250 }}
                                  onChange={(e) =>
                                    setTemplates({
                                      ...templates,
                                      userFontColor: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div style={{ display: "flex", gap: 50 }}>
                          <div className="color-column-wrapper">
                            <label
                              htmlFor="headerFontColor"
                              style={{ marginBottom: 20, fontWeight: "bold" }}
                            >
                              Option Background Color
                            </label>
                            <div className="color-wrapper">
                              <ColorPicker
                                inputId="cp-hex"
                                format="hex"
                                value={templates?.button}
                                onChange={(e) =>
                                  setTemplates({
                                    ...templates,
                                    button: `#${e.target.value}`,
                                  })
                                }
                              />
                              <div className="color-picker-wrapper">
                                <InputText
                                  id="greetingFontColor"
                                  value={templates?.button?.toUpperCase()}
                                  placeholder="Greeting Background-2"
                                  style={{ width: 250 }}
                                  onChange={(e) =>
                                    setTemplates({
                                      ...templates,
                                      button: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            className="color-column-wrapper"
                            style={{ marginBottom: 100 }}
                          >
                            <label
                              htmlFor="headerFontColor"
                              style={{ marginBottom: 20, fontWeight: "bold" }}
                            >
                              Option Font Color
                            </label>
                            <div className="color-wrapper">
                              <ColorPicker
                                inputId="cp-hex"
                                format="hex"
                                value={templates?.buttonColor}
                                onChange={(e) =>
                                  setTemplates({
                                    ...templates,
                                    buttonColor: `#${e.target.value}`,
                                  })
                                }
                              />
                              <div className="color-picker-wrapper">
                                <InputText
                                  id="greetingFontColor"
                                  value={templates?.buttonColor?.toUpperCase()}
                                  placeholder="Greeting Background"
                                  style={{ width: 250 }}
                                  onChange={(e) =>
                                    setTemplates({
                                      ...templates,
                                      buttonColor: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="settings-default-wrapper">
                          <Button
                            label="Set Default Values"
                            severity="success"
                            onClick={() =>
                              handleSetDefaultValues("chatsTemplates")
                            }
                          />
                          <Button
                            style={{ marginLeft: 10 }}
                            label="Save"
                            severity="success"
                            onClick={handleChangesSubmit}
                          />
                        </div>
                      </div>
                    </TabPanel>
                  </TabView>
                </div>
                <div className="widget-container">
                  {innerTabIndex === 0 ? (
                    <WidgetIntro
                      templates={templates}
                      translations={translations}
                    />
                  ) : (
                    <WidgetChat
                      templates={templates}
                      translations={translations}
                      tempTranslations={tempTranslations}
                    />
                  )}
                </div>
              </TabPanel>
              <TabPanel
                header="Translations"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <div className="appearance-wrapper">
                  <TabView
                    activeIndex={translationTabIndex}
                    onTabChange={onTranslationTabChange}
                  >
                    <TabPanel header="Greetings">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                          gap: 10,
                        }}
                      >
                        <div className="upload-wrapper">
                          <div className="upload-div">
                            <span style={{ marginTop: 10 }} htmlFor="username">
                              Privacy and Policy HTML File
                            </span>
                            <FileUpload
                              style={{ marginTop: 10, marginBottom: 10 }}
                              mode="advanced"
                              name="file"
                              url={`${API_BASE_URL}/admin/v1/settings/api_keys/detail/html`}
                              accept=".html,.htm"
                              maxFileSize={1000000}
                              onBeforeSend={(e) =>
                                handleOnBeforeSend(e, "privacyPolicyUrl")
                              }
                              onUpload={(e) =>
                                handleOnUpload(e, "privacyPolicyUrl")
                              }
                              emptyTemplate={
                                <p className="m-0">
                                  Drag and drop files to here to upload.
                                </p>
                              }
                            />
                            {/* <div style={{ marginTop: 10, marginBottom: 20 }}>
                              <span>
                                <span style={{ fontWeight: "bold" }}>
                                  HTML Upload Path:{" "}
                                </span>
                                {privacyPath}
                              </span>
                            </div> */}
                          </div>
                          <div className="upload-div">
                            <label style={{ marginTop: 10 }} htmlFor="username">
                              Term and Conditions HTML File
                            </label>
                            <FileUpload
                              style={{ marginTop: 10, marginBottom: 10 }}
                              mode="advanced"
                              name="file"
                              url={`${API_BASE_URL}/admin/v1/settings/api_keys/detail/html`}
                              accept=".html,.htm"
                              maxFileSize={1000000}
                              onBeforeSend={(e) =>
                                handleOnBeforeSend(e, "termsConditionsUrl")
                              }
                              onUpload={(e) =>
                                handleOnUpload(e, "termsConditionsUrl")
                              }
                              emptyTemplate={
                                <p className="m-0">
                                  Drag and drop files to here to upload.
                                </p>
                              }
                            />
                            {/* <div style={{ marginTop: 10, marginBottom: 20 }}>
                              <span>
                                <span style={{ fontWeight: "bold" }}>
                                  HTML Upload Path:{" "}
                                </span>
                                {termsPath}
                              </span>
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          marginTop: 20,
                          gap: 10,
                        }}
                      >
                        <div className="input-wrapper">
                          <label
                            htmlFor="username"
                            style={{ marginBottom: 10 }}
                          >
                            Privacy and Policy
                          </label>
                          <InputText
                            id="username"
                            aria-describedby="username-help"
                            value={translations?.privacyPolicy}
                            onChange={(e) =>
                              setTranslations({
                                ...translations,
                                privacyPolicy: e.target.value,
                              })
                            }
                            placeholder="Privacy and Policy"
                          />
                        </div>
                        <div className="input-wrapper">
                          <label
                            htmlFor="username"
                            style={{ marginBottom: 10 }}
                          >
                            Term and Conditions
                          </label>
                          <InputText
                            id="username"
                            aria-describedby="username-help"
                            value={translations?.termsConditions}
                            onChange={(e) =>
                              setTranslations({
                                ...translations,
                                termsConditions: e.target.value,
                              })
                            }
                            placeholder="Term and Conditions"
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "start",
                          marginTop: 20,
                          gap: 10,
                        }}
                      >
                        <div className="input-wrapper">
                          <label
                            htmlFor="username"
                            style={{ marginBottom: 10 }}
                          >
                            Widget Title
                          </label>
                          <InputText
                            id="username"
                            aria-describedby="username-help"
                            value={translations?.windowTitle}
                            onChange={(e) =>
                              setTranslations({
                                ...translations,
                                windowTitle: e.target.value,
                              })
                            }
                            placeholder="Widget Title"
                          />
                        </div>
                        <div className="input-wrapper">
                          <label
                            htmlFor="username"
                            style={{ marginBottom: 10 }}
                          >
                            Button Title
                          </label>
                          <InputText
                            id="username"
                            aria-describedby="username-help"
                            placeholder="Button Title"
                            value={translations?.agreeButtonTitle}
                            onChange={(e) =>
                              setTranslations({
                                ...translations,
                                agreeButtonTitle: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "start",
                          marginTop: 20,
                        }}
                      >
                        <div
                          className="input-wrapper"
                          style={{ width: "100%" }}
                        >
                          <label
                            htmlFor="username"
                            style={{ marginBottom: 10 }}
                          >
                            Welcome Message
                          </label>
                          <InputTextarea
                            autoResize
                            style={{ marginBottom: 70 }}
                            value={translations?.welcomeMessage}
                            onChange={(e) =>
                              setTranslations({
                                ...translations,
                                welcomeMessage: e.target.value,
                              })
                            }
                            rows={5}
                            cols={30}
                          />
                        </div>
                        <div className="settings-default-wrapper">
                          <Button
                            label="Set Default Values"
                            severity="success"
                            onClick={() =>
                              handleSetDefaultValues("greetingsTranslations")
                            }
                          />
                          <Button
                            style={{ marginLeft: 10 }}
                            label="Save"
                            severity="success"
                            onClick={handleChangesSubmit}
                          />
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel header="Chats">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-evenly",
                          gap: 10,
                        }}
                      >
                        <div className="input-wrapper-chat">
                          <label
                            htmlFor="username"
                            style={{ marginBottom: 10 }}
                          >
                            Continue Chat Button
                          </label>
                          <InputText
                            id="username"
                            aria-describedby="username-help"
                            placeholder="Continue Chat Button"
                            value={translations?.continueChat}
                            onChange={(e) =>
                              setTranslations({
                                ...translations,
                                continueChat: e.target.value,
                              })
                            }
                            onClick={() => setToggleInputBox("button")}
                          />
                        </div>
                        <div
                          className="input-wrapper-chat"
                          style={{ marginTop: 20 }}
                        >
                          <label
                            htmlFor="username"
                            style={{ marginBottom: 10 }}
                          >
                            Input Placeholder
                          </label>
                          <InputText
                            id="username"
                            aria-describedby="username-help"
                            placeholder="Input Placeholder"
                            value={translations?.inputPlaceholder}
                            onChange={(e) =>
                              setTranslations({
                                ...translations,
                                inputPlaceholder: e.target.value,
                              })
                            }
                            onClick={() => setToggleInputBox("input")}
                          />
                        </div>
                        <div className="settings-default-wrapper">
                          <Button
                            label="Set Default Values"
                            severity="success"
                            onClick={() =>
                              handleSetDefaultValues("chatsTranslations")
                            }
                          />
                          <Button
                            style={{ marginLeft: 10 }}
                            label="Save"
                            severity="success"
                            onClick={handleChangesSubmit}
                          />
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel header="Overlay Menu">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-evenly",
                          gap: 10,
                        }}
                      >
                        <div className="input-wrapper-chat">
                          <label
                            htmlFor="username"
                            style={{ marginBottom: 10 }}
                          >
                            First Input
                          </label>
                          <InputText
                            id="username"
                            aria-describedby="username-help"
                            placeholder="Start Over"
                            value={translations?.cardOverlay1}
                            onChange={(e) =>
                              setTranslations({
                                ...translations,
                                cardOverlay1: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div
                          className="input-wrapper-chat"
                          style={{ marginTop: 20 }}
                        >
                          <label
                            htmlFor="username"
                            style={{ marginBottom: 10 }}
                          >
                            Second Input
                          </label>
                          <InputText
                            id="username"
                            aria-describedby="username-help"
                            placeholder="About"
                            value={translations?.cardOverlay2}
                            onChange={(e) =>
                              setTranslations({
                                ...translations,
                                cardOverlay2: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="settings-default-wrapper">
                          <Button
                            label="Set Default Values"
                            severity="success"
                            onClick={() =>
                              handleSetDefaultValues("overlayTranslations")
                            }
                          />
                          <Button
                            style={{ marginLeft: 10 }}
                            label="Save"
                            severity="success"
                            onClick={handleChangesSubmit}
                          />
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel header="Reset Store">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-evenly",
                          gap: 10,
                        }}
                      >
                        <div className="input-wrapper-chat">
                          <label
                            htmlFor="username"
                            style={{ marginBottom: 10 }}
                          >
                            Reset Store
                          </label>
                          <InputText
                            id="username"
                            aria-describedby="username-help"
                            placeholder="Do you want to clear all stored data?"
                            value={translations?.resetStore}
                            onChange={(e) =>
                              setTranslations({
                                ...translations,
                                resetStore: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div
                          className="input-wrapper-chat"
                          style={{ marginTop: 20 }}
                        >
                          <label
                            htmlFor="username"
                            style={{ marginBottom: 10 }}
                          >
                            Yes Message
                          </label>
                          <InputText
                            id="username"
                            aria-describedby="username-help"
                            placeholder="Yes"
                            value={translations?.yes}
                            onChange={(e) =>
                              setTranslations({
                                ...translations,
                                yes: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div
                          className="input-wrapper-chat"
                          style={{ marginTop: 20 }}
                        >
                          <label
                            htmlFor="username"
                            style={{ marginBottom: 10 }}
                          >
                            No Message
                          </label>
                          <InputText
                            id="username"
                            aria-describedby="username-help"
                            placeholder="No"
                            value={translations?.no}
                            onChange={(e) =>
                              setTranslations({
                                ...translations,
                                no: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="settings-default-wrapper">
                          <Button
                            label="Set Default Values"
                            severity="success"
                            onClick={() =>
                              handleSetDefaultValues("resetTranslations")
                            }
                          />
                          <Button
                            style={{ marginLeft: 10 }}
                            label="Save"
                            severity="success"
                            onClick={handleChangesSubmit}
                          />
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel header="About">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "start",
                          marginTop: 20,
                        }}
                      >
                        <div
                          className="input-wrapper"
                          style={{ width: "100%" }}
                        >
                          <label
                            htmlFor="username"
                            style={{ marginBottom: 10 }}
                          >
                            Widget About Area
                          </label>
                          <InputTextarea
                            autoResize
                            value={translations?.about}
                            onChange={(e) =>
                              setTranslations({
                                ...translations,
                                about: e.target.value,
                              })
                            }
                            rows={5}
                            cols={30}
                            placeholder="Widget About Area"
                          />
                        </div>
                        <div className="settings-default-wrapper">
                          <Button
                            label="Set Default Values"
                            severity="success"
                            onClick={() =>
                              handleSetDefaultValues("aboutTranslations")
                            }
                          />
                          <Button
                            style={{ marginLeft: 10 }}
                            label="Save"
                            severity="success"
                            onClick={handleChangesSubmit}
                          />
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel header="Other Messages">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "start",
                          marginTop: 20,
                        }}
                      >
                        <div
                          className="input-wrapper"
                          style={{ width: "100%" }}
                        >
                          <label
                            htmlFor="username"
                            style={{ marginBottom: 10 }}
                          >
                            Polls Thanks Message Area
                          </label>
                          <InputTextarea
                            autoResize
                            value={translations?.pollThanksMsg}
                            onChange={(e) =>
                              setTranslations({
                                ...translations,
                                pollThanksMsg: e.target.value,
                              })
                            }
                            rows={5}
                            cols={30}
                            placeholder="Polls Thanks Message Area"
                          />
                        </div>
                        <div className="settings-default-wrapper">
                          <Button
                            label="Set Default Values"
                            severity="success"
                            onClick={() =>
                              handleSetDefaultValues("otherMessages")
                            }
                          />
                          <Button
                            style={{ marginLeft: 10 }}
                            label="Save"
                            severity="success"
                            onClick={handleChangesSubmit}
                          />
                        </div>
                      </div>
                    </TabPanel>
                  </TabView>
                </div>
                <div className="widget-container">
                  {translationTabIndex === 5 ? (
                    <div style={{ width: 400, height: 700 }}></div>
                  ) : translationTabIndex === 0 ? (
                    <WidgetIntro translations={translations} />
                  ) : (
                    <WidgetChat
                      templates={templates}
                      showMenu={translationTabIndex === 2}
                      showResetMenu={translationTabIndex === 3}
                      translations={translations}
                      toggleInputBox={toggleInputBox}
                      chats={translationTabIndex === 1}
                      isAbout={translationTabIndex === 4}
                    />
                  )}
                </div>
              </TabPanel>
              <TabPanel header="API">
                <div className="API-wrapper">
                  <div className="input-wrapper-chat">
                    <label htmlFor="username" style={{ marginBottom: 10 }}>
                      Domain
                    </label>
                    <InputText
                      id="username"
                      value={API_BASE_URL}
                      disabled
                      aria-describedby="username-help"
                      placeholder="Domain"
                      onChange={(e) =>
                        setTranslations({
                          ...translations,
                          cardOverlay1: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="input-wrapper-chat">
                    <label htmlFor="username" style={{ marginBottom: 10 }}>
                      API Key
                    </label>
                    <div className="p-inputgroup">
                      <InputText
                        ref={APIkeyInput}
                        value={"C94QRHT1AOE4AKWOFIRTOGNGNLHFDXAO"}
                        disabled
                        placeholder="API KEY"
                      />
                      <Button
                        icon="pi pi-copy"
                        onClick={handleAPICopy}
                        tooltip="Copy API Key"
                      />
                    </div>
                  </div>
                  <div className="input-wrapper-chat">
                    <label htmlFor="username" style={{ marginBottom: 10 }}>
                      Embed Code
                    </label>
                    <div className="p-inputgroup">
                      <InputTextarea
                        autoResize
                        placeholder="Embed Code"
                        ref={inputRef}
                        value={
                          '<script id="nurse-widget-script" defer src="https://nursen.s3.eu-west-1.amazonaws.com/js/embed.js?token=C94QRHT1AOE4AKWOFIRTOGNGNLHFDXAO"></script>'
                        }
                        disabled
                      />
                      <Button
                        icon="pi pi-copy"
                        tooltip="Copy Embed Code"
                        onClick={handleCopy}
                      />
                    </div>
                    <small>
                      Paste it at the bottom of the body tag in the source code
                      of the site you want the widget to work on.
                    </small>
                  </div>
                </div>
              </TabPanel>
            </TabView>
          )}
        </div>
        <div
          className="settings-button-wrapper"
          style={{ display: loading ? "none" : "flex" }}
        ></div>
      </div>
    </div>
  );
};

export default Settings;
