import React, {useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {getSelectedSessionDetail} from "../../../API/LogsAPI/logsAPI";
import Header from "../../../components/header/Header";
import {Image} from "primereact/image";
import profilePicture from "../../../assets/images/profile-picture.jpeg";
import ReactCountryFlag from "react-country-flag";
import {Toast} from "primereact/toast";
import {Dialog} from "primereact/dialog";
import './adminDetail.css'
import {InputText} from "primereact/inputtext";
import {Password} from "primereact/password";
import {
    createAdmin,
    editAdmin,
    getAdminDetail, getAdminSlice,
    getAuthTypeList,
    getInitialSettings,
    submitGreetingsSettings
} from "../../../API/helper";
import {ProgressSpinner} from "primereact/progressspinner";
import {Button} from "primereact/button";
import {MultiSelect} from "primereact/multiselect";
import Lottie from "react-lottie-player";
import settingsAnimation from "../../../assets/settingsAnimation.json";

function AdminDetail(props) {
    const { state } = useLocation();
    const toast = useRef(null);
    const [visible, setVisible] = useState(false);
    const [label,setLabel]=useState("")
    const [loading,setLoading]=useState(false)
    const [adminValue, setAdminValue] = useState({});
    const [selectedAuthType, setSelectedAuthType] = useState(null);
    const [allAuthTypes,setAllAuthTypes]=useState(null)
    const navigate = useNavigate();
    const getAdmin = async ()=>{
        setLoading(true)

        await getAdminDetail(state.id).then((res)=>{
            setAdminValue(res.data.admin)
            let checkArray=[]
            res.data.admin.auth.forEach((item)=>{
                item.checked===1 && checkArray.push(item)
            })
            const updatedData = checkArray.map((item) => {
                const { checked, ...rest } = item;
                return rest;
            });
            setSelectedAuthType(updatedData)
            setLoading(false)
        })
    }
    const getAuth = async () => {
        await getAuthTypeList().then((resp) => {
            if (resp.success) {
                setAllAuthTypes(resp.data.list)
            }
        });
    };
    const createNewAdmin = async () => {
        let authArray =[]
        selectedAuthType && selectedAuthType.forEach((auth)=>{
            authArray.push(auth.id)
        })

        const sortedArray = authArray.sort((a, b) => a - b);
        await createAdmin(adminValue,sortedArray).then((response) =>{
            if(response.success){
                toast.current.show({
                    severity: "success",
                    summary: "Successful",
                    detail: response.message,
                    life: 3000,
                })
                setTimeout(() => {
                    navigate("/admins")
                }, 1000);
            }
            else{
                toast.current.show({
                    severity: "error",
                    summary: "Error",
                    detail: response.message,
                    life: 3000,
                })
            }

        }

        );
    };

    const editAdminData = async (admin_id) => {
        let authArray =[]
        selectedAuthType && selectedAuthType.forEach((auth)=>{
            authArray.push(auth.id)
        })

        const sortedArray = authArray.sort((a, b) => a - b);
        await editAdmin(adminValue,admin_id,sortedArray).then((response) =>{
                if(response.success){
                    toast.current.show({
                        severity: "success",
                        summary: "Successful",
                        detail: response.message,
                        life: 3000,
                    })

                    setTimeout(() => {
                        window.location.reload()
                        window.location.href = '/admins'
                    }, 1000);
                }
                else{
                    toast.current.show({
                        severity: "error",
                        summary: "Error",
                        detail: response.message,
                        life: 3000,
                    })
                }

            }

        );
    };

    const onSubmit = ()=>{
        if(state?.create){
            createNewAdmin()
        }else{
            editAdminData(state?.id)
        }
    }
    useEffect(()=>{
        getAuth()
        if(!state?.create){
            setLabel("Admin Details")
            getAdmin()
        }else{
            setLabel("Create New Admin")
        }
    },[])
    return (
        <div>
            {loading ? (
                <div className="settings-loader">
                    <Lottie
                        loop
                        animationData={settingsAnimation}
                        play
                        style={{ width: 400, height: 400 }}
                    />
                </div>
            ) :  <div>
                <Header items={[{ label: 'Admins' , url: '/admins'},{ label: label }]} />
                <div style={{padding:20,display:"flex",gap:20,backgroundColor:"#f8f9fa",border:"1px solid #dee2e6",margin:10}}>
                    <div style={{display:"flex",flexDirection:"column",textAlign:"center",gap:20}}>
                        <Image src={profilePicture} alt="Image" width="250" className="selected-user-picture" imageStyle={{borderRadius:"50%"}}/>
                        <span>{adminValue?.name}</span>
                    </div>
                    <div>
                        <div className="admin-wrapper">
                            <div className="admin-info-wrapper">
                                <span>Name:</span>
                                <InputText value={adminValue.name} onChange={(e) => setAdminValue({...adminValue,name:e.target.value})} />
                            </div>
                            <div className="admin-info-wrapper">
                                <span>E-mail:</span>
                                <InputText value={adminValue.email} onChange={(e) => setAdminValue({...adminValue,email:e.target.value})} />
                            </div>
                            <div className="admin-info-wrapper">
                                <span>Country:</span>
                                <InputText value={adminValue.country} onChange={(e) => setAdminValue({...adminValue,country:e.target.value})} />
                            </div>
                            <div className="admin-info-wrapper">
                                <span>Language:</span>
                                <InputText value={adminValue.language} onChange={(e) => setAdminValue({...adminValue,language:e.target.value})} />
                            </div>
                            <div className="admin-info-wrapper">
                                <span>Admin Roles:</span>
                            <MultiSelect value={selectedAuthType} onChange={(e) => setSelectedAuthType(e.value)} options={allAuthTypes} optionLabel="title"
                                         placeholder="Select Admin Roles" maxSelectedLabels={3} className="w-full md:w-20rem" />
                            </div>
                            <div className="admin-info-wrapper">
                                <span>Password:</span>
                                <Password value={adminValue.password}  onChange={(e) => setAdminValue({...adminValue,password:e.target.value})} toggleMask />
                            </div>
                            <div className="admin-info-wrapper">
                                <span>Password Confirmation:</span>
                                <Password className="password-input" value={adminValue.password_confirmation}  onChange={(e) => setAdminValue({...adminValue,password_confirmation:e.target.value})} toggleMask />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{display:"flex",paddingRight:20,justifyContent:"flex-end",marginBottom:20}}>
                    <Button className="p-button-info"
                            onClick={()=>{onSubmit()
                    }}>{state?.create ? "Create New Admin":"Edit Admin Data"}</Button>
                </div>
                <div className="datatable-crud-demo">
                    <Toast ref={toast} />
                    <Dialog header="Chat Messages" visible={visible} draggable={false} blockScroll style={{ width: '40vw',marginLeft:50 }} onHide={() => setVisible(false)}>
                    </Dialog>
                </div>
            </div>}
        </div>

    )
}

export default AdminDetail;