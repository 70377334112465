import React, { useState, useEffect, useRef, useCallback } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputSwitch } from "primereact/inputswitch";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import "./Flows.css";
import { Dropdown } from "primereact/dropdown";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/header/Header";
import user1 from "../../assets/images/user-1.png";
import user2 from "../../assets/images/user-2.png";
import {
  deleteFlow,
  getCustomerDetail,
  getFilteredFlowList,
  getFlowDetailList,
  getFlowList,
  getGeneralList,
  getWidgetNodes,
  updateStatus,
} from "../../API/helper";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { Menu } from "primereact/menu";
import Lottie from "react-lottie-player";
import settingsAnimation from "../../assets/settingsAnimation.json";
import { Dialog } from "primereact/dialog";
import { Image } from "primereact/image";

const Flows = () => {
  const [nutritionMethods, setNutritionMethods] = useState([]);
  const [nutritionApplicationMethods, setNutritionApplicationMethods] =
    useState();
  const [nutritionApplicationMethod, setNutritionApplicationMethod] =
    useState(null);
  const [caseLabel, setCaseLabel] = useState(null);
  const [caseLabelOptions, setCaseLabelOptions] = useState(null);
  const [products, setProducts] = useState(null);
  const [nutritionMethod, setNutritionMethod] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [content, setContent] = useState("");
  const [rows, setRows] = useState(50);
  const [isFiltered, setIsFiltered] = useState(false);
  const [testWidgetData, setTestWidgetData] = useState(null);
  const toast = useRef(null);
  const searchButtonRef = useRef(null);
  const menu = useRef(null);
  const dt = useRef(null);
  const scrollRef = useRef(null);
  const [visible, setVisible] = useState(false);
  const [firstOptionVisible, setFirstOptionVisible] = useState(true);
  const [firstOptionData, setFirstOptionData] = useState(true);
  const [testWidgetArray, setTestWidgetArray] = useState([]);
  const [isAgainChatVisible, setIsAgainChatVisible] = useState("none");
  const [optionVisibility, setOptionVisibility] = useState({});
  const [loadingText, setLoadingText] = useState(false);
  let array3 = [];
  let tempArray = [];

  const scrollToEnd = useCallback(() => {
    document
      .getElementsByClassName("p-dialog-content")[0]
      .scrollTo(0, document.getElementById("test_controller").scrollHeight);
  }, []);

  useEffect(() => {
    localStorage.setItem("widgetTempArray", JSON.stringify(array3));
  }, []);

  const hideOption = (widgetId, test) => {
    setOptionVisibility((prevState) => ({
      ...prevState,
      [widgetId]: test,
    }));
  };
  const hideOptions = (display) => {
    const optionElements = document.querySelectorAll(".option-test");

    optionElements.forEach((optionElement) => {
      optionElement.style.display = display;
    });
  };
  const handleButtonClick = async (flowID, optionID) => {
    const storedData = localStorage.getItem("widgetTempArray");
    if (storedData !== "undefined") {
      tempArray = JSON.parse(storedData);
    }
    tempArray.push({ flowID: flowID, optionID: optionID });
    handleClick(flowID + "_" + optionID, tempArray);
  };
  useEffect(() => {
    if (testWidgetArray.length > 0 && visible) scrollToEnd();
  }, [testWidgetArray]);
  useEffect(() => {
    if (!visible) {
      setTestWidgetData([]);
    }
  }, [visible]);
  const handleClick = async (flowID, tempArray) => {
    let splitArray = [];
    let newArray = [];
    localStorage.setItem("widgetTempArray", JSON.stringify(tempArray));
    const filteredData = [];

    if (flowID !== undefined) {
      splitArray = flowID.split("_");
      testWidgetArray.forEach((item, index) => {
        if (item.type !== "option") {
          filteredData.push(item);
        }
        if (
          tempArray.some(
            (option) =>
              option.flowID === item.flow_id &&
              option.optionID === item.option_id
          )
        ) {
          filteredData.push(item);
        }
      });

      try {
        const res = await getWidgetNodes(splitArray[0], splitArray[1]);
        const responseArray = res.data.data;
        hideOptions("none");
        setLoadingText(true);

        for (const item of responseArray) {
          if (localStorage.getItem("testVisible") === "false") {
            break;
          }
          if (item.type !== "option") {
            newArray.push(item);
            await new Promise((resolve) => setTimeout(resolve, 2000));
            setTestWidgetArray([...filteredData, ...newArray]);
          }
        }
        setLoadingText(false);
        if (newArray.length !== 0) {
          hideOptions("flex");
        }
        for (const item of responseArray) {
          if (item.type === "option") {
            newArray.push(item);
            setTestWidgetArray([...filteredData, ...newArray]);
          }
        }

        if (
          newArray.length === 0 ||
          newArray[newArray.length - 1].type !== "option"
        ) {
          setIsAgainChatVisible("block");
        }
      } catch (error) {
        setLoadingText(false);
        console.error("Error fetching widget nodes:", error);
      }
    }
  };
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 50,
    page: 0,
    sortField: null,
    sortOrder: null,
    isFiltered: isFiltered,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getFlowData = async (flowListData) => {
    setLoading(true);
    const flow = await getFlowList(flowListData);
    setProducts(flow.data.list);
    setTotalRecords(flow.data.paging.total);
    setLoading(false);
  };
  const getGeneraData = async () => {
    const generalData = await getGeneralList();
    if (generalData) {
      setNutritionMethods(generalData.data.list.nutrition_method);
      setNutritionApplicationMethods(generalData.data.list.nutrition_type);
      setCaseLabelOptions(generalData.data.list.nlp_labels);
    }
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        searchButtonRef.current.click();
      }
    };
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);
  const caseLabelTemplate = (option) => {
    return (
      <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
        <span>
          <span style={{ fontWeight: "bold" }}>Label: </span>
          {option.title}
        </span>
        <span>
          <span style={{ fontWeight: "bold" }}>Description: </span>
          {option.description}
        </span>
      </div>
    );
  };
  const { state } = useLocation();
  const stopTestFunc = async () => {
    localStorage.setItem("testVisible", "false");
    setVisible(false);
  };
  const getFilteredData = async () => {
    setLoading(true);
    await getFilteredFlowList(
      content,
      nutritionMethod ? nutritionMethod.id : "",
      nutritionApplicationMethod ? nutritionApplicationMethod.id : "",
      caseLabel ? caseLabel.title : "",
      lazyParams.page,
      lazyParams.rows
    ).then((resp) => {
      if (resp.success) {
        setProducts(resp.data.list);
        setTotalRecords(resp.data.paging.total);
        setIsFiltered(true);
        setLoading(false);
      } else {
        setProducts([]);
        toast.current.show({
          severity: "error",
          summary: "Data not found!",
          life: 3000,
        });
        setLoading(false);
      }
    });
  };

  const handleChangeStatus = async (flow_id, status) => {
    const flow = await updateStatus(flow_id, !status);

    const updatedProducts = products.map((e) => {
      if (e.id === flow.data.data.flow_topic_id) {
        return { ...e, status: flow.data.data.status };
      }
      return e;
    });

    setProducts(updatedProducts);
  };
  const getFilteredPaginationData = async (page, row) => {
    console.log(nutritionMethod, "nutri");
    console.log(nutritionApplicationMethod, "typ");
    console.log(caseLabel, "typ");
    console.log(content, "contnet");
    console.log(page, "page");
    console.log(state, "row");
    const flow = await getFilteredFlowList(
      content ? content : state?.content || "",
      nutritionMethod ? nutritionMethod.id : state?.nutritionMethod?.id || "",
      nutritionApplicationMethod
        ? nutritionApplicationMethod?.id
        : state?.nutritionApplicationMethod?.id || "",
      caseLabel ? caseLabel.title : state?.caseLabel?.title || "",
      page,
      row ? row : state?.row || ""
    );
    //setLazyParams({...lazyParams,page:1})
    setLoading(true);
    setProducts(flow.data.list);
    setTotalRecords(flow.data.paging.total);
    setLoading(false);
  };
  const getBackFilterData = async (page, row) => {
    console.log(state.page);

    console.log(state.row, "state row");
    const flow = await getFilteredFlowList(
      state.content,
      state.nutritionMethod ? state.nutritionMethod.id : "",
      state.nutritionApplicationMethod
        ? state.nutritionApplicationMethod.id
        : "",
      state.caseLabel ? state.caseLabel.title : "",
      state.page ? state.page + 1 : "",
      state.row ? state.row : ""
    );
    //setLazyParams({...lazyParams,page:1})
    setLoading(true);
    setProducts(flow.data.list);
    setTotalRecords(flow.data.paging.total);
    setLoading(false);
  };
  useEffect(() => {
    if (state) {
      setContent(state.content);
      setNutritionMethod(state.nutritionMethod && state.nutritionMethod);
      setNutritionApplicationMethod(
        state.nutritionApplicationMethod && state.nutritionApplicationMethod
      );
      setCaseLabel(state.caseLabel && state.caseLabel);
      // getBackFilterData(state.page && state.page + 1, lazyParams.rows);
    }
    getGeneraData();
  }, []);

  useEffect(() => {
    loadLazyData();
  }, [lazyParams]);

  const loadLazyData = async () => {
    setLoading(true);
    let pageOptions = JSON.parse(sessionStorage.getItem("pageOptions"));
    let rowOption = sessionStorage.getItem("rowOption") || 50;
    let pageNo = pageOptions?.page || 0;
    if (isFiltered || state?.isFiltered) {
      await getFilteredPaginationData(pageNo + 1, rowOption);
    } else if (state === null) {
      await getFlowData({ page: pageNo + 1, limit: rowOption });
    } else {
      await getFlowData({ page: pageNo + 1, limit: rowOption });
    }
  };

  const onNutritionMethodChange = (e) => {
    setNutritionMethod(e.value);
  };
  const onNutritionApplicationMethodChange = (e) => {
    setNutritionApplicationMethod(e.value);
  };
  const onCaseLabelChange = (e) => {
    setCaseLabel(e.value);
  };

  const handleOnPage = (event) => {
    console.log(event, "evenettt");
    setRows(event.rows);
    sessionStorage.setItem("pageOptions", JSON.stringify(event));
    sessionStorage.setItem("rowOption", event.rows);
    setLazyParams(event);
  };
  useEffect(() => {
    let pageOptions = JSON.parse(sessionStorage.getItem("pageOptions"));
    let rowOption = sessionStorage.getItem("rowOption");
    if (pageOptions && Object.keys(pageOptions).length > 0) {
      setLazyParams(pageOptions);
    }
    if (rowOption) {
      setRows(Number(rowOption));
    }
  }, []);
  const handleNewRecord = () => {
    localStorage.removeItem("nutritionMethod");
    localStorage.removeItem("nutritionAppMethod");
    localStorage.removeItem("nutritionAppMethodID");
    localStorage.removeItem("nutritionMethodID");

    navigate("/flows/add");
  };
  const openWidget = (event) => {
    getFlowDetailList(event.id).then((response) => {
      setLoading(true);
      setTestWidgetData(response?.data);
      setTimeout(() => {
        setLoading(false);
        setVisible(true);
      }, 1000);
    });
    localStorage.setItem("testVisible", "true");
    setIsAgainChatVisible("none");
    setFirstOptionVisible(true);
    setOptionVisibility({});
    setTestWidgetArray([]);
  };

  const handleFlowDelete = (flow_id) => {
    confirmDialog({
      message: "Do you want to delete this record?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => {
        deleteFlow(flow_id).then((response) => {
          if (response.data.success) {
            const updatedProducts = products.filter((e) => e.id !== flow_id);
            setProducts(updatedProducts);
          }
        });
      },
    });
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          label="Create New Flow"
          icon="pi pi-plus"
          className="p-button-success mr-2"
          onClick={handleNewRecord}
        />
      </React.Fragment>
    );
  };
  useEffect(() => {
    setIsFiltered(state?.isFiltered);
  }, [state]);
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-comments"
          className="p-button p-button-info"
          data-id={rowData.id}
          onClick={() => openWidget(rowData)}
          style={{ textAlign: "left" }}
        />
      </React.Fragment>
    );
  };
  const tagBody = (rowData) => {
    return (
      <React.Fragment>
        <div
          onClick={() =>
            navigate(`/flows/${rowData.id}`, {
              state: {
                content,
                nutritionMethod,
                nutritionApplicationMethod,
                caseLabel,
                isFiltered,
                initialStatus: rowData.status,
                id: rowData.id,
                page: lazyParams.page,
                row: lazyParams.rows,
              },
            })
          }
          style={{ color: "#1d4ed8", cursor: "pointer" }}
        >
          {rowData.title}
        </div>
      </React.Fragment>
    );
  };
  const dotBodyTemplate = (rowData) => {
    const items = [
      {
        label: "Functions",
        items: [
          /*   {
               label: "Test Flow",
               icon: "pi pi-comments p-button p-button-info dotIcon",
               command: () => {
                 openWidget(selectedProducts.id)
               },
             },*/
          {
            label: "Delete Flow",
            icon: "pi pi-minus p-button p-button-danger dotIcon",
            command: () => {
              handleFlowDelete(selectedProducts.id);
            },
          },
        ],
      },
    ];
    return (
      <React.Fragment>
        <Menu model={items} popup ref={menu} />
        <i
          onClick={(e) => menu.current.toggle(e)}
          className="pi pi-ellipsis-v"
        ></i>
      </React.Fragment>
    );
  };
  return (
    <div>
      {loading ? (
        <div className="settings-loader">
          <Lottie
            loop
            animationData={settingsAnimation}
            play
            style={{ width: 400, height: 400 }}
          />
        </div>
      ) : (
        <div>
          <Header items={[{ label: "Flows" }]} />
          <div style={{ padding: 20 }}>
            <div className="filter-div">
              <div className="input-div">
                <ConfirmDialog />
                <Dialog
                  headerStyle={{
                    textAlign: "center",
                    backgroundColor: "#3B82F6",
                    color: "white",
                  }}
                  header="Diginurse"
                  visible={visible}
                  style={{ width: "50vw" }}
                  onHide={() => {
                    stopTestFunc();
                  }}
                >
                  <div
                    id="test_controller"
                    ref={scrollRef}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <div
                      className={`chv-message user`}
                      style={{ marginTop: 30 }}
                    >
                      <img src={user1} className="chv-avatar" />
                      <div className="chv-body-test" style={{ width: "%100" }}>
                        {testWidgetData?.builder?.nodes[0].data.message}
                      </div>
                    </div>
                    {firstOptionVisible ? (
                      <div className="options-wrapper-test">
                        <div
                          className="option-test"
                          onClick={() => {
                            handleClick(testWidgetData?.builder?.nodes[1].id);
                            setFirstOptionVisible(false);
                            setFirstOptionData(
                              testWidgetData?.builder?.nodes[1].data.message
                            );
                          }}
                        >
                          {testWidgetData?.builder?.nodes[1].data.message}
                        </div>
                        <div
                          className="option-test"
                          onClick={() => {
                            handleClick(testWidgetData?.builder?.nodes[2].id);
                            setFirstOptionVisible(false);
                            setFirstOptionData(
                              testWidgetData?.builder?.nodes[2].data.message
                            );
                          }}
                        >
                          {testWidgetData?.builder?.nodes[2].data.message}
                        </div>
                      </div>
                    ) : (
                      <div
                        className={`chv-message bot`}
                        style={{ marginTop: 10 }}
                      >
                        <img src={user2} className="chv-avatar" />
                        <div
                          className="chv-body-test"
                          style={{ width: "%100" }}
                        >
                          {firstOptionData}
                        </div>
                      </div>
                    )}
                    {testWidgetArray.map((testWidget, index) =>
                      testWidget?.type === "text" ? (
                        <div
                          className={`chv-message user`}
                          style={{ marginTop: 10 }}
                        >
                          <img src={user1} className="chv-avatar" />
                          <div
                            className="chv-body-test"
                            style={{ width: "%100" }}
                          >
                            {testWidget?.message}
                          </div>
                        </div>
                      ) : testWidget?.type === "video" ? (
                        <div
                          className={`chv-message user`}
                          style={{ marginTop: 10 }}
                        >
                          <img src={user1} className="chv-avatar" />
                          <div
                            className="chv-body-test"
                            style={{ width: "%100" }}
                          >
                            <video width="250" controls>
                              <source
                                src={testWidget?.action_value}
                                type="video/mp4"
                              ></source>
                            </video>
                          </div>
                        </div>
                      ) : testWidget?.type === "image" ? (
                        <div
                          className={`chv-message user`}
                          style={{ marginTop: 10 }}
                        >
                          <img src={user1} className="chv-avatar" />
                          <div
                            className="chv-body-test"
                            style={{ width: "%100" }}
                          >
                            <Image
                              src={testWidget?.action_value}
                              alt="Image"
                              width="250"
                              preview
                            />
                          </div>
                        </div>
                      ) : localStorage.getItem("widgetTempArray") !==
                          "undefined" &&
                        JSON.parse(
                          localStorage.getItem("widgetTempArray")
                        ).some(
                          (option) =>
                            option.flowID === testWidget.flow_id &&
                            option.optionID === testWidget.option_id
                        ) ? (
                        <div
                          className={`chv-message bot`}
                          style={{ marginTop: 10 }}
                        >
                          <img src={user2} className="chv-avatar" />
                          <div
                            className="chv-body-test"
                            style={{ width: "%100" }}
                          >
                            {testWidget?.option_title}
                          </div>
                        </div>
                      ) : (
                        <div
                          className="option-test"
                          style={{ marginTop: 10 }}
                          onClick={() => {
                            handleButtonClick(
                              testWidget?.flow_id,
                              testWidget?.option_id
                            );
                            hideOption(index, testWidget?.option_title);
                          }}
                        >
                          {testWidget?.option_title}
                        </div>
                      )
                    )}
                    {loadingText ? (
                      <div className="typing">
                        <div className="typing-circle" />
                        <div className="typing-circle" />
                        <div className="typing-circle" />
                        <div className="typing-text">typing...</div>
                      </div>
                    ) : null}
                    <div
                      className="last-option-test"
                      style={{ marginTop: 40, display: isAgainChatVisible }}
                      onClick={() => {
                        setTestWidgetArray([]);
                        setIsAgainChatVisible("none");
                        setFirstOptionVisible(true);
                        setOptionVisibility({});
                      }}
                    >
                      Do you have another problem I can help with?
                    </div>
                  </div>
                </Dialog>
                <span style={{ width: "30%" }} className="p-float-label">
                  <InputText
                    style={{ width: "100%" }}
                    id="By Content"
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                  />
                  <label htmlFor="byContent">By Content</label>
                </span>
                <Dropdown
                  className="dropdown"
                  value={nutritionMethod}
                  options={nutritionMethods}
                  placeholder="Select Nutrition Method"
                  onChange={onNutritionMethodChange}
                  optionLabel="title"
                  showClear
                />
                <Dropdown
                  className="dropdown"
                  value={nutritionApplicationMethod}
                  options={nutritionApplicationMethods}
                  placeholder="Select Nutrition Type"
                  onChange={onNutritionApplicationMethodChange}
                  optionLabel="title"
                  showClear
                />
                <Dropdown
                  className="dropdown"
                  value={caseLabel}
                  options={caseLabelOptions}
                  itemTemplate={caseLabelTemplate}
                  filter
                  placeholder="Select Case Label"
                  onChange={onCaseLabelChange}
                  optionLabel="title"
                  showClear
                />
              </div>
              <div className="bring-list-div">
                <Button
                  label="Search"
                  icon="pi pi-search"
                  ref={searchButtonRef}
                  iconPos="left"
                  onClick={getFilteredData}
                />
                <div
                  className="clear-button"
                  onClick={() => {
                    sessionStorage.removeItem("pageOptions");
                    sessionStorage.removeItem("rowOption");
                    navigate("/flows", { state: null });
                    window.location.reload();
                  }}
                >
                  Clear Filter
                </div>
              </div>
            </div>
          </div>
          <div className="datatable-crud-demo">
            <Toast ref={toast} />
            <div style={{ padding: 20 }}>
              <Toolbar
                className="mb-4"
                style={{ width: "100%", marginBottom: 20 }}
                left={leftToolbarTemplate}
              ></Toolbar>
              <DataTable
                ref={dt}
                value={products}
                selection={selectedProducts}
                onSelectionChange={(e) => setSelectedProducts(e.value)}
                dataKey="id"
                selectionMode={"single"}
                paginator
                onPage={handleOnPage}
                lazy
                loading={loading}
                rows={rows}
                first={lazyParams.first}
                rowsPerPageOptions={[10, 25, 50]}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} flows"
                totalRecords={totalRecords}
                responsiveLayout="scroll"
                style={{ width: "100%", border: "1px solid #dee2e6" }}
              >
                <Column
                  field="id"
                  header="ID"
                  style={{ minWidth: "5rem" }}
                ></Column>
                <Column
                  field="title"
                  header="Tag"
                  body={tagBody}
                  style={{ minWidth: "6rem" }}
                ></Column>
                <Column
                  field="label"
                  header="Case Label"
                  style={{ minWidth: "8rem" }}
                ></Column>
                <Column
                  field="message"
                  header="Case Message"
                  style={{ minWidth: "16rem", maxWidth: "25rem" }}
                ></Column>
                <Column
                  field="flow_count"
                  header="Flow Length"
                  style={{ maxWidth: "6rem", textAlign: "center" }}
                ></Column>
                <Column
                  field="nutrition_type"
                  header="Nutrition Type"
                  style={{ minWidth: "8rem", textAlign: "center" }}
                ></Column>
                <Column
                  field="nutrition_method"
                  header="Nutrition Method"
                  style={{ minWidth: "8rem", textAlign: "center" }}
                ></Column>
                <Column
                  header="Status"
                  field="status"
                  body={(a) => {
                    return (
                      <InputSwitch
                        checked={a.status}
                        onChange={(e) => handleChangeStatus(a.id, a.status)}
                      />
                    );
                  }}
                  style={{ minWidth: "4rem", textAlign: "center" }}
                ></Column>
                <Column
                  header="Functions"
                  body={actionBodyTemplate}
                  exportable={false}
                  style={{ minWidth: "5rem" }}
                ></Column>
                <Column body={dotBodyTemplate}></Column>
              </DataTable>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Flows;
