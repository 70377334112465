import { createSlice } from "@reduxjs/toolkit";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import HomeIcon from "../../assets/icons/home.png";
import AnalyticsIcon from "../../assets/icons/analytics.png";
import FlowsIcon from "../../assets/icons/flows.png";
import LogsIcon from "../../assets/icons/logs.png";
import LogoutIcon from "../../assets/icons/logout-img.png";
import NlpIcon from "../../assets/icons/nlp.png";
import SettingsIcon from "../../assets/icons/settings.png";
import UsersIcon from "../../assets/icons/users.png";
import CountriesIcon from "../../assets/icons/countries.png";
import LanguagesIcon from "../../assets/icons/languages.png";
import TranslateIcon from "../../assets/icons/translations.png";
import PollsIcon from "../../assets/icons/ballot.png";
import CustomersIcon from "../../assets/icons/customers.png";

const initialState = {
  navbarItems: [
    {
      title: "Dashboard",
      url: "/",
      icon: HomeIcon,
      activeItem: true,
      translations: false,
      auth: true,
    },
    {
      title: "Flow Management",
      url: "/flows",
      icon: FlowsIcon,
      translations: false,
      auth: true,
    },
    {
      title: "Translations",
      url: "/translations/translations-selection",
      translations: false,
      icon: TranslateIcon,
      auth: true,
    },
    {
      title: "Flow Translate",
      url: "/translations/translations-selection",
      translations: true,
      subMenu: true,
      auth: true,
    },
    {
      title: "Flow Translate Review",
      url: "/translations/translations-selection-review",
      translations: true,
      subMenu: true,
      auth: true,
    },
    {
      title: "NLP Management",
      url: "/nlp",
      icon: NlpIcon,
      translations: false,
      auth: true,
    },
    {
      title: "Cases/Labels",
      url: "nlp/cases-labels",
      translations: false,
      auth: true,
      subMenu: true,
    },
    {
      title: "Validation Queue",
      url: "nlp/validation-que",
      translations: false,
      auth: true,
      subMenu: true,
    },
    {
      title: "Training Jobs History",
      url: "nlp/training-jobs-history",
      translations: false,
      auth: true,
      subMenu: true,
    },
    {
      title: "Polls",
      url: "/polls",
      icon: PollsIcon,
      translations: false,
      auth: true,
      blocked:true,
    },
    {
      title: "Custom Polls",
      url: "/polls",
      translations: true,
      subMenu: true,
      auth: true,
    },
    {
      title: "Product Polls",
      url: "/polls/product-polls",
      translations: true,
      subMenu: true,
      auth: true,
    },
    {
      title: "Wellbeing Polls",
      url: "/polls/wellbeing-polls",
      translations: true,
      subMenu: true,
      auth: true,
    },
    {
      title: "Users",
      url: "/users",
      icon: UsersIcon,
      translations: false,
      auth: true,
    },
    {
      title: "Chat Logs",
      url: "/logs",
      translations: false,
      auth: true,
      icon: LogsIcon,
    },
    {
      title: "Analytics",
      url: "/general",
      icon: AnalyticsIcon,
      translations: false,
      auth: true,
    },
    {
      title: "Settings",
      url: "/settings",
      icon: SettingsIcon,
      translations: false,
      auth: true,
    },
    {
      title: "Admins",
      url: "/admins",
      icon: UsersIcon,
      translations: false,
      auth: true,
    },
    {
      title: "Countries",
      url: "/countries",
      icon: CountriesIcon,
      translations: false,
      auth: true,
    },
    {
      title: "Languages",
      url: "/languages",
      icon: LanguagesIcon,
      translations: false,
      auth: true,
    },
    {
      title: "Customers",
      url: "/customers",
      icon: CustomersIcon,
      translations: false,
      auth: true,
    },
    {
      title: "Logout",
      url: "/logout",
      icon: LogoutIcon,
      translations: false,
      auth: false,
    },
  ],
};

export const navbarSlice = createSlice({
  name: "navbar",
  initialState,
  reducers: {
    setActiveItem: (state, action) => {
      state.navbarItems.forEach((leftItem) => {
        leftItem.activeItem = leftItem.url === action.payload;
      });
    },
  },
});

export const getNavbar = (state) => {
  return state.navbar;
};

export const { setActiveItem } = navbarSlice.actions;
export default navbarSlice.reducer;
