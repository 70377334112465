import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
    createCustomer,
    deleteCustomers,
    getCountries,
    getCustomer,
} from "../../API/helper";
import Lottie from "react-lottie-player";
import settingsAnimation from "../../assets/settingsAnimation.json";
import Header from "../../components/header/Header";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";

function Customers(props) {
    const [products, setProducts] = useState(null);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState(10);
    const [totalRecords, setTotalRecord] = useState(0);
    const [customerName, setCustomerName] = useState("");
    const [rowID, setRowID] = useState("");
    const toast = useRef(null);
    const dt = useRef(null);
    const [visible, setVisible] = useState(false);
    const [deleteVisible, setDeleteVisible] = useState(false);
    const { state } = useLocation();
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 50,
        page: 0,
        sortField: null,
        sortOrder: null,
        filters: {
            name: { value: "", matchMode: "contains" },
            "country.name": { value: "", matchMode: "contains" },
            company: { value: "", matchMode: "contains" },
            "representative.name": { value: "", matchMode: "contains" },
        },
    });
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        if (selectedProducts !== null) {
            navigate("/customers/details", {
                state: { id: selectedProducts.id, create: false },
            });
        }
    }, [selectedProducts]);
    const getAllCustomers = async (page, row) => {
        setLoading(true);
        await getCustomer(page, row).then((res) => {
            setProducts(res.data.list);
            setTotalRecord(res.data.paging.total);
            setLoading(false);
        });
    };
    const createNewCustomer = async () => {
        await createCustomer(customerName).then((response) => {
            if (response.success) {
                toast.current.show({
                    severity: "success",
                    summary: "Successful",
                    detail: response.message,
                    life: 3000,
                });
                getAllCustomers(1, 10);
                setCustomerName("");
                setVisible(false);
            } else {
                toast.current.show({
                    severity: "error",
                    summary: "Error",
                    detail: response.message,
                    life: 3000,
                });
            }
        });
    };
    const handleCustomerDelete = async () => {
        await deleteCustomers(rowID).then((response) => {
            if (response.success) {
                toast.current.show({
                    severity: "success",
                    summary: "Successful",
                    detail: response.message,
                    life: 3000,
                });
                getAllCustomers(1, 10);
                setCustomerName("");
                setDeleteVisible(false);
            } else {
                toast.current.show({
                    severity: "error",
                    summary: "Error",
                    detail: response.message,
                    life: 3000,
                });
            }
        });
    };
    const footerContent = (
        <div>
            <Button
                label="No"
                icon="pi pi-times"
                onClick={() => setVisible(false)}
                className="p-button-text"
            />
            <Button
                label="Yes"
                icon="pi pi-check"
                onClick={() => {
                    createNewCustomer();
                }}
                autoFocus
            />
        </div>
    );

    const footerDeleteContent = (
        <div>
            <Button
                label="No"
                icon="pi pi-times"
                onClick={() => setDeleteVisible(false)}
                className="p-button-text"
            />
            <Button
                label="Yes"
                icon="pi pi-check"
                onClick={() => {
                    handleCustomerDelete();
                }}
                autoFocus
            />
        </div>
    );
    useEffect(() => {
        getAllCustomers();
    }, []);

    const header = (
        <div>
            <span>Customers</span>
        </div>
    );

    const handleOnPage = (event) => {
        setRows(event.rows);
        setLazyParams(event);
    };
    useEffect(() => {
        loadLazyData();
    }, [lazyParams]);
    const loadLazyData = () => {
        getAllCustomers(lazyParams.page + 1, lazyParams.rows);
    };

    const deleteBody = (rowData) => {
        return (
            <React.Fragment>
                <button
                    onClick={() => {
                        setRowID(rowData.id);
                        setDeleteVisible(true);
                    }}
                    style={{
                        fontSize: "1.4rem",
                        backgroundColor: "#ff7b5a",
                        color: "white",
                        padding: "9px",
                        border: "1px solid #ff7b5a",
                        borderRadius: "7px",
                        cursor: "pointer",
                    }}
                    className="p-button-raised  pi pi-trash"
                ></button>
            </React.Fragment>
        );
    };

    return (
        <div>
            {loading ? (
                <div className="settings-loader">
                    <Lottie
                        loop
                        animationData={settingsAnimation}
                        play
                        style={{ width: 400, height: 400 }}
                    />
                </div>
            ) : (
                <div>
                    <Header items={[{ label: "Customers" }]} />
                    <Dialog
                        header="Create New Customer"
                        visible={visible}
                        style={{ width: "50vw" }}
                        onHide={() => setVisible(false)}
                        footer={footerContent}
                    >
                        <div style={{ width: "50%" }} className="admin-info-wrapper">
                            <span>Name:</span>
                            <InputText
                                value={customerName}
                                onChange={(e) => setCustomerName(e.target.value)}
                            />
                        </div>
                    </Dialog>
                    <Dialog
                        header="Delete Customer"
                        visible={deleteVisible}
                        draggable={false}
                        blockScroll
                        style={{ width: "40vw", marginLeft: 50 }}
                        onHide={() => setDeleteVisible(false)}
                        footer={footerDeleteContent}
                    >
                        Are you sure you want to delete the customer?
                    </Dialog>
                    <div className="datatable-crud-demo">
                        <Toast ref={toast} />
                        <div
                            style={{
                                display: "flex",
                                paddingRight: 20,
                                justifyContent: "flex-end",
                            }}
                        >
                            <Button
                                className="p-button-info"
                                onClick={() => {
                                    setVisible(true);
                                }}
                            >
                                Create New Customer
                            </Button>
                        </div>
                        <div style={{ padding: 20 }}>
                            <DataTable
                                ref={dt}
                                header={header}
                                value={products}
                                selection={selectedProducts}
                                dataKey="id"
                                selectionMode={"single"}
                                onPage={handleOnPage}
                                paginator
                                lazy
                                loading={loading}
                                rows={rows}
                                first={lazyParams.first}
                                rowsPerPageOptions={[10, 25, 50]}
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} countries"
                                onSelectionChange={(e) => setSelectedProducts(e.value)}
                                totalRecords={totalRecords}
                                responsiveLayout="scroll"
                                style={{ width: "100%", border: "1px solid #dee2e6" }}
                            >
                                <Column
                                    field="id"
                                    header="ID"
                                    style={{ minWidth: "5rem" }}
                                ></Column>
                                <Column field="dev_dataset_id" header="Dataset ID"></Column>
                                <Column field="name" header="Name"></Column>
                                <Column field="created_at" header="Created Date"></Column>
                                <Column
                                    body={deleteBody}
                                    style={{ width: "10%", textAlign: "right" }}
                                />
                            </DataTable>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Customers;