import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ReactFlow, {
  Background,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
  useReactFlow,
  useStoreApi,
} from "reactflow";
import {
  changeOptionData,
  getFlowDetailList,
  getFlowTranslationDetails,
  getGeneralList,
  updateFlowNode,
} from "../../../API/helper";
import "./TranslationsFlowViewer.css";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Toast } from "primereact/toast";
import { ProgressSpinner } from "primereact/progressspinner";
import TranslateEdges from "./CustomTranslateEdges/TranslateEdges";
import QuestionTranslationNode from "./CustomTranslateNodes/Question/QuestionTranslationNode";
import OptionTranslationNode from "./CustomTranslateNodes/Option/OptionTranslationNode";
import ImageTranslationNode from "./CustomTranslateNodes/Image/ImageTranslationNode";
import VideoTranslationNode from "./CustomTranslateNodes/Video/VideoTranslationNode";
import { Dialog } from "primereact/dialog";
const EDGE_TYPE_NAME = "buttonedge";

const edgeTypes = {
  buttonedge: TranslateEdges,
};
const nodeTypes = {
  text: QuestionTranslationNode,
  option: OptionTranslationNode,
  image: ImageTranslationNode,
  video: VideoTranslationNode,
};

const TranslationsFlowViewer = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const toast = useRef(null);
  const routeParams = useParams();
  const nodesRef = useRef([]);
  const edgesRef = useRef([]);
  const { setCenter, getNode } = useReactFlow();
  const reactFlowWrapper = useRef(null);
  const chatContentRef = useRef();
  const viewportHeight = window.innerHeight;
  const [flowTopic, setFlowTopic] = useState("");
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [isChange, setIsChange] = useState(false);
  const [optionList, setOptionList] = useState([]);
  const [totalNode, setTotalNode] = useState(0);
  const [coordinate, setCoordinate] = useState({ x: 0, y: 0, zoom: 1 });
  const [isSave, setIsSave] = useState(false);
  const [ratio, setRatio] = useState(0);
  const [allTextNodes, setAllTextNodes] = useState([]);
  const [fullNode, setFullNode] = useState(0);
  const [firstSelectNode, setFirstSelectNode] = useState();
  const [prevCoordinate, setPrevCoordinate] = useState({ x: 0, y: 0, zoom: 1 });
  const [visible, setVisible] = useState(false);
  const [activeNode, setActiveNode] = useState(null);
  const store = useStoreApi();
  const handleFocusNode = (node) => {
    setActiveNode(node);
  };

  const footerContent = (
    <div>
      <Button
        label="No"
        icon="pi pi-times"
        onClick={() => setVisible(false)}
        className="p-button-text"
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        onClick={() => {
          if (localStorage.getItem("isReview") === "false") {
            navigate("/translations/flow-translations", { state });
          } else if (localStorage.getItem("isReview") === "true") {
            navigate("/translations/translations-review", { state });
          }
          window.select_index = 0;
          setVisible(false);
        }}
        autoFocus
      />
    </div>
  );

  useEffect(() => {
    if (!window.option_list) {
      getGeneralList().then(() => {
        setOptionList(window.option_list);
      });
    } else {
      setOptionList(window.option_list);
    }
    window.select_index = 0;
  }, []);
  let array = [];
  useEffect(() => {
    getFlowDetailList(routeParams.id).then((response) => {
      array = [...new Set(response?.data?.path_planner)];
    });
    let full_node = 0;
    let totalNode = 0;
    let ratio = 0;
    let textNodes = [];
    nodesRef.current = nodes;
    nodes.forEach((n) => {
      if (n.data.action === "text") {
        totalNode++;
        textNodes.push(n);
      }
      if (localStorage.getItem("isReview") === "false") {
        if (n.data.action === "text" && n.data.target_msg !== "") {
          full_node++;
        }
      } else {
        if (n.data.action === "text" && n.data.is_approved) {
          full_node++;
        }
      }
    });
    ratio = (full_node / totalNode) * 100;
    setRatio(ratio);
    setFullNode(full_node);
    setTotalNode(totalNode);
  }, [nodes, allTextNodes]);

  useEffect(() => {
    edgesRef.current = edges;
  }, [edges]);
  const sortArrayByOrder = (array1, array2) => {
    const sortedArray = [];
    const stringArray1 = array1.map((id) => id.toString());
    stringArray1.forEach((id) => {
      const object = array2.find((obj) => obj.id === id);
      if (object) {
        sortedArray.push(object);
      }
    });
    return sortedArray;
  };
  const focusNextNode = async () => {
    let selectedNode;
    let nodeIndex = window.select_index;
    const { nodeInternals } = store.getState();
    const nodes = Array.from(nodeInternals).map(([, node]) => node);
    const sortedNodes = sortArrayByOrder(array, nodes);
    let sortedTextNodes = sortedNodes.filter((item) => item.type === "text");
    await setAllTextNodes([]);
    await setAllTextNodes(sortedTextNodes);

    setIsChange(!isChange);
    if (sortedNodes[nodeIndex + 1] == undefined) {
      window.select_index = 0;
    }
    if (
      sortedNodes[nodeIndex + 1]?.type == "option" ||
      sortedNodes[nodeIndex + 1]?.type == "video" ||
      sortedNodes[nodeIndex + 1]?.type == "image"
    ) {
      window.select_index++;
      focusNextNode();
      return;
    }

    if (nodes.length > 0 && sortedNodes[nodeIndex+1]!==undefined) {
      console.log(sortedNodes[nodeIndex+1],"TEST")
      selectedNode = sortedNodes[nodeIndex + 1];
      const x = selectedNode.position.x + selectedNode.width / 2;
      const y = selectedNode.position.y + selectedNode.height / 2;
      document.querySelectorAll(".p-button").forEach(function (el, i) {
        if (el.attributes["aria-label"].value !== "Back") {
          el.style.display = "none";
        }
      });
      var tmp = selectedNode.id.split("_");
      tmp = tmp[0];
      document.querySelectorAll(".react-flow__node").forEach(function (el, i) {
        if (el.attributes["data-id"].value === tmp) {
          el.querySelectorAll(".question-translation-textarea").forEach(
            function (el, i) {
              el.disabled = false;
            }
          );
          el.querySelectorAll(".p-button").forEach(function (el, i) {
            el.style.display = "block";
          });
        }
      });
      const zoom = 1.3;
      setCoordinate({ x, y, zoom });
      setCenter(x, y, { zoom, duration: 1000 });
    }
    window.select_index = nodeIndex + 1;
    if (sortedNodes.length <= window.select_index) {
      window.select_index = 0;
    }

    getFlowTranslationDetails(
      localStorage.getItem("lang_id"),
      localStorage.getItem("country_id"),
      routeParams.id
    )
      .then((response) => {
        setFlowTopic(response.title);
        const nodesFromService = response.data?.builder?.nodes;
        const edgesFromService = response.data?.builder?.edges;
        if (!nodesFromService || !edgesFromService) return;
        setNodes(
          nodesFromService.map((e, i) => {
            const { style, ...nodeWithoutStyle } = e;
            return {
              topic_id: response.data.topic.id,
              ...nodeWithoutStyle,
              id: nodeWithoutStyle.id.toString(),
              width: 261,
              height: 198,
              style: { borderStyle: i < 3 ? "dashed" : "solid" },
              data: {
                onClickNextNode: focusNextNode,
                onLoading:setUpdating,
                onClickPreviousNode: focusPreviousNode,
                static: i < 3 || e.data.static,
                ...nodeWithoutStyle.data,
                topic_id: response.data.topic.id,
              },
            };
          })
        );
        setEdges(
          edgesFromService.map((e) => ({
            ...e,
            target: e.target.toString(),
            source: e.source.toString(),
            type: EDGE_TYPE_NAME,
            data: {},
          }))
        );

        setLoading(false);
      })
      .then(() => {

          setTimeout(function () {
            const { nodeInternals } = store.getState();
            const nodes = Array.from(nodeInternals).map(([, node]) => node);
            const sortedNodes = sortArrayByOrder(array, nodes);
            let sortedTextNodes = sortedNodes.filter(
              (item) => item.type === "text"
            );
            setAllTextNodes(sortedTextNodes);
            handleFocusNode(selectedNode);
          }, 100 );

      });
  };

  useEffect(() => {
    if (coordinate.x !== 0) {
      let zoom = coordinate.zoom;
      setCenter(coordinate.x, coordinate.y, { zoom, duration: 1000 });
    }
  }, [coordinate]);
  useEffect(() => {
    if (prevCoordinate.x !== 0) {
      let zoom = prevCoordinate.zoom;
      setCenter(prevCoordinate.x, prevCoordinate.y, { zoom, duration: 1000 });
    }
  }, [prevCoordinate]);
  const focusPreviousNode = () => {
    let selectedNode;
    let nodeIndex = window.select_index;
    const { nodeInternals } = store.getState();
    const nodes = Array.from(nodeInternals).map(([, node]) => node);
    const sortedNodes = sortArrayByOrder(array, nodes);
    let sortedTextNodes = sortedNodes.filter((item) => item.type === "text");
    setAllTextNodes(sortedTextNodes);
    setIsChange(!isChange);
    if (sortedNodes[nodeIndex - 1] == undefined) {
      window.select_index = 0;
    }
    if (
      sortedNodes[nodeIndex - 1].type == "option" ||
      sortedNodes[nodeIndex - 1].type == "video" ||
      sortedNodes[nodeIndex - 1].type == "image"
    ) {
      window.select_index--;
      focusPreviousNode();
      return;
    }

    if (nodes.length > 0) {
      selectedNode = sortedNodes[nodeIndex - 1];
      window.selectedNode = selectedNode;
      const x = selectedNode.position.x + selectedNode.width / 2;
      const y = selectedNode.position.y + selectedNode.height / 2;
      document.querySelectorAll(".p-button").forEach(function (el, i) {
        if (el.attributes["aria-label"].value !== "Back") {
          el.style.display = "none";
        }
      });
      document.querySelectorAll(".p-button").forEach(function (el, i) {
        if (el.attributes["aria-label"].value !== "Back") {
          el.style.display = "none";
        }
      });
      var tmp = selectedNode.id.split("_");
      tmp = tmp[0];
      document.querySelectorAll(".react-flow__node").forEach(function (el, i) {
        if (el.attributes["data-id"].value == tmp) {
          el.querySelectorAll(".question-translation-textarea").forEach(
            function (el, i) {
              el.disabled = false;
            }
          );
          el.querySelectorAll(".p-button").forEach(function (el, i) {
            el.style.display = "block";
          });
        }
      });

        setTimeout(function () {
          const { nodeInternals } = store.getState();
          const nodes = Array.from(nodeInternals).map(([, node]) => node);
          const sortedNodes = sortArrayByOrder(array, nodes);
          let sortedTextNodes = sortedNodes.filter(
            (item) => item.type === "text"
          );
          setAllTextNodes(sortedTextNodes);
          handleFocusNode(selectedNode);
        }, 100 );

      const zoom = 1.3;
      setPrevCoordinate({ x, y, zoom });
      setCenter(x, y, { zoom, duration: 1000 });
    }
    window.select_index = nodeIndex - 1;
  };
  const focusNode = (node) => {
    if (!isSave) {
      let selectedNode;
      let selectedNodeNum;
      const { nodeInternals } = store.getState();
      const nodes = Array.from(nodeInternals).map(([, node]) => node);
      if (nodes.length > 0) {
        const nodeIndex = nodes.findIndex(
          (selectedNode) => selectedNode.id === node.id
        );
        selectedNode = nodes[nodeIndex];
        const x = selectedNode.position.x + selectedNode.width / 2;
        const y = selectedNode.position.y + selectedNode.height / 2;
        document.querySelectorAll(".p-button").forEach(function (el, i) {
          if (el.attributes["aria-label"].value !== "Back") {
            el.style.display = "none";
          }
        });
        var tmp = selectedNode.id.split("_");
        tmp = tmp[0];

        document
          .querySelectorAll(".react-flow__node")
          .forEach(function (el, i) {
            if (el.attributes["data-id"].value == tmp) {
              el.querySelectorAll(".p-button").forEach(function (el, i) {
                el.style.display = "block";
              });
            }
          });
        const zoom = 1.3;
        setCenter(x, y, { zoom, duration: 1000 });
        selectedNodeNum = nodeIndex;
      }
      window.select_index = selectedNodeNum;
    }
  };
  const firstFocus = (node) => {
    let selectedNode;
    const { nodeInternals } = store.getState();
    const nodes = Array.from(nodeInternals).map(([, node]) => node);
    if (nodes.length > 0) {
      selectedNode = nodes[0];
      window.selectedNode = selectedNode;
      setFirstSelectNode(selectedNode);
      const x = selectedNode.position.x + selectedNode.width / 2;
      const y = selectedNode.position.y + selectedNode.height / 2;
      const zoom = 1.3;
      setCenter(x, y, { zoom, duration: 1000 });
    }
  };
  useEffect(()=>{
    if(allTextNodes.length>40){
      firstFocus()
    }
  },[allTextNodes])
  useEffect(() => {
    const timeout = setTimeout(() => {
      window.select_index = -1;
      focusNextNode();
    }, 1200);
    getFlowTranslationDetails(
      localStorage.getItem("lang_id"),
      localStorage.getItem("country_id"),
      routeParams.id
    ).then((response) => {
      setFlowTopic(response.title);
      const nodesFromService = response.data?.builder?.nodes;
      const edgesFromService = response.data?.builder?.edges;
      if (!nodesFromService || !edgesFromService) return;
      var reLink = [];

      nodesFromService.map((e, i) => {
        reLink[i] = { next: 0, prev: 0 };
        edgesFromService.map((e2, i2) => {
          if (e2.source == e.id) {
            reLink[i]["next"] = e2.target;
          }
          if (e2.target == e.id) {
            reLink[i]["prev"] = e2.source;
          }
        });
      });
      setNodes(
        nodesFromService.map((e, i) => {
          //console.log(e);
          const { style, ...nodeWithoutStyle } = e;

          return {
            topic_id: response.data.topic.id,
            ...nodeWithoutStyle,
            id: nodeWithoutStyle.id.toString(),
            width: 261,
            height: 198,
            className: "flow_" + e.id,
            style: { borderStyle: i < 3 ? "dashed" : "solid" },
            data: {
              //next_id:nodesFromService[i+1]!== undefined ? nodesFromService[i+1].id:0,
              //prev_id:nodesFromService[i-1]!== undefined ? nodesFromService[i-1].id:0,
              next_id: reLink[i].next !== undefined ? reLink[i].next : 0,
              prev_id: reLink[i].prev !== undefined ? reLink[i].prev : 0,
              //:nodesFromService[i-1].id,
              node_id: e.id,
              onClickNextNode: focusNextNode,
              onClickPreviousNode: focusPreviousNode,
              selectedNode: firstSelectNode,
              static: i < 3 || e.data.static,
              ...nodeWithoutStyle.data,
              topic_id: response.data.topic.id,
            },
          };
        })
      );
      setEdges(
        edgesFromService.map((e) => ({
          ...e,
          target: e.target.toString(),
          source: e.source.toString(),
          type: EDGE_TYPE_NAME,
          data: {},
        }))
      );
      setLoading(false);
    });
  }, []);

  const handleNodeClick = (event, node) => {
    /*    focusNode(node);
     handleFocusNode(node);*/
  };

  const leftContents = (
    <React.Fragment>
      <Button
        label="Back"
        icon="pi pi-arrow-circle-left"
        className="mr-2 p-button-secondary"
        style={{ marginRight: 10 }}
        onClick={() => {
          if (localStorage.getItem("isReview") === "false") {
            navigate("/translations/flow-translations", { state });
          } else if (localStorage.getItem("isReview") === "true") {
            navigate("/translations/translations-review", { state });
          }
          window.select_index = 0;
        }}
      />
      <div className="flow-viewer-title">
        {localStorage.getItem("country_name")} / {state?.selectedProduct.title}
      </div>
      <Button
        visible={false}
        label="Start"
        className="mr-2"
        style={{ marginRight: 10 }}
        onClick={() => {
          firstFocus();
        }}
      />
      {/*<Button
                tooltip="Drag and drop to add new Video node"
                tooltipOptions={{ position: "bottom", showDelay: 2500 }}
                label="Previous Node"
                icon="pi pi-arrow-left"
                iconPos="left"
                className="mr-2 node-drag-button"
                style={{ marginRight: 10 }}
                onClick={() => focusPreviousNode()}
            />
            <Button
                tooltip="Drag and drop to add new Video node"
                tooltipOptions={{ position: "bottom", showDelay: 2500 }}
                label="Next Node"
                icon="pi pi-arrow-right"
                iconPos="right"
                className="mr-2 node-drag-button"
                style={{ marginRight: 10 }}
                onClick={() => focusNextNode()}
            />*/}
    </React.Fragment>
  );
  const rightContents = (
    <React.Fragment>
      {!loading && (
        <div className="flow-viewer-title">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              {localStorage.getItem("isReview") === "false"
                ? "Translated Flow: "
                : "Approved Flow: "}
              {fullNode}
            </span>
            <span> Total: {totalNode}</span>
          </div>
          <span style={{ marginLeft: 20, fontSize: 48 }}>
            {Math.ceil(ratio)}%
          </span>
        </div>
      )}
    </React.Fragment>
  );
  return (
    <div className="flow-viewer" ref={reactFlowWrapper}>
      <Toolbar left={leftContents} right={rightContents} />
      <Dialog
        header="Translation Completed"
        visible={visible}
        style={{ width: "50vw" }}
        onHide={() => setVisible(false)}
        footer={footerContent}
      >
        <p className="m-0">
          Language translation complete. Please continue with other flows.
        </p>
      </Dialog>
      <div className="flow-translate-navigate">
        {allTextNodes?.map((node, index) => {
          const topOffset = index * 40;
          const isActive = activeNode?.id === node?.id;
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                position: "absolute",
                backgroundColor: "white",
                top: `${topOffset}px`,
              }}
              key={index}
            >
              {localStorage.getItem("isReview") === "true" ? (
                <span
                  style={
                    isActive
                      ? { border: "2px solid black" }
                      : { border: "none" }
                  }
                  className={node.data.is_approved === false ? "" : "done"}
                  onClick={() => {
                    focusNode(node);
                    handleFocusNode(node);
                  }}
                >
                  {node.data.message && node.data.message.substring(0, 25)}...
                </span>
              ) : (
                <span
                  style={
                    isActive
                      ? { border: "2px solid black" }
                      : { border: "none" }
                  }
                  className={
                    node.data.target_msg === "" || node.data.target_msg === null
                      ? ""
                      : "done"
                  }
                  onClick={() => {
                    focusNode(node);
                    handleFocusNode(node);
                  }}
                >
                  {node.data.message && node.data.message.substring(0, 25)}...
                </span>
              )}
            </div>
          );
        })}
      </div>

      <div className="flow-container" style={{ height: viewportHeight - 87 }}>
        <ReactFlow
          ref={chatContentRef}
          fitView
          maxZoom={1.4}
          minZoom={0.02}
          nodes={nodes}
          edges={edges}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          onNodeClick={handleNodeClick}
        >
          <Background variant="cross" color="#5b448f20" />
        </ReactFlow>
      </div>
      <Toast ref={toast} />
      {loading ? (
        <div className="flow-viewer-preloader">
          <ProgressSpinner />
        </div>
      ) : null}
      {updating ? (
        <div className="flow-viewer-updating">
          <div className="updating-text">
            Updates are saving on cloud.
            <ProgressSpinner
              style={{ width: "20px", height: "20px", marginLeft: 20 }}
              strokeWidth="8"
              fill="var(--surface-ground)"
              animationDuration=".5s"
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default () => (
  <ReactFlowProvider>
    <TranslationsFlowViewer />
  </ReactFlowProvider>
);
