import React, {memo, useEffect} from 'react';
import { Handle } from 'reactflow';
import './VideoNode.css';
import { FileUpload } from 'primereact/fileupload';
import { Button } from 'primereact/button';

import RemoveIcon from '../../../../assets/icons/remove.png';
import { useRef } from 'react';
import { useState } from 'react';
import { updateFlowNode } from '../../../../API/helper';

export default memo(({ data, isConnectable }) => {
  const inputFile = useRef(null);
  const [fileName, setFileName] = useState(null);
  const [tempThumbnail, setTempThumbnail] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSelectFile = (event) => {
    setLoading(true);
    setFileName(event.target.files[0].name);

    updateFlowNode(data.id, data.topic_id, '', 'video', event.target.files[0])
      .then((data) => {
        if (data.data.success) {
          setTempThumbnail(data.data.data.action_thumbnail);
        }
      })
      .catch((err) => {
        console.log('handleSelectFile/ERROR', err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Handle
        type="target"
        position="left"
        style={{ background: '#555' }}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />

      <div className="node-header">
        <div className="node-label">Video:</div>
        <div
          className="node-remove-button"
          onClick={() => data.deleteNodeItself()}
        >
          <img
            className="node-remove-button-icon"
            src={RemoveIcon}
            alt="Remove"
          />
        </div>
      </div>

      <div>
        <div className="video-node-body">
          {/* <div>{data.id}</div> */}

          {/* <video controls muted src={data.url} alt="node-image-preview" /> */}
          {data.action_value ? (
            // <img
            //   className="image-preview-image video-play-preview"
            //   src={tempThumbnail ? tempThumbnail : data.action_thumbnail}
            //   alt="node-preview"
            // />

            <div
              className="video-thumbnail"
              style={{
                  //backgroundImage:`url(https://staging-diginurse-media.s3.eu-central-1.amazonaws.com/456-g.jpg)`
                backgroundImage: tempThumbnail
                  ? `url(${tempThumbnail})`
                  : `url(${
                      tempThumbnail ? tempThumbnail : data?.action_thumbnail
                    })`,
              }}
            ></div>
          ) : (
            <>
              <img
                className="image-preview-image video-thumbnail"
                src="https://martialartspasadenantc.com/wp-content/uploads/sites/185/2015/12/video-placeholder.png"
                alt="node-preview"
              />
            </>
          )}

          <input
            ref={inputFile}
            type="file"
            id="imgupload"
            style={{ display: 'none' }}
            onChange={handleSelectFile}
            accept=".mp4, .mov, .avi"
          />
          <Button
            loading={loading}
            onClick={() => inputFile.current.click()}
            label={fileName ? fileName.substring(0, 14) : 'Upload Video'}
            icon="pi pi-cloud-upload"
            style={{
              width: '100%',
              marginTop: 20,
              maxWidth: 240,
            }}
          />
        </div>
      </div>
      <Handle
        type="source"
        position="right"
        id="b"
        isConnectable={isConnectable}
      />
    </>
  );
});
