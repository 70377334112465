import React, {useEffect, useRef, useState} from 'react';
import {changePollStatus, getGeneralList, pollsAddCheck} from "../../API/helper";
import {Card} from "primereact/card";
import {Dropdown} from "primereact/dropdown";
import {Button} from "primereact/button";
import {Checkbox} from "primereact/checkbox";
import {Toast} from "primereact/toast";
import {Dialog} from "primereact/dialog";
import './PollsNutritionMethods.css'
import {Calendar} from "primereact/calendar";
import {InputText} from "primereact/inputtext";
import {format} from "date-fns";
const PollsNutritionMethods = ({setActiveItem,activeItem,pollValue,setPollValue,setMethod,setSelectedDates,method,type,setType,dates,setDates,state,selectedPosition,setSelectedPosition}) => {
    const [response,setResponse]=useState(null)

    const toast = useRef(null);
    const position = [
        { name: 'After', code: 'After' },
        { name: 'Before', code: 'Before' },
    ];

    const onTypeChange = (e) => {
        let _ingredients = [...type];

        if (e.checked)
            _ingredients.push(e.value);
        else
            _ingredients.splice(_ingredients.indexOf(e.value), 1);

        setType(_ingredients);
    }
    const handleNext = () =>{
        pollCheck()
    }
   /* const changeStatus = async () => {
        await changePollStatus(response?.list.id,0).then((response) =>{
                if(response.success){
                    toast.current.show({
                        severity: "success",
                        summary: "Successful",
                        detail: response.message,
                        life: 3000,
                    })
                    setVisible(false)
                    setTimeout(() => {
                        setActiveItem(activeItem+1)
                    }, 1000);
                }
                else{
                    toast.current.show({
                        severity: "error",
                        summary: "Error",
                        detail: response.message,
                        life: 3000,
                    })
                    setResponse(response)
                    setVisible(true)
                }

            }

        );
    };*/

    const pollCheck = async () => {
        await pollsAddCheck(method,type,selectedPosition,"now","","",state,false).then((response) =>{
                if(response.success){
                    toast.current.show({
                        severity: "success",
                        summary: "Successful",
                        detail: response.message,
                        life: 3000,
                    })
                         setActiveItem(activeItem+1)

                }
                else{
                    let disabledDateRanges = response?.list.map(data => {
                            const startDate = new Date(data.start_date);
                            const endDate = new Date(data.end_date);
                            return { startDate, endDate };

                        });
                    const disabledDates = disabledDateRanges.reduce((dates, range) => {
                        const { startDate, endDate } = range;
                        const currentDate = new Date(startDate);
                        while (currentDate <= endDate) {
                            dates.push(new Date(currentDate));
                            currentDate.setDate(currentDate.getDate() + 1);
                        }
                        return dates;
                    }, []);
                    setSelectedDates(disabledDates)
                    setTimeout(() => {
                        setActiveItem(activeItem+1)
                    }, 1000);
                    setResponse(response)
                }
            }
        );
    };

    const onIngredientsChange = (e) => {
        let _ingredients = [...method];
        if (e.checked)
            _ingredients.push(e.value);
        else
            _ingredients.splice(_ingredients.indexOf(e.value), 1);
        setMethod(_ingredients);
    }
    const capitalize=(str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    return (
        <div className="nutrition-method-div">
            <Card className="nutrition-method-card">
                <Toast ref={toast} />
                <div className="nutrition-method-body" >
                    <div className="nutrition-method-title" style={{width:"100%"}}>
                        Please select nutrition type, method and poll postion to create polls in it.
                    </div>

                    <div style={{display:"flex",width:"100%",justifyContent:"center",gap:50}}>
                        <div className="admin-info-wrapper" style={{width:"30%"}}>
                            <span>Name:</span>
                            <InputText placeholder="Please Enter Poll Name" value={pollValue?.name} onChange={(e) => setPollValue({...pollValue,name:e.target.value})} />
                        </div>
                        <div className="admin-info-wrapper" style={{width:"30%"}}>
                            <span>Poll Position:</span>
                            <Dropdown
                                style={{ width: "100%" }}
                                placeholder="Select Position"
                                options={position}
                                optionLabel="name"
                                value={selectedPosition}
                                onChange={(e) => setSelectedPosition(e.value)}
                            />
                        </div>
                    </div>

                    <div className="radiobutton-wrapper" style={{marginTop:50}}>
                        <div style={{display:"flex",flexDirection:"column",gap:20}}>
                            <div style={{textDecoration:"underline",fontWeight:"bold"}}>Nutrition Methods</div>
                            <div className="radio-div-wrapper">
                                <div className="flex align-items-center">
                                    <Checkbox disabled={state?.create ? false : true} inputId="ingredient1" name="is_peg" value="1" onChange={onIngredientsChange} checked={state.create===false ? method.some(item=>item.title.includes("PEG")): method.includes('1')} />
                                    <label htmlFor="ingredient1" style={{marginLeft:5}} >Peg</label>
                                </div>
                                <div className="flex align-items-center">
                                    <Checkbox disabled={state?.create ? false : true} inputId="ingredient3" name="is_jeju" value="2" onChange={onIngredientsChange} checked={state.create===false ? method.some(item=>item.title.includes("JEJUNOSTOMY")): method.includes('2')} />
                                    <label htmlFor="ingredient3" style={{marginLeft:5}}>Jejunostomy</label>
                                </div>
                                <div className="flex align-items-center">
                                    <Checkbox disabled={state?.create ? false : true} inputId="ingredient2" name="is_nasal" value="3" onChange={onIngredientsChange} checked={state.create===false ? method.some(item=>item.title.includes("NASAL")): method.includes('3')} />
                                    <label htmlFor="ingredient2" style={{marginLeft:5}}>Nasal</label>
                                </div>
                            </div>
                        </div>
                        <div style={{display:"flex",flexDirection:"column",gap:20}}>
                            <div style={{textDecoration:"underline",fontWeight:"bold"}}>Nutrition Types</div>
                            <div className="radio-div-wrapper">
                                <div className="flex align-items-center">
                                    <Checkbox disabled={state?.create ? false : true} inputId="ingredient4" name="is_injection" value="1" onChange={onTypeChange} checked={state.create===false ? type.some(item=>item.title.includes("Injection")): type.includes('1')} />
                                    <label htmlFor="ingredient4" style={{marginLeft:5}} >Injection</label>
                                </div>
                                <div className="flex align-items-center">
                                    <Checkbox disabled={state?.create ? false : true} inputId="ingredient5" name="is_gravity" value="2" onChange={onTypeChange} checked={state.create===false ? type.some(item=>item.title.includes("Gravity")): type.includes('2')} />
                                    <label htmlFor="ingredient5" style={{marginLeft:5}}>Gravity</label>
                                </div>
                                <div className="flex align-items-center">
                                    <Checkbox disabled={state?.create ? false : true} inputId="ingredient6" name="is_pump" value="3" onChange={onTypeChange} checked={state.create===false ? type.some(item=>item.title.includes("Pump")): type.includes('3')}/>
                                    <label htmlFor="ingredient6" style={{marginLeft:5}}>Pump</label>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="nutrition-method-button-div">

                    <Button className="p-button-info" disabled={!(method.length>0 && type.length>0 && selectedPosition!==null && pollValue?.name?.replaceAll(" ", "") !== "")} onClick={()=>handleNext()}>Next</Button>
                </div>
            </Card>

        </div>
    );
};

export default PollsNutritionMethods;