import React, { useEffect, useRef } from "react";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { AutoComplete } from "primereact/autocomplete";

const ProductFeatureDetails = ({
  setActiveItem,
  activeItem,
  companies,
  setProductName,
  productName,
  setFilteredCompanies,
  selectedCompany,
  filteredCompanies,
  setSelectedCompany,
  productValue,
  setProductValue,
  selectedUnits,
  setSelectedUnits,
  units,
}) => {
  const toast = useRef(null);
  const handleNext = () => {
    setActiveItem(activeItem + 1);
  };

  console.log(selectedUnits, "selectedUnits");
  const search = (event) => {
    setTimeout(() => {
      let _filteredProducts;

      if (!event.query.trim().length) {
        _filteredProducts = [...companies];
      } else {
        _filteredProducts = companies.filter((product) => {
          return product.title
            .toLowerCase()
            .startsWith(event.query.toLowerCase());
        });
      }
      setFilteredCompanies(_filteredProducts);
    }, 250);
  };

  return (
    <div className="nutrition-method-div">
      <Card className="nutrition-method-card">
        <div className="nutrition-method-title">
          Please enter company name, product name, product value and unit to
          create polls in it.
        </div>

        <div className="combobox-wrapper" style={{ marginTop: 20 }}>
          <div className="product-value-wrapper" style={{ width: "73%" }}>
            <div className="admin-info-wrapper" style={{ width: "100%" }}>
              <span>Product Name:</span>
              <InputText
                style={{ width: "100%" }}
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            marginTop: 20,
            justifyContent: "center",
            gap: 50,
          }}
        >
          <div className="admin-info-wrapper" style={{ width: "73%" }}>
            <span>Company Name:</span>
            <AutoComplete
              field="title"
              dropdown
              value={selectedCompany}
              suggestions={filteredCompanies}
              completeMethod={search}
              onChange={(e) => setSelectedCompany(e.value)}
            />
          </div>
        </div>
        <div className="combobox-wrapper" style={{ marginTop: 20 }}>
          <div className="product-value-wrapper">
            <div className="admin-info-wrapper" style={{ width: "100%" }}>
              <span>Product Value:</span>
              <InputNumber
                style={{ width: "100%" }}
                placeholder="Please enter only numbers."
                value={productValue}
                onValueChange={(e) => setProductValue(e.value)}
              />
            </div>
          </div>
          <div className="product-value-wrapper">
            <div className="admin-info-wrapper" style={{ width: "100%" }}>
              <span>Product Unit:</span>
              <Dropdown
                value={selectedUnits}
                onChange={(e) => setSelectedUnits(e.value)}
                options={units}
                optionValue="name"
                optionLabel="name"
              />
            </div>
          </div>
        </div>
        <div className="poll-question-div">
          <Button
            className="p-button-info"
            onClick={() => setActiveItem(activeItem - 1)}
          >
            Back
          </Button>
          <Button
            className="p-button-info"
            disabled={
              !(
                productValue !== undefined &&
                productName !== "" &&
                selectedUnits !== null &&
                selectedCompany !== null
              )
            }
            onClick={() => handleNext()}
          >
            Next
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default ProductFeatureDetails;
