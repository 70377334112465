import { Route, Routes, useLocation, useSearchParams } from "react-router-dom";
import "./App.css";
import store from "./redux/store";
import { Provider } from "react-redux";
import { useEffect, useState } from "react";
import Flows from "./pages/flows/Flows";
import General from "./pages/general/General";
import Logs from "./pages/logs/Logs";
import Settings from "./pages/owner/Settings";
import MobileUsers from "./pages/mobile-users/MobileUsers";
import MasterDataset from "./pages/nlp-management/master-dataset/Master-dataset";
import SideBar from "./components/sidebar/Sidebar";
import Login from "./pages/login/Login";
import Cbu from "./pages/CBU/Cbu";
import FlowViewer from "./pages/flows/flow-viewer/FlowViewer";
import AddFlow from "./pages/add-flow/Add-flow";
import Dashboard from "./pages/dashboard/Dashboard";
import SelectedUser from "./pages/selected-user/selected-user";
import ValidationQue from "./pages/nlp-management/validation-que/Validation-que";
import TrainingJobHistory from "./pages/nlp-management/traning-job-history/Training-job-history";
import CasesLabels from "./pages/nlp-management/cases-labels/Cases-labels";
import CasesSentences from "./pages/nlp-management/cases-sentences/CasesSentences";
import Translations from "./pages/translations/Translations";
import TranslationsFlowViewer from "./pages/translations/translations-flow-viewer/TranslationsFlowViewer";
import TranslationsSelection from "./pages/translations/Translations-Selection";
import Admins from "./pages/admins/admins";
import AdminDetail from "./pages/admins/adminDetail/adminDetail";
import Country from "./pages/countries/country";
import CountryDetail from "./pages/countries/countryDetail/countryDetail";
import Languages from "./pages/languages/languages";
import LanguageDetail from "./pages/languages/languageDetail/languageDetail";
import Polls from "./pages/polls/Polls";
import PollsDetails from "./pages/polls/PollsDetails/PollsDetails";
import PollsDetailList from "./pages/polls/PollsDetailList/PollsDetailList";
import Customers from "./pages/customers/Customers";
import CustomerDetail from "./pages/customers/customerDetail/CustomerDetail";
import TranslationsViewSelection from "./pages/translations/Translations-View-Selection";
import TranslationsReview from "./pages/translations/Translations-Review";
import WellbeingPolls from "./pages/polls/WellbeingPolls/WellbeingPolls";
import ProductPolls from "./pages/polls/ProductPolls/ProductPolls";
import ProductPollDetails from "./pages/polls/ProductPollDetails/ProductPollDetails";
import WellbeingPollsDetail from "./pages/polls/WellbeingPollsDetails/WellbeingPollsDetail";
import WellbeingPollUpdate from "./pages/polls/WellbeingPollUpdate/WellbeingPollUpdate";
import ProductPollDetailList from "./pages/polls/ProductPollDetailList/ProductPollDetailList";
import WellBeingPollsListDetail from "./pages/polls/WellBeingPollsListDetail/WellBeingPollsListDetail";

const App = () => {
  const [login, setLogin] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [languageCheck, setLanguageCheck] = useState(false);
  const [country, setCountry] = useState("");
  const [NLP, setNLP] = useState("");
  const [errorMessage, setErrorMessage] = useState();
  const location = useLocation();

  const { pathname } = location;

  useEffect(() => {
    setLogin(localStorage.getItem("login"));
  }, []);
  useEffect(() => {
    if (!pathname.startsWith("/flows")) {
      sessionStorage.removeItem("pageOptions");
      sessionStorage.removeItem("rowOption");
    }
  }, [pathname]);
  useEffect(() => {
    setLanguageCheck(localStorage.getItem("language"));
  }, []);
  const isLogin = (result) => {
    if (result.success) {
      localStorage.setItem("token", result.data.token);
      localStorage.setItem("login", true);
      setLogin(localStorage.getItem("login"));
    } else {
      setErrorMessage(result.messages[0]);
    }
  };
  //Login Check
  if (!login) return <Login isLogin={isLogin} />;

  const handleSidebarHover = (hover) => {
    setIsHover(hover);
  };
  const languageRefresh = (language) => {
    setLanguageCheck(language);
  };
  const languageSelect = (language) => {
    setLanguageCheck(language);
  };
  const handleCbuUpdate = (country, NLP) => {
    setCountry(country);
    setNLP(NLP);
  };

  if (login && languageCheck)
    return <Cbu onLanguageSelect={languageSelect} onSelect={handleCbuUpdate} />;

  return (
    <Provider store={store}>
      <SideBar
        onHover={handleSidebarHover}
        languageRefresh={languageRefresh}
        country={{
          name: "Australia",
        }}
        NLP={{
          name: ["English"],
        }}
      />
      <div
        className="layout-container"
        style={{ marginLeft: isHover ? "14.5rem" : "6rem" }}
      >
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/flows" element={<Flows />} />
          <Route
            path="/translations/translations-selection"
            element={<TranslationsSelection />}
          />
          <Route
            path="/translations/flow-translations"
            element={<Translations />}
          />
          <Route path="/nlp" element={<MasterDataset />} />
          <Route path="/general" element={<General />} />
          <Route path="/logs" element={<Logs />} />
          <Route path="/users" element={<MobileUsers />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/nlp/validation-que" element={<ValidationQue />} />
          <Route path="/admins" element={<Admins />} />
          <Route path="/polls" element={<Polls />} />
          <Route path="/polls/wellbeing-polls" element={<WellbeingPolls />} />
          <Route path="/polls/product-polls" element={<ProductPolls />} />
          <Route
            path="/polls/product-polls-details"
            element={<ProductPollDetails />}
          />
          <Route
            path="/polls/wellbeing-polls-create"
            element={<WellbeingPollsDetail />}
          />
          <Route
            path="/polls/wellbeing-polls-detail"
            element={<WellbeingPollUpdate />}
          />
          <Route path="/polls/details" element={<PollsDetails />} />
          <Route path="/polls/details/:id" element={<PollsDetailList />} />
          <Route
            path="/polls/product-polls/:id"
            element={<ProductPollDetailList />}
          />
          <Route
            path="/polls/wellbeing-polls/:id"
            element={<WellBeingPollsListDetail />}
          />
          <Route path="/admins/details" element={<AdminDetail />} />
          <Route path="/countries" element={<Country />} />
          <Route path="/countries/details" element={<CountryDetail />} />
          <Route path="/customers" element={<Customers />} />
          <Route path="/customers/details" element={<CustomerDetail />} />
          <Route path="/languages" element={<Languages />} />
          <Route path="/languages/details" element={<LanguageDetail />} />
          <Route
            path="nlp/training-jobs-history"
            element={<TrainingJobHistory />}
          />
          <Route path="/nlp/cases-labels" element={<CasesLabels />} />
          <Route
            path="/translations/flow-translations/:id"
            element={<TranslationsFlowViewer />}
          />
          <Route
            path="/translations/flow-translations-review/:id"
            element={<TranslationsFlowViewer />}
          />
          <Route path="/flows/:id" element={<FlowViewer />} />
          <Route path="/flows/add" element={<AddFlow />} />
          <Route path="/selected-user" element={<SelectedUser />} />
          <Route path="/nlp/cases-sentences" element={<CasesSentences />} />
          <Route
            path="/translations/translations-selection-review"
            element={<TranslationsViewSelection />}
          />
          <Route
            path="/translations/translations-review"
            element={<TranslationsReview />}
          />
        </Routes>
      </div>
    </Provider>
  );
};

export default App;
