import { configureStore } from "@reduxjs/toolkit"
import { combineReducers } from "@reduxjs/toolkit"
import navbarReducer from "./slices/navbarslice"

import thunk from "redux-thunk"
import { persistReducer, persistStore } from "redux-persist"
import storage from "redux-persist/lib/storage"


const persistConfig = {
    key: "root",
    storage,
    blacklist: ["navbar", "loading"],
}

const reducers = combineReducers({
    navbar: navbarReducer,

})

const persistedReducer = persistReducer(persistConfig, reducers)
const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk],
})
let persistor = persistStore(store)
export default store
export { persistor }
