import React, {useRef, useState} from 'react';
import { Steps } from 'primereact/steps';
import { Toast } from 'primereact/toast';
import './Add-flow.css'
import {useLocation, useNavigate} from "react-router-dom";
import NutritionMethod from "../../components/Nutrition-Method/Nutrition-method";
import LabelDetermination from "../../components/Label-determination/Label-determination";
import Header from "../../components/header/Header";
import Overview from "../../components/Overview&Create-Flow/Overview";
import LabelSelection from "../../components/Label-Selection/Label-selection";
import InitialSentence from "../../components/Initial-Sentence/Initial-Sentence";
const AddFlow = () => {
    const navigate = useNavigate();
    const [activeIndex, setActiveIndex] = useState(0);
    const [nutritionApplicationMethod, setNutritionApplicationMethod] =
        useState(null);
    const [nutritionMethod, setNutritionMethod] = useState(null);
    const [caseLabel,setCaseLabel]=useState([])
    const [questionCaseLabel,setQuestionCaseLabel]=useState([])
    const [finalLabel,setFinalLabel]=useState({})
    const [sentenceList,setSentenceList]=useState([])
    const [initialSentence,setInitialSentence]=useState("")
    const [loading, setLoading] = useState(true);
    const [firstSentence,setFirstSentence]=useState("")
    const [startMessage,setStartMessage]=useState("")
    const [isBack,setIsBack]=useState(false)



    const toast = useRef(null);
    const selectedMethods = (nutritionMethod,nutritionApplicationMethod) =>{
        nutritionMethod !==null && setNutritionMethod(nutritionMethod)
        nutritionApplicationMethod !== null && setNutritionApplicationMethod(nutritionApplicationMethod)
    }
    const selectedCaseLabel = (caseLabel) =>{
        setCaseLabel(caseLabel)
    }
    const getQuestionCaseLabel = (caseLabel) => {
        setQuestionCaseLabel(caseLabel)
    }
    const selectedCase = (caseLabel) => {
        setFinalLabel(caseLabel)
    }
    const steps = [
        <div className="under-page"><NutritionMethod activeItem={activeIndex} setActiveItem={setActiveIndex} selectedMethods={selectedMethods}/></div>,
        <div className="under-page"><LabelDetermination activeItem={activeIndex} firstSentence={firstSentence} setFirstSentence={setFirstSentence} getQuestionCaseLabel={getQuestionCaseLabel} setActiveItem={setActiveIndex} selectedCaseLabel={selectedCaseLabel} setLoading={setLoading} sentenceList={sentenceList} setSentenceList={setSentenceList} isBack={isBack} setIsBack={setIsBack} /></div>,
        <div className="under-page"><LabelSelection activeItem={activeIndex} setActiveItem={setActiveIndex} allCaseLabels={questionCaseLabel} selectedCaseLabels={selectedCase} loading={loading} setLoading={setLoading} setIsBack={setIsBack}/></div>,
        <div className="under-page"><InitialSentence startMessage={startMessage} setStartMessage={setStartMessage} activeItem={activeIndex} setActiveItem={setActiveIndex} /></div>,
        <div className="under-page"><Overview startMessage={startMessage} activeItem={activeIndex} setActiveItem={setActiveIndex} nutritionMethod={nutritionMethod} nutritionApplicationMethod={nutritionApplicationMethod} caseLabel={finalLabel} initialSentence={initialSentence}/></div>,
    ]

    const items = [
        {
            label: 'Nutrition Methods',
        },
        {
            label: 'Label Determination',
        },
        {
            label: 'Label Selection',
        },
        {
            label: 'Initial Sentence',
        },
        {
            label: 'Overview & Create Flow',
        }
    ];
    return (
        <div className="add-flow-main">
            <Header items={[{ label: 'Flows', url: '/Flows' },{ label: 'Create New Flow' }]} />
            <div className="flow-wrapper">
                <Toast ref={toast}></Toast>
                <div className="steps-div">
                    <Steps model={items} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={true} />
                </div>
                {steps.map((item,index)=>activeIndex===index ? item : <div></div>)}
            </div>
        </div>
    );
};

export default AddFlow;
