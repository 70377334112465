import React, {useEffect} from 'react';
import './Chat-history-viewer.css'
import user1 from '../../assets/images/user-1.png'
import user2 from '../../assets/images/user-2.png'
import {Image} from "primereact/image";
import profilePicture from "../../assets/images/profile-picture.jpeg";


const ChatHistoryViewer = (chvData) => {
    useEffect(() => {
        const elements = document.querySelectorAll("#startOver");
        if (elements.length > 0) {
            elements[0].addEventListener("click", function (e) {
                e.preventDefault();
            });
        }
    }, []);
    return (
        <div className="chat-history-viewer">
            <div className="chv-wrapper">
                <div className="chv-information-wrapper">
                    <span><span className="title-span">Session ID: </span>{chvData.chvData.data.list.session.session_id}</span>
                    <span><span className="title-span">Session Start Date: </span>{chvData.chvData.data.list.session.start_date}</span>
                </div>
                {chvData.chvData.data?.list.chat_logs.map((message,index)=>{
                    return (
                        <div key={index} className={`chv-message ${message.type}`}>
                            <img src={message.type ==="user" ? user1 : user2} className="chv-avatar"/>
                            <div className="chv-body" style={message.action ==="question_video" || message.action ==="question_image" ? {padding:5}:{}}>
                                {message.action ==="label" ? window.nlp_labels.find((e)=> e.title === message.message).description :
                                    message.action ==="question_image" ? <Image src={message.message} alt="Image" width="250" preview/>
                                        : message.action ==="question_video"? <video width="250" controls>
                                            <source src={message.message} type="video/mp4"></source>
                                        </video>: <div
                                            dangerouslySetInnerHTML={{__html: message.message}}
                                        className="chat-log-message-body"/>}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    );
};

export default ChatHistoryViewer;