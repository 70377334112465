import React, {useEffect, useState} from 'react';
import './Chart-Horizontal.css';
import {Card} from "primereact/card";
import {Chart} from "primereact/chart";

const ChartHorizontal = ({title,month,userTrafficData}) => {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    let groupedItems = {};
    const groupItemsByMonth = (data, months) => {
        const result = {};

        months.forEach((month) => {
            const items = data[month];
            if (items) {
                items.forEach((item) => {
                    const { item_title, item_value } = item;
                    if (!result[item_title]) {
                        result[item_title] = Array(months.length).fill(0);
                    }
                    const index = months.indexOf(month);
                    result[item_title][index] += item_value;
                });
            }
        });

        return result;
    };
    let otherChartData = []
    useEffect(()=>{
        if(userTrafficData){
            groupedItems = groupItemsByMonth(userTrafficData, month);

        }
    },[userTrafficData])

    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
        const data = {
            labels: month,
            datasets: [
                {
                    label: 'Chrome',
                    backgroundColor: documentStyle.getPropertyValue('--blue-500'),
                    borderColor: documentStyle.getPropertyValue('--blue-500'),
                    data: groupedItems["Chrome"]
                },
                {
                    label: 'Edge',
                    backgroundColor: documentStyle.getPropertyValue('--yellow-500'),
                    borderColor: documentStyle.getPropertyValue('--yellow-500'),
                    data: groupedItems["Edge"]
                },
                {
                    label: 'Opera',
                    backgroundColor: documentStyle.getPropertyValue('--red-500'),
                    borderColor: documentStyle.getPropertyValue('--red-500'),
                    data: groupedItems["Opera"]
                },
                {
                    label: 'Other',
                    backgroundColor: documentStyle.getPropertyValue('--green-500'),
                    borderColor: documentStyle.getPropertyValue('--green-500'),
                    data: groupedItems["0"]
                }
            ]
        };
        const options = {
            indexAxis: 'y',
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                legend: {
                    labels: {
                        fontColor: textColor
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: textColorSecondary,
                        font: {
                            weight: 500
                        }
                    },
                    grid: {
                        display: false,
                        drawBorder: false
                    }
                },
                y: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder,
                        drawBorder: false
                    }
                }
            }
        };

        setChartData(data)
        setChartOptions(options);
    }, [month,userTrafficData]);


    return (
        <Card className="chart-card"  title={title}>
            <Chart type="bar" data={chartData} options={chartOptions} />
        </Card>
    );
};

export default ChartHorizontal;
