import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Toast } from "primereact/toast";
import { createProductPoll, editProductPoll } from "../../API/helper";

const OverViewProductPoll = ({
  setActiveItem,
  activeItem,
  type,
  method,
  productValue,
  companyName,
  productName,
  unit,
  state,
}) => {
  const toast = useRef(null);
  const [newNutTypes, setNewNutTypes] = useState([]);
  const [newNutMethods, setNewNutMethods] = useState([]);
  const navigate = useNavigate();
  const nutMethods = [
    {
      title: "PEG",
      id: 1,
    },
    {
      title: "JEJUNOSTOMY",
      id: 2,
    },
    {
      title: "NASAL",
      id: 3,
    },
  ];
  const saveProductPoll = async () => {
    await createProductPoll(
      method,
      type,
      productName,
      companyName,
      productValue,
      unit
    ).then((response) => {
      if (response.success) {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: response.message,
          life: 3000,
        });
        setTimeout(() => {
          navigate("/polls/product-polls");
        }, 1000);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: response.message,
          life: 3000,
        });
      }
    });
  };
  const editProduct = async () => {
    let methodID = [];
    let typeID = [];
    method?.forEach((mt) => methodID.push(mt.id));
    type?.forEach((typ) => typeID.push(typ.id));

    await editProductPoll(
      methodID,
      typeID,
      productName,
      companyName,
      productValue,
      unit,
      state?.id
    ).then((response) => {
      if (response.success) {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: response.message,
          life: 3000,
        });
        setTimeout(() => {
          navigate("/polls/product-polls");
        }, 1000);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: response.message,
          life: 3000,
        });
      }
    });
  };
  const nutTypes = [
    {
      title: "Injection",
      id: 1,
    },
    {
      title: "Gravity",
      id: 2,
    },
    {
      title: "Pump",
      id: 3,
    },
  ];
  const onSubmit = () => {
    if (state?.create) {
      saveProductPoll();
    } else {
      editProduct();
    }
  };
  console.log(unit);
  useEffect(() => {
    const transformedArray = type.map((id) => {
      const matchingItem = nutTypes.find((item) => item.id === parseInt(id));
      return matchingItem ? matchingItem : null;
    });
    const transformedMethodArray = method.map((id) => {
      const matchingItem = nutMethods.find((item) => item.id === parseInt(id));
      return matchingItem ? matchingItem : null;
    });
    setNewNutTypes(transformedArray);
    setNewNutMethods(transformedMethodArray);
  }, [type, method]);

  return (
    <div className="nutrition-method-div">
      <Card className="nutrition-method-card">
        <div className="overview-body">
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="nutrition-method-title">
              Please review all product detail before generating.
            </div>
          </div>

          <Toast ref={toast}></Toast>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: 300,
            }}
          >
            <div className="spans-wrapper">
              <div className="step-wrapper">
                {state.create === false ? (
                  <div>
                    <div className="spans-div" style={{ gap: 3 }}>
                      <span className="overview-span">Nutrition types :</span>
                      {type?.map((typ, i) => {
                        return type.length - 1 !== i ? (
                          <span style={{ fontWeight: "normal" }}>
                            {typ.title.toUpperCase()} /
                          </span>
                        ) : (
                          <span style={{ fontWeight: "normal" }}>
                            {typ.title.toUpperCase()}{" "}
                          </span>
                        );
                      })}
                    </div>
                    <div className="spans-div" style={{ gap: 3 }}>
                      <span className="overview-span">Nutrition methods :</span>
                      {method?.map((mt, i) => {
                        return method.length - 1 !== i ? (
                          <span style={{ fontWeight: "normal" }}>
                            {mt.title.toUpperCase()} /
                          </span>
                        ) : (
                          <span style={{ fontWeight: "normal" }}>
                            {mt.title.toUpperCase()}{" "}
                          </span>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="spans-div" style={{ gap: 3 }}>
                      <span className="overview-span">Nutrition types :</span>
                      {newNutTypes?.map((typ, i) => {
                        return type.length - 1 !== i ? (
                          <span style={{ fontWeight: "normal" }}>
                            {typ.title.toUpperCase()} /
                          </span>
                        ) : (
                          <span style={{ fontWeight: "normal" }}>
                            {typ.title.toUpperCase()}{" "}
                          </span>
                        );
                      })}
                    </div>
                    <div className="spans-div" style={{ gap: 3 }}>
                      <span className="overview-span">Nutrition methods :</span>
                      {newNutMethods?.map((mt, i) => {
                        return method.length - 1 !== i ? (
                          <span style={{ fontWeight: "normal" }}>
                            {mt.title.toUpperCase()} /
                          </span>
                        ) : (
                          <span style={{ fontWeight: "normal" }}>
                            {mt.title.toUpperCase()}{" "}
                          </span>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
              <div className="step-wrapper">
                {typeof companyName === "string" ? (
                  <div className="spans-div">
                    <span className="overview-span">Company Name:</span>
                    <span style={{ fontWeight: "normal" }}>{companyName}</span>
                  </div>
                ) : (
                  <div className="spans-div">
                    <span className="overview-span">Company Name:</span>
                    <span style={{ fontWeight: "normal" }}>
                      {companyName.title}
                    </span>
                  </div>
                )}
                <div className="spans-div">
                  <span className="overview-span">Product Name:</span>
                  <span style={{ fontWeight: "normal" }}>{productName}</span>
                </div>
                <div className="spans-div">
                  <span className="overview-span">Product Value:</span>
                  <span style={{ fontWeight: "normal" }}>{productValue}</span>
                </div>
                <div className="spans-div">
                  <span className="overview-span">Product Unit:</span>
                  <span style={{ fontWeight: "normal" }}>{unit}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="poll-question-div">
          <Button
            className="p-button-info"
            onClick={() => setActiveItem(activeItem - 1)}
          >
            Back
          </Button>
          <Button className="p-button-info" onClick={() => onSubmit()}>
            {state.create ? "Create" : "Edit"}
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default OverViewProductPoll;
