const generateNodes = (data) => {
  let initialNodes = [];

  const nodeList = data.drawflow.Home.data;

  for (const nodeKey in nodeList) {
    const newNode = {
      data: {},
      style: { border: "1px solid #777", padding: 10 },
    };
    const old_node = nodeList[nodeKey];

    newNode.position = {
      x: Math.trunc(old_node.pos_x / 1.5),
      y: Math.trunc(old_node.pos_y),
    };

    switch (old_node.data.input_type) {
      case "textarea":
        newNode.id = nodeKey;
        newNode.type = "questionNode";

        newNode.data.message = old_node.data.message;
        break;
      case "option":
        newNode.id = nodeKey;
        newNode.type = "optionNode";
        newNode.data.value = old_node.data.title;

        break;

      default:
        break;
    }
    initialNodes.push(newNode);
  }

  return initialNodes;
};

const generateEdges = (a) => {
  const nodes = a.drawflow.Home.data;
  let arr = [];

  Object.keys(nodes).forEach((nodeKey) => {
    const node = nodes[nodeKey];

    Object.values(node.outputs)
      .filter((e) => e.connections.length !== 0)
      .forEach((element) => {
        element.connections.forEach((e) => {
          const id = "e" + nodeKey + "-" + e.node;
          const source = nodeKey;
          const target = e.node;
          arr.push({ id, source, target });
        });
      });
  });

  return arr;
};

const packEdgesAndNodes = (nodes, edges) => {
  if (!edges) return;

  const nodesWithTargets = nodes.map((node) => {
    node.targets = [];
    edges.forEach((edge) => {
      if (edge.source === node.id) {
        node.targets.push(edge.target);
      }
    });
    return node;
  });
  const dataForService = {
    nodes: nodesWithTargets,
    edges: edges,
  };

  return dataForService;
};

const validateNodesOnConnect = (sourceType, targetType) => {
  if (sourceType === "option" && targetType === "option") {
    return false;
  }

  return true;
};
const validateNodesOnConnectByText = (
  sourceType,
  targetType,
  sourceId,
  nodesFromData
) => {
  let checkOption = nodesFromData.filter(
    (x) => x.id?.toString().includes(sourceId) && x.type === "option"
  );

  if (
    sourceType === "text" &&
    targetType === "text" &&
    checkOption.length > 0
  ) {
    return false;
  }

  return true;
};

export {
  generateNodes,
  generateEdges,
  packEdgesAndNodes,
  validateNodesOnConnect,
  validateNodesOnConnectByText,
};
