import React, {useEffect, useState} from 'react';
import {InputTextarea} from "primereact/inputtextarea";
import {Button} from "primereact/button";
import {ListBox} from "primereact/listbox";
import {Card} from "primereact/card";
import "./Label-selection.css"
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import { ProgressBar } from 'primereact/progressbar';
import Lottie from 'react-lottie-player'
import nlpAnimation from '../../assets/nlpAnimation.json'

const LabelSelection = ({setActiveItem,activeItem,allCaseLabels,selectedCaseLabels,loading,setLoading,setIsBack}) => {
    const [selectedDetermination, setSelectedDetermination] = useState(null);
    const [display,setDisplay]=useState("flex")
    const [selectedCase, setSelectedCase] = useState(null);
    const [products, setProducts] = useState([]);



    useEffect(()=>{
        selectedCaseLabels(selectedCase)
    },[selectedCase])


    useEffect(()=>{
        setProducts(allCaseLabels)
    },[allCaseLabels])
    const handleBack = () =>{
        setProducts([])
        setActiveItem(activeItem-1)
        setIsBack(true)
    }
    return (
        <div className="label-selection-div">
            <Card className="label-selection-card">
                <div className="label-selection-body">
                    <div className="label-selection-title">

                    </div>
                    <ProgressBar mode="indeterminate" style={{ height: '6px' , display:loading ? "block" : "none"}} ></ProgressBar>
                    <div className="label-selection-title">
                        {loading ? "Please wait. Your sentences are being analyzed." : "We have analysed your sentence and found best case labels below.Please select best one to describe your flow."  }
                    </div>
                    <div style={{display:display,flexDirection:"column",marginTop:50,gap:20,alignItems:"center", width:'100%', justifyContent:"center"}}>
                        {loading ? <Lottie
                                loop
                                animationData={nlpAnimation}
                                play
                                style={{ width: 300, height: 300 }}
                            /> :
                            <DataTable value={products} selectionMode="single" selection={selectedCase} onSelectionChange={e => setSelectedCase(e.value)} dataKey="id" responsiveLayout="scroll">
                                <Column field="code" header="Case Label"></Column>
                                <Column field="name_format" header="Prediction Rate"></Column>
                                <Column field="flow_count" header="Flow Count"></Column>
                                <Column style={{ minWidth: '50rem' }} field="description" header="Description"></Column>
                            </DataTable>}
                    </div>
                </div>
                <div className="label-selection-button-div">
                    <Button className="p-button-info" onClick={()=>handleBack()}>Back</Button>
                    <Button className="p-button-info" disabled={!selectedCase} onClick={()=>{setActiveItem(activeItem+1)}}>Next</Button>
                </div>
            </Card>

        </div>
    );
};

export default LabelSelection;
