import React, { useCallback, useEffect, useRef } from "react";
import "./FlowViewer.css";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import { ProgressSpinner } from "primereact/progressspinner";
import ReactFlow, {
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  useReactFlow,
  ReactFlowProvider,
  useNodes,
} from "reactflow";

import "reactflow/dist/style.css";
import { ConfirmDialog } from "primereact/confirmdialog";

import {
  validateNodesOnConnect,
  validateNodesOnConnectByText,
} from "./helpers/flowHelpers";

import QuestionNode from "./CustomNodes/QuestionNode";
import OptionNode from "./CustomNodes/OptionNode";
import ImageNode from "./CustomNodes/ImageNode";
import VideoNode from "./CustomNodes/VideoNode";
import EdgeWithDeleteButton from "./CustomEdges/EdgeWithDeleteButton";
import {
  addNodeRelation,
  createNewFlowNode,
  deleteFlowNode,
  getFlowDetailList,
  updateFlowNode,
  updateNodePosition,
  deleteNodeRelation,
  changeOptionData,
  getGeneralList,
  changeTopicStatus,
  updateStatus,
  getWidgetNodes,
  createNewOption,
  changeOptionDataSplit,
} from "../../../API/helper";
import { useState } from "react";
import { createFileName, useScreenshot } from "use-react-screenshot";
import { Dialog } from "primereact/dialog";
import user1 from "../../../assets/images/user-1.png";
import user2 from "../../../assets/images/user-2.png";
import { Image } from "primereact/image";

const nodeTypes = {
  text: QuestionNode,
  option: OptionNode,
  image: ImageNode,
  video: VideoNode,
};

const EDGE_TYPE_NAME = "buttonedge";

const edgeTypes = {
  buttonedge: EdgeWithDeleteButton,
};

const FlowViewer = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const toast = useRef(null);

  const routeParams = useParams();
  const nodesRef = useRef([]);
  const edgesRef = useRef([]);

  const { project, setCenter, fitView } = useReactFlow();
  const reactFlowWrapper = useRef(null);
  const draggedItem = useRef(null);
  const chatContentRef = useRef();
  const viewportHeight = window.innerHeight;
  const [firstOptionVisible, setFirstOptionVisible] = useState(true);
  const [firstOptionData, setFirstOptionData] = useState(true);
  const [testWidgetArray, setTestWidgetArray] = useState([]);
  const [isAgainChatVisible, setIsAgainChatVisible] = useState("none");
  const [optionVisibility, setOptionVisibility] = useState({});
  const [flowTopic, setFlowTopic] = useState("");
  const [flowID, setFlowID] = useState();
  const [flowStatus, setFlowStatus] = useState("");
  const [deletePopupVisible, setDeletePopupVisible] = useState(false);
  const nodeIdToBeDeleted = useRef();
  const [nodesFromData, setNodesFromData] = useState([]);
  const [inputID, setInputID] = useState();
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [status, setStatus] = useState(false);
  const preventStatusChange = useRef(0);
  const [visible, setVisible] = useState(false);
  const [loadingText, setLoadingText] = useState(false);
  const [testWidgetData, setTestWidgetData] = useState(null);
  const scrollRef = useRef(null);
  const [selectedEdge, setSelectedEdge] = useState();
  const [changedOptionsFlowId, setChangedOptionsFlowId] = useState(null);
  let array3 = [];
  let tempArray = [];
  const openWidget = (event) => {
    getFlowDetailList(flowID).then((response) => {
      setLoading(true);
      setTestWidgetData(response?.data);
      setTimeout(() => {
        setLoading(false);
        setVisible(true);
      }, 1000);
    });
    localStorage.setItem("testVisible", "true");
    setIsAgainChatVisible("none");
    setFirstOptionVisible(true);
    setOptionVisibility({});
    setTestWidgetArray([]);
  };
  const scrollToEnd = useCallback(() => {
    document
      .getElementsByClassName("p-dialog-content")[0]
      .scrollTo(0, document.getElementById("test_controller").scrollHeight);
  }, []);

  useEffect(() => {
    localStorage.setItem("widgetTempArray", JSON.stringify(array3));
    if (state?.initialStatus) {
      setStatus(state?.initialStatus);
    } else {
      setStatus(flowStatus);
    }
  }, []);

  const hideOption = (widgetId, test) => {
    setOptionVisibility((prevState) => ({
      ...prevState,
      [widgetId]: test,
    }));
  };
  const hideOptions = (display) => {
    const optionElements = document.querySelectorAll(".option-test");
    optionElements.forEach((optionElement) => {
      optionElement.style.display = display;
    });
  };
  const handleButtonClick = async (flowID, optionID) => {
    const storedData = localStorage.getItem("widgetTempArray");
    if (storedData !== "undefined") {
      tempArray = JSON.parse(storedData);
    }
    tempArray.push({ flowID: flowID, optionID: optionID });
    handleClick(flowID + "_" + optionID, tempArray);
  };
  useEffect(() => {
    if (testWidgetArray.length > 0 && visible) scrollToEnd();
  }, [testWidgetArray]);
  const handleClick = async (flowID, tempArray) => {
    let splitArray = [];
    let newArray = [];
    localStorage.setItem("widgetTempArray", JSON.stringify(tempArray));
    const filteredData = [];

    if (flowID !== undefined) {
      splitArray = flowID.split("_");
      testWidgetArray.forEach((item, index) => {
        if (item.type !== "option") {
          filteredData.push(item);
        }
        if (
          tempArray.some(
            (option) =>
              option.flowID === item.flow_id &&
              option.optionID === item.option_id
          )
        ) {
          filteredData.push(item);
        }
      });

      try {
        const res = await getWidgetNodes(splitArray[0], splitArray[1]);
        const responseArray = res.data.data;
        hideOptions("none");
        setLoadingText(true);

        for (const item of responseArray) {
          if (localStorage.getItem("testVisible") === "false") {
            break;
          }
          if (item.type !== "option") {
            newArray.push(item);
            await new Promise((resolve) => setTimeout(resolve, 2000));
            setTestWidgetArray([...filteredData, ...newArray]);
          }
        }
        setLoadingText(false);
        if (newArray.length !== 0) {
          hideOptions("flex");
        }
        for (const item of responseArray) {
          if (item.type === "option") {
            newArray.push(item);
            setTestWidgetArray([...filteredData, ...newArray]);
          }
        }
        if (
          newArray.length === 0 ||
          newArray[newArray.length - 1].type !== "option"
        ) {
          setIsAgainChatVisible("block");
        }
      } catch (error) {
        setLoadingText(false);
      }
    }
  };
  const [optionList, setOptionList] = useState([]);

  const [image, takeScreenShot] = useScreenshot();

  useEffect(() => {
    //get flow option list from window scope
    if (!window.option_list) {
      getGeneralList().then(() => {
        setOptionList(window.option_list);
      });
    } else {
      setOptionList(window.option_list);
    }
  }, []);

  useEffect(() => {
    nodesRef.current = nodes;
  }, [nodes]);

  useEffect(() => {
    edgesRef.current = edges;
  }, [edges]);

  useEffect(() => {
    if (preventStatusChange.current < 1) {
      preventStatusChange.current = preventStatusChange.current + 1;
    } else {
      setTopicStatus(status);
    }
  }, [status]);

  useEffect(() => {
    getLoadData();
  }, []);
  const getLoadData = () => {
    getFlowDetailList(routeParams.id).then((response) => {
      setStatus(response.data.topic.status);
      setFlowTopic(response.data.topic.title);
      setFlowID(response.data.topic.id);
      const nodesFromService = response.data?.builder?.nodes;
      const edgesFromService = response.data?.builder?.edges;
      setNodesFromData(response.data?.builder?.nodes);
      if (!nodesFromService || !edgesFromService) return;

      setNodes(
        nodesFromService.map((e, i) => {
          const { style, ...nodeWithoutStyle } = e;
          return {
            topic_id: response.data.topic.id,
            ...nodeWithoutStyle,
            id: nodeWithoutStyle.id.toString(),
            width: 261,
            height: 198,
            style: { borderStyle: i < 3 ? "dashed" : "solid" },
            data: {
              static: i < 3 || e.data.static,
              ...nodeWithoutStyle.data,
              updateNodeData: (payload) => {
                onChangeNodeData(nodeWithoutStyle.id.toString(), payload);
              },
              deleteNodeItself: (id) => {
                deleteNode(null, nodeWithoutStyle.id.toString());
              },
              topic_id: response.data.topic.id,
            },
          };
        })
      );

      setEdges(
        edgesFromService.map((e) => ({
          ...e,
          target: e.target.toString(),
          source: e.source.toString(),
          type: EDGE_TYPE_NAME,
          data: {
            onEdgeRemoveButtonClick: (event, id) => {
              deleteEdge(event, e);
            },
          },
        }))
      );
      setLoading(false);
    });
  };
  const closeUpdatingNotification = (duration) => {
    const timeout = setTimeout(() => {
      setUpdating(false);
      clearTimeout(timeout);
    }, duration);
  };
  const getParentOptionNode = (lastEdgeSource) => {
    const prevNode = nodesRef.current.find(
      (e) => e.id.toString() === lastEdgeSource.toString()
    );
    if (prevNode.type === "option") {
      return prevNode;
    } else {
      const temp = edgesRef.current.find(
        (e) => e.target === prevNode.id
      )?.source;
      if (!temp) {
        return false;
      }

      const a = getParentOptionNode(temp);
      return a;
    }
  };
  const findUnconnectedNodes = () => {
    const unLinkedNodeList = nodesRef.current.filter((node) => {
      return (
        edgesRef.current.filter(
          (edge) => edge.target === node.id || edge.source === node.id
        ).length <= 0
      );
    });

    return unLinkedNodeList;
  };
  const updateFlowState = (nodesFromService, edgesFromService) => {
    setNodes(
      nodesFromService.map((e, i) => {
        const { style, ...nodeWithoutStyle } = e;
        return {
          topic_id: routeParams.id,
          ...nodeWithoutStyle,
          id: nodeWithoutStyle.id.toString(),
          width: 261,
          height: 198,
          style: { borderStyle: i < 3 ? "dashed" : "solid" },
          data: {
            static: i < 3 || e.data.static,
            ...nodeWithoutStyle.data,
            updateNodeData: (payload) => {
              onChangeNodeData(nodeWithoutStyle.id.toString(), payload);
            },
            deleteNodeItself: (id) => {
              deleteNode(null, nodeWithoutStyle.id.toString());
            },
            topic_id: routeParams.id,
          },
        };
      })
    );
    setEdges(
      edgesFromService.map((e) => ({
        ...e,
        target: e.target.toString(),
        source: e.source.toString(),
        type: EDGE_TYPE_NAME,
        data: {
          onEdgeRemoveButtonClick: (event, id) => {
            deleteEdge(event, e);
          },
        },
      }))
    );
  };
  const onConnect = useCallback(
    (params) => {
      var targetId = null;
      const sourceNode = nodes.find((node) => node.id === params.source);
      const TargetNode = nodes.find((node) => node.id === params.target);
      if (
        !validateNodesOnConnectByText(
          sourceNode.type,
          TargetNode.type,
          sourceNode.id,
          nodesFromData
        )
      ) {
        toast.current.show({
          severity: "error",
          summary: "This flow depends on an option.",
          life: 3000,
        });
        return;
      }
      if (validateNodesOnConnect(sourceNode.type, TargetNode.type)) {
      } else {
        toast.current.show({
          severity: "error",
          summary: "You cannot connect an option to another.",
          life: 3000,
        });
        return;
      }

      // if (sourceNode.type === "option" && TargetNode.type === "text") {
      // }
      if (
        (sourceNode.type === "text" ||
          sourceNode.type === "image" ||
          sourceNode.type === "video") &&
        TargetNode.type === "option"
      ) {
        if (TargetNode.data.id === null) {
          toast.current.show({
            severity: "error",
            summary: "Please select an option.",
            life: 3000,
          });
          return;
        } else {
          setInputID(sourceNode.id);
          setUpdating(true);
          let none_output = edgesRef?.current?.find((x) =>
            x.source.includes(TargetNode.data.id)
          )?.target;
          targetId = `${sourceNode.id}_${TargetNode.id}`;
          addNodeRelation(
            routeParams.id,
            sourceNode.id,
            "flow",
            sourceNode.id,
            "option",
            parseInt(
              TargetNode.data.old_id === undefined
                ? TargetNode.data.id
                : TargetNode.data.old_id
            ),
            none_output
          )
            .then((data) => {
              if (data.data.success) {
                const nodesFromService = data.data?.data?.builder?.nodes;
                const edgesFromService = data.data.data?.builder?.edges;

                updateFlowState(nodesFromService, edgesFromService);
                updateNodePosition(
                  routeParams.id,
                  sourceNode.id,
                  parseInt(TargetNode.position.x),
                  parseInt(TargetNode.position.y),
                  "option",
                  TargetNode.data.id
                )
                  .then((data) => {})
                  .catch((err) => {
                    console.log("updateNodePosition/ERRdOR", err);
                  })
                  .finally(() => {
                    closeUpdatingNotification(600);
                    // getLoadData();
                  });
              }
            })
            .catch((err) => {
              console.log("addNodeRelation/ERROR", err.response.data.message);
            })
            .finally(() => {
              closeUpdatingNotification(600);
            });

          setUpdating(true);
        }
      } else if (
        (sourceNode.type === "text" ||
          sourceNode.type === "image" ||
          sourceNode.type === "video") &&
        (TargetNode.type === "text" ||
          TargetNode.type === "image" ||
          TargetNode.type === "video")
      ) {
        const parentOptionNode = getParentOptionNode(params.source);
        if (!parentOptionNode) {
          toast.current.show({
            severity: "error",
            summary:
              "The node you want to connect is not connected to an option",
            life: 1700,
          });
          return;
        }
        setUpdating(true);
        let none_output = edgesRef?.current?.find((x) =>
          x.source.includes(TargetNode.data.id)
        )?.target;
        addNodeRelation(
          routeParams.id,
          parseInt(parentOptionNode.id),
          "option",
          parseInt(parentOptionNode.data.id),
          "flow",
          parseInt(TargetNode.id),
          none_output
        )
          .then((data) => {
            setEdges((eds) => {
              return addEdge(
                {
                  ...params,
                  type: EDGE_TYPE_NAME,
                  data: {
                    onEdgeRemoveButtonClick: (event, id) => {
                      deleteEdge(event, params);
                    },
                  },
                },
                eds
              );
            });
          })
          .catch((err) => {
            console.log(
              "addNodeRelation/ERROR",
              err.response.data.message,
              err.response.data.title
            );
          })
          .finally(() => {
            closeUpdatingNotification(600);
          });
      } else if (
        sourceNode.type === "option" &&
        (TargetNode.type === "text" ||
          TargetNode.type === "video" ||
          TargetNode.type === "image")
      ) {
        setUpdating(true);
        let none_output = edgesRef?.current?.find((x) =>
          x.source.includes(TargetNode.data.id)
        )?.target;

        addNodeRelation(
          routeParams.id,
          inputID === undefined ? parseInt(sourceNode.id) : parseInt(inputID),
          "option",
          parseInt(
            sourceNode.data.old_id === undefined
              ? sourceNode.data.id
              : sourceNode.data.old_id.split("_")[1]
          ),
          "flow",
          parseInt(TargetNode.id),
          none_output
        )
          .then((data) => {
            const nodesFromService = data.data?.data?.builder?.nodes;
            const edgesFromService = data.data.data?.builder?.edges;

            updateFlowState(nodesFromService, edgesFromService);
          })
          .catch((err) => {
            console.log(
              "addNodeRelation/ERROR",
              err.response.data.message,
              err.response.data.title
            );
          })
          .finally(() => {
            closeUpdatingNotification(600);
            // getLoadData();
          });
      }
      // setEdges((eds) => {
      //   return addEdge(
      //     {
      //       ...params,
      //       type: EDGE_TYPE_NAME,
      //       data: {
      //         onEdgeRemoveButtonClick: (event, id) => {
      //           deleteEdge(event, params);
      //         },
      //       },
      //     },
      //     eds
      //   );
      // });

      const previousNodeID = edgesRef.current.filter(
        (e) => e.target === sourceNode.id
      )[0]?.source;
    },
    [setEdges, nodes]
  );

  const onChangeNodeData = (id, payload) => {
    if (payload.action === "text") {
      if (payload.action_from_node !== "color") {
        updateFlowNode(id, routeParams.id, payload.message, payload.action)
          .then((data) => {
            console.log("onChangeNodeData/updateFlowNode", data);
          })
          .catch((err) => {
            console.log("onChangeNodeData/updateFlowNode/ERROR", err);
          });
      } else {
        updateFlowNode(id, routeParams.id, payload.message, "color", "red")
          .then((data) => {
            console.log("onChangeNodeData/updateFlowNode", data);
          })
          .catch((err) => {
            console.log("onChangeNodeData/updateFlowNode/ERROR", err);
          });
      }
    } else if (payload.action === "option") {
      const prevNodeID = edgesRef.current.find(
        (edge) => edge.target === id
      )?.source;
      if (prevNodeID) {
        changeOptionData(
          routeParams.id,
          prevNodeID,
          payload.old_id,
          payload.id
        );
      } else {
        changeOptionDataSplit(routeParams.id, id, payload.id);
      }
    }

    const updatedNodes = nodesRef.current.map((node) => {
      if (node.id === id) {
        return {
          ...node,
          data: {
            ...node.data,
            ...payload,
          },
        };
      }
      return node;
    });

    setNodes(updatedNodes);
  };

  const handleStatusChange = (event) => {
    const unLinkedNodes = findUnconnectedNodes();
    if (unLinkedNodes.length > 0) {
      toast.current.show({
        severity: "error",
        summary: "There are unconnected nodes, you cannot activate flow.",
        life: 1700,
      });
      setCenter(
        unLinkedNodes[0].position.x + 70,
        unLinkedNodes[0].position.y + 170,
        {
          duration: 1000,
          zoom: 0.9,
        }
      );
    } else {
      setStatus(!status);
    }
  };

  const setTopicStatus = (status) => {
    updateStatus(routeParams.id, status).then((res) => {
      //console.log('SET_STATAUS_EP', res.data.message);
    });
  };

  const download = (image, { name = "img", extension = "jpg" } = {}) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };

  const handleScreenShotButton = () => {
    takeScreenShot(chatContentRef.current).then(download);
  };
  const leftContents = (
    <React.Fragment>
      <Button
        label="Back"
        icon="pi pi-arrow-circle-left"
        className="mr-2 p-button-secondary"
        style={{ marginRight: 10 }}
        onClick={() => navigate("/flows", { state })}
      />
      <div className="flow-viewer-title">{flowTopic}</div>
      <Button
        tooltip="Drag and drop to add new Text node"
        tooltipOptions={{ position: "bottom", showDelay: 2500 }}
        label="Text"
        icon="pi pi-comment"
        className="mr-2 node-drag-button"
        style={{ marginRight: 10 }}
        draggable
        onDragStart={() => {
          draggedItem.current = "text";
        }}
      />
      <Button
        tooltip="Drag and drop to add new Option node"
        tooltipOptions={{ position: "bottom", showDelay: 2500 }}
        label="Option"
        icon="pi pi-question-circle"
        className="p-button-success mr-2 node-drag-button"
        draggable
        style={{ marginRight: 10 }}
        onDragStart={() => {
          draggedItem.current = "option";
        }}
      />
      <Button
        label="Image"
        icon="pi pi-image"
        className="mr-2 node-drag-button"
        style={{ marginRight: 10 }}
        draggable
        onDragStart={() => {
          draggedItem.current = "image";
        }}
      />
      <Button
        tooltip="Drag and drop to add new Video node"
        tooltipOptions={{ position: "bottom", showDelay: 2500 }}
        label="Video"
        icon="pi pi-video"
        className="mr-2 node-drag-button"
        style={{ marginRight: 10 }}
        draggable
        onDragStart={() => {
          draggedItem.current = "video";
        }}
      />
    </React.Fragment>
  );
  const rightContents = (
    <React.Fragment>
      <div>
        <InputSwitch
          onChange={handleStatusChange}
          checked={status}
          className="mr-2"
          style={{ marginRight: 10, marginTop: 5 }}
        />
      </div>

      {/* <Button
        onClick={handleScreenShotButton}
        icon="pi pi-file-pdf"
        className="mr-2 p-button-secondary"
        style={{ marginRight: 10 }}
      /> */}
      <Button
        //handleTestChange
        /*toast.current.show({
                  severity: 'warn',
                  summary: "This feature is under maintenance.",
                  life: 2000,
                })*/
        onClick={(event) => openWidget(event)}
        icon="pi pi-comments"
        label="Test"
        className="mr-2 p-button-secondary"
        style={{ marginRight: 10 }}
      />
    </React.Fragment>
  );
  const deleteEdge = useCallback(
    (_, edge) => {
      const edgesOnRight = edgesRef.current.filter((e) => {
        return edge.target.toString() === e.source.toString();
      });

      /*      if (edgesOnRight.length > 0) {
                    toast.current.show({
                      severity: "error",
                      summary:
                        "You cannot break this link. The link you want to delete contains a node with other links.",
                      life: 3000,
                    });
                    return;
                  }*/

      const targetNodeTypeOfEdge = nodesRef.current.find(
        (e) => e.id.toString() === edge.target.toString()
      )?.type;
      const targetNodeTypeOfid = nodesRef.current.find(
        (e) => e.id.toString() === edge.target.toString()
      );

      if (targetNodeTypeOfEdge === "option") {
        setSelectedEdge(edge);
        const option = getParentOptionNode(edge.source);
        setUpdating(true);
        const answer_flow_id = edge.target;
        const option_id = answer_flow_id.split("_");
        const flow_id = edgesRef.current.find(
          (e) => e.target === answer_flow_id
        ).source;

        deleteNodeRelation(
          routeParams.id,
          "flow",
          flow_id,
          option_id[1],
          answer_flow_id
        )
          .then((data) => {
            if (data.data.success) {
              const nodesFromService = data.data?.data?.builder?.nodes;
              const edgesFromService = data.data.data?.builder?.edges;

              updateFlowState(nodesFromService, edgesFromService);
              // if (!edge.id) {
              //   setEdges((eds) =>
              //     eds.filter((e) => {
              //       return e.source !== edge.id && e.target !== edge.target;
              //     })
              //   );
              // }

              // //when an edge deleted, set status as false.
              // //TODO: request service to disable this flow status
              // setStatus(false);
              // //console.log(edges);
              // setEdges((eds) => eds.filter((e) => e.id !== edge.id));
              // //console.log(edges);
            }
          })
          .catch((err) => {
            //console.log('deleteNodeRelation/ERROR', err);
          })
          .finally(() => {
            closeUpdatingNotification(600);
          });
      } else if (
        targetNodeTypeOfEdge === "text" ||
        targetNodeTypeOfEdge === "image" ||
        targetNodeTypeOfEdge === "video"
      ) {
        const answer_flow_id = edge.target;
        const option = getParentOptionNode(edge.source);
        const option_id = option?.data.id;

        let flow_id = "";
        if (option.id.startsWith("0")) {
          flow_id = 0;
        } else {
          flow_id = edgesRef.current.find((e) => e.target === option.id).source;
        }
        setUpdating(true);
        deleteNodeRelation(
          routeParams.id,
          "option",
          flow_id,
          option_id,
          answer_flow_id
        )
          .then((data) => {
            if (data.data.success) {
              const nodesFromService = data.data?.data?.builder?.nodes;
              const edgesFromService = data.data.data?.builder?.edges;

              updateFlowState(nodesFromService, edgesFromService);
              // if (!edge.id) {
              //   setEdges((eds) =>
              //     eds.filter((e) => {
              //       return e.source !== edge.id && e.target !== edge.target;
              //     })
              //   );
              // }
              // setStatus(false);
              // setEdges((eds) => eds.filter((e) => e.id !== edge.id));
            }
          })
          .catch((err) => {
            //console.log('deleteNodeRelation/ERROR', err);
          })
          .finally(() => {
            closeUpdatingNotification(600);
          });
      }
    },
    [edges]
  );
  const deleteNode = useCallback((_, node_id) => {
    nodeIdToBeDeleted.current = node_id;
    //console.log('nodeIdToBeDeleted', nodeIdToBeDeleted.current);
    setDeletePopupVisible(true);
  }, []);
  const handleAcceptDeleteButtonClick = () => {
    if (nodeIdToBeDeleted.current > 1000000) {
      setNodes((nodes) =>
        nodes.filter((e) => e.id !== nodeIdToBeDeleted.current)
      );
      return;
    }
    let deletedId = nodeIdToBeDeleted.current;
    if (nodeIdToBeDeleted.current != undefined) {
      if (nodeIdToBeDeleted.current.includes("_")) {
        deletedId = nodeIdToBeDeleted.current.toString().split("_")[1];
      }
    }

    //delete servis
    closeUpdatingNotification(600);
    deleteFlowNode(routeParams.id, parseInt(deletedId))
      .then((res) => {
        //console.log('handleAcceptDeleteButtonClick API', res);
        if (res.data.success) {
          setNodes((nodes) => nodes.filter((e) => e.id !== deletedId));
        }
      })
      .catch((err) => {
        toast.current.show({
          severity: "error",
          summary: err.response.data.message,
          life: 3000,
        });
      })
      .finally(() => {
        closeUpdatingNotification(600);
      });
  };

  const handleOnDrop = (event) => {
    //console.log(event.clientX, event.clientY, draggedItem.current);
    createNewNode(draggedItem.current, { x: event.clientX, y: event.clientY });
  };

  const createNewNode = (nodeType, position) => {
    //when a new node added, set, flow status as false.
    //TODO: request service to disable this flow
    setStatus(false);

    //console.log(position);
    let data = null;

    const { top, left } = reactFlowWrapper.current.getBoundingClientRect();
    //console.log('top-left', top, left);
    //console.log(top, left);
    const newID = Math.trunc(Math.random() * 200000 + 1000000).toString();

    const positions = project({ x: position.x - left, y: position.y - left });
    //console.log(position);

    if (nodeType === "text") {
      createNewFlowNode(
        routeParams.id,
        "New message...",
        nodeType,
        parseInt(positions.x),
        parseInt(positions.y)
      )
        .then((res) => {
          //console.log(res.data.data.flow_id, res.data);
          data = {
            message: "New message...",
            action: "text",
            id: res.data.data.flow_id.toString(),
            updateNodeData: (payload) => {
              onChangeNodeData(res.data.data.flow_id.toString(), payload);
            },
            deleteNodeItself: (id) => {
              deleteNode(null, res.data.data.flow_id.toString());
            },
          };

          const newNode = {
            data: data,
            position: project({ x: position.x - left, y: position.y - left }),
            id: res.data.data.flow_id.toString(),
            type: nodeType,
          };

          setNodes((prevNodes) => {
            return [...prevNodes, newNode];
          });
        })
        .catch((err) => {
          //console.log('Create new node. ERROR', err.response.data.message);
        });
    } else if (nodeType === "option") {
      createNewOption(
        routeParams.id,
        "New message...",
        nodeType,
        parseInt(positions.x),
        parseInt(positions.y)
      )
        .then((res) => {
          setChangedOptionsFlowId(res.data.data.flow_id);
          data = {
            value: "New Option",
            id: res.data.data.flow_id.toString(),
            updateNodeData: (payload) => {
              onChangeNodeData(res.data.data.flow_id.toString(), payload);
            },
            deleteNodeItself: (id) => {
              deleteNode(null, res.data.data.flow_id.toString());
            },
          };

          const newNode = {
            data: data,
            position: project({ x: position.x - left, y: position.y - left }),
            id: res.data.data.flow_id.toString(),
            type: nodeType,
          };

          setNodes((prevNodes) => {
            return [...prevNodes, newNode];
          });
        })
        .catch((err) => {
          //console.log('Create new node. ERROR', err.response.data.message);
        });

      //console.log('option', newNode);
    } else if (nodeType === "image") {
      createNewFlowNode(
        routeParams.id,
        "New message...",
        nodeType,
        parseInt(positions.x),
        parseInt(positions.y)
      )
        .then((res) => {
          //console.log(res.data.data.flow_id, res.data);
          data = {
            message: "New message...",
            action: "image",
            id: res.data.data.flow_id.toString(),
            updateNodeData: (payload) => {
              onChangeNodeData(res.data.data.flow_id.toString(), payload);
            },
            deleteNodeItself: (id) => {
              deleteNode(null, res.data.data.flow_id.toString());
            },
          };

          const newNode = {
            data: data,
            position: project({ x: position.x - left, y: position.y - left }),
            id: res.data.data.flow_id.toString(),
            type: nodeType,
          };

          setNodes((prevNodes) => {
            return [...prevNodes, newNode];
          });
        })
        .catch((err) => {
          //console.log('Create new node. ERROR', err.response.data.message);
        });
    } else if (nodeType === "video") {
      createNewFlowNode(
        routeParams.id,
        "New message...",
        nodeType,
        parseInt(positions.x),
        parseInt(positions.y)
      )
        .then((res) => {
          //console.log(res.data.data.flow_id, res.data);
          data = {
            message: "New message...",
            action: "video",
            id: res.data.data.flow_id.toString(),
            updateNodeData: (payload) => {
              onChangeNodeData(res.data.data.flow_id.toString(), payload);
            },
            deleteNodeItself: (id) => {
              deleteNode(null, res.data.data.flow_id.toString());
            },
          };

          const newNode = {
            data: data,
            position: project({ x: position.x - left, y: position.y - left }),
            id: res.data.data.flow_id.toString(),
            type: nodeType,
          };

          setNodes((prevNodes) => {
            return [...prevNodes, newNode];
          });
        })
        .catch((err) => {
          //console.log('Create new node. ERROR', err.response.data.message);
        });
    }

    // const newNode = {
    //   data: data,
    //   position: project({ x: position.x - left, y: position.y - left }),
    //   id: newID,
    //   type: nodeType,
    // };

    // console.log('updatedNodes', packEdgesAndNodes(nodes, edges));
    // updateFlow(routeParams.id, nodes, edges);
  };
  const handleOnNodeDropStop = (event, changedNode) => {
    // console.log(
    //   'handleOnNodeDropStop',
    //   changedNode.id,
    //   changedNode.topic_id,
    //   changedNode.position.x,
    //   changedNode.position.y
    // );

    if (changedNode.type === "option") {
      // if (!changedNode.id.includes("_")) return;
      const option_id = changedNode.id.includes("_")
        ? changedNode.id.split("_")[1]
        : changedNode.id;
      const flow_id = changedNode.id.startsWith("0")
        ? option_id
        : changedNode.id.split("_")[0];
      updateNodePosition(
        routeParams.id,
        flow_id,
        parseInt(changedNode.position.x),
        parseInt(changedNode.position.y),
        "option",
        option_id
      )
        .then((data) => {
          console.log("updateNodePosition/DATA", data);
        })
        .catch((err) => {
          // setNodes((nodes) =>
          //   nodes.filter((e) => e.id !== nodeIdToBeDeleted.current)
          // );
          //console.log("updateNodePosition/ERROR", err);
        });
      return;
    }

    updateNodePosition(
      routeParams.id,
      parseInt(changedNode.id),
      parseInt(changedNode.position.x),
      parseInt(changedNode.position.y)
    )
      .then((res) => {
        // console.log('STATUS', res.status);
        // console.log('STATUS', res.statusText);
        // console.log('STATUS', res.data.message);
        // console.log('STATUS', res.data.type);
        // console.log(nodes);
      })
      .catch((err) => {
        console.log("handleOnNodeDropStop-ERROR", err.response.data.message);
      });
  };
  return (
    <div className="flow-viewer" ref={reactFlowWrapper}>
      <Toolbar left={leftContents} right={rightContents} />
      <Dialog
        headerStyle={{
          textAlign: "center",
          backgroundColor: "#3B82F6",
          color: "white",
        }}
        header="Diginurse"
        visible={visible}
        style={{ width: "50vw" }}
        onHide={() => setVisible(false)}
      >
        <div
          id="test_controller"
          ref={scrollRef}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div className={`chv-message user`} style={{ marginTop: 30 }}>
            <img src={user1} className="chv-avatar" />
            <div className="chv-body-test" style={{ width: "%100" }}>
              {testWidgetData?.builder?.nodes[0].data.message}
            </div>
          </div>
          {firstOptionVisible ? (
            <div className="options-wrapper-test">
              <div
                className="option-test"
                onClick={() => {
                  handleClick(testWidgetData?.builder?.nodes[1].id);
                  setFirstOptionVisible(false);
                  setFirstOptionData(
                    testWidgetData?.builder?.nodes[1].data.message
                  );
                }}
              >
                {testWidgetData?.builder?.nodes[1].data.message}
              </div>
              <div
                className="option-test"
                onClick={() => {
                  handleClick(testWidgetData?.builder?.nodes[2].id);
                  setFirstOptionVisible(false);
                  setFirstOptionData(
                    testWidgetData?.builder?.nodes[2].data.message
                  );
                }}
              >
                {testWidgetData?.builder?.nodes[2].data.message}
              </div>
            </div>
          ) : (
            <div className={`chv-message bot`} style={{ marginTop: 10 }}>
              <img src={user2} className="chv-avatar" />
              <div className="chv-body-test" style={{ width: "%100" }}>
                {firstOptionData}
              </div>
            </div>
          )}
          {testWidgetArray.map((testWidget, index) =>
            testWidget?.type === "text" ? (
              <div className={`chv-message user`} style={{ marginTop: 10 }}>
                <img src={user1} className="chv-avatar" />
                <div className="chv-body-test" style={{ width: "%100" }}>
                  {testWidget?.message}
                </div>
              </div>
            ) : testWidget?.type === "video" ? (
              <div className={`chv-message user`} style={{ marginTop: 10 }}>
                <img src={user1} className="chv-avatar" />
                <div className="chv-body-test" style={{ width: "%100" }}>
                  <video width="250" controls>
                    <source
                      src={testWidget?.action_value}
                      type="video/mp4"
                    ></source>
                  </video>
                </div>
              </div>
            ) : testWidget?.type === "image" ? (
              <div className={`chv-message user`} style={{ marginTop: 10 }}>
                <img src={user1} className="chv-avatar" />
                <div className="chv-body-test" style={{ width: "%100" }}>
                  <Image
                    src={testWidget?.action_value}
                    alt="Image"
                    width="250"
                    preview
                  />
                </div>
              </div>
            ) : localStorage.getItem("widgetTempArray") !== "undefined" &&
              JSON.parse(localStorage.getItem("widgetTempArray")).some(
                (option) =>
                  option.flowID === testWidget?.flow_id &&
                  (option.optionID === testWidget.option_id) &&
                  testWidget.option_id
              ) ? (
              <div className={`chv-message bot`} style={{ marginTop: 10 }}>
                <img src={user2} className="chv-avatar" />
                <div className="chv-body-test" style={{ width: "%100" }}>
                  {testWidget?.option_title}
                </div>
              </div>
            ) : (
              <div
                className="option-test"
                style={{ marginTop: 10 }}
                onClick={() => {
                  handleButtonClick(testWidget?.flow_id, testWidget?.option_id);
                  hideOption(index, testWidget?.option_title);
                }}
              >
                {testWidget?.option_title}
              </div>
            )
          )}
          {loadingText ? (
            <div className="typing">
              <div className="typing-circle" />
              <div className="typing-circle" />
              <div className="typing-circle" />
              <div className="typing-text">typing...</div>
            </div>
          ) : null}
          <div
            className="last-option-test"
            style={{ marginTop: 40, display: isAgainChatVisible }}
            onClick={() => {
              setTestWidgetArray([]);
              setIsAgainChatVisible("none");
              setFirstOptionVisible(true);
              setOptionVisibility({});
            }}
          >
            Do you have another problem I can help with?
          </div>
        </div>
      </Dialog>

      <div className="flow-container" style={{ height: viewportHeight - 87 }}>
        <ReactFlow
          ref={chatContentRef}
          fitView
          maxZoom={1.4}
          minZoom={0.02}
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          connectOnClick={false}
          onNodeDragStop={handleOnNodeDropStop}
          // onEdgeDoubleClick={(event, edge) => deleteEdge(event, edge)}
          onCompositionUpdate={() => console.log("composition update")}
          onDrop={handleOnDrop}
          onDragOver={(e) => {
            e.preventDefault();
          }}
        >
          <Background variant="cross" color="#5b448f20" />
        </ReactFlow>
      </div>
      <Toast ref={toast} />
      <ConfirmDialog
        visible={deletePopupVisible}
        onHide={() => setDeletePopupVisible(false)}
        message="Do you really want to delete this node?"
        header="Confirmation"
        icon="pi pi-exclamation-triangle"
        accept={() => handleAcceptDeleteButtonClick()}
        // reject={reject}
      />

      {loading ? (
        <div className="flow-viewer-preloader">
          <ProgressSpinner />
        </div>
      ) : null}

      {updating ? (
        <div className="flow-viewer-updating">
          <div className="updating-text">
            Updates are saving on cloud.
            <ProgressSpinner
              style={{ width: "20px", height: "20px", marginLeft: 20 }}
              strokeWidth="8"
              fill="var(--surface-ground)"
              animationDuration=".5s"
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default () => (
  <ReactFlowProvider>
    <FlowViewer />
  </ReactFlowProvider>
);
