import React, {useRef, useState} from 'react';
import {Card} from "primereact/card";
import {Toast} from "primereact/toast";
import {Checkbox} from "primereact/checkbox";
import {Button} from "primereact/button";
import {useNavigate} from "react-router-dom";
import Header from "../../../components/header/Header";
import {InputText} from "primereact/inputtext";
import {createNewPoll, createNewWellBeingPoll} from "../../../API/helper";

const WellbeingPollsDetail = () => {
    const toast = useRef(null);
    const navigate = useNavigate();
    const [polls, setPolls] = useState([
        {
            question: "",
            answers: [
                { id: 1, answer: "" },
                { id: 2, answer: "" },
            ],
        },
    ]);

    const handleAddQuestion = () => {
        const newQuestion = {
            question: "",
            answers: [
                { id: 1, answer: "" },
                { id: 2, answer: "" },
            ],
        };
        setPolls([...polls, newQuestion]);
    };

    const handleRemoveQuestion = (index) => {
        if (polls.length > 1) {
            const updatedPolls = polls.filter((_, i) => i !== index);
            setPolls(updatedPolls);
        } else {
            // Handle error here
        }
    };

    const handleQuestionChange = (index, question) => {
        const updatedPolls = polls.map((poll, i) =>
            i === index ? { ...poll, question } : poll
        );
        setPolls(updatedPolls);
    };

    const handleAnswerChange = (questionIndex, answerIndex, answer) => {
        const updatedPolls = polls.map((poll, i) =>
            i === questionIndex
                ? {
                    ...poll,
                    answers: poll.answers.map((a, j) =>
                        j === answerIndex ? { ...a, answer } : a
                    ),
                }
                : poll
        );
        setPolls(updatedPolls);
    };
    const handleAddItem = (questionIndex) => {
        const updatedPolls = [...polls];
        updatedPolls[questionIndex].answers.push({
            id: updatedPolls[questionIndex].answers.length + 1,
            answer: "",
        });
        setPolls(updatedPolls);
    };
    const isItemFilled = (item) => {
        return item.question !== "" && item.answers.every(answer => answer.answer !== "");
    };
    const handleRemoveItem = (questionIndex, answerIndex) => {
        const updatedPolls = [...polls];
        if (updatedPolls[questionIndex].answers.length > 2) {
            updatedPolls[questionIndex].answers.splice(answerIndex, 1);
            setPolls(updatedPolls);
        } else {
            // Handle error here
        }
    };

    const createPoll = async () => {
        await createNewWellBeingPoll(polls).then((response) =>{
                if(response.success){
                    toast.current.show({
                        severity: "success",
                        summary: "Successful",
                        detail: response.message,
                        life: 3000,
                    })
                    setTimeout(() => {
                        navigate("/polls/wellbeing-polls")
                    }, 1000);
                }
                else{
                    toast.current.show({
                        severity: "error",
                        summary: "Error",
                        detail: response.message,
                        life: 3000,
                    })
                }
            }
        );
    };
    return (
        <div>
            <Header items={[{ label: 'Wellbeing Polls' , url: '/polls/wellbeing-polls'},{ label: "Create Poll" }]} />
            <div className="flow-wrapper">
                <div className="nutrition-method-div">
                    <Toast ref={toast} />
                    <Card className="nutrition-method-card">
                        <div className="nutrition-method-title">
                            Please enter question and answers to create polls in it.
                        </div>
                        {polls.map((poll, questionIndex) => (
                            <div key={questionIndex} style={{border:"1px solid gray",marginTop:20,padding:20,borderRadius:20}}>
                                <div className="admin-info-wrapper" style={{marginTop:50}}>
                                    {questionIndex!==0 &&  <div style={{width:"100%",display:"flex",justifyContent:"flex-end"}}>
                                        <Button
                                            label="Remove Question"
                                            className="p-button p-button-danger"
                                            onClick={()=>{handleRemoveQuestion(questionIndex)}}
                                        />
                                    </div>}
                                    <span>Question:</span>
                                    <InputText
                                        placeholder="Please Enter Question"
                                        value={poll.question}
                                        onChange={(e) =>
                                            handleQuestionChange(questionIndex, e.target.value)
                                        }
                                    />
                                </div>
                                <div style={{marginTop:20}}>
                                    <span>Answers:</span>
                                    {poll.answers.map((item, answerIndex) => (
                                        <div className="answer-wrapper" key={item.id}>
                                            <InputText
                                                placeholder="Please Enter Answer"
                                                style={{ marginRight: 10, width: "100%" }}
                                                value={item.answer}
                                                onChange={(e) =>
                                                    handleAnswerChange(
                                                        questionIndex,
                                                        answerIndex,
                                                        e.target.value
                                                    )
                                                }
                                            />
                                            {answerIndex > 1 && (
                                                <Button
                                                    onClick={() =>
                                                        handleRemoveItem(questionIndex, answerIndex)
                                                    }
                                                    className="p-button p-button-danger"
                                                    icon="pi pi-minus"
                                                />
                                            )}
                                        </div>
                                    ))}

                                    <Button
                                        onClick={() => handleAddItem(questionIndex)}
                                        label="Add Answer"
                                        className="p-button-info"
                                        icon="pi pi-plus"
                                    />
                                </div>
                            </div>
                        ))}
                        <div style={{width:"100%",display:"flex",justifyContent:"flex-start",marginTop:30}}>
                            <Button className="p-button-info" icon="pi pi-plus" label="Add Question" onClick={()=>{handleAddQuestion()}}></Button>
                        </div>
                        <div className="poll-question-div" style={{justifyContent:"flex-end"}}>
                            <Button className="p-button-info" onClick={()=> {
                                createPoll()
                            }} disabled={polls.every(isItemFilled) ? false : true}>Save</Button>
                        </div>
                    </Card>
                </div>
            </div>
        </div>

    );
};

export default WellbeingPollsDetail;