import React, {useState} from 'react';
import {Card} from "primereact/card";
import {Button} from "primereact/button";
import {Calendar} from "primereact/calendar";
import {Checkbox} from "primereact/checkbox";
import './PollsEdit.css'


const PollsEdit = ({setActiveItem,activeItem,publishNow,setPublishNow,pollValue,selectedDates,dates,setDates}) => {
    const handleNext = () =>{
        setActiveItem(activeItem+1)
    }
    let minDate = new Date();

    return (
        <div className="nutrition-method-div">
            <Card className="nutrition-method-card">
                <div className="nutrition-method-body">
                    <div className="nutrition-method-title" style={{display:"flex",justifyContent:"center",width:"100%"}}>
                        Please select status, name to create polls in it.
                    </div>
                    <div>
                        <div style={{display:"flex",alignItems:"center",gap:50,width:"100%",justifyContent:"center",marginTop:30}}>
                            <div className="flex align-items-center">
                                <Checkbox inputId="ingredient4" name="publish_now" value="now" onChange={e => setPublishNow(e.checked)} checked={publishNow}/>
                                <label htmlFor="ingredient1" style={{marginLeft:5}} >Publish Now</label>
                            </div>
                            <div className="admin-info-wrapper" >
                                <span>Start-End Date:</span>
                                <Calendar
                                    value={dates}
                                    disabled={publishNow}
                                    style={{width:400}}
                                    onChange={(e) => setDates(e.value)}
                                    selectionMode="range"
                                    readOnlyInput
                                    dateFormat="dd/mm/yy"
                                    placeholder="Select Start-End Date"
                                    showButtonBar
                                    disabledDates={selectedDates}
                                    minDate={minDate}
                                    className="dropdown"
                                    showIcon

                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="poll-question-div">
                    <Button className="p-button-info" onClick={()=>setActiveItem(activeItem-1)}>Back</Button>
                    <Button className="p-button-info" disabled={publishNow ? false : dates!==null  ? false : true} onClick={()=>handleNext()}>Next</Button>
                </div>
            </Card>

        </div>
    );
};

export default PollsEdit;