import React, {useEffect, useRef, useState} from 'react';
import {Card} from "primereact/card";
import {Button} from "primereact/button";
import {Toast} from "primereact/toast";
import {createNewPoll, editPoll, pollsAddCheck} from "../../API/helper";
import {useNavigate} from "react-router-dom";
import {format} from "date-fns";
import {SelectButton} from "primereact/selectbutton";
import {Dialog} from "primereact/dialog";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";

const OverviewPoll  = ({setActiveItem,activeItem,type,method,items,pollValue,selectedPosition,status,dates,setStatus,publishNow,state}) => {
    const toast = useRef(null);
    const options = ['Active', 'Passive'];
    const [visible, setVisible] = useState(false);
    const [publishDate,setPublishDate]=useState("")
    const [header,setHeader]=useState("")
    const [errorMessage,setErrorMessage]=useState("")
    const [activeStatus,setActiveStatus]=useState("")
    const [products, setProducts] = useState([]);
    const [newNutTypes,setNewNutTypes] = useState([])
    const [newNutMethods,setNewNutMethods] = useState([])
    const navigate = useNavigate();
    let today = new Date();
    const nutMethods =[
        {
            "title": "PEG",
            "id": 1
        },
        {
            "title": "JEJUNOSTOMY",
            "id": 2
        },
        {
            "title": "NASAL",
            "id": 3
        }
    ]
    const nutTypes = [
        {
            "title": "Injection",
            "id": 1
        },
        {
            "title": "Gravity",
            "id": 2
        },
        {
            "title": "Pump",
            "id": 3
        }
    ];
    const onSubmit = ()=>{
        if(state?.create){
            createPoll()
        }else{
            editPollData(state?.id)
        }
    }
    const editPollData = async (admin_id) => {
        await editPoll(admin_id,pollValue.name,selectedPosition,pollValue.question,items,status,dates ? format(dates[0], "yyyy/MM/dd") : "",
            dates && dates[1]
                ? format(dates[1], "yyyy/MM/dd")
                : "",publishDate,type,method).then((response) =>{
                if(response.success){
                    toast.current.show({
                        severity: "success",
                        summary: "Successful",
                        detail: response.message,
                        life: 3000,
                    })
                    setTimeout(() => {
                        navigate("/polls")
                    }, 1000);
                }
                else{
                    toast.current.show({
                        severity: "error",
                        summary: "Error",
                        detail: response.message,
                        life: 3000,
                    })
                }
            }
        );
    };
    useEffect(()=>{
        const transformedArray = type.map(id => {
            const matchingItem = nutTypes.find(item => item.id === parseInt(id));
            return matchingItem ? matchingItem : null;
        });
        const transformedMethodArray = method.map(id => {
            const matchingItem = nutMethods.find(item => item.id === parseInt(id));
            return matchingItem ? matchingItem : null;
        });
        setNewNutTypes(transformedArray)
        setNewNutMethods(transformedMethodArray)
    },[type,method])
    const footerContent = (
        <div>
            <Button label="No" icon="pi pi-times" onClick={() => setVisible(false)} className="p-button-text" />
            <Button label="Yes" icon="pi pi-check" onClick={() => {
                //changeStatus()
                if(activeStatus === "Active"){
                    setStatus("Passive")
                }else{
                    setStatus("Active")
                }
                setVisible(false)
            }} autoFocus />
        </div>
    );
    const handleNext = () =>{
        createPoll()
    }
    useEffect(()=>{
        if (publishNow){
            setPublishDate("now")
        }else{
            setPublishDate("schedule")
        }
    },[publishNow])
    const pollCheck = async (value) => {
        await pollsAddCheck(method,type,selectedPosition,publishDate,dates ? format(dates[0], "yyyy/MM/dd") : "",
            dates && dates[1]
                ? format(dates[1], "yyyy/MM/dd")
                : "",state,true).then((response) =>{
                if(response.success){
                    toast.current.show({
                        severity: "success",
                        summary: "Successful",
                        detail: response.message,
                        life: 3000,
                    })
                    setStatus(value)
                }
                else{
                    setActiveStatus(status)
                    setErrorMessage(response.message)
                    setHeader(response.title)
                    setProducts(response?.list)
                    setVisible(true)
                }
            }
        );
    };
    const changeStatus = async (value) => {
       if(value==="Active"){
           pollCheck(value)

       }else{
           setStatus(value)
       }
    };
    const createPoll = async () => {
        await createNewPoll(pollValue.name,method,type,selectedPosition,pollValue.question,items,status, dates ? format(dates[0], "yyyy/MM/dd") : "",
            dates && dates[1] ? format(dates[1], "yyyy/MM/dd") : "",publishDate).then((response) =>{
                if(response.success){
                    toast.current.show({
                        severity: "success",
                        summary: "Successful",
                        detail: response.message,
                        life: 3000,
                    })
                    setTimeout(() => {
                        navigate("/polls")
                    }, 1000);
                }
                else{
                    toast.current.show({
                        severity: "error",
                        summary: "Error",
                        detail: response.message,
                        life: 3000,
                    })
                }
            }
        );
    };

    return (
        <div className="nutrition-method-div">
            <Card className="nutrition-method-card">
                <Dialog header={header} draggable={false} visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)} footer={footerContent}>
                    <span>{errorMessage}</span>
                </Dialog>
                <div className="overview-body">
                    <div style={{display:"flex",alignItems:"center"}}>
                        <div className="nutrition-method-title">
                            Please review all poll information before generating.
                        </div>
                    </div>
                    <div style={{width:"100%"}}>
                        <div style={{alignItems:"flex-start"}}>
                            <div className="admin-info-wrapper">
                                <span>Status:</span>
                                <SelectButton value={status} onChange={(e) => changeStatus(e.value)} options={options} />
                            </div>
                        </div>
                    </div>
                    <Toast ref={toast}></Toast>
                    <div style={{display:"flex",justifyContent:"space-between",gap:300}}>

                        <div className="spans-wrapper">
                            <div className="step-wrapper">
                                {state.create === false ?
                                    <div><div className="spans-div" style={{gap:3}}>
                                        <span className="overview-span">Nutrition types :</span>
                                        {type?.map((typ,i)=>{
                                            return type.length-1!==i ? <span style={{fontWeight:"normal"}}>{typ.title.toUpperCase()} /</span> :<span style={{fontWeight:"normal"}}>{typ.title.toUpperCase()} </span>
                                        })}
                                    </div>
                                        <div className="spans-div" style={{gap:3}}>
                                            <span className="overview-span">Nutrition methods :</span>
                                            {method?.map((mt,i)=>{
                                                return method.length-1!==i ? <span style={{fontWeight:"normal"}}>{mt.title.toUpperCase()} /</span> :<span style={{fontWeight:"normal"}}>{mt.title.toUpperCase()} </span>
                                            })}
                                        </div></div>:
                                    <div><div className="spans-div" style={{gap:3}}>
                                        <span className="overview-span">Nutrition types :</span>
                                        {newNutTypes?.map((typ,i)=>{
                                            return type.length-1!==i ? <span style={{fontWeight:"normal"}}>{typ.title.toUpperCase()} /</span> :<span style={{fontWeight:"normal"}}>{typ.title.toUpperCase()} </span>
                                        })}
                                    </div>
                                        <div className="spans-div" style={{gap:3}}>
                                            <span className="overview-span">Nutrition methods :</span>
                                            {newNutMethods?.map((mt,i)=>{
                                                return method.length-1!==i ? <span style={{fontWeight:"normal"}}>{mt.title.toUpperCase()} /</span> :<span style={{fontWeight:"normal"}}>{mt.title.toUpperCase()} </span>
                                            })}
                                        </div></div>}

                                <div className="spans-div">
                                    <span className="overview-span">Poll Status:</span>
                                    <span style={{fontWeight:"normal"}}>{status}</span>
                                </div>
                                <div className="spans-div">
                                    <span className="overview-span">Poll Name:</span>
                                    <span style={{fontWeight:"normal"}}>{pollValue?.name}</span>
                                </div>
                            </div>
                            <div className="step-wrapper">
                                <div className="spans-div">
                                    <span className="overview-span">Poll Start Date:</span>
                                    <span style={{fontWeight:"normal"}}>{dates ? format(dates[0], "yyyy/MM/dd") : format(today, "yyyy/MM/dd")}</span>
                                </div>
                                {publishNow ? <div></div> : <div className="spans-div">
                                    <span className="overview-span">Poll End Date:</span>
                                    <span style={{fontWeight:"normal"}}>{dates !==null ? dates[1]!==null ? format(dates[1], "yyyy/MM/dd"): "-":"-"}</span>
                                </div>}
                            </div>
                            <div className="step-wrapper">
                            <div className="spans-div">
                                <span className="overview-span">Poll Position:</span>
                                <span style={{fontWeight:"normal"}}>{selectedPosition?.name}</span>
                            </div>
                            <div className="spans-div">
                                <span className="overview-span">Poll Question:</span>
                                <span style={{fontWeight:"normal"}}>{pollValue?.question}</span>
                            </div>
                            {items?.map((answer,i)=>{
                                return <div className="spans-div">
                                    <span className="overview-span">Poll Answer {i+1} :</span>
                                    <span style={{fontWeight:"normal"}}>{answer.answer}</span>
                                </div>
                            })}
                            </div>


                        </div>
                    </div>



                </div>
                <div className="poll-question-div">
                    <Button className="p-button-info" onClick={()=>setActiveItem(activeItem-1)}>Back</Button>
                    <Button className="p-button-info" onClick={()=>onSubmit()}>{state.create ? "Create" : "Edit"}</Button>
                </div>
            </Card>

        </div>
    );
};

export default OverviewPoll;