import React, { useEffect, useRef, useState } from "react";
import Header from "../../../components/header/Header";
import "./Master-dataset.css";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import MasterDataEdit from "../../../components/NLP-MasterData-Edit/MasterDataEdit";
import {
  deleteMasterDataset,
  getGeneralList,
  getMasterDataset,
  postNlpUndo,
} from "../../../API/helper";
import { Tag } from "primereact/tag";
import Lottie from "react-lottie-player";
import settingsAnimation from "../../../assets/settingsAnimation.json";
const MasterDataset = () => {
  const [byContent, setByContent] = useState("");
  const [caseLabel, setCaseLabel] = useState(null);
  const [source, setSource] = useState(null);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [visible, setVisible] = useState(false);
  const [sourceOptions, setSourceOptions] = useState(null);
  const [status, setStatus] = useState(null);
  const [statusOptions, setStatusOptions] = useState(null);
  const [loading, setLoading] = useState(false);
  const [caseLabelOptions, setCaseLabelOptions] = useState(null);
  const [masterDataList, setMasterDataList] = useState(false);
  const [undoDialogVisible, setUndoDialogVisible] = useState(false);
  const [undoInfos, setUndoInfos] = useState(null);
  const [deleteMasterID, setDeleteMasterID] = useState("");
  const [totalRecords, setTotalRecords] = useState("");
  const [rows, setRows] = useState(10);
  const [selectedCustomerData, setSelectedCustomerData] = useState({
    id: null,
    sentence: "",
    case_label: "",
    source: "",
  });
  const searchButtonRef = useRef();
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 100,
  });
  const onCaseLabelChange = (e) => {
    setCaseLabel(e.value);
  };
  const onStatusChange = (e) => {
    setStatus(e.value);
  };
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        searchButtonRef.current.click();
      }
    };
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);
  const caseLabelTemplate = (option) => {
    return (
      <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
        <span>
          <span style={{ fontWeight: "bold" }}>Label: </span>
          {option.title}
        </span>
        <span>
          <span style={{ fontWeight: "bold" }}>Description: </span>
          {option.description}
        </span>
      </div>
    );
  };
  const statusTemplate = (option) => {
    return (
      <div>
        <Tag severity={option.severity} value={option.value}></Tag>
      </div>
    );
  };
  const statusValueTemplate = (option) => {
    return (
      <div>
        <Tag severity={option.severity} value={option.value}></Tag>
      </div>
    );
  };

  const getGeneralData = async () => {
    const generalData = await getGeneralList();
    if (generalData) {
      setCaseLabelOptions(generalData.data.list.nlp_labels);
      setSourceOptions(generalData.data.list.nlp.case_label.source_list);
      setStatusOptions(generalData.data.list.change_log.actions);
    }
  };
  const handleEdit = (rowData) => {
    let sentence = rowData.sentence;
    let caseLabel = rowData.case_label;
    if (rowData.change_logs?.list.length > 0) {
      rowData.change_logs?.list.forEach((log) => {
        if (log.type === "sentence") {
          sentence = log.new_value;
        }
        if (log.type === "case_label") {
          caseLabel = log.new_value;
        }
      });
    }
    setSelectedCustomerData({
      id: rowData.id,
      sentence: sentence,
      case_label: caseLabel,
      source: rowData.source,
    });
  };
  const handleOnPage = (event) => {
    setRows(event.rows);
    setLazyParams(event);
  };
  const onSourceChange = (e) => {
    setSource(e.value);
  };

  const caseLabelColumnTemp = (rowData) => {
    let changedSentence;
    let type;

    rowData.change_logs.list?.forEach((log) => {
      if (log.type === "case_label") {
        changedSentence = log.new_value;
        type = log.type;
      }
    });
    if (rowData.change_logs.list?.length > 0 && type === "case_label") {
      return (
        <React.Fragment>
          <div
            style={{
              opacity: 0.5,
              textDecoration:
                rowData.change_logs.last_action === "deleted"
                  ? "line-through"
                  : "none",
            }}
          >
            {rowData.case_label}
          </div>
          <div
            style={{
              textDecoration:
                rowData.change_logs.last_action === "deleted"
                  ? "line-through"
                  : "none",
              marginTop: 5,
            }}
          >
            {changedSentence}
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div
            style={{
              textDecoration:
                rowData.change_logs.last_action === "deleted"
                  ? "line-through"
                  : "none",
            }}
          >
            {rowData.case_label}
          </div>
        </React.Fragment>
      );
    }
  };
  const sentenceBody = (rowData) => {
    let changedSentence;
    let type;
    rowData.change_logs.list?.forEach((log) => {
      if (log.type === "sentence") {
        changedSentence = log.new_value;
        type = log.type;
      }
    });
    if (rowData.change_logs.list?.length > 0 && type === "sentence") {
      return (
        <React.Fragment>
          <div
            style={{
              opacity: 0.5,
              textDecoration:
                rowData.change_logs.last_action === "deleted"
                  ? "line-through"
                  : "none",
              marginLeft: 10,
            }}
          >
            {rowData.sentence}
          </div>
          <div
            className="clear-button"
            style={{
              textDecoration:
                rowData.change_logs.last_action === "deleted"
                  ? "line-through"
                  : "none",
              marginTop: 5,
            }}
            onClick={() => {
              setVisible(true);
              handleEdit(rowData);
            }}
          >
            {changedSentence}
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div
            style={{
              textDecoration:
                rowData.change_logs.last_action === "deleted"
                  ? "line-through"
                  : "none",
            }}
            className="clear-button"
            onClick={() => {
              setVisible(true);
              handleEdit(rowData);
            }}
          >
            {rowData.sentence}
          </div>
        </React.Fragment>
      );
    }
  };

  const sourceBody = (rowData) => {
    return (
      <React.Fragment>
        <div
          style={{
            textDecoration:
              rowData.change_logs.last_action === "deleted"
                ? "line-through"
                : "none",
          }}
        >
          {rowData.source}
        </div>
      </React.Fragment>
    );
  };

  const tableTags = (rowData) => {
    let value = "";
    let severity = "";

    if (rowData.change_logs.last_action === "deleted") {
      value = "Deleted";
      severity = "danger";
    } else if (rowData.change_logs.last_action === "updated") {
      value = "Updated";
      severity = "warning";
    } else if (rowData.change_logs.last_action === "added") {
      value = "Added";
      severity = "success";
    } else {
      return null;
    }

    return (
      <React.Fragment>
        <div>
          <Tag value={value} severity={severity}></Tag>
        </div>
      </React.Fragment>
    );
  };
  useEffect(() => {
    loadLazyData();
  }, [lazyParams]);


  const getMasterData = async () => {
    setLoading(true);
    const response = await getMasterDataset(
      caseLabel ? caseLabel.title : "",
      lazyParams.page + 1,
      lazyParams.rows,
      byContent,
      source ? source.source : "",
      status ? status : ""
    );
    setTotalRecords(response.data.paging.total);
    setMasterDataList(response.data.list);
    setLoading(false);
  };
  const getClearMasterData = async () => {
    setLoading(true);
    const response = await getMasterDataset(
      "",
      lazyParams.page + 1,
      lazyParams.rows,
      byContent,
      "",
      ""
    );
    setTotalRecords(response.data.paging.total);
    setMasterDataList(response.data.list);
    setLoading(false);
  };
  const loadLazyData = () => {
    getMasterData();
  };
  useEffect(() => {
    getGeneralData();
  }, []);
  useEffect(() => {
    if (caseLabel === null && source === null && byContent === "") {
      getMasterData();
    }
  }, [caseLabel, source, byContent]);

  const deleteMaster = async () => {
    await deleteMasterDataset(deleteMasterID);
    getMasterData();
  };
  const deleteBody = (rowData) => {
    if (rowData.change_logs.last_action === "deleted") {
      return (
        <React.Fragment>
          <button
            onClick={() => {
              setUndoInfos(rowData);
              setUndoDialogVisible(true);
            }}
            style={{
              fontSize: "1.4rem",
              backgroundColor: "rgb(154 170 183)",
              color: "white",
              padding: "9px",
              border: "1px solid rgb(154 170 183)",
              borderRadius: "7px",
              cursor: "pointer",
            }}
            className="p-button-raised pi pi-undo"
          ></button>
        </React.Fragment>
      );
    } else if (rowData.change_logs.last_action === "updated") {
      return (
        <React.Fragment>
          <div style={{ display: "flex", gap: "5px" }}>
            <button
              onClick={() => {
                setUndoInfos(rowData);
                setUndoDialogVisible(true);
              }}
              style={{
                fontSize: "1.4rem",
                backgroundColor: "rgb(154 170 183)",
                color: "white",
                padding: "9px",
                border: "1px solid rgb(154 170 183)",
                borderRadius: "7px",
                cursor: "pointer",
              }}
              className="p-button-raised pi pi-undo"
            ></button>
            <button
              onClick={() => {
                setDeleteMasterID(rowData.id);
                setDialogVisible(true);
              }}
              className="p-button-raised  pi pi-trash"
              style={{
                fontSize: "1.4rem",
                backgroundColor: "#ff7b5a",
                color: "white",
                padding: "9px",
                border: "1px solid #ff7b5a",
                borderRadius: "7px",
                cursor: "pointer",
              }}
            ></button>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <button
            onClick={() => {
              setDeleteMasterID(rowData.id);
              setDialogVisible(true);
            }}
            className="p-button-raised  pi pi-trash"
            style={{
              fontSize: "1.4rem",
              backgroundColor: "#ff7b5a",
              color: "white",
              padding: "9px",
              border: "1px solid #ff7b5a",
              borderRadius: "7px",
              cursor: "pointer",
            }}
          ></button>
        </React.Fragment>
      );
    }
  };
  const undoMaster = async () => {
    let id = undoInfos.id;
    let action = undoInfos.change_logs.last_action;
    if (undoInfos.change_logs.last_action.length > 0) {
      await postNlpUndo(id, action);
      getMasterData();
    }
  };

  return (
    <div>
      {loading ? (
        <div className="settings-loader">
          <Lottie
            loop
            animationData={settingsAnimation}
            play
            style={{ width: 400, height: 400 }}
          />
        </div>
      ) : (
        <div>
          <Header items={[{ label: "Master Dataset" }]} />
          <Dialog
            header={"Undo Changes"}
            visible={undoDialogVisible}
            onHide={() => setUndoDialogVisible(false)}
            style={{ width: "50vw" }}
            breakpoints={{ "960px": "75vw", "641px": "100vw" }}
          >
            <div style={{ fontWeight: "bold", marginTopTop: 10 }}>
              <span>Are you sure you want to undo the changes made?</span>
            </div>
            <div className="validation-dialog-buttons">
              <Button
                className="p-button-raised p-button-outlined"
                onClick={() => setUndoDialogVisible(false)}
              >
                No
              </Button>
              <Button
                onClick={() => {
                  undoMaster();
                  setUndoDialogVisible(false);
                }}
                label="Yes"
              />
            </div>
          </Dialog>
          <Dialog
            header={"Deleting Sentence"}
            visible={dialogVisible}
            onHide={() => setDialogVisible(false)}
            style={{ width: "50vw" }}
            breakpoints={{ "960px": "75vw", "641px": "100vw" }}
          >
            <div style={{ fontWeight: "bold", marginTopTop: 10 }}>
              <span>
                You are about to delete the selected sentence. Do you confirm ?
              </span>
            </div>
            <div className="validation-dialog-buttons">
              <Button
                className="p-button-raised p-button-outlined"
                onClick={() => setDialogVisible(false)}
              >
                No
              </Button>
              <Button
                onClick={() => {
                  deleteMaster();
                  setDialogVisible(false);
                }}
                label="Yes"
              />
            </div>
          </Dialog>
          <div className="masterDataSet">
            <div className="masterTop">
              <div className="masterMid">
                <InputText
                  placeholder="By content"
                  style={{ width: "20%" }}
                  value={byContent}
                  onChange={(e) => setByContent(e.target.value)}
                />
                <Dropdown
                  style={{ width: "20%" }}
                  value={status}
                  itemTemplate={statusTemplate}
                  options={statusOptions}
                  placeholder="Select Status"
                  onChange={onStatusChange}
                  optionLabel={statusValueTemplate}
                  showClear
                />
                <Dropdown
                  className="master-dataset-dropdown"
                  value={caseLabel}
                  itemTemplate={caseLabelTemplate}
                  filter
                  options={caseLabelOptions}
                  placeholder="Select Case Label"
                  onChange={onCaseLabelChange}
                  optionLabel="title"
                  showClear
                />
                <Dropdown
                  value={source}
                  style={{ width: "20%" }}
                  onChange={onSourceChange}
                  options={sourceOptions}
                  virtualScrollerOptions={{ itemSize: 38 }}
                  placeholder="Source"
                  optionLabel="source"
                  className="w-full md:w-14rem"
                  showClear
                />
              </div>

              <div style={{ display: "flex" }}>
                <Button
                  ref={searchButtonRef}
                  label="Search"
                  onClick={() => getMasterData()}
                />
                <div
                  className="clear-button"
                  onClick={() => {
                    setByContent("");
                    setCaseLabel(null);
                    setSource(null);
                    setStatus(null);
                    getClearMasterData();
                  }}
                >
                  Clear Filter
                </div>
              </div>
            </div>
            <div className="masterBot">
              <DataTable
                value={masterDataList}
                paginator
                dataKey="id"
                rows={rows}
                lazy
                selectionMode
                totalRecords={totalRecords}
                onPage={handleOnPage}
                first={lazyParams.first}
                rowsPerPageOptions={[5, 25, 50, 100]}
                tableStyle={{ minWidth: "50rem" }}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
              >
                <Column field="id" header="ID"></Column>
                <Column body={tableTags}></Column>
                <Column
                  field="sentence"
                  header="SENTENCE"
                  body={sentenceBody}
                  style={{ width: "55%" }}
                />
                <Column
                  field="case_label"
                  header="CASE LABEL"
                  body={caseLabelColumnTemp}
                  style={{ width: "20%" }}
                ></Column>
                <Column
                  field="source"
                  header="SOURCE"
                  body={sourceBody}
                  style={{ width: "55%" }}
                ></Column>
                <Column style={{ width: "10%" }} />
                <Column
                  body={deleteBody}
                  style={{ width: "10%", textAlign: "right" }}
                />
              </DataTable>
              <Dialog
                header="SENTENCE"
                visible={visible}
                onHide={() => setVisible(false)}
                style={{ width: "50vw" }}
                breakpoints={{ "960px": "75vw", "641px": "100vw" }}
              >
                <MasterDataEdit
                  selectedCustomerData={selectedCustomerData}
                  setDialogVisible={setVisible}
                  getMasterData={getMasterData}
                />
              </Dialog>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MasterDataset;
