import React from "react";
import "./Widget-Chat.css";
function WidgetChat({
  templates,
  showMenu,
  showResetMenu,
  translations,
  toggleInputBox,
  chats,
  isAbout,
  tempTranslations,
}) {
  return (
    <div className="widget-chat-wrapper">
      <div className="App-chat">
        <div className="widget-header">
          <div className="header-button">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-more-horizontal"
            >
              <circle cx="12" cy="12" r="1"></circle>
              <circle cx="19" cy="12" r="1"></circle>
              <circle cx="5" cy="12" r="1"></circle>
            </svg>
          </div>
          <div className="header-middle">
            <div
              style={{ fontFamily: `${templates?.fontFamily}` }}
              className="header-title"
            >
              Diginurse
            </div>
          </div>
          <div className="header-button">
            <div
              className="header-popover-menu"
              style={{ display: showMenu ? "flex" : "none" }}
            >
              <div className="header-popover-menu-item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-refresh-ccw"
                >
                  <polyline points="1 4 1 10 7 10"></polyline>
                  <polyline points="23 20 23 14 17 14"></polyline>
                  <path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"></path>
                </svg>
                <span style={{ fontFamily: `${templates?.fontFamily}` }}>
                  {translations?.cardOverlay1 === ""
                    ? "Start Over"
                    : translations.cardOverlay1}
                </span>
              </div>
              <div
                style={{ fontFamily: `${templates?.fontFamily}` }}
                className="header-popover-menu-item"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-info"
                >
                  <circle cx="12" cy="12" r="10"></circle>
                  <line x1="12" y1="16" x2="12" y2="12"></line>
                  <line x1="12" y1="8" x2="12.01" y2="8"></line>
                </svg>
                <span style={{ fontFamily: `${templates?.fontFamily}` }}>
                  {translations?.cardOverlay2 === ""
                    ? "About"
                    : translations.cardOverlay2}
                </span>
              </div>
            </div>
            <div
              className="flex-container"
              style={{ display: showResetMenu ? "flex" : "none" }}
            >
              <div className="reset-container">
                <div
                  style={{ fontFamily: `${templates?.fontFamily}` }}
                  className="app-description"
                >
                  {translations?.resetStore === ""
                    ? "Do you want to clear all stored data?"
                    : translations.resetStore}
                </div>
                <div className="row">
                  <div className="col">
                    <button
                      style={{ fontFamily: `${templates?.fontFamily}` }}
                    >
                      {translations?.yes === "" ? "Yes" : translations?.yes}
                    </button>
                  </div>
                  <div className="col">
                    <button
                      style={{ fontFamily: `${templates?.fontFamily}` }}
                    >
                      {translations?.no === "" ? "No" : translations?.no}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-chevron-down"
            >
              <polyline points="6 9 12 15 18 9"></polyline>
            </svg>
          </div>
        </div>
        {isAbout ? (
          <div className="about-page">
            <div className="about-container">
              <div className="close-about-page">
                <div className="close-about-button">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                  </svg>
                </div>
              </div>
              <img
                src="https://nursen.s3.eu-west-1.amazonaws.com/assets/chat-bot-logo-54538d7e.png"
                alt="logo"
              />
              <div className="app-title">Diginurse</div>
              <div className="app-version">version 1.1</div>
              <div
                style={{ fontFamily: `${templates?.fontFamily}` }}
                className="app-description"
              >
                {translations?.about === ""
                  ? "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut " +
                    "labore et dolore magna aliqua."
                  : translations?.about}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div
          className="conversation-container"
          style={{
            display: isAbout ? "none" : "flex",
            filter: showMenu || showResetMenu ? "blur(5px)" : "blur(0)",
          }}
        >
          <div
            className="chat-bubbles"
            style={{ filter: chats ? "blur(5px)" : "blur(0)" }}
          >
            <div className="chat-bubble">
              <div
                className="chat-bubble-content"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <div className="avatar" style={{ opacity: 1 }}>
                  <img
                    alt=""
                    draggable="false"
                    className="avatar-image"
                    src={
                      templates?.businessIcon === ""
                        ? "https://nursen.s3.eu-west-1.amazonaws.com/icon/default/user-1.png"
                        : templates?.businessIcon
                    }
                  />
                </div>
                <div
                  className="bubble-body rounded-top "
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <div
                    className="message-content left"
                    style={{
                      color: `${
                        templates?.botFontColor === "#FFFFFF"
                          ? ""
                          : templates?.botFontColor
                      }`,
                      backgroundColor: `${
                        templates?.botBubbleColor === "#FFFFFF"
                          ? ""
                          : templates?.botBubbleColor
                      }`,
                      fontFamily: `${templates?.fontFamily}`,
                    }}
                  >
                    <div
                        style={{ fontFamily: `${templates?.fontFamily}` }}
                      className="message-text"
                    >
                      Hello, my name is Diginurse and I would like to help you
                      during your feeding period.
                    </div>
                    <div
                        style={{ fontFamily: `${templates?.fontFamily}` }}
                      className="message-time"
                    >
                      20:09
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="chat-bubble">
              <div
                className="chat-bubble-content"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <div className="avatar" style={{ opacity: 0 }}>
                  <img
                    alt=""
                    draggable="false"
                    className="avatar-image"
                    src={
                      translations === undefined
                        ? "https://nursen.s3.eu-west-1.amazonaws.com/icon/default/user-1.png"
                        : templates?.businessIcon === ""
                        ? "https://nursen.s3.eu-west-1.amazonaws.com/icon/default/user-1.png"
                        : templates?.businessIcon
                    }
                  />
                </div>
                <div
                  className="bubble-body  rounded-bottom"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <div
                    className="message-content left"
                    style={{
                      color: `${
                        templates?.botFontColor === "#111111"
                          ? ""
                          : templates?.botFontColor
                      }`,
                      backgroundColor: `${
                        templates?.botBubbleColor === "#FFFFFF"
                          ? ""
                          : templates?.botBubbleColor
                      }`,
                      fontFamily: `${templates?.fontFamily}`,
                    }}
                  >
                    <div
                        style={{ fontFamily: `${templates?.fontFamily}` }}
                      className="message-text"
                    >
                      I would like to know your feeding method.
                    </div>
                    <div
                        style={{ fontFamily: `${templates?.fontFamily}` }}
                      className="message-time"
                    >
                      20:09
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="chat-bubble">
              <div
                className="chat-bubble-content"
                style={{ display: "flex", flexDirection: "row-reverse" }}
              >
                <div className="avatar" style={{ opacity: 1 }}>
                  <img
                    alt=""
                    draggable="false"
                    className="avatar-image"
                    src={
                      templates?.customerIcon === ""
                        ? "https://nursen.s3.eu-west-1.amazonaws.com/icon/default/user-2.png"
                        : templates?.customerIcon
                    }
                  />
                </div>
                <div
                  className="bubble-body rounded-top rounded-bottom"
                  style={{ display: "flex", flexDirection: "row-reverse" }}
                >
                  <div
                    className="message-content right"
                    style={{
                      color: `${
                        templates?.userFontColor === "#FFFFFF"
                          ? ""
                          : templates?.userFontColor
                      }`,
                      backgroundColor: `${
                        templates?.userBubbleColor === "#111111"
                          ? ""
                          : templates?.userBubbleColor
                      }`,
                      fontFamily: `${templates?.fontFamily}`,
                    }}
                  >
                    <div
                      style={{ fontFamily: `${templates?.fontFamily}`}}
                      className="message-text"
                    >
                      JEJUNOSTOMY
                    </div>
                    <div
                        style={{ fontFamily: `${templates?.fontFamily}`, fontWeight:500,color:"white !important"}}
                      className="message-time"
                    >
                      20:19
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="chat-bubble">
              <div
                className="chat-bubble-content"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <div className="avatar" style={{ opacity: 1 }}>
                  <img
                    alt=""
                    draggable="false"
                    className="avatar-image"
                    src={
                      templates?.businessIcon === ""
                        ? "https://nursen.s3.eu-west-1.amazonaws.com/icon/default/user-1.png"
                        : templates?.businessIcon
                    }
                  />
                </div>
                <div
                  className="bubble-body rounded-top rounded-bottom"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <div
                    className="message-content left"
                    style={{
                      color: `${
                        templates?.botFontColor === "#FFFFFF"
                          ? ""
                          : templates?.botFontColor
                      }`,
                      backgroundColor: `${
                        templates?.botBubbleColor === "#FFFFFF"
                          ? ""
                          : templates?.botBubbleColor
                      }`,
                      fontFamily: `${templates?.fontFamily}`,
                    }}
                  >
                    <div
                      style={{ fontFamily: `${templates?.fontFamily}` }}
                      className="message-text"
                    >
                      Thank you, now may I know the feeding type?
                    </div>
                    <div style={{color:"black!important"}} className="message-time">20:19</div>
                  </div>
                </div>
              </div>
              <div className="options-wrapper">
                <div
                  className="option"
                  style={{
                    color: `${
                      templates?.buttonColor === ""
                        ? "#FFFFFF"
                        : templates?.buttonColor
                    }`,
                    backgroundColor: `${
                      templates?.button === "" ? "#026BFD" : templates?.button
                    }`,
                    fontFamily: `${templates?.fontFamily}`,
                  }}
                >
                  Injection
                </div>
                <div
                  className="option"
                  style={{
                    color: `${
                      templates?.buttonColor === ""
                        ? "#FFFFFF"
                        : templates?.buttonColor
                    }`,
                    backgroundColor: `${
                      templates?.button === "" ? "#026BFD" : templates?.button
                    }`,
                    fontFamily: `${templates?.fontFamily}`,
                  }}
                >
                  Gravity
                </div>
                <div
                  className="option"
                  style={{
                    color: `${
                      templates?.buttonColor === ""
                        ? "#FFFFFF"
                        : templates?.buttonColor
                    }`,
                    backgroundColor: `${
                      templates?.button === "" ? "#026BFD" : templates?.button
                    }`,
                    fontFamily: `${templates?.fontFamily}`,
                  }}
                >
                  Pump
                </div>
              </div>
            </div>
          </div>
          {toggleInputBox === "button" ? (
            <button
              style={{ fontFamily: `${templates?.fontFamily}` }}
              className="start-over-button"
            >
              {translations?.continueChat === ""
                ? "Do you have another problem I can help with?"
                : translations?.continueChat}
            </button>
          ) : (
            <div className="chat-input-wrapper">
              <form
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div
                  style={{ fontFamily: `${templates?.fontFamily}` }}
                  className="text-input"
                >
                  {translations?.inputPlaceholder === ""
                    ? "Type The Message..."
                    : translations.inputPlaceholder}
                </div>
                <button className="send-button">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-send send-button"
                  >
                    <line x1="22" y1="2" x2="11" y2="13"></line>
                    <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                  </svg>
                </button>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default WidgetChat;
