import React, {useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import axios from 'axios';
import {
    changePollStatus,
    deletePollByID, deleteProductPollByID,
    getPollList,
    getPollsDetailAnswers,
    getProductPollList
} from "../../../API/helper";
import {Button} from "primereact/button";
import Lottie from "react-lottie-player";
import settingsAnimation from "../../../assets/settingsAnimation.json";
import Header from "../../../components/header/Header";
import {Dialog} from "primereact/dialog";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Toast} from "primereact/toast";
import {InputSwitch} from "primereact/inputswitch";
import {FileUpload} from "primereact/fileupload";

function ProductPolls(props) {
    const [products, setProducts] = useState(null);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [loading,setLoading]=useState(false)
    const [rows, setRows] = useState(10);
    const [totalRecords,setTotalRecord]=useState(0)
    const [visible,setVisible]=useState(false)
    const [rowID,setRowID]=useState(false)
    const toast = useRef(null);
    const dt = useRef(null);
    const { state } = useLocation();
    let token=""
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 0,
        sortField: null,
        sortOrder: null,
        filters: {
            name: { value: "", matchMode: "contains" },
            "country.name": { value: "", matchMode: "contains" },
            company: { value: "", matchMode: "contains" },
            "representative.name": { value: "", matchMode: "contains" },
        },
    });
    const navigate = useNavigate();
    const getToken = () => {
        if (token === "") {
            token = localStorage.getItem("token");
            return token;
        } else {
            return token;
        }
    };
    const handleFileUpload = async (event) => {
        const file = event.files[0];
        const formData = new FormData();
        formData.append('file', file);
        const uploadResponse = await axios.post('http://diginurse-200089454.eu-central-1.elb.amazonaws.com/admin/v1/products/import-excel', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${getToken()}`
            }
        }).then((resp)=>{
            if(resp.statusText==="OK"){
                toast.current.show({
                    severity: "success",
                    summary: "Successful",
                    life: 3000,
                })
                setTimeout(() => {
                    window.location.reload()
                }, 1000);
            }
        });

    };
    const deletePollData = async (poll_id) => {
        await deleteProductPollByID(poll_id).then((response) =>{
                if(response.success){
                    toast.current.show({
                        severity: "success",
                        summary: "Successful",
                        detail: response.message,
                        life: 3000,
                    })
                    getPolls(1,10)
                }
                else{
                    toast.current.show({
                        severity: "error",
                        summary: "Error",
                        detail: response.message,
                        life: 3000,
                    })
                }
            }
        );
    };
    useEffect(()=>{
        if(selectedProducts!==null){
            navigate("/polls/details",{state:{id:selectedProducts.id,create:false}})
        }
    },[selectedProducts])
    const getPolls = async (page,row)=>{
        setLoading(true)
        await getProductPollList(page,row).then((res)=>{
            setProducts(res.data.list)
            setTotalRecord(res.data.paging.total);
            setLoading(false)
        })
    }
    const nutMethodBody = (rowData) => {
        return (
            <React.Fragment>
                <div>
                    {rowData?.methods.map((mt)=><div style={{display:"flex"}}>{mt.title}</div>)}
                </div>
            </React.Fragment>
        );
    };
    const nutTypeBody = (rowData) => {
        return (
            <React.Fragment>
                <div>
                    {rowData?.types.map((typ)=><div style={{display:"flex"}}>{typ.title.toUpperCase()}</div>)}
                </div>
            </React.Fragment>
        );
    };

    const header = (
        <div style={{display:"flex",justifyContent:"space-between"}}>
            <div>
                <span>Product Polls</span>
            </div>
            <span className="p-input-icon-left">
                <FileUpload mode="basic" accept=".xlsx" chooseOptions={{icon: 'pi pi-file-excel'}} chooseLabel="Import Excel" auto={true} customUpload={true} uploadHandler={handleFileUpload} />
                </span>
        </div>

    );
    const footerContent = (
        <div>
            <Button label="No" icon="pi pi-times" onClick={() => setVisible(false)} className="p-button-text" />
            <Button label="Yes" icon="pi pi-check" onClick={() => {
                deletePollData(rowID)
                setVisible(false)
            }} autoFocus />
        </div>
    );
    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div style={{display:"flex",gap:10}}>
                    <Button
                        icon="pi pi-minus"
                        className="p-button p-button-danger"
                        onClick={()=> {
                            setRowID(rowData.id)
                            setVisible(true)
                        }}
                        style={{ textAlign: "left" }}
                    />
                    <Button
                        icon="pi pi-chart-bar"
                        className="p-button p-button-info"
                        onClick={()=> {
                            navigate(`/polls/product-polls/${rowData.id}`,{state:{pollID:rowData.id}})
                        }}
                        style={{ textAlign: "left" }}
                    />
                    <Button
                        icon="pi pi-pencil"
                        className="p-button p-button-warning"
                        onClick={()=> {
                            navigate("/polls/product-polls-details",{state:{id:rowData.id,create:false}})
                        }}
                        style={{ textAlign: "left" }}
                    />
                </div>

            </React.Fragment>
        );
    };


    const handleOnPage = (event) => {
        setRows(event.rows);
        setLazyParams(event);
    };
    useEffect(() => {
        loadLazyData();
    }, [lazyParams]);
    const loadLazyData = () => {
        getPolls(lazyParams.page+1,lazyParams.rows)
    };
    return (
        <div>
            {loading ? (
                <div className="settings-loader">
                    <Lottie
                        loop
                        animationData={settingsAnimation}
                        play
                        style={{ width: 400, height: 400 }}
                    />
                </div>
            ) : <div >
                <Header items={[{ label: "Product Polls" }]} />
                <Dialog header="Delete Poll" visible={visible} draggable={false} blockScroll style={{ width: '40vw',marginLeft:50 }} onHide={() => setVisible(false)} footer={footerContent}>
                    Are you sure you want to delete the poll?
                </Dialog>
                <div className="datatable-crud-demo">
                    <Toast ref={toast} />
                    <div style={{display:"flex",paddingRight:20,justifyContent:"flex-end"}}>
                        <Button className="p-button-info" onClick={()=>{
                            navigate("/polls/product-polls-details",{state:{create:true}})
                        }}>Add Product</Button>
                    </div>
                    <div style={{ padding: 20 }}>
                        <DataTable
                            ref={dt}
                            header={header}
                            value={products}
                            selection={selectedProducts}
                            dataKey="id"
                            onPage={handleOnPage}
                            paginator
                            lazy
                            loading={loading}
                            rows={rows}
                            first={lazyParams.first}
                            rowsPerPageOptions={[10, 25, 50]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} polls"
                            onSelectionChange={(e) => setSelectedProducts(e.value)}
                            totalRecords={totalRecords}
                            responsiveLayout="scroll"
                            style={{ width: "100%", border: "1px solid #dee2e6" }}
                        >
                            <Column
                                field="id"
                                header="ID"
                                style={{ minWidth: "5rem" }}
                            ></Column>
                            <Column
                                header="Product Name"
                                field="name"
                            ></Column>
                            <Column
                                header="Nutrition Methods"
                                body={nutMethodBody}
                            ></Column>
                            <Column
                                header="Nutrition Types"
                                body={nutTypeBody}
                            ></Column>
                            <Column
                                field="value"
                                header="Product Value"
                            ></Column>
                            <Column
                                header="Functions"
                                body={actionBodyTemplate}
                            ></Column>
                        </DataTable>
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default ProductPolls;