import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {createProductPoll, getCompanyList, getGeneralList} from "../../API/helper";
import Header from "../header/Header";
import {Card} from "primereact/card";
import {Toast} from "primereact/toast";
import {InputNumber} from "primereact/inputnumber";
import {Dropdown} from "primereact/dropdown";
import {AutoComplete} from "primereact/autocomplete";
import {Button} from "primereact/button";
import {Checkbox} from "primereact/checkbox";

const ProductNutritionMethods = ({activeItem, setActiveItem, method, setMethod, type, setType, state}) => {
    const toast = useRef(null);
    const navigate = useNavigate();

    const onTypeChange = (e) => {
        let _ingredients = [...type];

        if (e.checked)
            _ingredients.push(e.value);
        else
            _ingredients.splice(_ingredients.indexOf(e.value), 1);

        setType(_ingredients);
    }
    const onIngredientsChange = (e) => {
        let _ingredients = [...method];
        if (e.checked)
            _ingredients.push(e.value);
        else
            _ingredients.splice(_ingredients.indexOf(e.value), 1);
        setMethod(_ingredients);
    }

    return (
            <div className="nutrition-method-div">
                <Card className="nutrition-method-card">
                    <Toast ref={toast} />
                    <div className="nutrition-method-body" >
                        <div className="nutrition-method-title" style={{width:"100%"}}>
                            Please select nutrition method, nutrition type to add products.
                        </div>
                        <div className="radiobutton-wrapper" style={{marginTop:50}}>
                            <div style={{display:"flex",flexDirection:"column",gap:20}}>
                                <div style={{textDecoration:"underline",fontWeight:"bold"}}>Nutrition Methods</div>
                                <div className="radio-div-wrapper">
                                    <div className="flex align-items-center">
                                        <Checkbox inputId="ingredient1" disabled={!state?.create} name="is_peg" value="1" onChange={onIngredientsChange} checked={state.create===false ? method.some(item=>item.title.includes("PEG")): method.includes('1')} />
                                        <label htmlFor="ingredient1" style={{marginLeft:5}} >Peg</label>
                                    </div>
                                    <div className="flex align-items-center">
                                        <Checkbox inputId="ingredient3" disabled={!state?.create} name="is_jeju" value="2" onChange={onIngredientsChange} checked={state.create===false ? method.some(item=>item.title.includes("JEJUNOSTOMY")): method.includes('2')} />
                                        <label htmlFor="ingredient3" style={{marginLeft:5}}>Jejunostomy</label>
                                    </div>
                                    <div className="flex align-items-center">
                                        <Checkbox inputId="ingredient2" disabled={!state?.create} name="is_nasal" value="3" onChange={onIngredientsChange} checked={state.create===false ? method.some(item=>item.title.includes("NASAL")): method.includes('3')} />
                                        <label htmlFor="ingredient2" style={{marginLeft:5}}>Nasal</label>
                                    </div>
                                </div>
                            </div>
                            <div style={{display:"flex",flexDirection:"column",gap:20}}>
                                <div style={{textDecoration:"underline",fontWeight:"bold"}}>Nutrition Types</div>
                                <div className="radio-div-wrapper">
                                    <div className="flex align-items-center">
                                        <Checkbox  inputId="ingredient4" disabled={!state?.create}  name="is_injection" value="1" onChange={onTypeChange} checked={state.create===false ? type.some(item=>item.title.includes("Injection")): type.includes('1')} />
                                        <label htmlFor="ingredient4" style={{marginLeft:5}} >Injection</label>
                                    </div>
                                    <div className="flex align-items-center">
                                        <Checkbox  inputId="ingredient5" disabled={!state?.create} name="is_gravity" value="2" onChange={onTypeChange} checked={state.create===false ? type.some(item=>item.title.includes("Gravity")): type.includes('2')} />
                                        <label htmlFor="ingredient5" style={{marginLeft:5}}>Gravity</label>
                                    </div>
                                    <div className="flex align-items-center">
                                        <Checkbox  inputId="ingredient6"disabled={!state?.create} name="is_pump" value="3" onChange={onTypeChange} checked={state.create===false ? type.some(item=>item.title.includes("Pump")): type.includes('3')}/>
                                        <label htmlFor="ingredient6" style={{marginLeft:5}}>Pump</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="nutrition-method-button-div">
                        <Button className="p-button-info"  disabled={!(method.length>0 && type.length>0)} onClick={()=>{setActiveItem(activeItem+1)}} >Next</Button>
                    </div>
                </Card>
            </div>
    );
};

export default ProductNutritionMethods;