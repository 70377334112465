import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getNavbar, setActiveItem } from "../../redux/slices/navbarslice";
import { useSelector, useDispatch } from "react-redux";
import "./Sidebar.css";
import logo from "../../assets/images/nursen-logo.png";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { getAdminSlice, getLanguagesDetail } from "../../API/helper";

const SideBar = ({ onHover, country, NLP, languageRefresh }) => {
  const { navbarItems } = useSelector(getNavbar);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [hover, setHover] = useState(false);
  const activeScreen = useRef("");
  const [visible, setVisible] = useState(false);
  const [navbarArray, setNavbarArray] = useState([]);
  const [selectURL, setSelectURL] = useState("");
  const footerContent = (
    <div>
      <Button
        label="No"
        icon="pi pi-times"
        onClick={() => setVisible(false)}
        className="p-button-text"
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        onClick={() => setVisible(false)}
        autoFocus
      />
    </div>
  );
  const urlArray = window.location.href.split("/");
  activeScreen.current = urlArray[urlArray.length - 1];
  const getAdmins = async () => {
    let navArray = [];
    await getAdminSlice().then((res) => {
      res.data.list.forEach((item1) => {
        navbarItems.forEach((item2) => {
          if (item1.title === item2.title) {
            navArray.push(item2);
          }
        });
      });
      navArray.push({
        title: "Logout",
        url: "/logout",
        icon: "/static/media/logout-img.adca536b4163efd4483d.png",
        translations: false,
        auth: false,
      });
      setNavbarArray(navArray);
    });
  };
  useEffect(() => {
    getAdmins();
  }, []);

  return (
    <div
      className="nav-head"
      onMouseEnter={() => {
        onHover(true);
        setHover(true);
      }}
      onMouseLeave={() => {
        onHover(false);
        setHover(false);
      }}
    >
      <div className="menu-logo">
        <img
          alt="nursen-logo"
          width={60}
          height={60}
          src={logo}
          className="menu-logo-img"
        />
      </div>
      <div
        className="content"
        style={!hover ? { alignItems: "center" } : { alignItems: "" }}
      >
        {navbarArray.map((item, index) => {
          if (item.subMenu) {
            return (
              <div key={index}>
                <div
                  style={hover ? { display: "block" } : { display: "none" }}
                  className={`content-item-sub ${ item.url.split("/")[2] !==undefined ? 
                      activeScreen.current === item.url.split("/")[2] ? "selected" : "" :
                    activeScreen.current === item.url.split("/")[1]
                      ? "selected"
                      : ""
                  }`}
                  onClick={() => {
                    if (item.title === "Flow Translate Review") {
                      localStorage.setItem("isReview", "true");
                    } else {
                      localStorage.setItem("isReview", "false");
                    }
                      dispatch(setActiveItem(item.url));
                      navigate(`${item.url}`);

                  }}
                >
                  <span style={{ fontSize: 14 }}>{item.title}</span>
                </div>
              </div>
            );
          } else {
            return (
              <div key={index}>
                <div
                  className={`content-item ${
                    activeScreen.current!=="polls" && activeScreen.current === item.url.substring(1)
                      ? "selected"
                      : ""
                  }`}
                  onClick={() => {
                    if (item.auth) {
                      if(!item.blocked){
                        navigate(`${item.url}`);
                      }
                      dispatch(setActiveItem(item.url));
                    } else {
                      localStorage.removeItem("token");
                      localStorage.removeItem("login");
                      window.location.reload();
                    }
                  }}
                >
                  <img
                    className="side-bar-icon"
                    src={item.icon}
                    alt="side-bar-icons"
                  />
                  <span style={{ fontSize: 14 }}>{item.title}</span>
                </div>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default SideBar;
