import React, {useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {
    createAdmin,
    createCountry,
    editAdmin,
    editCountry,
    getAdminDetail,
    getCountriesDetail
} from "../../../API/helper";
import {ProgressSpinner} from "primereact/progressspinner";
import Header from "../../../components/header/Header";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {Toast} from "primereact/toast";
import {Dialog} from "primereact/dialog";
import Lottie from "react-lottie-player";
import settingsAnimation from "../../../assets/settingsAnimation.json";

function CountryDetail(props) {
    const { state } = useLocation();
    const toast = useRef(null);
    const [visible, setVisible] = useState(false);
    const [label,setLabel]=useState("")
    const [loading,setLoading]=useState(false)
    const [countryValue, setCountryValue] = useState({});
    const navigate = useNavigate();
    const getAdmin = async ()=>{
        setLoading(true)
        await getCountriesDetail(state.id).then((res)=>{
            setCountryValue(res.data.list)
            setLoading(false)
        })
    }
    const createNewCountry = async () => {
        await createCountry(countryValue).then((response) =>{
                if(response.success){
                    toast.current.show({
                        severity: "success",
                        summary: "Successful",
                        detail: response.message,
                        life: 3000,
                    })
                    setTimeout(() => {
                        navigate("/countries")
                    }, 1000);
                }
                else{
                    toast.current.show({
                        severity: "error",
                        summary: "Error",
                        detail: response.message,
                        life: 3000,
                    })
                }

            }

        );
    };
    const editCountryData = async (admin_id) => {
        await editCountry(countryValue,admin_id).then((response) =>{
                if(response.success){
                    toast.current.show({
                        severity: "success",
                        summary: "Successful",
                        detail: response.message,
                        life: 3000,
                    })
                    setTimeout(() => {
                        navigate("/countries")
                    }, 1000);
                }
                else{
                    toast.current.show({
                        severity: "error",
                        summary: "Error",
                        detail: response.message,
                        life: 3000,
                    })
                }

            }

        );
    };

    const onSubmit = ()=>{
        if(state?.create){
            createNewCountry()
        }else{
            editCountryData(state?.id)
        }
    }
    useEffect(()=>{
        if(!state?.create){
            setLabel("Country Details")
            getAdmin()
        }else{
            setLabel("Create New Country")
        }
    },[])
    return (
        <div>
            {loading ? (
                <div className="settings-loader">
                    <Lottie
                        loop
                        animationData={settingsAnimation}
                        play
                        style={{ width: 400, height: 400 }}
                    />
                </div>
            ) :  <div>
                <Header items={[{ label: 'Countries' , url: '/countries'},{ label: label }]} />
                <div style={{padding:20,display:"flex",gap:20,backgroundColor:"#f8f9fa",border:"1px solid #dee2e6",margin:10}}>
                    <div>
                        <div className="admin-wrapper">
                            <div className="admin-info-wrapper">
                                <span>Code:</span>
                                <InputText value={countryValue.code} onChange={(e) => setCountryValue({...countryValue,code:e.target.value})} />
                            </div>
                            <div className="admin-info-wrapper">
                                <span>Title:</span>
                                <InputText value={countryValue.title} onChange={(e) => setCountryValue({...countryValue,title:e.target.value})} />
                            </div>
                            <div className="admin-info-wrapper">
                                <span>Local:</span>
                                <InputText value={countryValue.locale} onChange={(e) => setCountryValue({...countryValue,locale:e.target.value})} />
                            </div>

                        </div>
                    </div>
                </div>
                <div style={{display:"flex",paddingRight:20,justifyContent:"flex-end",marginBottom:20}}>
                    <Button className="p-button-info"
                            onClick={()=>{onSubmit()
                            }}>{state?.create ? "Create New Country":"Edit Country Data"}</Button>
                </div>
                <div className="datatable-crud-demo">
                    <Toast ref={toast} />
                    <Dialog header="Chat Messages" visible={visible} draggable={false} blockScroll style={{ width: '40vw',marginLeft:50 }} onHide={() => setVisible(false)}>
                    </Dialog>
                </div>
            </div>}
        </div>

    )
}

export default CountryDetail;