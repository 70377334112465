import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getAllCountryLanguage,
  getAllTranslations,
  getGeneralList,
  getInProgressTranslations,
  getWaitingTranslations,
} from "../../API/helper";
import Header from "../../components/header/Header";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { TabPanel, TabView } from "primereact/tabview";
import { ProgressBar } from "primereact/progressbar";
import "./Translations.css";
import Lottie from "react-lottie-player";
import settingsAnimation from "../../assets/settingsAnimation.json";

const Translations = () => {
  const [nutritionMethods, setNutritionMethods] = useState([]);
  const [nutritionApplicationMethods, setNutritionApplicationMethods] =
    useState([]);
  const [products, setProducts] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(10);
  const [waitingRows, setWaitingRows] = useState(10);
  const [doneRows, setDoneRows] = useState(10);
  const [country, setCountry] = useState(null);
  const [waitingProduct, setWaitingProduct] = useState(null);
  const [onProcessProduct, setOnProcessProduct] = useState(null);
  const [waitingSize, setWaitingSize] = useState(0);
  const [totalRatio, setTotalRatio] = useState(0);
  const [onProcessSize, setOnProcessSize] = useState(0);
  const [doneSize, setDoneSize] = useState(0);
  const [allCountries, setAllCountries] = useState([]);
  const toast = useRef(null);
  const menu = useRef(null);
  const dt = useRef(null);
  const [doneLazyParams, setDoneLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: null,
  });
  const [waitingLazyParams, setWaitingLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: null,
  });
  const [onProcessLazyParams, setOnProcessLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: null,
  });
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getFlowData = async (flowListData, country_id) => {
    // setLoading(true);
    await getAllTranslations(
      localStorage.getItem("lang_id"),
      localStorage.getItem("country_id"),
      flowListData.page,
      doneLazyParams.rows
    ).then((res) => {
      if (res.status) {
        setProducts(res.doneList);
        setTotalRecords(res.donePaging.total);
        setDoneSize(res.donePaging.total);
        // setLoading(false);
      }
    });
  };
  const getWaitingData = async (flowListData, country_id) => {
    await getWaitingTranslations(
      localStorage.getItem("lang_id"),
      localStorage.getItem("country_id"),
      flowListData.page,
      waitingLazyParams.rows
    ).then((res) => {
      if (res.status) {
        setWaitingProduct(res.waitingList);
        setWaitingSize(res.waitingPaging.total);
      }
    });
  };
  const getInProgressData = async (flowListData, country_id) => {
    await getInProgressTranslations(
      localStorage.getItem("lang_id"),
      localStorage.getItem("country_id"),
      flowListData.page,
      onProcessLazyParams.rows
    ).then((res) => {
      if (res.status) {
        setOnProcessProduct(res.onProcessList);
        setOnProcessSize(res.onProcessPaging.total);
      }
    });
  };
  const getGeneraData = async () => {
    const generalData = await getGeneralList();
    if (generalData) {
      setNutritionMethods(generalData.data.list.nutrition_method);
      setNutritionApplicationMethods(generalData.data.list.nutrition_type);
    }
  };
  const getCountryLanguages = async () => {
    await getAllCountryLanguage().then((res) => {
      setTotalRatio(
        res.list[localStorage.getItem("translation_selection_id") - 1]
          .total_as_ratio * 100
      );
      setAllCountries(res.list);
    });
  };
  const caseLabelTemplate = (option) => {
    return (
      <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
        <span>
          <span style={{ fontWeight: "bold" }}>Label: </span>
          {option.title}
        </span>
        <span>
          <span style={{ fontWeight: "bold" }}>Description: </span>
          {option.description}
        </span>
      </div>
    );
  };
  const { state } = useLocation();

  useEffect(() => {
    if (state) {
      getWaitingData(
        { page: waitingLazyParams.page + 1, limit: waitingLazyParams.rows },
        localStorage.getItem("lang_id")
      );
      getFlowData(
        { page: doneLazyParams.page + 1, limit: doneLazyParams.rows },
        localStorage.getItem("lang_id")
      );
      getInProgressData(
        { page: onProcessLazyParams.page + 1, limit: onProcessLazyParams.rows },
        localStorage.getItem("lang_id")
      );
      getCountryLanguages();
    } else {
      getCountryLanguages();
    }
  }, []);

  const header = (type) => (
    <div>
      <span>
        {localStorage.getItem("country_name")} {type}
      </span>
    </div>
  );
  const tabHeader = (type, size) => (
    <div>
      <span>
        {type} ({size})
      </span>
    </div>
  );

  useEffect(() => {
    getGeneraData();
  }, []);

  useEffect(() => {
    loadLazyData();
  }, [onProcessLazyParams]);

  useEffect(() => {
    loadLazyWaitingData();
  }, [waitingLazyParams]);

  useEffect(() => {
    loadLazyDoneData();
  }, [doneLazyParams]);

  useEffect(() => {
    getWaitingData(
      { page: waitingLazyParams.page + 1, limit: waitingLazyParams.rows },
      localStorage.getItem("lang_id")
    );
    getFlowData(
      { page: doneLazyParams.page + 1, limit: doneLazyParams.rows },
      localStorage.getItem("lang_id")
    );
    getInProgressData(
      { page: onProcessLazyParams.page + 1, limit: onProcessLazyParams.rows },
      localStorage.getItem("lang_id")
    );
  }, []);

  const loadLazyData = () => {
    getInProgressData(
      { page: onProcessLazyParams.page + 1, limit: onProcessLazyParams.rows },
      localStorage.getItem("lang_id")
    );
  };
  const loadLazyWaitingData = () => {
    getWaitingData(
      { page: waitingLazyParams.page + 1, limit: waitingLazyParams.rows },
      localStorage.getItem("lang_id")
    );
  };
  const loadLazyDoneData = () => {
    getFlowData(
      { page: doneLazyParams.page + 1, limit: doneLazyParams.rows },
      localStorage.getItem("lang_id")
    );
  };

  const handleOnProcessPage = (event) => {
    setRows(event.rows);
    setOnProcessLazyParams(event);
  };
  const handleOnWaitingPage = (event) => {
    setWaitingRows(event.rows);
    setWaitingLazyParams(event);
  };
  const handleOnDonePage = (event) => {
    setDoneRows(event.rows);
    setDoneLazyParams(event);
  };

  const tagBody = (rowData) => {
    return (
      <React.Fragment>
        <div
          onClick={() => {
            navigate(`/translations/flow-translations/${rowData.id}`, {
              state: {
                selectedProduct: rowData,
                initialStatus: rowData.status,
                country_id: country?.country_id,
                lang_id: country?.lang_id,
                ratio: rowData?.ratio,
              },
            });
          }}
          style={{ color: "#1d4ed8", cursor: "pointer" }}
        >
          {rowData.title}
        </div>
      </React.Fragment>
    );
  };
  const nutritionMethodBody = (rowData) => {
    let methodData = nutritionApplicationMethods.find(
      (method) => method.id === rowData.nutrition_type_id
    );
    return <div>{methodData?.title}</div>;
  };
  const nutritionTypeBody = (rowData) => {
    let methodData = nutritionMethods.find(
      (method) => method.id === rowData.nutrition_method_id
    );
    return <div>{methodData?.title}</div>;
  };

  const getColor = (data) => {
    let color;
    if (data < 30) {
      color = "#EF4444";
    } else if (data > 30 && data < 60) {
      color = "#F59E0B";
    } else {
      color = "#22C55E";
    }
    return color;
  };
  const progressBody = (rowData) => {
    let color;
    if (rowData.ratio * 100 < 30) {
      color = "#EF4444";
    } else if (rowData.ratio * 100 > 30 && rowData.ratio * 100 < 60) {
      color = "#F59E0B";
    } else {
      color = "#22C55E";
    }
    return (
      <React.Fragment>
        <div style={{ position: "relative", flexDirection: "row" }}>
          <span
            style={{
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              zIndex: 999,
              top: 4,
              color: "black",
              fontWeight: "bold",
            }}
          >
            {parseInt(rowData.ratio * 100)}%
          </span>
          <ProgressBar
            value={parseInt(rowData.ratio * 100)}
            color={color}
          ></ProgressBar>
        </div>
      </React.Fragment>
    );
  };
  return (
    <div>
      {loading ? (
        <div className="settings-loader">
          <Lottie
            loop
            animationData={settingsAnimation}
            play
            style={{ width: 400, height: 400 }}
          />
        </div>
      ) : (
        <div>
          <Header
            items={[
              {
                label: "Language Selection",
                url: "/translations/translations-selection",
              },
              { label: "Translations" },
            ]}
          />
          <div
            style={{
              position: "absolute",
              right: 20,
              zIndex: 999,
              top: 30,
              display: "flex",
              gap: 10,
              alignItems: "center",
            }}
          >
            <span className="translation-total-ratio">Total Ratio</span>
            <React.Fragment>
              <div style={{ position: "relative", flexDirection: "row" }}>
                <span
                  style={{
                    position: "absolute",
                    left: "50%",
                    transform: "translateX(-50%)",
                    zIndex: 999,
                    top: 4,
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  {totalRatio.toString().substring(0, 3)}%
                </span>
                <ProgressBar
                  style={{ width: 250 }}
                  value={totalRatio.toString().substring(0, 3)}
                  color={getColor(totalRatio)}
                ></ProgressBar>
              </div>
            </React.Fragment>
          </div>
          <div style={{ padding: 20 }}>
            <TabView>
              <TabPanel header={tabHeader("In Progress", onProcessSize)}>
                <div className="datatable-crud-demo">
                  <Toast ref={toast} />
                  <div>
                    <DataTable
                      ref={dt}
                      header={header("")}
                      value={onProcessProduct}
                      selection={selectedProducts}
                      onSelectionChange={(e) => setSelectedProducts(e.value)}
                      dataKey="id"
                      loading={loading}
                      onPage={handleOnProcessPage}
                      lazy
                      paginator
                      rows={rows}
                      first={onProcessLazyParams.first}
                      rowsPerPageOptions={[10, 25, 50]}
                      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} flows"
                      totalRecords={onProcessSize}
                      responsiveLayout="scroll"
                      style={{ width: "100%", border: "1px solid #dee2e6" }}
                    >
                      <Column
                        field="id"
                        header="ID"
                        style={{ minWidth: "5rem" }}
                      ></Column>
                      <Column
                        field="title"
                        header="Tag"
                        body={tagBody}
                        style={{ minWidth: "6rem" }}
                      ></Column>
                      <Column
                        field="message"
                        header="Case Message"
                        style={{ minWidth: "16rem", maxWidth: "25rem" }}
                      ></Column>
                      <Column
                        field="nutrition_type"
                        header="Nutrition Type"
                        style={{ minWidth: "6rem" }}
                        body={nutritionTypeBody}
                      ></Column>
                      <Column
                        field="nutrition_method"
                        header="Nutrition Method"
                        body={nutritionMethodBody}
                        style={{ minWidth: "8rem" }}
                      ></Column>
                      <Column
                        field="progressValue"
                        header="Ratio"
                        body={progressBody}
                        style={{ minWidth: "10rem" }}
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              </TabPanel>
              <TabPanel header={tabHeader("Waiting", waitingSize)}>
                <div className="datatable-crud-demo">
                  <Toast ref={toast} />
                  <div>
                    <DataTable
                      ref={dt}
                      header={header("")}
                      value={waitingProduct}
                      selection={selectedProducts}
                      onPage={handleOnWaitingPage}
                      onSelectionChange={(e) => setSelectedProducts(e.value)}
                      dataKey="id"
                      loading={loading}
                      paginator
                      lazy
                      rows={waitingRows}
                      first={waitingLazyParams.first}
                      rowsPerPageOptions={[10, 25, 50]}
                      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} flows"
                      totalRecords={waitingSize}
                      responsiveLayout="scroll"
                      style={{ width: "100%", border: "1px solid #dee2e6" }}
                    >
                      <Column
                        field="id"
                        header="ID"
                        style={{ minWidth: "5rem" }}
                      ></Column>
                      <Column
                        field="title"
                        header="Tag"
                        body={tagBody}
                        style={{ minWidth: "6rem" }}
                      ></Column>
                      <Column
                        field="message"
                        header="Case Message"
                        style={{ minWidth: "16rem", maxWidth: "25rem" }}
                      ></Column>
                      <Column
                        field="nutrition_type"
                        header="Nutrition Type"
                        style={{ minWidth: "8rem" }}
                        body={nutritionTypeBody}
                      ></Column>
                      <Column
                        field="nutrition_method"
                        header="Nutrition Method"
                        body={nutritionMethodBody}
                        style={{ minWidth: "8rem" }}
                      ></Column>
                      <Column
                        field="progressValue"
                        header="Ratio"
                        body={progressBody}
                        style={{ minWidth: "10rem" }}
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              </TabPanel>
              <TabPanel header={tabHeader("Done", doneSize)}>
                <div className="datatable-crud-demo">
                  <Toast ref={toast} />
                  <div>
                    <DataTable
                      ref={dt}
                      header={header("")}
                      value={products}
                      onPage={handleOnDonePage}
                      selection={selectedProducts}
                      onSelectionChange={(e) => setSelectedProducts(e.value)}
                      dataKey="id"
                      loading={loading}
                      paginator
                      lazy
                      rows={doneRows}
                      first={doneLazyParams.first}
                      rowsPerPageOptions={[10, 25, 50]}
                      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} flows"
                      totalRecords={totalRecords}
                      responsiveLayout="scroll"
                      style={{ width: "100%", border: "1px solid #dee2e6" }}
                    >
                      <Column
                        field="id"
                        header="ID"
                        style={{ minWidth: "5rem" }}
                      ></Column>
                      <Column
                        field="title"
                        header="Tag"
                        body={tagBody}
                        style={{ minWidth: "6rem" }}
                      ></Column>
                      <Column
                        field="message"
                        header="Case Message"
                        style={{ minWidth: "16rem", maxWidth: "25rem" }}
                      ></Column>
                      <Column
                        field="nutrition_type"
                        header="Nutrition Type"
                        style={{ minWidth: "8rem" }}
                        body={nutritionTypeBody}
                      ></Column>
                      <Column
                        field="nutrition_method"
                        header="Nutrition Method"
                        body={nutritionMethodBody}
                        style={{ minWidth: "8rem" }}
                      ></Column>
                      <Column
                        field="progressValue"
                        header="Ratio"
                        body={progressBody}
                        style={{ minWidth: "10rem" }}
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              </TabPanel>
            </TabView>
          </div>
        </div>
      )}
    </div>
  );
};

export default Translations;
