import React, {memo, useEffect, useRef} from 'react';
import { Handle } from 'reactflow';
import './ImageNode.css';
import { Button } from 'primereact/button';
import RemoveIcon from '../../../../assets/icons/remove.png';

import { useState } from 'react';
import { updateFlowNode } from '../../../../API/helper';

export default memo(({ data, isConnectable }) => {
  const inputFile = useRef(null);
  const [fileName, setFileName] = useState(null);
  const [tempThumbnail, setTempThumbnail] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSelectFile = (event) => {
    setLoading(true);

    setFileName(event.target.files[0].name);
    updateFlowNode(
      parseInt(data.id),
      data.topic_id,
      '',
      'image',
      event.target.files[0]
    )
      .then((data) => {
        if (data.data.success) {
          setTempThumbnail(data.data.data.action_value);
        }
      })
      .catch((err) => {
        console.log('handleSelectFile/ERROR', err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Handle
        type="target"
        position="left"
        style={{ background: '#555' }}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />
      <div>
        <div className="node-header">
          <div className="node-label">Image:</div>

          <div
            className="node-remove-button"
            onClick={() => data.deleteNodeItself()}
          >
            <img
              className="node-remove-button-icon"
              src={RemoveIcon}
              alt="Remove"
            />
          </div>
        </div>

        <div className="image-node-body">
          {/* <div>{data.id}</div> */}

          {data.action_value ? (
            <img
              className="image-preview-image"
              //src="https://staging-diginurse-media.s3.eu-central-1.amazonaws.com/456-g.jpg"
              src={tempThumbnail ? tempThumbnail : data.action_value}
              alt="node-preview"
            />
          ) : (
            <img
              className="image-preview-image video-thumbnail"
              src="https://staging-diginurse-media.s3.amazonaws.com/upload/widget/5Zr3jxt1SxGc3XEGhIip1DGk49WtaglgKPm9a3he.png"
              alt="node-preview"
            />
          )}

          <input
            ref={inputFile}
            type="file"
            id="imgupload"
            style={{ display: 'none' }}
            onChange={handleSelectFile}
            accept=".jpg, .jpeg, .png .gif"
          />
          <Button
            loading={loading}
            onClick={() => inputFile.current.click()}
            label={fileName ? fileName : 'Upload Image'}
            icon="pi pi-cloud-upload"
            style={{
              width: '100%',
              marginTop: 20,
              maxWidth: 240,
            }}
          />
        </div>
      </div>

      <Handle
        type="source"
        position="right"
        id="b"
        style={{ background: '#555' }}
        isConnectable={isConnectable}
      />
    </>
  );
});
