import React, {useEffect, useRef, useState} from 'react';
import {Card} from "primereact/card";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import './PollsQuestionDetails.css'

const PollsQuestionDetails  = ({setActiveItem,activeItem,selectedDates,state,items,setItems,pollValue,setPollValue,dates,setDates,publishNow,setPublishNow}) => {
    const toast = useRef(null);
    const handleNext = () =>{
        setActiveItem(activeItem+1)
    }
    useEffect(()=>{
        if(publishNow){
            setDates(null)
        }
    },[publishNow])
    const handleInputChange = (id, value) => {
        const updatedItems = items.map((item) => {
            if (item.id === id) {
                return { ...item, answer: value };
            }
            return item;
        });

        setItems(updatedItems);
    };
    let minDate = new Date();
    const handleAddItem = () => {

            const newId = items.length > 0 ? items[items.length - 1].id + 1 : 1;
            const newItem = { id: newId, text: '' };
            setItems([...items, newItem]);

    };
    const handleRemoveItem = (id) => {
        if (items.length > 2) {
            const updatedItems = items.filter((item) => item.id !== id);
            setItems(updatedItems);
        }else{
            toast.current.show({
                severity: "error",
                summary: "Error",
                detail: "There must be at least two answers!",
                life: 3000,
            })
        }
    };

    return (
        <div className="nutrition-method-div">
            <Card className="nutrition-method-card">
                <div className="nutrition-method-title">
                    Please enter question and answers to create polls in it.
                </div>
                <div className="polls-wrapper" style={{marginLeft:0}}>
                    <div className="admin-info-wrapper">
                        <span>Question:</span>
                        <InputText placeholder="Please Enter Question" value={pollValue.question} onChange={(e) => setPollValue({...pollValue,question:e.target.value})} />
                    </div>
                    <div>
                        <span>Answers:</span>
                        {items.map((item) => (
                            <div className="answer-wrapper" key={item.id}>
                                <InputText placeholder="Please Enter Answer" style={{marginRight:10,width:"100%"}} value={item.answer} onChange={(e) => handleInputChange(item.id, e.target.value)} />
                                {item.id>2 && <Button disabled={state?.create ? false : true} onClick={() => handleRemoveItem(item.id)} className="p-button p-button-danger"  icon="pi pi-minus "/>}
                            </div>
                        ))}
                        {<Button disabled={state?.create ? false : true} onClick={handleAddItem} label="Add Answer" className="p-button-info" icon="pi pi-plus"/>}
                    </div>
                </div>
                <div className="poll-question-div">
                    <Button className="p-button-info" onClick={()=>setActiveItem(activeItem-1)}>Back</Button>
                    <Button className="p-button-info" disabled={!(pollValue?.question?.replaceAll(" ", "") !== "" && items?.[0]?.answer?.replaceAll(" ", "") !== "" && items?.[1]?.answer?.replaceAll(" ", "") !== "")} onClick={()=>handleNext()}>Next</Button>
                </div>
            </Card>

        </div>
    );
};

export default PollsQuestionDetails;