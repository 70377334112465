import React, { memo, useEffect, useRef, useState } from "react";
import { Handle } from "reactflow";
import "./QuestionTranslationNode.css";
import { Button } from "primereact/button";
import { addFlow, changeNodeText } from "../../../../../API/helper";
import { Toast } from "primereact/toast";
import { da } from "date-fns/locale";

export default memo(({ data, isConnectable }) => {
  const textAreaRef = useRef();
  const checkBoxRef = useRef();
  const toast = useRef(null);
  const [selectSentence, setSelectSentence] = useState("");
  const [isReview, setIsReview] = useState(localStorage.getItem("isReview"));
  const [isFirst,setIsFirst]=useState(data.prev_id===0 ? true : false)
  useEffect(() => {
    setSelectSentence(data?.target_msg);
  }, []);

  const sendNewValue = async (approve) => {
      data.onLoading(true)
    changeNodeText(
      selectSentence,
      localStorage.getItem("lang_id"),
      localStorage.getItem("country_id"),
      data.id,
      approve
    ).then((resp) => {
      if (resp.success) {
        data.onClickNextNode();
        data.onLoading(false)

      }
    });
  };

  return (
    <>
      <Handle
        type="target"
        position="left"
        style={{ background: "#555" }}
        isConnectable={isConnectable}
        onConnect={(params) => console.log("handle onConnect", params)}
      />
      <Toast ref={toast}></Toast>
      <div>
        <div className="node-header">
          <div className="node-translation-label">{data.message}</div>
        </div>
        <div className="question-node-body">
          <textarea
            ref={textAreaRef}
            style={{
              borderColor: `${
                selectSentence?.length < 1 ? "red" : "rgb(17, 130, 242)"
              }`,
            }}
            className="question-translation-textarea"
            name="Text1"
            cols="25"
            rows="5"
            defaultValue={selectSentence}
            value={selectSentence}
            onChange={(e) => {
              setSelectSentence(e.target.value);
            }}
          ></textarea>
          {/*{data?.similar.length>0 && <span className="translation-question-span">Did you mean ? :</span>}*/}
          {data?.similar.map((question, index) => (
            <div
              key={index}
              className="question-selection-wrapper"
              onClick={() => setSelectSentence(question.s)}
            >
              <span>{question.s}</span>
              <span></span>
            </div>
          ))}
          <div className="translation-button-wrapper">
              <Button style={isFirst ? {visibility:"hidden"}:{visibility:"visible"}} label="Previous" severity="info" onClick={()=>{
                  data.onClickPreviousNode()
              }}/>
            {isReview === "true" ? (
              <Button
                label={"Approved"}
                severity="info"
                onClick={() => {
                  sendNewValue(true);
                }}
              />
            ) : (
              <Button
                label={"Save"}
                severity="info"
                onClick={() => {
                  sendNewValue(false);
                }}
              />
            )}
          </div>
        </div>
      </div>
      <Handle
        type="source"
        position="right"
        id="a"
        style={{ background: "#555" }}
        isConnectable={isConnectable}
      />
    </>
  );
});
