import React, {useEffect, useState} from 'react';
import {Dropdown} from "primereact/dropdown";
import {Card} from "primereact/card";
import './Nutrition-method.css'
import {Button} from "primereact/button";
import {Tooltip} from "primereact/tooltip";
import {useSelector} from "react-redux";
import {getGeneralList} from "../../API/helper";

const NutritionMethod = ({setActiveItem,activeItem,selectedMethods}) => {
    const [nutritionApplicationMethod, setNutritionApplicationMethod] =
        useState(null);
    const [nutritionMethod, setNutritionMethod] = useState(null);
    const [nutritionMethods,setNutritionMethods] = useState([]);
    const [nutritionApplicationMethods,setNutritionApplicationMethods] = useState([]);


    const getGeneraData = async ()=>{
        const generalData = await getGeneralList()
        if(generalData){
            setNutritionMethods(generalData.data.list.nutrition_method)
            setNutritionApplicationMethods(generalData.data.list.nutrition_type)
        }
    }

    useEffect(()=>{
        getGeneraData()
        const nutritionMethodFromLS =localStorage.getItem('nutritionMethod')
        const nutritionMethodAppFromLS = localStorage.getItem('nutritionAppMethod')
        if(!nutritionMethodFromLS || !nutritionMethodAppFromLS){
            setNutritionApplicationMethod('')
            setNutritionApplicationMethod('')
        }else{
            setNutritionMethod(nutritionMethodFromLS)
            setNutritionApplicationMethod(nutritionMethodAppFromLS)
        }
    },[])
    useEffect(()=>{
        selectedMethods(nutritionMethod,nutritionApplicationMethod)
    },[nutritionApplicationMethod,nutritionMethod])
    const onNutritionMethodChange = (e) => {
        setNutritionMethod(e.value);
        localStorage.setItem("nutritionMethodID",e.value.id)

    };
    const onNutritionApplicationMethodChange = (e) => {
        setNutritionApplicationMethod(e.value);
        localStorage.setItem("nutritionAppMethodID",e.value.id)


    };
    const handleNext = () =>{
        localStorage.setItem("nutritionMethod",nutritionMethod)
        localStorage.setItem("nutritionAppMethod",nutritionApplicationMethod)
        setActiveItem(activeItem+1)
    }
    return (
        <div className="nutrition-method-div">
        <Card className="nutrition-method-card">
            <div className="nutrition-method-body">
                <div className="nutrition-method-title">
                    Please select nutrition type and method to create flow in it.
                </div>
                <div className="combobox-wrapper">
                    <div className="dropdown-wrapper">
                        <Dropdown
                            className="nutrition-dropdown"
                            value={nutritionMethod}
                            options={nutritionMethods}
                            placeholder="Please Select Feeding Method"
                            onChange={onNutritionMethodChange}
                            optionLabel="title"
                            showClear
                        />
                        <Button icon="pi pi-info-circle" tooltip="Choose the nutrition method. As soon as the conversation starts, the bot asks the user for the information. It is important that we know the feeding method in order to determine the problem." tooltipOptions={{position: 'bottom'}} className="p-button-rounded p-button-text p-button-plain" aria-label="Filter" />
                    </div>
                    <div className="dropdown-wrapper">
                        <Dropdown
                            className="nutrition-dropdown"
                            value={nutritionApplicationMethod}
                            options={nutritionApplicationMethods}
                            placeholder="Please Select Feeding type"
                            onChange={onNutritionApplicationMethodChange}
                            optionLabel="title"
                        />
                        <Button icon="pi pi-info-circle" tooltip="Select the type of nutrition. After learning the feeding method, the bot asks the user for information. It is important to know the type of nutrition in order to determine the problem." tooltipOptions={{position: 'bottom'}} className="p-button-rounded p-button-text p-button-plain" aria-label="Filter" />
                    </div>
                </div>
            </div>
            <div className="nutrition-method-button-div">
                <Button className="p-button-info" disabled={!(nutritionMethod && nutritionApplicationMethod)} onClick={()=>handleNext()}>Next</Button>
            </div>
        </Card>

        </div>
    );
};

export default NutritionMethod;