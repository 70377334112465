import React, { memo, useRef } from 'react';
import { Handle } from 'reactflow';
import { Button } from 'primereact/button';

import { useState } from 'react';
import {changeNodeText, updateFlowNode} from '../../../../../API/helper';

export default memo(({ data, isConnectable }) => {
    const inputFile = useRef(null);
    const [fileName, setFileName] = useState(null);
    const [tempThumbnail, setTempThumbnail] = useState('');
    const [loading, setLoading] = useState(false);
    const [selectSentence,setSelectSentence]=useState("")

    const handleSelectFile = (event) => {
        setLoading(true);
        setFileName(event.target.files[0].name);
        updateFlowNode(
            parseInt(data.id),
            data.topic_id,
            '',
            'image',
            event.target.files[0]
        )
            .then((data) => {
                if (data.data.success) {
                    setTempThumbnail(data.data.data.action_value);
                }
            })
            .catch((err) => {
                console.log('handleSelectFile/ERROR', err.response.data.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const sendNewValue = async ()=>{
        if(selectSentence.length>3){
            changeNodeText(selectSentence,localStorage.getItem("lang_id"),localStorage.getItem("country_id"),data.id)
                .then((resp)=>{
                    if(resp.status){
                        data.onClickNextNode()
                    }
                })
        }else{
            data.onClickNextNode()
        }
    }
    return (
        <>
            <Handle
                type="target"
                position="left"
                style={{ background: '#555' }}
                onConnect={(params) => console.log('handle onConnect', params)}
                isConnectable={isConnectable}
            />
            <div>
                <div className="node-header">
                    <div className="node-label">Image:</div>

                </div>

                <div className="image-node-body">
                    {/* <div>{data.id}</div> */}

                    {data.action_value ? (
                        <img
                            className="image-preview-image"
                            src={tempThumbnail ? tempThumbnail : data.action_value}
                            alt="node-preview"
                        />
                    ) : (
                        <img
                            className="image-preview-image video-thumbnail"
                            src="https://staging-diginurse-media.s3.amazonaws.com/upload/widget/5Zr3jxt1SxGc3XEGhIip1DGk49WtaglgKPm9a3he.png"
                            alt="node-preview"
                        />
                    )}

                    <input
                        ref={inputFile}
                        type="file"
                        id="imgupload"
                        style={{ display: 'none' }}
                        onChange={handleSelectFile}
                        accept=".jpg, .jpeg, .png .gif"
                    />
                </div>
                <div className="translation-button-wrapper">
                    <Button label="Previous" severity="info" onClick={()=>{
                        data.onClickPreviousNode()
                    }}/>
                    <Button label={selectSentence?.length>3 ?"Save":"Skip"} severity="info" onClick={()=>{
                        sendNewValue()
                    }}/>
                </div>
            </div>

            <Handle
                type="source"
                position="right"
                id="b"
                style={{ background: '#555' }}
                isConnectable={isConnectable}
            />
        </>
    );
});
