import React, {useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {getProductPollsDetailAnswers, getWellPollsDetailAnswers} from "../../../API/helper";
import Lottie from "react-lottie-player";
import settingsAnimation from "../../../assets/settingsAnimation.json";
import Header from "../../../components/header/Header";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {Toast} from "primereact/toast";
import {Dialog} from "primereact/dialog";
import ChartPie from "../../../components/Chart-Pie/Chart-Pie";

function WellBeingPollsListDetail(props){
    const { state } = useLocation();
    const toast = useRef(null);
    const [visible, setVisible] = useState(false);
    const [loading,setLoading]=useState(false)
    const [pollAnswerData, setPollAnswerData] = useState([]);
    const [chartData,setChartData]=useState([])
    const [chartLabel,setChartLabel]=useState([])
    const [totalRecords,setTotalRecords]=useState()
    const navigate = useNavigate();
    const dt = useRef(null);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 15,
        page: 0,
        sortField: null,
        sortOrder: null,
        filters: {
            name: { value: "", matchMode: "contains" },
            "country.name": { value: "", matchMode: "contains" },
            company: { value: "", matchMode: "contains" },
            "representative.name": { value: "", matchMode: "contains" },
        },
    });
    const getPollDetail = async (page,row)=>{
        setLoading(true)
        await getWellPollsDetailAnswers(state.pollID,page,row).then((res)=>{
            console.log(res,"RES")
            let chartDataAnswer=[]
            let chartLabelAnswer=[]
            res.data.list?.chart.forEach((data)=>{
                if(data.item_title!=="null" && data.item_title!=="" && data.item_title!=="undefined")
                {
                    chartLabelAnswer.push(data.item_title)
                    chartDataAnswer.push(data.item_value)
                }})
            setChartData(chartDataAnswer)
            setChartLabel(chartLabelAnswer)
            setPollAnswerData(res.data.list.detail.user_answers)
            //setTotalRecords(res.data.paging.total)
            setLoading(false)
        })
    }
    const handleOnPage = (event) => {
        setLazyParams(event);
    };
    useEffect(() => {
        loadLazyData();
    }, [lazyParams]);
    const loadLazyData = () => {
        getPollDetail(lazyParams.page+1,lazyParams.rows)
    };
    return (
        <div>
            {loading ? (
                <div className="settings-loader">
                    <Lottie
                        loop
                        animationData={settingsAnimation}
                        play
                        style={{ width: 400, height: 400 }}
                    />
                </div>
            ) :  <div>
                <Header items={[{ label: 'Wellbeing Polls' , url: '/polls/wellbeing-polls/'},{ label: "Detail" }]} />
                <div style={{padding:20,display:"flex",gap:20,backgroundColor:"#f8f9fa",border:"1px solid #dee2e6",flexDirection:"column"}}>
                    <div style={{display:"flex"}}>
                        <DataTable
                            ref={dt}
                            rows={lazyParams.rows}
                            dataKey="id"
                            paginator
                            lazy
                            onPage={handleOnPage}
                            value={pollAnswerData}
                            loading={loading}
                            totalRecords={totalRecords}
                            first={lazyParams.first}
                            rowsPerPageOptions={[15, 25, 50]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} answers"
                            responsiveLayout="scroll"
                            style={{width:"100%", border: "1px solid #dee2e6" }}
                        >
                            <Column
                                field="user_id"
                                header="User ID"
                                style={{ minWidth: "5rem" }}
                            ></Column>
                            <Column
                                field="session_id"
                                header="Session ID"
                            ></Column>
                            <Column
                                field="date"
                                header="Date"
                            ></Column>
                        </DataTable>
                        <div style={{textAlign:"center",padding:"0,0"}}>
                            <ChartPie
                                title="Wellbeing Poll Answers"
                                data={chartLabel} number={chartData} />
                        </div>
                    </div>


                </div>
                <div className="datatable-crud-demo">
                    <Toast ref={toast} />
                    <Dialog header="Chat Messages" visible={visible} draggable={false} blockScroll style={{ width: '40vw',marginLeft:50 }} onHide={() => setVisible(false)}>
                    </Dialog>
                </div>
            </div>}
        </div>

    )
}
export default WellBeingPollsListDetail;