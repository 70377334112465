import React, {useEffect, useRef, useState} from 'react';
import {Card} from "primereact/card";
import {Toast} from "primereact/toast";
import {Button} from "primereact/button";
import {InputTextarea} from "primereact/inputtextarea";
import './Initial-Sentence.css'
const InitialSentence = ({activeItem,setActiveItem,setStartMessage,startMessage}) => {
    const [firstInputValue,setFırstInputValue]=useState("")

    const toast = useRef();
    const inputRef = useRef();

    useEffect(()=>{
        if(startMessage!==""){
            setFırstInputValue(startMessage)
        }
    },[])

    const handleNext = () =>{
        setStartMessage(firstInputValue)
        setActiveItem(activeItem+1)
    }

    return (
        <div className="initial-sentence-wrapper">
            <Card className="initial-sentence-card">
                <div className="initial-sentence-body">
                    <div className="initial-sentence-title">
                        Please enter the first sentence required for flow.
                    </div>
                    <div>
                        E,g.:<span style={{fontWeight:"bold"}}> "You have stated that there is a discharge at the PEG input. I will ask you a few questions to help you with this."</span>
                    </div>
                    <Toast ref={toast}></Toast>
                    <div className="initial-sentence-text-wrapper">
                        <InputTextarea className="initial-sentence-text" value={firstInputValue} onChange={(e)=>setFırstInputValue(e.target.value)} placeholder="Initial Sentence" rows={1} cols={100} autoResize/>
                    </div>
                </div>
                <div className="initial-sentence-button-div">
                    <Button className="p-button-info" onClick={()=>setActiveItem(activeItem-1)} >Back</Button>
                    <Button className="p-button-info" disabled={firstInputValue.length<10} onClick={()=>handleNext()} >Next</Button>
                </div>
            </Card>
        </div>
    );
};

export default InitialSentence;
