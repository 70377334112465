import React, {useRef, useState} from 'react';
import {InputTextarea} from "primereact/inputtextarea";
import {Button} from "primereact/button";
import {ListBox} from "primereact/listbox";
import {Card} from "primereact/card";
import './Overview.css'
import {useNavigate} from "react-router-dom";
import {addFlow} from "../../API/helper";
import {Toast} from "primereact/toast";
const Overview = ({activeItem,setActiveItem,nutritionMethod,nutritionApplicationMethod,caseLabel,initialSentence,startMessage}) => {
    const navigate = useNavigate();
    const toast = useRef(null);
    const createDataFunc = async (createData)=>{
        const addFlowResp = await addFlow(createData)
        if(addFlowResp){
            toast.current.show({
                severity: 'success',
                summary: 'Successful',
                detail: addFlowResp.message,
                life: 3000,
            });
            navigate(`/flows/${addFlowResp.data.flow_topic}`)
        }
    }

    const nutritionMethodID =localStorage.getItem("nutritionMethodID")
    const nutritionAppMethodID = localStorage.getItem("nutritionAppMethodID")
    const handleCreate = () => {
        const addFlowData={nutritionMethodID:nutritionMethodID,nutritionApplicationMethodID:nutritionAppMethodID,nlpLabelID:caseLabel.id,message:startMessage}
        createDataFunc(addFlowData)
    }
    return (
        <div className="overview-wrapper">
            <Card className="overview-card">
                <div className="overview-body">
                    <div className="overview-title">
                        Please review the information below and if everything looks okay for your flow you can create and start to design
                    </div>
                    <Toast ref={toast}></Toast>
                    <div className="spans-wrapper" style={{flexDirection:"column",gap:10}}>
                        <div className="spans-div">
                            <span className="overview-span">Nutrition type & method:</span>
                            <span style={{fontWeight:"normal"}}>{nutritionMethod.title} / {nutritionApplicationMethod.title}</span>
                        </div>
                        <div className="spans-div">
                            <span className="overview-span">Case Label:</span>
                            <span style={{fontWeight:"normal"}}>{caseLabel.code} with {caseLabel.name_format} prediction rate.</span>
                        </div>
                        <div className="spans-div">
                            <span className="overview-span">Case Label Description: </span>
                            <span style={{fontWeight:"normal"}}>{caseLabel.description}</span>
                        </div>
                        <div className="spans-div">
                            <span className="overview-span">Initial Chat Message: </span>
                            <span style={{fontWeight:"normal"}}>{startMessage}</span>
                        </div>
                    </div>

                </div>
                <div className="overview-button-div">
                    <Button className="p-button-info" onClick={()=>setActiveItem(activeItem-1)} >Back</Button>
                    <Button className="p-button-info" onClick={()=>handleCreate()} >Create</Button>
                </div>
            </Card>
        </div>
    );
};

export default Overview;
