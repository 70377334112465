import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/header/Header";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "./MobileUsers.css";
import { useNavigate } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import ReactCountryFlag from "react-country-flag";
import { Calendar } from "primereact/calendar";
import {
  getFilteredUserList,
  getUserList,
  getUserLogData,
} from "../../API/UsersAPI/userAPI";
import { getGeneralList } from "../../API/helper";
import countryCodes from "./CountryCodes.json";
import { getFilteredLogList } from "../../API/LogsAPI/logsAPI";
import { format } from "date-fns";
import Lottie from "react-lottie-player";
import settingsAnimation from "../../assets/settingsAnimation.json";
const MobileUsers = () => {
  const navigate = useNavigate();
  const [totalRecords, setTotalRecords] = useState(0);
  const [lastSeenLocation, setLastSeenLocation] = useState(null);
  const [lastSeenOptions, setLastSeenOptions] = useState([]);
  const [userBrowserOptions, setUserBrowserOptions] = useState([]);
  const [userBrowser, setUserBrowser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userID, setUserID] = useState("");
  const toast = useRef(null);
  const searchButtonRef = useRef(null);
  const [rows, setRows] = useState(10);
  const [isFiltered, setIsFiltered] = useState(false);
  const [products, setProducts] = useState([]);
  const [sessionDates, setSessionDates] = useState(null);
  const [registerDates, setRegisterDates] = useState(null);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: {
      name: { value: "", matchMode: "contains" },
      "country.name": { value: "", matchMode: "contains" },
      company: { value: "", matchMode: "contains" },
      "representative.name": { value: "", matchMode: "contains" },
    },
  });
  const getAllUsers = async (userData) => {
    await getUserList(userData).then((res) => {
      setLoading(true);
      setProducts(res.data.list);
      setTotalRecords(res.data.paging.total);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    });
  };
  const getGeneraData = async () => {
    const generalData = await getGeneralList();
    if (generalData) {
      setLastSeenOptions(generalData.data.list.user_last_seen_locations);
      setUserBrowserOptions(generalData.data.list.user_browser_list);
    }
  };
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        searchButtonRef.current.click();
      }
    };
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);
  const getFilteredData = async (userData) => {
    setLoading(true);
    const log = await getFilteredUserList(
      userID,
      lastSeenLocation ? lastSeenLocation.country_title : "",
      sessionDates ? format(sessionDates[0], "yyyy/MM/dd") : "",
      sessionDates && sessionDates[1]
        ? format(sessionDates[1], "yyyy/MM/dd")
        : "",
      registerDates ? format(registerDates[0], "yyyy/MM/dd") : "",
      registerDates && registerDates[1]
        ? format(registerDates[1], "yyyy/MM/dd")
        : "",
      userBrowser ? userBrowser.browser : "",
      userData.page,
      userData.row
    );
    if (log.success) {
      setIsFiltered(true);
      setProducts(log.data.list);
      setTotalRecords(log.data.paging.total);
      setLoading(false);
    } else {
      toast.current.show({
        severity: "error",
        summary: log.message,
        life: 3000,
      });
    }
  };

  useEffect(() => {
    getAllUsers();
    getGeneraData();
  }, []);

  const onSelectRow = (rowData) => {
    let selectedCountryCode = countryCodes.find(
      (countryCode) => rowData.value.last_seen_location === countryCode.name
    );
    navigate("/selected-user", {
      state: {
        user_id: rowData.value.id,
        last_seen_country: rowData.value.last_seen_location,
        last_seen_country_code: selectedCountryCode?.code,
        numberOfSession: rowData.value.session_count,
        user_last_seen_date: rowData.value.last_session_date,
        user_register_date: rowData.value.user_created_at,
        user_browser: rowData.value.user_browser,
        userRowID: rowData.value.id,
        user_products: rowData?.value?.products
      },
    });
  };
  const handleSubmit = () => {
    getFilteredData({ page: 1, row: 10 });
  };

  const typeBodyTemplate = (rowData) => {
    let selectedCountryCode = countryCodes.find(
      (countryCode) => rowData.last_seen_location === countryCode.name
    );
    return (
      <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
        <ReactCountryFlag
          countryCode={selectedCountryCode?.code}
          style={{
            fontSize: "1.6em",
          }}
          svg
        />
        <span>{rowData.last_seen_location}</span>
      </div>
    );
  };
  const browserBodyTemplate = (rowData) => {
    return (
      <div>
        {rowData.user_browser === "0" ? "Unknown" : rowData?.user_browser}
      </div>
    );
  };
  const loadLazyData = () => {
    isFiltered
      ? getFilteredData({ page: lazyParams.page + 1, row: lazyParams.rows })
      : getAllUsers({ page: lazyParams.page + 1, rows: lazyParams.rows });
  };
  useEffect(() => {
    loadLazyData();
  }, [lazyParams]);
  const handleOnPage = (event) => {
    setRows(event.rows);
    setLazyParams(event);
  };
  return (
    <div>
      {loading ? (
        <div className="settings-loader">
          <Lottie
            loop
            animationData={settingsAnimation}
            play
            style={{ width: 400, height: 400 }}
          />
        </div>
      ) : (
        <div>
          <Header items={[{ label: "Users" }]} />
          <div style={{ padding: 20 }}>
            <div className="filter-div">
              <div className="users-input-div">
                <span style={{ width: "30%" }} className="p-float-label">
                  <InputText
                    style={{ width: "100%" }}
                    id="By Content"
                    ovalue={userID}
                    onChange={(e) => setUserID(e.target.value)}
                  />
                  <label htmlFor="byContent">Enter User ID</label>
                </span>
                <Dropdown
                  className="dropdown"
                  placeholder="Select Last Seen Location"
                  options={lastSeenOptions}
                  optionLabel="country_title"
                  value={lastSeenLocation}
                  onChange={(e) => {
                    setLastSeenLocation(e.value);
                  }}
                  showClear
                />
                <Calendar
                  value={registerDates}
                  onChange={(e) => setRegisterDates(e.value)}
                  selectionMode="range"
                  readOnlyInput
                  dateFormat="dd/mm/yy"
                  placeholder="Select Register Date Range"
                  showButtonBar
                  className="dropdown"
                  showIcon
                />
                <Calendar
                  value={sessionDates}
                  onChange={(e) => setSessionDates(e.value)}
                  selectionMode="range"
                  readOnlyInput
                  dateFormat="dd/mm/yy"
                  placeholder="Select Session Date Range"
                  showButtonBar
                  className="dropdown"
                  showIcon
                />
                <Dropdown
                  className="dropdown"
                  options={userBrowserOptions}
                  value={userBrowser}
                  placeholder="Select User Browser"
                  optionLabel="browser"
                  onChange={(e) => setUserBrowser(e.value)}
                  showClear
                />
              </div>
              <div className="users-bring-list-div">
                <Button
                  ref={searchButtonRef}
                  label="Search"
                  icon="pi pi-search"
                  iconPos="left"
                  onClick={() => handleSubmit({ page: 0, row: 10 })}
                />
                <div
                  className="clear-button"
                  onClick={() => {
                    getAllUsers({ page: 1, row: 10 });
                    setUserID("");
                    setLastSeenLocation(null);
                    setRegisterDates(null);
                    setSessionDates(null);
                    setUserBrowser(null);
                  }}
                >
                  Clear Filter
                </div>
              </div>
            </div>
          </div>
          <div className="datatable-crud-demo">
            <Toast ref={toast} />
            <div style={{ padding: 20 }}>
              <DataTable
                dataKey="id"
                paginator
                lazy
                value={products}
                rows={rows}
                onPage={handleOnPage}
                first={lazyParams.first}
                rowsPerPageOptions={[10, 25, 50]}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
                totalRecords={totalRecords}
                responsiveLayout="scroll"
                style={{ width: "100%", border: "1px solid #dee2e6" }}
                selectionMode="single"
                onSelectionChange={(e) => onSelectRow(e)}
              >
                <Column
                  field="id"
                  header="ID"
                  style={{ minWidth: "5rem" }}
                ></Column>
                <Column
                  field="type"
                  header="Last Seen Location"
                  style={{ minWidth: "8rem" }}
                  body={typeBodyTemplate}
                ></Column>
                <Column
                  field="session_count"
                  header="Number of session"
                  style={{ minWidth: "8rem" }}
                ></Column>
                <Column
                  field="user_created_at"
                  header="Register Date"
                  style={{ minWidth: "12rem" }}
                ></Column>
                <Column
                  field="last_session_date"
                  header="Last Session Date"
                  style={{ minWidth: "12rem" }}
                ></Column>
                <Column
                  header="Browser"
                  field="user_browser"
                  style={{ minWidth: "10rem" }}
                  body={browserBodyTemplate}
                ></Column>
              </DataTable>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileUsers;
