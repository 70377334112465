import React from "react";
import "./Widget-intro.css";

function WidgetIntro({ templates, translations }) {
  const handlePartialTextReplace = () => {
    const tempArray = translations?.welcomeMessage.split(" ");
    const output = tempArray?.map((e) => {
      if (e === "%terms%") {
        return (
          <a target="_blank"
            style={{ fontFamily: `${templates?.fontFamily}` }}
            href={translations?.termsConditionsUrl}

          >
            {translations?.termsConditions === ""
              ? "Terms & Conditions"
              : translations?.termsConditions}{" "}
          </a>
        );
      } else if (e === "%privacy%") {
        return (
          <a
              target="_blank"
            style={{ fontFamily: `${templates?.fontFamily}` }}
            href={translations?.privacyPolicyUrl}
          >
            {" "}
            {translations?.privacyPolicy === ""
              ? "Privacy & Policy "
              : translations?.privacyPolicy}{" "}
          </a>
        );
      } else {
        return (
          <span style={{ fontFamily: `${templates?.fontFamily}` }}>
            {e + " "}
          </span>
        );
      }
    });
    return output;
  };
  return (
    <div className="widget-preview-wrapper">
      <div className="App">
        <div
          className="widget-header"
          style={{
            color: `${
              templates?.headerFontColor === "#FFFFFF"
                ? ""
                : templates?.headerFontColor
            }`,
            backgroundColor: `${
              templates?.headerBgColor === "#1d7bfe"
                ? ""
                : templates?.headerBgColor
            }`,
            fontFamily: `${templates?.fontFamily===undefined ? "Test" : templates?.fontFamily}`
          }}
        >
          <div className="header-button">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-more-horizontal"
            >
              <circle cx="12" cy="12" r="1"></circle>
              <circle cx="19" cy="12" r="1"></circle>
              <circle cx="5" cy="12" r="1"></circle>
            </svg>
          </div>
          <div className="header-middle">
            <div className="header-title">
              {translations?.windowTitle === undefined
                ? "Diginurse"
                : translations?.windowTitle === ""
                ? "Diginurse"
                : translations?.windowTitle}
            </div>
          </div>
          <div className="header-button">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-chevron-down"
            >
              <polyline points="6 9 12 15 18 9"></polyline>
            </svg>
          </div>
        </div>
        <div
          id="greeting"
          className="greetings"
          style={{
            backgroundImage: `radial-gradient(circle,${
              templates?.greetingBackground === ""
                ? "#1d7bfe"
                : templates?.greetingBackground
            } 0%, 
                ${
                  templates?.greetingBackground === ""
                    ? "#1d7bfe"
                    : templates?.greetingBackground
                } 100%)`,
          }}
        >
          <div className="brand-logo">
            <img
              alt="logo"
              src={
                translations?.brand === ""
                  ? "https://kroppa.com/svg/kroppa-logo.svg"
                  : translations?.brand
              }
            />
          </div>
          <div className="greetings-card">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-file-text"
            >
              <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
              <polyline points="14 2 14 8 20 8"></polyline>
              <line x1="16" y1="13" x2="8" y2="13"></line>
              <line x1="16" y1="17" x2="8" y2="17"></line>
              <polyline points="10 9 9 9 8 9"></polyline>
            </svg>
            <div className="policy-text">
              {translations === undefined ? (
                <div
                  classname="policy-text"
                >
                  <span>
                    Hello,
                  </span>
                  <span>you</span>
                  <span>need</span>
                  <span>to</span>
                  <span>agree</span>
                  <a
                    href="https://diginurse.kroppalab.com/DigiNurse_TnC_[CBU]_EN_10.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    terms &amp; conditions{" "}
                  </a>
                  <span>and </span>
                  <a
                    href="https://diginurse.kroppalab.com/DigiNurse_PP_[CBU]_EN_10.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    privacy &amp; policy{" "}
                  </a>
                  <span>before </span>
                  <span>starting </span>
                  <span>chat. </span>
                </div>
              ) : translations?.welcomeMessage === "" ? (
                "Hello, you need to agree terms & conditions and privacy & policy before starting chat."
              ) : (
                handlePartialTextReplace()
              )}
            </div>
            <button
              id="button"
              className="accept-button"
              style={{
                color: `${
                  templates?.agreeButtonTextColor === "#FFFFFF"
                    ? ""
                    : templates?.agreeButtonTextColor
                }`,
                backgroundColor: `${
                  templates?.agreeButtonBackgroundColor === "#026BFD"
                    ? ""
                    : templates?.agreeButtonBackgroundColor
                }`,
                fontFamily: `${templates?.fontFamily===undefined ?  "Test": templates?.fontFamily}`,
              }}
            >
              {translations === undefined
                ? "I Agree"
                : translations?.agreeButtonTitle === ""
                ? "I Agree"
                : translations?.agreeButtonTitle}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WidgetIntro;
