import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import ReactCountryFlag from "react-country-flag";
import Header from "../../components/header/Header";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Image } from "primereact/image";
import profilePicture from "../../assets/images/profile-picture.jpeg";
import "./selected-user.css";
import { getSelectedSessionDetail } from "../../API/LogsAPI/logsAPI";
import ChatHistoryViewer from "../../components/Chat-History-Viewer/Chat-history-viewer";
import { getUserLogData } from "../../API/UsersAPI/userAPI";
import Lottie from "react-lottie-player";
import settingsAnimation from "../../assets/settingsAnimation.json";
function SelectedUser() {
  const { state } = useLocation();
  const [totalRecords, setTotalRecords] = useState();
  const toast = useRef(null);
  const [chvData, setChvData] = useState({});
  const [products, setProducts] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: {
      name: { value: "", matchMode: "contains" },
      "country.name": { value: "", matchMode: "contains" },
      company: { value: "", matchMode: "contains" },
      "representative.name": { value: "", matchMode: "contains" },
    },
  });
  const getUserLogs = async (user_id, page, row) => {
    setLoading(true);
    const log = await getUserLogData(user_id, page, row).then((response) => {
      setTotalRecords(response.data.paging.total);
      setProducts(response.data.list);
      setLoading(false);
    });
    return log;
  };
  useEffect(() => {
    getUserLogs(state.userRowID, lazyParams.page + 1, lazyParams.rows);
  }, [state]);
  const loadLazyData = () => {
    getUserLogs(state.userRowID, lazyParams.page + 1, lazyParams.rows);
  };
  useEffect(() => {
    loadLazyData();
  }, [lazyParams]);
  const handleOnPage = (event) => {
    setLazyParams(event);
  };
  const getSelectedSession = async (sessionID) => {
    const selectedSession = await getSelectedSessionDetail(sessionID);
    return selectedSession;
  };
  const onSelectRow = (rowData) => {
    setChvData({});
    getSelectedSession(rowData.value.session_id).then((resp) => {
      setChvData(resp);
      setVisible(true);
    });
  };
  const feedBackTemplate = (rowData) => {
    return rowData.feedback_option_value === "yes" ? (
      <i
        className="pi pi-thumbs-up"
        style={{ fontSize: "2rem", color: "#6366F1" }}
      ></i>
    ) : rowData.feedback_option_value === "no" ? (
      <i
        className="pi pi-thumbs-down"
        style={{ fontSize: "2rem", color: "#6366F1" }}
      ></i>
    ) : (
      <i
        className="pi pi-minus"
        style={{ fontSize: "2rem", color: "#6366F1" }}
      ></i>
    );
  };
  const sessionIDBodyTemplate = (rowData) => {
    return <span onClick={() => setVisible(true)}>{rowData.session_id}</span>;
  };
  useEffect(() => {
    const closeDialog = (event) => {
      if (event.target.classList.contains("p-component-overlay")) {
        setVisible(false);
      }
    };
    window.addEventListener("mousedown", closeDialog);
    return () => window.removeEventListener("mousedown", closeDialog);
  });
  console.log(state, "state");
  return (
    <div>
      {loading ? (
        <div className="settings-loader">
          <Lottie
            loop
            animationData={settingsAnimation}
            play
            style={{ width: 400, height: 400 }}
          />
        </div>
      ) : (
        <div>
          <Header
            items={[
              { label: "Users", url: "/users" },
              { label: "Selected User" },
            ]}
          />
          <div style={{ padding: 20, display: "flex", gap: 20 }}>
            <Image
              src={profilePicture}
              alt="Image"
              width="250"
              className="selected-user-picture"
              imageStyle={{ borderRadius: "50%" }}
            />
            {state?.isSession ? (
              <div className="profile-info-wrapper">
                <span>
                  <span className="profile-span">User ID: </span>
                  {state?.userDetail.id}
                </span>
                <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                  <span className="profile-span">Last Seen Location: </span>
                  <ReactCountryFlag
                    countryCode={state?.last_seen_country_code}
                    style={{
                      fontSize: "1.6em",
                    }}
                    svg
                  />
                  <span>{state?.userDetail.last_seen_location}</span>
                </div>
                <span>
                  <span className="profile-span">Number Of Session: </span>
                  {state?.userDetail.session_count}
                </span>
                <span>
                  <span className="profile-span">Register Date: </span>
                  {state?.userDetail.user_created_at}
                </span>
                <span>
                  <span className="profile-span">Last Session Date: </span>
                  {state?.userDetail.chat_log_last_created_at}
                </span>
                <span>
                  <span className="profile-span">Browser: </span>
                  {state?.userDetail.user_browser}
                </span>
                <span>
                  <span className="profile-span">User Products: </span>
                  <div>
                    {state?.user_products?.length > 0 &&
                      state?.user_products?.map((prod) => (
                        <div>{prod?.product_name}</div>
                      ))}
                  </div>
                </span>
              </div>
            ) : (
              <div className="profile-info-wrapper">
                <span>
                  <span className="profile-span">User ID: </span>
                  {state?.user_id}
                </span>
                <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                  <span className="profile-span">Last Seen Location: </span>
                  <ReactCountryFlag
                    countryCode={state?.last_seen_country_code}
                    style={{
                      fontSize: "1.6em",
                    }}
                    svg
                  />
                  <span>{state?.last_seen_country}</span>
                </div>
                <span>
                  <span className="profile-span">Number Of Session: </span>
                  {state?.numberOfSession}
                </span>
                <span>
                  <span className="profile-span">Register Date: </span>
                  {state?.user_register_date}
                </span>
                <span>
                  <span className="profile-span">Last Session Date: </span>
                  {state?.user_last_seen_date}
                </span>
                <span>
                  <span className="profile-span">Browser: </span>
                  {state?.user_browser}
                </span>
                <span style={{ display: "flex" }}>
                  <span className="profile-span">User Products: </span>
                  <div
                    style={{ display: "flex", flexWrap: "wrap", marginLeft: 5 }}
                  >
                    {state?.user_products.length > 0 &&
                      state?.user_products.map((prod) => (
                        <div>{prod?.product_name},</div>
                      ))}
                  </div>
                </span>
              </div>
            )}
          </div>
          <div className="datatable-crud-demo">
            <Toast ref={toast} />
            <Dialog
              header="Chat Messages"
              visible={visible}
              draggable={false}
              blockScroll
              style={{ width: "40vw", marginLeft: 50 }}
              onHide={() => setVisible(false)}
            >
              <ChatHistoryViewer chvData={chvData} />
            </Dialog>
            <div style={{ padding: 20 }}>
              <DataTable
                dataKey="id"
                paginator
                lazy
                value={products}
                onPage={handleOnPage}
                rows={lazyParams.rows}
                first={lazyParams.first}
                rowsPerPageOptions={[10, 25, 50]}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} logs"
                totalRecords={totalRecords}
                responsiveLayout="scroll"
                style={{ width: "100%", border: "1px solid #dee2e6" }}
                selectionMode="single"
                onSelectionChange={(e) => onSelectRow(e)}
              >
                <Column
                  field="session_id"
                  header="Session ID"
                  style={{ minWidth: "6rem" }}
                  body={sessionIDBodyTemplate}
                ></Column>
                <Column
                  field="session_start_date"
                  header="Session Start Date"
                  style={{ minWidth: "8rem" }}
                ></Column>
                <Column
                  field="session_end_date"
                  header="Session End Date"
                  style={{ minWidth: "8rem" }}
                ></Column>
                <Column
                  field="chat_message_length"
                  header="Node Length"
                  style={{ minWidth: "8rem" }}
                ></Column>
                <Column
                  field="nutrition_method"
                  header="Nutrition Method"
                  style={{ minWidth: "12rem" }}
                ></Column>
                <Column
                  field="nutrition_type"
                  header="Nutrition Type"
                  style={{ minWidth: "12rem" }}
                ></Column>
                <Column
                  field="nutritionMethod"
                  body={feedBackTemplate}
                  header="Feedback Status"
                  style={{ maxWidth: "8rem" }}
                ></Column>
              </DataTable>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SelectedUser;
