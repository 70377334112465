import React, { memo, useRef, useState } from 'react';
import { Handle } from 'reactflow';
import './QuestionNode.css';
import RemoveIcon from '../../../../assets/icons/remove.png';
import {da} from "date-fns/locale";

export default memo(({ data, isConnectable }) => {
  const [warning, setWarning] = useState(data.action_value === 'red');
  const textAreaRef = useRef();
  const checkBoxRef = useRef();
  return (
    <>
      <Handle
        type="target"
        position="left"
        style={{ background: '#555' }}
        isConnectable={isConnectable}
      />
      <div>
        <div className="node-header">
          <div className="node-label">Message:</div>

          <div
            className={`node-remove-button ${data.static ? 'disabled' : ''}`}
            onClick={() => (!data.static ? data.deleteNodeItself() : {})}
          >
            <img
              className="node-remove-button-icon"
              src={RemoveIcon}
              alt="Remove"
            />
          </div>
        </div>

        <div className="question-node-body">
          {/* <div>{data.id}</div> */}

          <textarea
            ref={textAreaRef}
            name="Text1"
            cols="25"
            rows="5"
            defaultValue={data.message}
            onBlur={() =>
              data.updateNodeData({
                message: textAreaRef.current.value,
                action: 'text',
                action_from_node: warning,
              })
            }
          ></textarea>

          <div
            className="question-node-warning"
            style={{ background: warning ? 'tomato' : '' }}
          >
            <input
              ref={checkBoxRef}
              style={{ marginRight: 5 }}
              type="checkbox"
              id="scales"
              name="scales"
              checked={warning}
              onChange={(event) => {
                setWarning(event.target.checked);
                data.updateNodeData({
                  message: textAreaRef.current.value,
                  action: 'text',
                  action_from_node: checkBoxRef.current.checked
                    ? 'color'
                    : 'text',
                });
              }}
            />
            <label>Warning</label>
          </div>
        </div>
      </div>
      <Handle
        type="source"
        position="right"
        id="a"
        style={{ background: '#555' }}
        isConnectable={isConnectable}
      />
    </>
  );
});
