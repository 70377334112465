import React, {useEffect, useState} from 'react';
import './Dashboard.css';
import { Card } from 'primereact/card';
import Header from '../../components/header/Header';
import ChartDoughnut from "../../components/Chart-Doughnut/Chart-Doughnut";
import ChartCombo from "../../components/Chart-Combo/Chart-Combo";
import {getAnalyticsData, getGeneralList} from "../../API/helper";
import Lottie from "react-lottie-player";
import settingsAnimation from "../../assets/settingsAnimation.json";

const Dashboard = () => {
  const [userAppType,setUserAppType]=useState([])
  const [userAppData,setUserAppData]=useState([])
  const [appType,setAppType]=useState([])
  const [appData,setAppData]=useState([])
  const [analyticsData,setAnalyticsData]=useState()
  const [loading,setLoading]=useState(false)
  const getGeneraData = async () => {
    const generalData = await getGeneralList();

  };
  const getAnalytics = async () => {
    try {
      const res = await getAnalyticsData("","","","");
      setLoading(true)
      if (res.success) {
        const user_app_type = [];
        const user_app_data = [];
        const application_type =[]
        const application_data =[]
        setAnalyticsData(res.data.list.dashboard)
        res.data.list.number_of_users_by_nutrition_method.forEach((app_type) => {
          user_app_type.push(app_type.item_title);
          user_app_data.push(app_type.item_value);
        });
        res.data.list.number_of_users_by_application_type.forEach((app_type) => {
          application_type.push(app_type.item_title);
          application_data.push(app_type.item_value);
        });
        setUserAppType(user_app_data);
        setUserAppData(user_app_type);
        setAppType(application_type);
        setAppData(application_data);
        setLoading(false)
      }
    } catch (error) {
      setLoading(true)
      console.error('Error fetching analytics data:', error);
    }
  };
  useEffect(()=>{
    getAnalytics()
    getGeneraData()
  },[])


  return (
      <div>
        {loading ? <div className="settings-loader">
          <Lottie
              loop
              animationData={settingsAnimation}
              play
              style={{ width: 400, height: 400 }}
          />
        </div> :  <div>
          <Header />

          <div className="dashboard-page">
            <Card className="dashboard-card">
              <div className="dashboard-card-content">
                <div className="dashboard-card-left">
                  <div className="dashboard-card-title">Flows</div>
                  <div className="dashboard-card-value">{analyticsData?.flows}</div>
                </div>
                <div className="dashboard-card-right">
                  <i className="pi pi-list" style={{ fontSize: '1.5em' }}></i>
                </div>
              </div>
            </Card>

            <Card className="dashboard-card">
              <div className="dashboard-card-content">
                <div className="dashboard-card-left">
                  <div className="dashboard-card-title">Users</div>
                  <div className="dashboard-card-value">{analyticsData?.users}</div>
                </div>
                <div className="dashboard-card-right">
                  <i className="pi pi-users" style={{ fontSize: '1.5em' }}></i>
                </div>
              </div>
            </Card>

            <Card className="dashboard-card">
              <div className="dashboard-card-content">
                <div className="dashboard-card-left">
                  <div className="dashboard-card-title">Chats</div>
                  <div className="dashboard-card-value">455</div>
                </div>
                <div className="dashboard-card-right">
                  <i className="pi pi-inbox" style={{ fontSize: '1.5em' }}></i>
                </div>
              </div>
            </Card>
            <Card className="dashboard-card">
              <div className="dashboard-card-content">
                <div className="dashboard-card-left">
                  <div className="dashboard-card-title">Admins</div>
                  <div className="dashboard-card-value">{analyticsData?.admins}</div>
                </div>
                <div className="dashboard-card-right">
                  <i className="pi pi-verified" style={{ fontSize: '1.5em' }}></i>
                </div>
              </div>
            </Card>

            <Card className="dashboard-card">
              <div className="dashboard-card-content">
                <div className="dashboard-card-left">
                  <div className="dashboard-card-title">Languages</div>
                  <div className="dashboard-card-value">{analyticsData?.languages}</div>
                </div>
                <div className="dashboard-card-right">
                  <i className="pi pi-language" style={{ fontSize: '1.5em' }}></i>
                </div>
              </div>
            </Card>
          </div>
          <div className="grid">
            <div className="col">
              <ChartDoughnut
                  title="Number of Users by Nutrition Method"
                  data={userAppData}
                  number={userAppType} />
            </div>
            <div className="col">
              <ChartDoughnut
                  title="Number of Users by Application Type"
                  data={appType}
                  number={appData} />
            </div>
          </div>
        </div>}
      </div>

  );
};

export default Dashboard;
