import React, {useEffect, useState} from 'react';
import './Chart-Combo.css';
import {Card} from "primereact/card";
import {Chart} from "primereact/chart";

const ChartCombo = ({month,comboData}) => {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    let array=[]
    let userSession = []
    let numberOfChat=[]
    useEffect(()=>{
        if(comboData){
            for (const month in comboData) {
                const item = comboData[month];
                array.push({
                    month,
                    user_sessions: item.user_sessions,
                    number_of_chat_sessions: item.number_of_chat_sessions
                });
            }
            array.forEach((item)=>{
                numberOfChat.push(item.number_of_chat_sessions)
                userSession.push(item.user_sessions)
            })
        }
    },[comboData])
    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
        const data = {
            labels: month ,
            datasets: [
                {
                    type: 'line',
                    label: 'User Session',
                    borderColor: documentStyle.getPropertyValue('--orange-500'),
                    borderWidth: 2,
                    fill: false,
                    tension: 0.4,
                    data: userSession
                },
                {
                    type: 'bar',
                    label: 'Number of Chat Sessions',
                    backgroundColor: documentStyle.getPropertyValue('--green-500'),
                    data: numberOfChat
                }
            ]
        };
        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.6,
            plugins: {
                legend: {
                    labels: {
                        color: textColor
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                },
                y: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                }
            }
        };

        setChartData(data);
        setChartOptions(options);
    }, [month]);

    return (
        <Card className="chart-card"   title="Session Analysis">
            <Chart type="line" data={chartData} options={chartOptions}  width={"100%"} height={"400px"} />
        </Card>
    );
};

export default ChartCombo;
