import React, {useEffect, useState} from 'react';
import './Chart-Pie.css';
import {Card} from "primereact/card";
import {Chart} from "primereact/chart";

const ChartPie = ({title, data, number}) => {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    const myData = data;
    const myNumber = number;

    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const data = {
            labels: myData,
            datasets: [
                {
                    data: myNumber,
                    backgroundColor: [
                        documentStyle.getPropertyValue('--green-500'),
                        documentStyle.getPropertyValue('--red-500'),
                        documentStyle.getPropertyValue('--blue-500'),
                        documentStyle.getPropertyValue('--yellow-500'),
                        documentStyle.getPropertyValue('--purple-500'),
                        documentStyle.getPropertyValue('--pink-500'),
                        documentStyle.getPropertyValue('--orange-500')

                    ],
                    hoverBackgroundColor: [
                        documentStyle.getPropertyValue('--green-500'),
                        documentStyle.getPropertyValue('--red-500'),
                        documentStyle.getPropertyValue('--blue-500')
                    ]
                }
            ]
        }
        const options = {

        };

        setChartData(data);
        setChartOptions(options);
    }, [data,number]);

    return (
        <Card className="chart-card card flex justify-content-center" style={{textAlign:"center"}} title={title}>
            <Chart type="pie" data={chartData} options={chartOptions}  style={{width:415}} className="w-full md:w-30rem" />
            {data?.map((dat,i)=><div style={{marginTop:5}}>
                <span><span style={{fontWeight:"bold"}}>{dat}: </span>{number[i]}</span>
            </div>)}
        </Card>
    );
};

export default ChartPie;
