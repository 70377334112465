import React, { memo, useEffect, useState } from "react";
import { Handle } from "reactflow";
import "./OptionNode.css";
import { Dropdown } from "primereact/dropdown";
import TestOptionList from "../mock/test-option-list.json";
import RemoveIcon from "../../../../assets/icons/remove.png";

export default memo(({ data, isConnectable }) => {
  const [isUpdate, setIsUpdate] = useState(false);
  let optionList;
  if (JSON.parse(localStorage.getItem("option_list"))) {
    optionList = JSON.parse(localStorage.getItem("option_list"))?.map((e) => {
      return {
        label: e.title.toLocaleUpperCase(),
        value: e.id,
      };
    });
  }
  return (
    <>
      <Handle
        type="target"
        position="left"
        style={{ background: "#555" }}
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />

      <div className="node-header">
        <div className="node-label">Option:</div>
        <div
          className={`node-remove-button ${data.static ? "disabled" : ""}`}
          onClick={() => (!data.static ? data.deleteNodeItself() : {})}
        >
          <img
            className="node-remove-button-icon"
            src={RemoveIcon}
            alt="Remove"
          />
        </div>
      </div>

      <div className="node-body">
        <div>
          {/* <div className="option-node-body">{data.value}</div> */}
          {/*
          <Dropdown
            value={data.value}
            options={optionList}
            onChange={(e) => data.updateNodeData({ value: e.value })}
            placeholder="Select an option"
            style={{ maxWidth: 250 }}
          /> */}
          {/* <div>{data.id}</div> */}

          <Dropdown
            disabled={data.static}
            value={isUpdate ? data.id : Number(data.action_value)}
            options={optionList}
            onChange={(e) => {
              data.updateNodeData({
                id: e.value,
                action: "option",
                old_id: data.id,
              });
              setIsUpdate(true);
            }}
            filter
            filterBy="label"
            placeholder="Search"
            panelClassName="option-dropdown-panel"
          />
        </div>
      </div>
      {data.message === "No" && data.static ? (
        <div></div>
      ) : (
        <Handle
          type="source"
          position="right"
          id="b"
          style={{ background: "#555" }}
          isConnectable={isConnectable}
        />
      )}
    </>
  );
});
