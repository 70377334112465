import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import Header from "../../components/header/Header";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import "./Logs.css";
import { getFilteredFlowList, getGeneralList } from "../../API/helper";
import {
  getFilteredLogList,
  getLogsList,
  getSelectedSessionDetail,
} from "../../API/LogsAPI/logsAPI";
import ChatHistoryViewer from "../../components/Chat-History-Viewer/Chat-history-viewer";
import { format } from "date-fns";
import Lottie from "react-lottie-player";
import settingsAnimation from "../../assets/settingsAnimation.json";
const Logs = () => {
  const navigate = useNavigate();
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [nutritionMethods, setNutritionMethods] = useState([]);
  const [nutritionApplicationMethods, setNutritionApplicationMethods] =
    useState();
  const [selectedNutritionMethod, setSelectedNutritionMethod] = useState(null);
  const [selectedNutritionAppMethod, setSelectedNutritionAppMethod] =
    useState(null);
  const [caseLabelOptions, setCaseLabelOptions] = useState(null);
  const [selectedSessionStatus, setSelectedSessionStatus] = useState(null);
  const [caseLabel, setCaseLabel] = useState(null);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState(10);
  const [products, setProducts] = useState([]);
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const [visible, setVisible] = useState(false);
  const [dates, setDates] = useState(null);
  const [chvData, setChvData] = useState({});
  const [content, setContent] = useState("");
  const [isFiltered, setIsFiltered] = useState(false);
  const toast = useRef(null);
  const searchButtonRef = useRef(null);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: {
      name: { value: "", matchMode: "contains" },
      "country.name": { value: "", matchMode: "contains" },
      company: { value: "", matchMode: "contains" },
      "representative.name": { value: "", matchMode: "contains" },
    },
  });
  const sessionStatusOption = [
    { title: "Completed", value: true },
    { title: "Uncompleted", value: false },
  ];
  const feedbackOptions = [
    { title: "All", value: "" },
    { title: "Positive", value: "yes" },
    { title: "Negative", value: "no" },
    { title: "Not Evaluated", value: "false" },
  ];

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        searchButtonRef.current.click();
      }
    };
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const getGeneraData = async () => {
    const generalData = await getGeneralList();
    if (generalData) {
      setNutritionMethods(generalData.data.list.nutrition_method);
      setNutritionApplicationMethods(generalData.data.list.nutrition_type);
      setCaseLabelOptions(generalData.data.list.nlp_labels);
    }
  };
  const getAllLogs = async (logListData) => {
    setLoading(true);
    await getLogsList(logListData).then((resp) => {
      setProducts(resp.data.list);
      setTotalRecords(resp.data.paging.total);
      setLoading(false);
    });
  };
  const getFilteredData = async (filteredData) => {
    const log = await getFilteredLogList(
      content,
      caseLabel ? caseLabel.title : "",
      selectedNutritionMethod ? selectedNutritionMethod.id : "",
      selectedNutritionAppMethod ? selectedNutritionAppMethod.id : "",
      dates ? format(dates[0], "yyyy/MM/dd") : "",
      dates && dates[1] ? format(dates[1], "yyyy/MM/dd") : "",
      selectedFeedback,
      selectedSessionStatus,
      filteredData.page,
      filteredData.row
    );
    if (log.success) {
      setLoading(true);
      setIsFiltered(true);
      setProducts(log.data.list);
      setTotalRecords(log.data.paging.total);
      setLoading(false);
    } else {
      toast.current.show({
        severity: "error",
        summary: log.message,
        life: 3000,
      });
    }
  };
  const getSelectedSession = async (sessionID) => {
    const selectedSession = await getSelectedSessionDetail(sessionID);
    return selectedSession;
  };
  const getAllLabels = async () => {
    const labels = await getGeneralList();
    return labels;
  };
  useEffect(() => {
    getGeneraData();
    getAllLogs();
    getAllLabels();
  }, []);

  const loadLazyData = () => {
    isFiltered
      ? getFilteredData({ page: lazyParams.page + 1, row: lazyParams.rows })
      : getAllLogs({ page: lazyParams.page + 1, limit: lazyParams.rows });
  };
  useEffect(() => {
    loadLazyData();
  }, [lazyParams]);
  const handleOnPage = (event) => {
    setRows(event.rows);
    setLazyParams(event);
  };
  const onSelectRow = (rowData) => {
    setChvData({});
    getSelectedSession(rowData.value.session_id).then((resp) => {
      setChvData(resp);
      setVisible(true);
    });
  };
  const onCaseLabelChange = (e) => {
    setCaseLabel(e.value);
  };
  const feedBackTemplate = (rowData) => {
    return rowData.feedback_option_value === "yes" ? (
      <i
        className="pi pi-thumbs-up"
        style={{ fontSize: "2rem", color: "#6366F1" }}
      ></i>
    ) : rowData.feedback_option_value === "no" ? (
      <i
        className="pi pi-thumbs-down"
        style={{ fontSize: "2rem", color: "#6366F1" }}
      ></i>
    ) : (
      <i
        className="pi pi-minus"
        style={{ fontSize: "2rem", color: "#6366F1" }}
      ></i>
    );
  };
  const sessionStatusTemplate = (rowData) => {
    return rowData.session_end_date_status ? "Completed" : "Uncompleted";
  };
  const idBodyTemplate = (rowData) => {
    return (
      <div
        style={{
          width: 30,
          height: 30,
          background: "#6366F1",
          display: "flex",
          borderRadius: 4,
          cursor: "pointer",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span>{rowData.id}</span>
      </div>
    );
  };
  const sessionIDBodyTemplate = (rowData) => {
    return (
      <div
        style={{
          background: "gray",
          color: "white",
          cursor: "pointer",
          padding: 5,
          borderRadius: 4,
          textAlign: "center",
        }}
        onClick={() =>
          navigate("/selected-user", {
            state: {
              userDetail: rowData.user_detail,
              isSession: true,
            },
          })
        }
      >
        {rowData.user_id}
      </div>
    );
  };
  const caseLabelTemplate = (option) => {
    return (
      <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
        <span>
          <span style={{ fontWeight: "bold" }}>Label: </span>
          {option.title}
        </span>
        <span>
          <span style={{ fontWeight: "bold" }}>Description: </span>
          {option.description}
        </span>
      </div>
    );
  };
  useEffect(() => {
    const closeDialog = (event) => {
      if (event.target.classList.contains("p-component-overlay")) {
        setVisible(false);
      }
    };
    window.addEventListener("mousedown", closeDialog);
    return () => window.removeEventListener("mousedown", closeDialog);
  });

  return (
    <div>
      {loading ? (
        <div className="settings-loader">
          <Lottie
            loop
            animationData={settingsAnimation}
            play
            style={{ width: 400, height: 400 }}
          />
        </div>
      ) : (
        <div>
          <Header items={[{ label: "Logs" }]} />
          <div style={{ padding: 20 }}>
            <div className="filter-div">
              <div className="users-input-div">
                <span style={{ width: "30%" }} className="p-float-label">
                  <InputText
                    style={{ width: "100%" }}
                    id="By Content"
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                  />
                  <label htmlFor="byContent">Enter User ID</label>
                </span>
                <Dropdown
                  className="dropdown"
                  placeholder="Select Nutrition Method"
                  optionLabel="title"
                  options={nutritionMethods}
                  value={selectedNutritionMethod}
                  onChange={(e) => setSelectedNutritionMethod(e.value)}
                  showClear
                />
                <Dropdown
                  className="dropdown"
                  placeholder="Select Nutrition Type"
                  optionLabel="title"
                  options={nutritionApplicationMethods}
                  value={selectedNutritionAppMethod}
                  onChange={(e) => setSelectedNutritionAppMethod(e.value)}
                  showClear
                />
              </div>
              <div className="users-input-div">
                <Calendar
                  value={dates}
                  onChange={(e) => setDates(e.value)}
                  selectionMode="range"
                  readOnlyInput
                  dateFormat="dd/mm/yy"
                  placeholder="Select Start-End Date"
                  showButtonBar
                  className="dropdown"
                  showIcon
                />
                <Dropdown
                  className="dropdown"
                  placeholder="Select Feedback Type"
                  optionLabel="title"
                  options={feedbackOptions}
                  value={selectedFeedback}
                  onChange={(e) => setSelectedFeedback(e.value)}
                  showClear
                />
                <Dropdown
                  className="dropdown"
                  value={selectedSessionStatus}
                  options={sessionStatusOption}
                  onChange={(e) => setSelectedSessionStatus(e.value)}
                  placeholder="Select Session Status"
                  optionLabel="title"
                  showClear
                />
                <Dropdown
                  className="dropdown"
                  value={caseLabel}
                  options={caseLabelOptions}
                  itemTemplate={caseLabelTemplate}
                  filter
                  placeholder="Select Case Label"
                  onChange={onCaseLabelChange}
                  optionLabel="title"
                  showClear
                />
              </div>

              <div className="log-bring-list-div">
                <Button
                  ref={searchButtonRef}
                  label="Search"
                  icon="pi pi-search"
                  iconPos="left"
                  onClick={() => getFilteredData({ page: 0, row: 10 })}
                />
                <div
                  className="clear-button"
                  onClick={() => {
                    getAllLogs({ page: 0, limit: 50 });
                    setDates(null);
                    setContent("");
                    setSelectedFeedback(null);
                    setSelectedNutritionMethod(null);
                    setSelectedNutritionAppMethod(null);
                    setSelectedSessionStatus(null);
                    setCaseLabel(null);
                  }}
                >
                  Clear Filter
                </div>
              </div>
            </div>
          </div>
          <div className="datatable-crud-demo">
            <Toast ref={toast} />
            <Dialog
              header="Messages"
              visible={visible}
              draggable={false}
              blockScroll
              style={{ width: "40vw", marginLeft: 50 }}
              onHide={() => setVisible(false)}
            >
              <ChatHistoryViewer chvData={chvData} />
            </Dialog>
            <div style={{ padding: 20 }}>
              <DataTable
                dataKey="id"
                paginator
                lazy
                value={products}
                rows={rows}
                onPage={handleOnPage}
                first={lazyParams.first}
                rowsPerPageOptions={[10, 25, 50]}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} logs"
                totalRecords={totalRecords}
                responsiveLayout="scroll"
                style={{ width: "100%", border: "1px solid #dee2e6" }}
                selectionMode="single"
                onSelectionChange={(e) => onSelectRow(e)}
                loading={loading}
              >
                <Column
                  field="id"
                  header="ID"
                  style={{ minWidth: "5rem" }}
                ></Column>
                <Column
                  header="User ID"
                  style={{ minWidth: "6rem" }}
                  body={sessionIDBodyTemplate}
                ></Column>
                <Column
                  header="Session ID"
                  style={{ minWidth: "6rem" }}
                  field="session_id"
                ></Column>
                <Column
                  field="session_start_date"
                  header="Session Start Date"
                  style={{ minWidth: "8rem" }}
                ></Column>
                <Column
                  field="session_end_date"
                  header="Session End Date"
                  style={{ minWidth: "8rem" }}
                ></Column>
                <Column
                  field="session_end_date_status"
                  header="Session Status"
                  style={{ minWidth: "8rem" }}
                  body={sessionStatusTemplate}
                ></Column>
                <Column
                  field="nutrition_type"
                  header="Nutrition Type"
                  style={{ minWidth: "8rem", textAlign: "center" }}
                ></Column>
                <Column
                  field="nutrition_method"
                  header="Nutrition Method"
                  style={{ minWidth: "8rem", textAlign: "center" }}
                ></Column>
                <Column
                  field="label"
                  header="Case Label"
                  style={{ minWidth: "8rem" }}
                ></Column>
                <Column
                  field="chat_message_length"
                  header="Chat Message Length"
                  style={{ minWidth: "8rem" }}
                ></Column>
                <Column
                  body={feedBackTemplate}
                  header="Feedback Status"
                  style={{ maxWidth: "8rem" }}
                ></Column>
              </DataTable>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Logs;
