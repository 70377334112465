import React, {useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import { getLanguages} from "../../API/helper";
import {ProgressSpinner} from "primereact/progressspinner";
import Header from "../../components/header/Header";
import {Toast} from "primereact/toast";
import {Button} from "primereact/button";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import Lottie from "react-lottie-player";
import settingsAnimation from "../../assets/settingsAnimation.json";

function Languages(props) {
    const [products, setProducts] = useState(null);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [loading,setLoading]=useState(false)
    const [rows, setRows] = useState(10);
    const [totalRecords,setTotalRecord]=useState(0)
    const toast = useRef(null);
    const dt = useRef(null);
    const { state } = useLocation();
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 50,
        page: 0,
        sortField: null,
        sortOrder: null,
        filters: {
            name: { value: "", matchMode: "contains" },
            "country.name": { value: "", matchMode: "contains" },
            company: { value: "", matchMode: "contains" },
            "representative.name": { value: "", matchMode: "contains" },
        },
    });
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(()=>{
        if(selectedProducts!==null){
            navigate("/languages/details",{state:{id:selectedProducts.id,create:false}})
        }
    },[selectedProducts])
    const getAllLanguages = async (page,row)=>{
        setLoading(true)
        await getLanguages(page,row).then((res)=>{
            setProducts(res.data.list)
            setTotalRecord(res.data.paging.total);
            setLoading(false)
        })
    }

    useEffect(()=>{
        getAllLanguages()
    },[])

    const header = (
        <div>
            <span>Languages</span>
        </div>
    );

    const handleOnPage = (event) => {
        setRows(event.rows);
        setLazyParams(event);
    };
    useEffect(() => {
        loadLazyData();
    }, [lazyParams]);
    const loadLazyData = () => {
        getAllLanguages(lazyParams.page+1,lazyParams.rows)
    };
    return (
        <div>
            {loading ? (
                <div className="settings-loader">
                    <Lottie
                        loop
                        animationData={settingsAnimation}
                        play
                        style={{ width: 400, height: 400 }}
                    />
                </div>
            ) : <div >
                <Header items={[{ label: "Languages" }]} />
                <div className="datatable-crud-demo">
                    <Toast ref={toast} />
                    <div style={{display:"flex",paddingRight:20,justifyContent:"flex-end"}}>
                        <Button className="p-button-info" onClick={()=>{
                            navigate("/languages/details",{state:{create:true}})
                        }}>Create New Language</Button>
                    </div>
                    <div style={{ padding: 20 }}>
                        <DataTable
                            ref={dt}
                            header={header}
                            value={products}
                            selection={selectedProducts}
                            dataKey="id"
                            selectionMode={"single"}
                            onPage={handleOnPage}
                            paginator
                            lazy
                            loading={loading}
                            rows={rows}
                            first={lazyParams.first}
                            rowsPerPageOptions={[10, 25, 50]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} languages"
                            onSelectionChange={(e) => setSelectedProducts(e.value)}
                            totalRecords={totalRecords}
                            responsiveLayout="scroll"
                            style={{ width: "100%", border: "1px solid #dee2e6" }}
                        >
                            <Column
                                field="id"
                                header="ID"
                                style={{ minWidth: "5rem" }}
                            ></Column>
                            <Column
                                field="nlp_url"
                                header="NLP URL"
                            ></Column>
                            <Column
                                field="code"
                                header="Code"
                            ></Column>
                        </DataTable>
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default Languages;