import React, {useEffect, useState} from 'react';
import './Chart-Doughnut.css';
import {Card} from "primereact/card";
import {Chart} from "primereact/chart";

const ChartDoughnut = ({title, data, number}) => {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    const myData = data;
    const myNumber = number;
    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const data = {
            labels: Object.values(myData),
            datasets: [
                {
                    data: myNumber,
                    backgroundColor: [
                        documentStyle.getPropertyValue('--blue-500'),
                        documentStyle.getPropertyValue('--yellow-500'),
                        documentStyle.getPropertyValue('--green-500')
                    ],
                    hoverBackgroundColor: [
                        documentStyle.getPropertyValue('--blue-400'),
                        documentStyle.getPropertyValue('--yellow-400'),
                        documentStyle.getPropertyValue('--green-400')
                    ]
                }
            ]
        };
        const options = {
            cutout: '60%'
        };

        setChartData(data);
        setChartOptions(options);
    }, [data,number]);

    return (
        <Card className="chart-card card flex justify-content-center" style={{textAlign:"center"}} title={title}>
            <Chart type="doughnut" data={chartData} options={chartOptions}  className="w-full md:w-30rem" />
            {data?.map((dat,i)=><div style={{marginTop:5}}>
                <span><span style={{fontWeight:"bold"}}>{dat.toUpperCase()}: </span>{number[i]}</span>
            </div>)}
        </Card>
    );
};

export default ChartDoughnut;
