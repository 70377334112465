import React, {useEffect, useRef, useState} from 'react';
import {Card} from "primereact/card";
import './Label-determination.css'
import {Button} from "primereact/button";
import {InputTextarea} from "primereact/inputtextarea";
import {ListBox} from "primereact/listbox";
import './Label-determination.css'
import {Toast} from "primereact/toast";
import {getCaseLabels, getFilteredFlowList} from "../../API/helper";

const LabelDetermination = ({setActiveItem,activeItem,selectedCaseLabel,getQuestionCaseLabel,setSentenceList,sentenceList,setLoading,firstSentence,setFirstSentence,setIsBack,isBack}) => {
    const [textInput,setTextInput] = useState("")
    const [selectedDetermination, setSelectedDetermination] = useState(null);
    const [inputValue,setInputValue]= useState('')
    const [initialFlowQuestions,setInitialFlowQuestions] = useState([])
    const toast = useRef(null);
    const [editableInput,setEditableInput]=useState("")
    const [firstInputValue,setFirstInputValue]=useState("")
    const inputRef = useRef([]);


    const nutritionAppID = localStorage.getItem("nutritionAppMethodID")
    const nutritionID = localStorage.getItem("nutritionMethodID")
    useEffect(()=>{
        selectedCaseLabel(initialFlowQuestions)
    },[initialFlowQuestions])

    useEffect(()=>{
        if(sentenceList!==0){
            setInitialFlowQuestions(sentenceList)
        }
        if(firstSentence!==""){
            setFirstInputValue(firstSentence)
        }
    },[])

    useEffect(()=>{
        inputRef.current = inputRef.current.slice(0, initialFlowQuestions.length);
    },[initialFlowQuestions])

    useEffect(()=>{},[])
    const addNewRecord = (e,key) =>{
        toast.current.show({
            severity: 'success',
            summary: 'New question added.',
            life: 3000,
        })
        const newID = parseInt(Math.random()*10000);
        setInitialFlowQuestions([...initialFlowQuestions,{id:newID,text:""}])
        setEditableInput("")
    }

    const deleteText = (id) =>{
        const temp = initialFlowQuestions.filter((e)=>{
            return e.id !== id
        })
        setInitialFlowQuestions(temp)
    }
    const getAllCaseLabels = async (formData)=>{
        setLoading(true)
        const caseLabels = await getCaseLabels(formData,nutritionID,nutritionAppID)
        if(caseLabels.success){
            getQuestionCaseLabel(caseLabels.data.list)
            setLoading(false)
        }else{
            toast.current.show({
                severity: 'warning',
                summary: caseLabels.message,
                life: 3000,
            })
        }
    }
    const handleNext = () =>{
        const initialQuestionsPayload = inputRef.current.map((question)=>{
            return question.value
        })
        setIsBack(false)
        setSentenceList(initialFlowQuestions)
        setFirstSentence(firstInputValue)
        getAllCaseLabels(JSON.stringify(initialQuestionsPayload))
        setActiveItem(activeItem+1)
    }

    return (
        <div className="label-determination-div">
            <Toast ref={toast} />
            <Card className="label-determination-card">
                <div className="label-determination-body">
                    <div className="description-wrapper">
                        Please describe one or more sample DNA sentences which explain the flow detail. This sentence should be
                        clear and understandable. If you want to add a symptom for leakage, your sentence should answer who,where,what,
                        how,questions,E,g.:<span style={{fontWeight:"bold"}}> "I have a leakage on my PEG tube."</span>
                    </div>
                    <div className="flow-question-text-wrapper">
                        <InputTextarea className="flow-question-text" value={firstInputValue} onChange={(e)=>setFirstInputValue(e.target.value)}  placeholder="I have a leakage on my PEG tube"  ref={el => inputRef.current[0] = el}  rows={1} cols={100} autoResize/>
                    </div>
                    { initialFlowQuestions.map((initialFlow,key)=>{
                        return (<div className="flow-question-text-wrapper new-question-wrapper">
                            <InputTextarea rows={1} cols={100} value={initialFlowQuestions[key].text} onChange={(e)=> {
                                initialFlowQuestions[key].text = e.target.value;
                                setEditableInput(e.target.value)
                            }} autoResize ref={el => inputRef.current[key+1] = el}/>
                            <Button icon="pi pi-minus" className="p-button-danger" onClick={()=>deleteText(initialFlow.id)}/>
                        </div>)
                    })}
                    <div className="add-button-wrapper">
                        <Button  icon="pi pi-plus" disabled={firstInputValue.length > 5 && initialFlowQuestions.length===0 ? false : editableInput.length > 5 ? false: isBack ? false  : true}
                                 label="ADD SENTENCE" className="p-button-info label-add-button" onClick={()=>addNewRecord()}/>
                    </div>
                </div>

                <div className="label-determination-button-div">
                    <Button className="p-button-info" onClick={()=>setActiveItem(activeItem-1)}>Back</Button>
                    <Button className="p-button-info" disabled={firstInputValue.length > 5 && initialFlowQuestions.length===0 ? false : editableInput.length > 5 ? false : isBack ? false : true} onClick={()=> handleNext()}>Next</Button>
                </div>
            </Card>

        </div>
    );
};

export default LabelDetermination;
