import axios from "axios";
import FormData from "form-data";

let API_BASE_URL = "";
const DEV_MODE = process.env.REACT_APP_MODE;
const PRODUCT_URL = process.env.REACT_APP_PRODUCT_URL;
const DEV_URL = process.env.REACT_APP_STAGING_URL;
let token = "";
const getToken = () => {
  if (token === "") {
    token = localStorage.getItem("token");
    return token;
  } else {
    return token;
  }
};
const getURL = () => {
  if (DEV_MODE === "DEV") {
    console.log("APP STARTED WITH STAGING MODE");
    API_BASE_URL = DEV_URL;
  } else if (DEV_MODE === "PRODUCT") {
    console.log("APP STARTED WITH PRODUCT MODE");
    API_BASE_URL = PRODUCT_URL;
  }
};
getURL();
export const getLogsList = async (logListData) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}/admin/v1/chat_log/list?page=${logListData?.page}&limit=${logListData?.limit}`,
    requestOptions
  );
  const data = await response.json();
  if (!data.success) {
    localStorage.removeItem("token");
    localStorage.removeItem("login");
    window.location.reload();
  }
  return data;
};
export const getFilteredLogList = async (
  user_id,
  nlpLabel,
  nutritionMethodID,
  nutritionAppMethodID,
  start_date,
  end_date,
  feedback_status,
  session_status,
  page,
  row
) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}/admin/v1/chat_log/list?` +
      new URLSearchParams({
        user_id: user_id,
        label: nlpLabel,
        nutrition_method: nutritionMethodID,
        nutrition_type: nutritionAppMethodID,
        start_date,
        end_date,
        feedback_status,
        session_status,
        page,
        limit:row
      }),
    requestOptions
  );
  const data = await response.json();
  return data;
};
export const getSelectedSessionDetail = async (sessionID) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}/admin/v1/chat_log/detail/${sessionID}`,
    requestOptions
  );
  const data = await response.json();
  return data;
};
