import React, { useEffect, useRef, useState } from "react";
import Header from "../../../components/header/Header";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "./Cases-labels.css";
import { ProgressSpinner } from "primereact/progressspinner";
import mockDatas from "./mock.json";
import { Dialog } from "primereact/dialog";
import {
  getChartData,
  getFilteredFlowList,
  getGeneralList,
  getNLPCaseLabelList,
} from "../../../API/helper";
import { Chart } from "primereact/chart";
import dataArray from "./dataArray.json";
import Lottie from "react-lottie-player";
import settingsAnimation from "../../../assets/settingsAnimation.json";

function CasesLabels(props) {
  const [caseLabel, setCaseLabel] = useState(null);
  const [visible, setVisible] = useState(false);
  const [chartReady, setChartReady] = useState(false);
  const [caseLabelOptions, setCaseLabelOptions] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [caseListValue, setCaseListValue] = useState(false);
  const [infoBackground, setInfoBackground] = useState("");
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [totalRecords, setTotalRecords] = useState("");
  const [rows, setRows] = useState(10);
  const [lineData, setLineData] = useState(0);
  const [dataArray, setDataArray] = useState([]);
  const chartRef = useRef(null);
  const searchButtonRef = useRef(null);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
  });
  const chartWidth = window.innerWidth;

  let infoBack;
  if (dataArray.filter((e) => e.caseError === true).length > 0) {
    infoBack = "rgba(255, 159, 64, 1)";
  } else {
    infoBack = "#7BE876";
  }
  const getAllCaseList = async () => {
    setLoading(true);
    const caseList = await getNLPCaseLabelList(
      lazyParams.page + 1,
      lazyParams.rows,
      caseLabel ? caseLabel.title : ""
    );
    setCaseListValue(caseList.data.list);
    setTotalRecords(caseList.data.paging.total);
    setLoading(false);
  };
  const getAllChartData = async () => {
    await getChartData().then((res) => {
      setDataArray(res.data.list);
      setLineData(res.data.avg_margin_of_error);
    });
  };
  useEffect(() => {
    setInfoBackground(infoBack);
  }, [infoBack]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        searchButtonRef.current.click();
      }
    };
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  useEffect(() => {
    let labels = [];
    let allData = [];
    let borderData = [];
    let allBackgrounds = [];
    for (let i = 0; i < dataArray.length; i++) {
      labels.push(dataArray[i].label);
      allData.push(dataArray[i].value);
      borderData.push(lineData);
      dataArray[i].caseError
        ? allBackgrounds.push("rgba(255, 159, 64, 1)")
        : allBackgrounds.push("rgba(75, 192, 192, 1)");
    }
    const data = {
      labels: labels,
      datasets: [
        {
          type: "line",
          label: "Required Average Balance Value",
          borderColor: "red",
          borderWidth: 1,
          borderDash: [3, 3],
          fill: false,
          data: borderData,
          pointBackgroundColor: "#00000000",
          pointBorderColor: "#00000000",
        },
        {
          data: allData,
          backgroundColor: allBackgrounds,
        },
      ],
    };
    const options = {
      plugins: {
        legend: {
          display: false,
        },
        labels: {
          display: false,
        },
      },
      animation: {
        duration: 0,
      },
      scales: {
        x: {
          ticks: {
            display: false,
          },
        },
      },
    };

    setChartData(data);
    setChartOptions(options);
  }, [dataArray]);
  const handleOnPage = (event) => {
    setRows(event.rows);
    setLazyParams(event);
  };
  const getGeneralData = async () => {
    const generalData = await getGeneralList();
    if (generalData) {
      setCaseLabelOptions(generalData.data.list.nlp_labels);
    }
  };
  const caseLabelTemplate = (option) => {
    return (
      <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
        <span>
          <span style={{ fontWeight: "bold" }}>Label: </span>
          {option.title}
        </span>
        <span>
          <span style={{ fontWeight: "bold" }}>Description: </span>
          {option.description}
        </span>
      </div>
    );
  };
  const loadLazyData = () => {
    getAllCaseList();
  };
  useEffect(() => {
    loadLazyData();
  }, [lazyParams]);
  const onCaseLabelChange = (e) => {
    setCaseLabel(e.value);
  };
  const handleCasesSentences = (rowData) => {
    navigate("/nlp/cases-sentences", { state: { rowData } });
  };
  useEffect(() => {
    getGeneralData();
    getAllChartData();
  }, []);
  const caseLabelBody = (rowData) => {
    return (
      <React.Fragment>
        <div
          className="clear-button"
          onClick={() => {
            handleCasesSentences(rowData);
            setVisible(true);
          }}
        >
          <i style={{ marginRight: 5 }} className="pi pi-folder-open"></i>
          {rowData.caselabel}
        </div>
      </React.Fragment>
    );
  };
  const caseLabelChangesBody = (rowData) => {
    return (
      <React.Fragment>
        <div
          onClick={() => {
            handleCasesSentences(rowData);
            setVisible(true);
          }}
        >
          <div className="caseChange-wrapper">
            {rowData.count_created > 0 && (
              <Button
                className="caseChange-create-icon-wrapper"
                style={{ backgroundColor: "white" }}
                tooltip="This icon represents the number of sentences added."
                tooltipOptions={{ position: "top" }}
              >
                <i className="pi pi-plus"></i>
                <span>{rowData.count_created}</span>
              </Button>
            )}
            {rowData.count_deleted > 0 && (
              <Button
                className="caseChange-delete-icon-wrapper"
                style={{ backgroundColor: "white" }}
                tooltip="This icon represents the number of sentences deleted."
                tooltipOptions={{ position: "top" }}
              >
                <i className="pi pi-times"></i>
                <span>{rowData.count_deleted}</span>
              </Button>
            )}
            {rowData?.count_updated > 0 && (
              <Button
                className="caseChange-update-icon-wrapper"
                style={{ backgroundColor: "white" }}
                tooltip="This icon represents the number of sentences edited."
                tooltipOptions={{ position: "top" }}
              >
                <i className="pi pi-refresh"></i>
                <span>{rowData.count_updated}</span>
              </Button>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  };
  useEffect(() => {
    if (caseLabel === null) {
      getAllCaseList();
    }
  }, [caseLabel]);
  return (
    <div>
      {loading ? (
        <div className="settings-loader">
          <Lottie
            loop
            animationData={settingsAnimation}
            play
            style={{ width: 400, height: 400 }}
          />
        </div>
      ) : (
        <div className="">
          <Header items={[{ label: "Cases Labels" }]} />
          <div className="caseLabelChart">
            {chartData?.labels?.length > 0 ? (
              <Chart
                type="bar"
                ref={chartRef}
                data={chartData}
                options={chartOptions}
                height={300}
                width={chartWidth - 130}
              />
            ) : (
              <div
                style={{
                  flex: 1,
                  height: "300px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ProgressSpinner />
              </div>
            )}
            {chartData?.labels?.length > 0 ? (
              <div
                className="information-div"
                style={{ backgroundColor: infoBackground }}
              >
                DATA HEALTH: SOME OF CASE LABELS HAVE UNBALANCED DATA
              </div>
            ) : null}
          </div>
          <div className="caseLabels">
            <div className="caseLabelsFilter">
              <Dropdown
                className="cases-label-dropdown"
                value={caseLabel}
                itemTemplate={caseLabelTemplate}
                filter
                options={caseLabelOptions}
                placeholder="Select Case Label"
                onChange={onCaseLabelChange}
                optionLabel="title"
                showClear
              />
              <Button
                label="Search"
                ref={searchButtonRef}
                onClick={() => {
                  getAllCaseList();
                }}
              />
              <div
                className="clear-button"
                onClick={() => {
                  setCaseLabel(null);
                }}
              >
                Clear Filter
              </div>
            </div>
            <div className="caseLabelsTable">
              <DataTable
                value={caseListValue}
                paginator
                dataKey="id"
                rows={rows}
                lazy
                selectionMode
                totalRecords={totalRecords}
                onPage={handleOnPage}
                first={lazyParams.first}
                rowsPerPageOptions={[5, 10, 25, 50]}
                tableStyle={{ minWidth: "50rem" }}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
              >
                <Column
                  field="id"
                  header="ID"
                  style={{ width: "10%" }}
                ></Column>
                <Column
                  body={caseLabelBody}
                  header="Case Label"
                  style={{ width: "25%" }}
                ></Column>
                <Column
                  body={caseLabelChangesBody}
                  header="Case Label Changes"
                  style={{ width: "25%" }}
                ></Column>
                <Column
                  field="description"
                  header="Description"
                  style={{ width: "55%" }}
                ></Column>
                <Column
                  field="flows"
                  header="#OF FLOWS"
                  style={{ width: "25%" }}
                ></Column>
                <Column
                  field="sentences"
                  header="#OF SENTENCES"
                  style={{ width: "45%" }}
                ></Column>
              </DataTable>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CasesLabels;
