import React, {useEffect, useRef, useState} from 'react';
import {Card} from "primereact/card";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {createNewWellBeingPoll, getAdminDetail, getWellBeingPollDetail, updateWellBeingPoll} from "../../../API/helper";
import {useLocation, useNavigate} from "react-router-dom";
import Header from "../../../components/header/Header";
import {Toast} from "primereact/toast";
import Lottie from "react-lottie-player";
import settingsAnimation from "../../../assets/settingsAnimation.json";

const WellbeingPollUpdate = () => {
    const toast = useRef(null);
    const navigate = useNavigate();
    const { state } = useLocation();
    const [loading,setLoading]=useState(false)
    const [pollValue,setPollValue]=useState({})
    const [items,setItems]=useState([])
    const getWellBeing = async ()=>{
        setLoading(true)
        await getWellBeingPollDetail(state.id).then((res)=>{
            setItems(res.data.list.detail.answers)
            setPollValue({...pollValue,question:res.data.list.detail.question})
            setLoading(false)
        })
    }
    useEffect(()=>{
        getWellBeing()
    },[])
    const handleInputChange = (id, value) => {
        const updatedItems = items.map((item) => {
            if (item.id === id) {
                return { ...item, answer: value };
            }
            return item;
        });

        setItems(updatedItems);
    };
    const handleAddItem = () => {

        const newId = items.length > 0 ? items[items.length - 1].id + 1 : 1;
        const newItem = { id: newId, answer: '' };
        setItems([...items, newItem]);

    };
    const handleRemoveItem = (id) => {
        if (items.length > 2) {
            const updatedItems = items.filter((item) => item.id !== id);
            setItems(updatedItems);
        }else{
            toast.current.show({
                severity: "error",
                summary: "Error",
                detail: "There must be at least two answers!",
                life: 3000,
            })
        }
    };
    const updatePoll = async () => {
        await updateWellBeingPoll(state.id,pollValue.question,items).then((response) =>{
                if(response.success){
                    toast.current.show({
                        severity: "success",
                        summary: "Successful",
                        detail: response.message,
                        life: 3000,
                    })
                    setTimeout(() => {
                        navigate("/polls/wellbeing-polls")
                    }, 1000);
                }
                else{
                    toast.current.show({
                        severity: "error",
                        summary: "Error",
                        detail: response.message,
                        life: 3000,
                    })
                }
            }
        );
    };

    return (
        <div>
            {loading ? (
                <div className="settings-loader">
                    <Lottie
                        loop
                        animationData={settingsAnimation}
                        play
                        style={{ width: 400, height: 400 }}
                    />
                </div>
            ) :  <div>
                <Header items={[{ label: 'Wellbeing Polls' , url: '/polls/wellbeing-polls'},{ label: "Create Poll" }]} />
                <div className="flow-wrapper">
                    <Toast ref={toast} />
                    <Card className="nutrition-method-card">
                        <div className="nutrition-method-title">
                            Please enter question and answers to create polls in it.
                        </div>
                        <div className="polls-wrapper" style={{marginLeft:0}}>
                            <div className="admin-info-wrapper">
                                <span>Question:</span>
                                <InputText placeholder="Please Enter Question" value={pollValue.question} onChange={(e) => setPollValue({...pollValue,question:e.target.value})} />
                            </div>
                            <div>
                                <span>Answers:</span>
                                {items.map((item) => (
                                    <div className="answer-wrapper" key={item.id}>
                                        <InputText disabled placeholder="Please Enter Answer" style={{marginRight:10,width:"100%"}} value={item.answer} onChange={(e) => handleInputChange(item.id, e.target.value)} />
                                        {item.id>2 && <Button disabled onClick={() => handleRemoveItem(item.id)} className="p-button p-button-danger"  icon="pi pi-minus "/>}
                                    </div>
                                ))}
                                {<Button onClick={handleAddItem} disabled label="Add Answer" className="p-button-info" icon="pi pi-plus"/>}
                            </div>
                        </div>
                        <div className="poll-question-div" style={{justifyContent:"flex-end"}}>
                            <Button className="p-button-info" disabled={!(pollValue?.question?.replaceAll(" ", "") !== "" && items?.[0]?.answer?.replaceAll(" ", "") !== "" && items?.[1]?.answer?.replaceAll(" ", "") !== "")} onClick={()=>{updatePoll()}}>Save</Button>
                        </div>
                    </Card>

                </div>
            </div>}
        </div>


    );
};

export default WellbeingPollUpdate;