import React, {useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {
    changePollStatus,
    deletePollByID,
    editPoll,
    getAllAdmins,
    getPollList,
    getPollsDetailAnswers,
    updateStatus
} from "../../API/helper";
import {ProgressSpinner} from "primereact/progressspinner";
import Header from "../../components/header/Header";
import {Toast} from "primereact/toast";
import {Button} from "primereact/button";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Tag} from "primereact/tag";
import {Dialog} from "primereact/dialog";
import Lottie from "react-lottie-player";
import settingsAnimation from "../../assets/settingsAnimation.json";
import {InputSwitch} from "primereact/inputswitch";
import './Polls.css'

function Polls(props) {
    const [products, setProducts] = useState(null);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [loading,setLoading]=useState(false)
    const [rows, setRows] = useState(10);
    const [totalRecords,setTotalRecord]=useState(0)
    const [visible,setVisible]=useState(false)
    const [pollDetailVisible,setPollDetailVisible]=useState(false)
    const [answers,setAnswers]=useState([])
    const [rowID,setRowID]=useState(false)
    const toast = useRef(null);
    const dt = useRef(null);
    const { state } = useLocation();
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 0,
        sortField: null,
        sortOrder: null,
        filters: {
            name: { value: "", matchMode: "contains" },
            "country.name": { value: "", matchMode: "contains" },
            company: { value: "", matchMode: "contains" },
            "representative.name": { value: "", matchMode: "contains" },
        },
    });
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const deletePollData = async (poll_id) => {
        await deletePollByID(poll_id).then((response) =>{
                if(response.success){
                    toast.current.show({
                        severity: "success",
                        summary: "Successful",
                        detail: response.message,
                        life: 3000,
                    })
                    getPolls(1,10)
                }
                else{
                    toast.current.show({
                        severity: "error",
                        summary: "Error",
                        detail: response.message,
                        life: 3000,
                    })
                }
            }
        );
    };
    useEffect(()=>{
        if(selectedProducts!==null){
            navigate("/polls/details",{state:{id:selectedProducts.id,create:false}})
        }
    },[selectedProducts])
    const getPolls = async (page,row)=>{
        setLoading(true)
        await getPollList(page,row).then((res)=>{
            setProducts(res.data.list)
            setTotalRecord(res.data.paging.total);
            setLoading(false)
        })
    }

    const header = (
        <div>
            <span>Polls</span>
        </div>
    );
    const footerContent = (
        <div>
            <Button label="No" icon="pi pi-times" onClick={() => setVisible(false)} className="p-button-text" />
            <Button label="Yes" icon="pi pi-check" onClick={() => {
                deletePollData(rowID)
                setVisible(false)
            }} autoFocus />
        </div>
    );
    const footerDetailContent = (
        <div>
            <Button label="Close" onClick={() => {
                setPollDetailVisible(false)
            }} autoFocus />
        </div>
    );
    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div style={{display:"flex",gap:10}}>
                    <Button
                        icon="pi pi-minus"
                        className="p-button p-button-danger"
                        onClick={()=> {
                            setRowID(rowData.id)
                            setVisible(true)
                        }}
                        style={{ textAlign: "left" }}
                    />
                    <Button
                        icon="pi pi-chart-bar"
                        className="p-button p-button-info"
                        onClick={()=> {
                            navigate(`/polls/details/${rowData.id}`,{state:{pollID:rowData.id}})
                        }}
                        style={{ textAlign: "left" }}
                    />
                    <Button
                        icon="pi pi-pencil"
                        className="p-button p-button-warning"
                        onClick={()=> {
                            navigate("/polls/details",{state:{id:rowData.id,create:false}})
                        }}
                        style={{ textAlign: "left" }}
                    />
                </div>

            </React.Fragment>
        );
    };
    function checkArrayIntersection(arr1, arr2) {
        for (let item1 of arr1) {
            for (let item2 of arr2) {
                if (item1.id === item2.id) {
                    return true;
                }
            }
        }
        return false;
    }
    const handleChangeStatus = async (poll_id, status) => {
        const poll = await changePollStatus(poll_id,status===1 ? 0 : 1)
        const selectedPoll = products.find(product=>product.id===poll_id)
        const updatedProducts = products.map((e) => {
            if (e.id === poll_id) {
                return { ...e, active: poll.data.list.active===1 ? 1 : 0 };
            }
            if(checkArrayIntersection(e.methods,selectedPoll.methods) && checkArrayIntersection(e.types,selectedPoll.types) && e?.poll_position === selectedPoll?.poll_position && e.active===1)
            {
                return { ...e, active: poll.data.list.active=== 0 };
            }
            return e;
        });
        setProducts(updatedProducts);

    };
    const getPollDetail = async (page,row,id)=>{
        await getPollsDetailAnswers(id,page,row).then((res)=>{
            setAnswers(res.data.list.chart)
            setPollDetailVisible(true)
        })
    }
    const nameBody = (rowData) => {
        return (
            <React.Fragment>
                <div
                    onClick={() => {
                        getPollDetail(1,10,rowData.id)
                    }
                    }
                    style={{ color: "#1d4ed8", cursor: "pointer" }}
                >
                    {rowData.name}
                </div>
            </React.Fragment>
        );
    };
    const questionBody = (rowData) => {
        return (
            <React.Fragment>
                <div>
                    {rowData.poll_position !== null && rowData?.poll_position.toUpperCase()}
                </div>
            </React.Fragment>
        );
    };
    const nutMethodBody = (rowData) => {
        return (
            <React.Fragment>
                <div>
                    {rowData?.methods.map((mt)=><div style={{display:"flex"}}>{mt.title}</div>)}
                </div>
            </React.Fragment>
        );
    };
    const nutTypeBody = (rowData) => {
        return (
            <React.Fragment>
                <div>
                    {rowData?.types.map((typ)=><div style={{display:"flex"}}>{typ.title.toUpperCase()}</div>)}
                </div>
            </React.Fragment>
        );
    };
    const startDateBody = (rowData) => {
        return (
            <React.Fragment>
                <div>
                    {rowData.schedule.length>0 && rowData.schedule[0].start_date}
                </div>
            </React.Fragment>
        );
    };
    const endDateBody = (rowData) => {
        return (
            <React.Fragment>
                <div>
                    {rowData.schedule.length>0 && rowData.schedule[0].end_date}
                </div>
            </React.Fragment>
        );
    };

    const handleOnPage = (event) => {
        setRows(event.rows);
        setLazyParams(event);
    };
    useEffect(() => {
        loadLazyData();
    }, [lazyParams]);
    const loadLazyData = () => {
        getPolls(lazyParams.page+1,lazyParams.rows)
    };
    return (
        <div>
            {loading ? (
                <div className="settings-loader">
                    <Lottie
                        loop
                        animationData={settingsAnimation}
                        play
                        style={{ width: 400, height: 400 }}
                    />
                </div>
            ) : <div >
                <Header items={[{ label: "Polls" }]} />
                <Dialog header="Delete Poll" visible={visible} draggable={false} blockScroll style={{ width: '40vw',marginLeft:50 }} onHide={() => setVisible(false)} footer={footerContent}>
                    Are you sure you want to delete the poll?
                </Dialog>
                <Dialog header="Poll Detail" visible={pollDetailVisible} draggable={false} blockScroll style={{ width: '40vw',marginLeft:50 }} onHide={() => setPollDetailVisible(false)} footer={footerDetailContent}>

                        <DataTable value={answers} >
                            <Column field="item_title" header="Answer Name"></Column>
                            <Column field="item_value" header="Answer Count"></Column>
                        </DataTable>

                </Dialog>
                <div className="datatable-crud-demo">
                    <Toast ref={toast} />
                    <div style={{display:"flex",paddingRight:20,justifyContent:"flex-end"}}>
                        <Button className="p-button-info" onClick={()=>{
                            navigate("/polls/details",{state:{create:true}})
                        }}>Create New Poll</Button>
                    </div>
                    <div style={{ padding: 20 }}>
                        <DataTable
                            ref={dt}
                            header={header}
                            value={products}
                            selection={selectedProducts}
                            dataKey="id"
                            onPage={handleOnPage}
                            paginator
                            lazy
                            loading={loading}
                            rows={rows}
                            first={lazyParams.first}
                            rowsPerPageOptions={[10, 25, 50]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} polls"
                            onSelectionChange={(e) => setSelectedProducts(e.value)}
                            totalRecords={totalRecords}
                            responsiveLayout="scroll"
                            style={{ width: "100%", border: "1px solid #dee2e6" }}
                        >
                            <Column
                                field="id"
                                header="ID"
                                style={{ minWidth: "5rem" }}
                            ></Column>
                            <Column
                                field="name"
                                header="Name"
                                body={nameBody}
                            ></Column>
                            <Column
                                field="question"
                                header="Question"
                            ></Column>
                            <Column
                                field="start_date"
                                header="Start Date"
                                body={startDateBody}
                            ></Column>
                            <Column
                                field="end_date"
                                header="End Date"
                                body={endDateBody}
                            ></Column>
                            <Column
                                field="position"
                                header="Question Position"
                                body={questionBody}
                            ></Column>
                            <Column
                                header="Nutrition Methods"
                                body={nutMethodBody}
                            ></Column>
                            <Column
                                header="Nutrition Types"
                                body={nutTypeBody}
                            ></Column>
                            <Column
                                header="Status"
                                field="active"
                                body={(a) => {
                                    return (
                                        <InputSwitch
                                            checked={a.active===1 ? true : false}
                                            onChange={(e) => handleChangeStatus(a.id, a.active)}
                                        />
                                    );
                                }}
                            ></Column>
                            <Column
                                header="Functions"
                                body={actionBodyTemplate}
                            ></Column>
                        </DataTable>
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default Polls;