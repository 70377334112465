import React, {useEffect, useRef, useState} from "react";
import "./Login.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { InputSwitch } from "primereact/inputswitch";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
let API_BASE_URL = "";
const DEV_MODE = process.env.REACT_APP_MODE;
const PRODUCT_URL = process.env.REACT_APP_PRODUCT_URL;
const DEV_URL = process.env.REACT_APP_STAGING_URL;
const Login = ({ isLogin }) => {
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [switchChecked, setSwitchChecked] = useState(false);
  const [error, setError] = useState("");
  const loginButtonRef = useRef(null);
  let formData = new FormData();
  const toast = useRef(null);
  const showError = () => {
    toast.current.show({
      severity: "error",
      summary: "E-Mail or password is not valid.",
      life: 3000,
    });
  };
  const getURL = () => {
    if (DEV_MODE === "DEV") {
      console.log("APP STARTED WITH STAGING MODE");
      API_BASE_URL = DEV_URL;
    } else if (DEV_MODE === "PRODUCT") {
      console.log("APP STARTED WITH PRODUCT MODE");
      API_BASE_URL = PRODUCT_URL;
    }
  };
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        loginButtonRef.current.click();
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);
  const handleLogin = () => {
    getURL();
    formData.append("email", username);
    formData.append("password", password);
    fetch(`${API_BASE_URL}/admin/v1/auth/login`, {
      method: "POST",
      body: formData,
    }).then((response) =>
      response.json().then((result) => {
        if (response.ok) {
          isLogin(result);
        } else {
          showError();
        }
      })
    );
  };
  return (
    <div className="header">
      <div className="card">
        <span>Nursen - Management Panel</span>
        <Toast ref={toast} />
        <div className="input-username">
          <div className="p-inputgroup">
            <span className="p-inputgroup-addon">
              <i className="pi pi-user"></i>
            </span>
            <InputText
              placeholder="Username"
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
        </div>
        <div className="input-password">
          <div className="p-inputgroup">
            <span className="p-inputgroup-addon">
              <i className="pi pi-key"></i>
            </span>
            <Password
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              toggleMask
              feedback={false}
            />
          </div>
        </div>
        <div className="remember-group">
          <div className="p-inputgroup">
            <div className="rememberMe-div">
              <InputSwitch
                checked={switchChecked}
                onChange={(e) => setSwitchChecked(e.value)}
              />
              <span className="remember-span">Remember Me</span>
            </div>
          </div>
          <div className="forgot-div">
            <span className="forgot-span">Forgot Password</span>
          </div>
        </div>
        <div className="button-div">
          <Button
            label="Login"
            icon="pi pi-unlock"
            className="login-button"
            ref={loginButtonRef}
            onClick={() => handleLogin()}
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
