import React, { useEffect, useRef, useState } from "react";
import Header from "../../../components/header/Header";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import {
  deleteDataset,
  forkDataset, getChangeLog, getChangeLogData, getChartData, getChartDataByID,
  getDatasetList,
  getNLPCaseLabelList, getValidationQueue, selectActiveDataset, selectDevelopmentDataset,
  startTrainJob
} from "../../../API/helper";
import { Toast } from "primereact/toast";
import "./Training-job-history.css";
import { Menu } from "primereact/menu";
import { Chart } from "primereact/chart";
import { ProgressSpinner } from "primereact/progressspinner";

import ReactDiffViewer from 'react-diff-viewer';
import {Accordion, AccordionTab} from "primereact/accordion";
import Lottie from "react-lottie-player";
import settingsAnimation from "../../../assets/settingsAnimation.json";

function TrainingJobHistory(props) {
  const [selectedProduct, setSelectedProduct] = useState();
  const [infoBackground, setInfoBackground] = useState("");
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [dialogVisible, setDialogVisible] = useState(false);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [changeLogVisible, setChangelogVisible] = useState(false);
  const [lineData, setLineData] = useState(0);
  const [trainingData, setTrainingData] = useState()
  const [dataArray, setDataArray] = useState([]);
  const chartRef = useRef(null);
  const [activeNlpDialog, setActiveNlpDialog] = useState(false)
  const [caseLabel, setCaseLabel] = useState(null);
  const [batchLogVisible, setBatchLogVisible] = useState(false)
  const [taskLog, setTaskLog] = useState("")
  const [changeDataSize, setChangeDataSize] = useState("")
  const [validationSize,setValidationSize]=useState()
  const [batchLog, setBatchLog] = useState("")
  const [changeLog, setChangeLog] = useState([])
  const [loading,setLoading]=useState(false)
  const [taskLoader, setTaskLoader] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [changeUpdate,setChangeUpdate]=useState(0)
  const [changeCreate,setChangeCreate]=useState(0)
  const [changeDelete,setChangeDelete]=useState(0)

  let updateChange=0
  let createChange=0
  let deleteChange=0

  useEffect(()=>{

    changeLog.forEach((change)=>{
      if(change?.change_type===100){
        updateChange++
      }else if(change?.change_type===300){
        createChange++
      }
      else if(change?.change_type===200){
        deleteChange++
      }
      setChangeUpdate(updateChange)
      setChangeCreate(createChange)
      setChangeDelete(deleteChange)
    })
  },[changeLog])

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
  });
  const menu = useRef(null);
  const chartWidth = window.innerWidth;
  const dataArray1 = dataArray;
  const toast = useRef();

  let infoBack;
  if (dataArray1.filter((e) => e.caseError === true).length > 0) {
    infoBack = "rgba(255, 159, 64, 1)";
  } else {
    infoBack = "#7BE876";
  }
  const checkStatus = (data) => {
    for (let i = 0; i < data.length; i++) {
      const status = data[i].status;
      if (![0, 700, 900, 910, 920].includes(status)) {
        return true;
      }
    }
    return false;
  };


  let isStatusValid = false
  const getDatasetLists = async (isLoading) => {
    isLoading && setLoading(true)
    await getDatasetList(1, 50).then((resp) => {
      let sortingArray = resp.data.list.sort((a, b) => a.id - b.id);
      isStatusValid=checkStatus(sortingArray)
      setTrainingData(sortingArray)
      const isButtonEnabled = sortingArray.every(item => {
        const status = item.status;
        return status === 0 || status === 700 || status === 900 || status === 910 || status === 920;
      });
      if (isButtonEnabled) {
       setButtonDisable(false);
      } else {
        setButtonDisable(true)
      }
      isLoading && setLoading(false)
     setTimeout(() => {
        getDatasetLists(false);
      }, 5000);
    })
  };
  const refreshDatasetList = async () => {
    await getDatasetList(1, 50).then((resp) => {
      if(resp.success){
          toast.current.show({
            severity: "success",
            summary: "Updated",
            detail: "Data Table Updated",
            life: 3000,
          });

      }
      let sortingArray = resp.data.list.sort((a, b) => a.id - b.id);
      setTrainingData(sortingArray)
    })

  };
  const getAllCaseList = async () => {
    const caseList = await getNLPCaseLabelList(
        lazyParams.page + 1,
        lazyParams.rows,
        caseLabel ? caseLabel.title : ""
    );
  };

  const getAllChartDataByID = async (id) => {
    await getChartDataByID(id).then((res)=>{
      setDataArray(res.data.list);
      setLineData(res.data.avg_margin_of_error);
    })

  };
  const getAllChartData = async () => {
    await getChartData().then((res)=>{
      setDataArray(res.data.list);
      setLineData(res.data.avg_margin_of_error);
    })

  };
  useEffect(() => {
    setInfoBackground(infoBack);
  }, [infoBack]);

  useEffect(() => {
    getAllChangeLog()
    getValidationQueueData()
    getAllChartData()
    getDatasetLists(true)
    getAllCaseList();
  }, []);

  const startTrain = async (data_id) => {
    setTaskLoader(true)
    await startTrainJob(data_id).then((resp) => {
      if(resp){
        setTaskLoader(false)
        getDatasetLists()
        toast.current.show({
          severity: "success",
          summary: "Updated",
          detail: "Train stated successfully.",
          life: 3000,
        });
      }
    })
  }
  useEffect(() => {
    let labels = [];
    let allData = [];
    let allBackgrounds = [];
    let borderData = [];
    for (let i = 0; i < dataArray1.length; i++) {
      labels.push(dataArray1[i].label);
      allData.push(dataArray1[i].value);
      borderData.push(lineData);
      dataArray1[i].caseError
          ? allBackgrounds.push("rgba(255, 159, 64, 1)")
          : allBackgrounds.push("rgba(75, 192, 192, 1)");
    }

    const data = {
      labels: labels,
      datasets: [
        {
          type: "line",
          label: "Avarage",
          borderColor: "red",
          borderWidth: 1,
          borderDash: [3, 3],
          fill: false,
          data: borderData,
          pointBackgroundColor: "#00000000",
          pointBorderColor: "#00000000",
        },
        {
          data: allData,
          backgroundColor: allBackgrounds,
        },
      ],
    };
    const options = {
      plugins: {
        legend: {
          display: false,
        },
        labels: {
          display: false,
        },
      },
      animation: {
        duration: 0,
      },
      scales: {
        x: {
          ticks: {
            display: false,
          },
        },
      },
    };
    setChartData(data);
    setChartOptions(options);
  }, [dataArray]);

  const statusTemplate = (rowData) => {
    return (
        <React.Fragment>
          {rowData.status === "Training" ? (
              <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
              >
                <span>{rowData.status}</span>
                <ProgressSpinner
                    style={{width: "20px", height: "20px"}}
                    strokeWidth="10"
                    fill="var(--surface-ground)"
                    animationDuration=".5s"
                />
              </div>
          ) : (
              <span>{rowData.status}</span>
          )}
        </React.Fragment>
    );
  };
  const getValidationQueueData = async () => {
   await getValidationQueue(
        lazyParams.page + 1,
        lazyParams.rows
    ).then((response)=>{
     setValidationSize(response.data.paging.total)

   })

  };

  const sendActiveDataset = async () => {
    await selectActiveDataset(selectedProduct?.id).then((resp) => {
      if (resp.status) {
        getAllChangeLog()
        getDatasetLists()
        toast.current.show({
          severity: "success",
          summary: "Updated",
          detail: "Active NLP dataset has been updated.",
          life: 3000,
        });
      }
    })
  }



  const getAllChangeLog = async () => {
    await getChangeLog().then((res) => {
      if (res.status) {
        setChangeLog(res.data)
        let updateCount=0,deletedCount=0,createCount=0
        res.data.forEach((changeData)=>{
          if(changeData.change_type===100){
            updateCount++
          }else if(changeData.change_type===200){
            deletedCount++
          }else{
            createCount++
          }
        })
        setChangeDataSize([...changeDataSize,{updateCount:updateCount},{deletedCount:deletedCount},{createCount:createCount}])

      }

    })
  }
  const getSeverity = (product) => {
    switch (product.status) {
      case 0:
        return {name: "New Dataset", train: false, reTrain: true}

      case 100:
        return {name: "Sent to train.", train: true, reTrain: false}

      case 600:
        return {name: "Folder created.", train: true, reTrain: false}

      case 650:
        return {name: "Source program cloned.", train: true, reTrain: false}

      case 680:
        return {name: "Training running.", train: true, reTrain: false}

      case 685:
        return {name: "Test Running.", train: true, reTrain: false}

      case 700:
        return {name: "Training Completed.", train: false, reTrain: false}

      case 800:
        return {name: "TSV file exported.", train: true, reTrain: false}

      case 850:
        return {name: "Train started.", train: true, reTrain: false}

      case 880:
        return {name: "Started testing of the trained package.", train: true, reTrain: false}

      case 900:
        return {name: "Unexpected error was encountered.", train: false, reTrain: true}

      case 910:
        return {name: "There was an error during the training.", train: false, reTrain: true}

      case 920:
        return {name: "Problem encountered while testing the trained package.", train: false, reTrain: true}
      default:
        return null
    }
  }

  useEffect(()=>{
    if(selectedProduct!==undefined){
      getChangeLogData(selectedProduct?.id).then((resp)=>{
        setTaskLog(resp?.data.info.task_train_log)
        setBatchLog(resp?.data.info.task_batch_test_log)
      })
    }

  },[selectedProduct])
  const activeRow = (rowData) => {
    return (
        <React.Fragment>
          <div>
            {rowData?.is_nlp_engine && <div className="dataset_status dataset_status_nlp">Active NLP Engine</div>}
          </div>
        </React.Fragment>
    );
  };
  const actionBodyTemplate = (rowData) => {
    return (
        <React.Fragment>
          {
            getSeverity(rowData)?.reTrain ?
                <div style={{
                  display: "flex",
                  alignItems: "center",
                  flex: 1,
                  justifyContent: "center",
                }}>
                  <Button
                      className="activeButton"
                      label="Start to Training"
                      onClick={() => startTrain(rowData.id)}
                  />
                </div> : null
          }
        </React.Fragment>
    );
  };

  const footerContent = (
      <div>
        <Button label="No" icon="pi pi-times" onClick={() => setActiveNlpDialog(false)} className="p-button-text"/>
        <Button label="Yes" icon="pi pi-check" onClick={() => {
          startTrain(selectedProduct?.id)
          setActiveNlpDialog(false)
        }} autoFocus/>
      </div>
  );


  const dotBodyTemplate = (rowData) => {
    const items = [
      {
        label: 'Functions',
        items: [
          {
            disabled: selectedProduct?.status === 0,
            label: "Train Log",
            icon: "pi pi-file",
            command: () => {
              setChangelogVisible(true)
            },
          },
          {
            disabled: selectedProduct?.status === 0,
            label: "Batch Test Log",
            icon: "pi pi-file",
            command: () => {
              setBatchLogVisible(true)
            },
          },
          {
            disabled: !selectedProduct?.status === 700,
            label: "Set as Default NLP Engine",
            icon: "pi pi-spin pi-cog",
            command: () => {
              setDialogVisible(true)
            },
          },
          {
            label: "Delete Dataset",
            icon: "pi pi-delete-left",
            command: () => {
              setDeleteDialogVisible(true)
            },
          },
          /*{
            disabled: selectedProduct?.status!==0 ,
            label: "Set as Development Version",
            icon: "pi pi-spin pi-cog",
            command: () => {
              sendDatasetToDevelopment()
            },
          },*/
          /* {
             disabled: !selectedProduct?.status===700,
             label: "Clone Dataset",
             icon: "pi pi-spin pi-cog",
             command: () => {
               sendDatasetToFork()
             },
           }*/
        ],
      },
      {
        label: 'Download',
        items: [
          {
            label: 'Datatable TSV',
            icon: 'pi pi-download',
            url: `https://dev-diginurse.hive.digital4danone.com/admin/v1/datasets/download/${selectedProduct?.id}/dataset_as_tsv?_token=${localStorage.getItem("token")}`,
          },
          {
            label: 'Datatable CSV',
            icon: 'pi pi-download',
            url: `https://dev-diginurse.hive.digital4danone.com/admin/v1/datasets/download/${selectedProduct?.id}/dataset_as_csv?_token=${localStorage.getItem("token")}`,

          },
          {
            label: 'Train Log Download',
            icon: 'pi pi-download',
            url: `https://dev-diginurse.hive.digital4danone.com/admin/v1/datasets/download/${selectedProduct?.id}/training_log?_token=${localStorage.getItem("token")}`,

          },
          {
            label: 'Batch Log Download',
            icon: 'pi pi-download',
            url: `https://dev-diginurse.hive.digital4danone.com/admin/v1/datasets/download/${selectedProduct?.id}/batch_test_log?_token=${localStorage.getItem("token")}`,

          }
        ]
  }
    ];
    return (
        <React.Fragment>
          <Menu model={items} popup ref={menu}/>
          <i
              onClick={(e) => menu.current.toggle(e)}
              className="pi pi-ellipsis-v"
          ></i>
        </React.Fragment>
    );
  };
  const statusBodyTemplate = (product) => {
    return <div
        style={{display: "flex"}}
    >
      <span>{getSeverity(product)?.name}</span>
      {getSeverity(product)?.train ? <ProgressSpinner
          style={{width: "20px", height: "20px"}}
          strokeWidth="10"
          fill="var(--surface-ground)"
          animationDuration=".5s"
      /> : <div></div>}
    </div>
  }

  const IDBodyTemplate = (product) => {
      return <div>{parseInt(product.id+7000)}</div>

  }
  const RefIDBodyTemplate = (product) => {
    if(product.ref_dataset_id!==""){
      return <div>{parseInt(parseInt(product.ref_dataset_id)+7000)}</div>
    }
  }
  const fileSizeBodyTemplate = (product) => {
    if(product?.model_size === "" || product?.model_size === "0"){
      return <div>-</div>
    }
    else {
      return <div>{parseInt(product.model_size)}MB</div>
    }

  }
  const changeRateBodyTemplate = (product) => {
    return <div>%{product.change_ratio}</div>
  }
  const accRateBodyTemplate = (product) => {
    if(product?.task_result_accuracy === "" || product?.task_result_accuracy === "0%"){
      return <div>-</div>
    }
    else {
      return <div>{product.task_result_accuracy}</div>
    }
  }

  const header = (
      <div className="training-datatable-header">
        <Button icon="pi pi-refresh" onClick={() => {
          refreshDatasetList()
        }}/>
      </div>
  );
  function downloadFile(url) {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', '');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
      <div>{loading ? <div className="settings-loader">
        <Lottie
            loop
            animationData={settingsAnimation}
            play
            style={{ width: 400, height: 400 }}
        />
      </div>:
          <div>
            {taskLoader ? <div className="flow-viewer-preloader">
              <ProgressSpinner />
            </div>: <div>
              <Header items={[{label: "Training Jobs History"}]}/>
              <Dialog
                  header={"Delete Dataset"}
                  visible={deleteDialogVisible}
                  onHide={() => setDeleteDialogVisible(false)}
                  style={{width: "50vw"}}
                  breakpoints={{"960px": "75vw", "641px": "100vw"}}
              >
                <div style={{fontWeight: "bold", marginTopTop: 10}}>
                  <span>You are about to delete the dataset. Do you confirm ?</span>
                </div>
                <div className="validation-dialog-buttons">
                  <Button
                      className="p-button-raised p-button-outlined"
                      onClick={() => {
                        setDeleteDialogVisible(false)
                      }}
                  >
                    No
                  </Button>
                  <Button label="Yes" onClick={() => {
                    setDeleteDialogVisible(false)
                    deleteDataset(selectedProduct?.id).then(()=>{
                      toast.current.show({
                        severity: "success",
                        summary: "Delete",
                        detail: "Dataset Deleted.",
                        life: 3000,
                      });
                      refreshDatasetList()
                      getAllChartData(selectedProduct?.id)
                      setDeleteDialogVisible(false)
                    })

                  }}/>
                </div>
              </Dialog>
              <Dialog
                  header={"Set as Default NLP Engine"}
                  visible={dialogVisible}
                  onHide={() => setDialogVisible(false)}
                  style={{width: "50vw"}}
                  breakpoints={{"960px": "75vw", "641px": "100vw"}}
              >
                <div style={{fontWeight: "bold", marginTopTop: 10}}>
                  <span>You are about to change the default NLP Engine. Do you confirm ?</span>
                </div>
                <div className="validation-dialog-buttons">
                  <Button
                      className="p-button-raised p-button-outlined"
                      onClick={() => {
                        setDialogVisible(false)
                      }}
                  >
                    No
                  </Button>
                  <Button label="Yes" onClick={() => {
                    setDialogVisible(false)
                    sendActiveDataset()
                    getAllChartDataByID(selectedProduct?.id)
                  }}/>
                </div>
              </Dialog>
              <Dialog header="Create New Job" visible={activeNlpDialog} style={{width: '50vw'}}
                      onHide={() => setActiveNlpDialog(false)} footer={footerContent} draggable={false}>
                <div className="change-log-wrapper">
                  <span>Change Summary</span>
                  <div style={{display:"flex",gap:10,fontWeight:500}}>
                    <Button className="caseChange-update-icon-wrapper" style={{backgroundColor:"white"}} tooltip="This icon represents the number of sentences edited." tooltipOptions={{ position: "top"}}><i className="pi pi-refresh"></i><span>{changeUpdate}</span></Button>
                    <Button className="caseChange-delete-icon-wrapper" style={{backgroundColor:"white"}} tooltip="This icon represents the number of sentences deleted." tooltipOptions={{ position: "top"}}><i className="pi pi-times"></i><span>{changeDelete}</span></Button>
                    <Button className="caseChange-create-icon-wrapper" style={{backgroundColor:"white"}} tooltip="This icon represents the number of sentences added." tooltipOptions={{ position: "top"}}><i className="pi pi-plus"></i><span>{changeCreate}</span></Button>
                  </div>
                </div>
                <Accordion style={{padding:"0!important"}}>
                  <AccordionTab  header="Change Log ">
                    <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "25px",
                          backgroundColor: "#f1f1f1",
                          padding: "20px",
                          maxHeight: "300px",
                          overflowY: "scroll",
                        }}
                    >
                      {changeLog?.map((log,i) => {
                        return <div key={i}>
                          <div style={{marginBottom:"25px;"}}>
                            <em style={{width:"100%",display:"block",textAlign:"right", marginBottom:"15px"}}>
                              <b style={{float:"left"}}>{i+1}. Change</b>
                              Change type: {log.change_type === 100 ? <>Update</>:(log.change_type === 200 ? <>Delete</>:<>Create</>)}</em>

                            <ReactDiffViewer oldValue={"CASE: "+log.ref_case} newValue={"CASE: "+log.case} splitView={false} />
                            <ReactDiffViewer oldValue={"SENTENCE: "+log.ref_sentence} newValue={"SENTENCE: "+log.sentence} splitView={false} />
                          </div>


                        </div>
                      })}
                    </div>
                  </AccordionTab>
                </Accordion>


                <p className="m-0">
                  You are about to create the new job. Do you confirm ?
                </p>
              </Dialog>
              <Dialog
                  header={"Train Log"}
                  visible={changeLogVisible}
                  blockScroll={true}
                  onHide={() => setChangelogVisible(false)}
                  style={{width: "70vw"}}
                  breakpoints={{"960px": "75vw", "641px": "100vw"}}
              >
                <div style={{display:"flex",justifyContent:"space-between"}}>
                  <Button
                      icon="pi pi-refresh"
                      onClick={()=>{getChangeLogData(selectedProduct?.id).then((resp)=>{
                        setBatchLog(resp?.data.info.task_train_log)
                        toast.current.show({
                          severity: "success",
                          summary: "Updated",
                          detail: "Train Log Updated",
                          life: 3000,
                        });
                      })}}
                  />
                  <div style={{display:"flex",justifyContent:"flex-end",gap:10}}>
                    <Button
                        label="Download"
                        icon="pi pi-download"
                        onClick={()=>{downloadFile(`https://dev-diginurse.hive.digital4danone.com/admin/v1/datasets/download/${selectedProduct?.id}/training_log?_token=${localStorage.getItem("token")}`)}}
                    />
                    <Button
                        label="View Batch Test"
                        onClick={()=>{setBatchLogVisible(true);setChangelogVisible(false)}}
                    />
                  </div>
                </div>
                <div className="log_viewer">
                  <div
                      className="consolebody"
                      style={{
                        marginTop: "30px",

                        overflowY: "scroll",
                      }}
                  >
                    {taskLog?.replace(/\n/g, "<BR>").split("<BR>").map(function(x,index){
                      return <p key={index}>{x.toString() !== "" && <b> > </b>} &nbsp; {x.toString()}</p>
                    })}
                  </div>
                </div>
              </Dialog>
              <Dialog
                  header={"Batch Log"}
                  visible={batchLogVisible}
                  onHide={() => setBatchLogVisible(false)}
                  style={{width: "70vw"}}
                  breakpoints={{"960px": "75vw", "641px": "100vw"}}
              >
                <div className="log_viewer">
                  <div style={{display:"flex",justifyContent:"space-between"}}>
                    <Button
                        icon="pi pi-refresh"
                        onClick={()=>{getChangeLogData(selectedProduct?.id).then((resp)=>{
                          setBatchLog(resp?.data.info.task_batch_test_log)
                          toast.current.show({
                            severity: "success",
                            summary: "Updated",
                            detail: "Batch Log Updated",
                            life: 3000,
                          });
                        })}}
                    />
                    <div style={{display:"flex",justifyContent:"flex-end",gap:10}}>
                      <Button
                          label="Download"
                          icon="pi pi-download"
                          onClick={()=>{downloadFile(`https://dev-diginurse.hive.digital4danone.com/admin/v1/datasets/download/${selectedProduct?.id}/batch_test_log?_token=${localStorage.getItem("token")}`)}}
                      />
                      <Button
                          label="View Train Log"
                          onClick={()=>{setBatchLogVisible(false);setChangelogVisible(true)}}
                      />
                    </div>
                  </div>

                  <div
                      className="consolebody"
                      style={{
                        marginTop: "30px",

                        overflowY: "scroll",
                      }}
                  >
                    {batchLog?.replace(/\n/g, "<BR>").split("<BR>").map(function(x,index){
                      return <p key={index}>{x.toString() !== "" && <b> > </b>} &nbsp; {x.toString()}</p>
                    })}
                  </div>
                </div>
              </Dialog>
              <div className="masterDataSet">
                {chartData?.labels?.length > 0 ? (
                    <Chart
                        type="bar"
                        ref={chartRef}
                        data={chartData}
                        options={chartOptions}
                        height={300}
                        width={chartWidth - 130}
                    />
                ) : (
                    <div
                        style={{
                          flex: 1,
                          height: "300px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                    >
                      <ProgressSpinner/>
                    </div>
                )}
                {chartData?.labels?.length > 0 ? (
                    <div>
                      <div
                          className="information-div"
                          style={{backgroundColor: infoBackground}}>
                        DATA HEALTH: SOME OF CASE LABELS HAVE UNBALANCED DATA
                      </div>
                      {validationSize!==undefined ?<div
                          className="training-error-div"
                          style={{backgroundColor: infoBackground}}
                      >
                        {validationSize<2 ? "There is a sentence ":`There are ${validationSize} sentences `}
                        waiting for validation, please validate
                        them to
                        create new training job.
                      </div>:null}

                    </div>

                ) : null}
                <Toast ref={toast}></Toast>
                <div className="masterBot">
                  <DataTable
                      rows={5}
                      className="dataTable"
                      header={header}
                      selection={selectedProduct}
                      lazy
                      loading={loading}
                      scrollable
                      value={trainingData}
                      selectionMode={"single"}
                      onSelectionChange={(e) => setSelectedProduct(e.value)}
                      tableStyle={{minWidth: "50rem"}}
                      paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                      currentPageReportTemplate="{first} to {last} of {totalRecords}"
                  >
                    <Column
                        field="id"
                        header="ID"
                        body={IDBodyTemplate}
                        style={{width: "10%"}}
                    ></Column>
                    {/*   <Column
                  field="session"
                  header="Session"
                  style={{ width: "10%" }}
              ></Column>*/}
                    <Column
                        field="sentence_count"
                        header="Sentences"
                        style={{ width: "10%" }}
                    ></Column>
                    <Column
                        field="case_count"
                        header="Cases"
                        style={{ width: "10%" }}
                    ></Column>
                    <Column
                        field="task_train_begin"
                        header="Training Start Date"
                        style={{width: "15%"}}
                    ></Column>
                    <Column
                        field="task_train_end"
                        header="Training End Date"
                        style={{width: "15%"}}
                    ></Column>
                    <Column
                        field="triggered_by_name"
                        header="Triggered By"
                        style={{width: "10%"}}
                    ></Column>
                    <Column
                        body={fileSizeBodyTemplate}
                        header="File Size"
                        style={{width: "10%"}}
                    ></Column>
                    <Column
                        body={accRateBodyTemplate}
                        header="Acc. Rate"
                        style={{width: "10%"}}
                    ></Column>
                    <Column
                        body={changeRateBodyTemplate}
                        header="Change Rate"
                        style={{width: "10%"}}
                    ></Column>
                    <Column
                        body={RefIDBodyTemplate}
                        header="Ref. Dataset ID"
                        style={{width: "20%"}}
                    ></Column>
                    <Column
                        field="status"
                        body={statusBodyTemplate}
                        header="Status"
                        style={{width: "25%"}}
                    ></Column>
                    <Column
                        header=""
                        body={activeRow}
                        exportable={false}
                        style={{minWidth: "11rem"}}
                    ></Column>
                    {/*<Column
                  header="Active Session"
                  body={actionBodyTemplate}
                  exportable={false}
                  style={{ minWidth: "11rem" }}
              ></Column>*/}
                    <Column body={dotBodyTemplate}></Column>
                  </DataTable>
                </div>
                <div className="newjob-wrapper">
                  <Button
                      label="Create New Job"
                      onClick={() => {
                        if(validationSize>0){
                          toast.current.show({
                            severity: "error",
                            summary: "Error",
                            detail: "You need to clear the sentences from the validation queue.",
                            life: 3000,
                          });
                        }else if(buttonDisable){
                          toast.current.show({
                            severity: "error",
                            summary: "Error",
                            detail: "There is a job currently running.",
                            life: 3000,
                          });
                        }else{
                          setActiveNlpDialog(true)

                        }
                      }}
                  />
                </div>
              </div>
            </div>}
          </div>
      }</div>


  );
}

export default TrainingJobHistory;