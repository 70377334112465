import React, { useEffect, useState } from "react";
import { InputTextarea } from "primereact/inputtextarea";
import "./MasterDataEdit.css";
import { Button } from "primereact/button";
import { getGeneralList, updateMasterDataset } from "../../API/helper";
import { Dropdown } from "primereact/dropdown";
const MasterDataEdit = ({
  selectedCustomerData,
  setDialogVisible,
  getMasterData,
}) => {
  const [caseLabel, setCaseLabel] = useState(null);
  const [caseLabelOptions, setCaseLabelOptions] = useState(null);
  const [initialSentence,setInitialSentence]=useState("")
  const [editSentence, setEditSentence] = useState(
    selectedCustomerData.sentence
  );
  const getGeneraData = async () => {
    const generalData = await getGeneralList();
    if (generalData) {
      setCaseLabelOptions(generalData.data.list.nlp_labels);
      setInitialSentence(generalData.data.list.nlp_labels.find(
          (caseLabel) => caseLabel.title === selectedCustomerData.case_label
      ).description)
      setCaseLabel(
        generalData.data.list.nlp_labels.find(
          (caseLabel) => caseLabel.title === selectedCustomerData.case_label
        )
      );
    }
  };
  const caseLabelTemplate = (option) => {
    return (
      <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
        <span>
          <span style={{ fontWeight: "bold" }}>Label: </span>
          {option.title}
        </span>
        <span>
          <span style={{ fontWeight: "bold" }}>Description: </span>
          {option.description}
        </span>
      </div>
    );
  };
  const onCaseLabelChange = (e) => {
    setCaseLabel(e.value);
  };
  useEffect(() => {
    getGeneraData();
  }, []);

  const updateMasterData = async () => {
    await updateMasterDataset(
      selectedCustomerData.id,
      caseLabel.title,
      editSentence ? editSentence : selectedCustomerData.sentence
    );
    getMasterData();
  };
  return (
    <div className="masterEdit">
      <span style={{ marginTop: "25px" }} className="p-float-label">
        <InputTextarea
          id="description"
          style={{ width: "100%" }}
          value={editSentence}
          onChange={(e) => setEditSentence(e.target.value)}
          rows={5}
          cols={30}
          placeholder={"Please enter correct sentence."}
        />
      </span>
      <div className="masterDataCase">
        <h3>CASE LABEL</h3>
        <Dropdown
          className="master-data-edit-dropdown"
          value={caseLabel}
          itemTemplate={caseLabelTemplate}
          filter
          options={caseLabelOptions}
          placeholder="Select Case Label"
          onChange={onCaseLabelChange}
          optionLabel="title"
          showClear
        />
        <span style={{ fontSize: "13px" }}>
         {initialSentence}
        </span>
      </div>
      <div className="masterDataBottom">
        <Button
          className="p-button-raised p-button-outlined"
          onClick={() => setDialogVisible(false)}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            setDialogVisible(false);
            updateMasterData();
          }}
          label="Save Changes"
          severity="info"
        />
      </div>
    </div>
  );
};
export default MasterDataEdit;
