import React, { useEffect, useRef, useState } from "react";
import "./General.css";
import Header from "../../components/header/Header";
import { Card } from "primereact/card";
import ChartCombo from "../../components/Chart-Combo/Chart-Combo";
import ChartDoughnut from "../../components/Chart-Doughnut/Chart-Doughnut";
import ChartHorizontal from "../../components/Chart-Horizontal/Chart-Horizontal";
import ChartPie from "../../components/Chart-Pie/Chart-Pie";
import {
  getAnalyticsData,
  getGeneralList,
  getLanguagesDetail,
} from "../../API/helper";
import Lottie from "react-lottie-player";
import settingsAnimation from "../../assets/settingsAnimation.json";
import { Chart } from "primereact/chart";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { format } from "date-fns";
const General = () => {
  let user_app_type = [];
  let user_app_data = [];
  const [userAppType, setUserAppType] = useState([]);
  const [userAppData, setUserAppData] = useState([]);
  const [appType, setAppType] = useState([]);
  const [appData, setAppData] = useState([]);
  const [mostCaseTitle, setMostCaseTitle] = useState([]);
  const [mostCaseValue, setMostCaseValue] = useState([]);
  const [userTrafficData, setUserTrafficData] = useState([]);
  const [userTrafficMonth, setUserTrafficMonth] = useState([]);
  const [analyticsMonthData, setAnalyticsMonthData] = useState();
  const [analyticsData, setAnalyticsData] = useState();
  const [loading, setLoading] = useState(false);
  const [dates, setDates] = useState(null);
  const [nutritionMethods, setNutritionMethods] = useState([]);
  const [nutritionApplicationMethods, setNutritionApplicationMethods] =
    useState();
  const [selectedNutritionMethod, setSelectedNutritionMethod] = useState(null);
  const [selectedNutritionAppMethod, setSelectedNutritionAppMethod] =
    useState(null);
  const searchButtonRef = useRef();
  const getGeneraData = async () => {
    const generalData = await getGeneralList();
    if (generalData) {
      setNutritionMethods(generalData.data.list.nutrition_method);
      setNutritionApplicationMethods(generalData.data.list.nutrition_type);
    }
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        searchButtonRef.current.click();
      }
    };
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);
  const getAnalytics = async () => {
    setLoading(true);
    try {
      const res = await getAnalyticsData(
        selectedNutritionMethod ? selectedNutritionMethod.id : "",
        selectedNutritionAppMethod ? selectedNutritionAppMethod.id : "",
        dates ? format(dates[0], "yyyy/MM/dd") : "",
        dates && dates[1] ? format(dates[1], "yyyy/MM/dd") : ""
      );
      if (res.success) {
        const user_app_type = [];
        const user_app_data = [];
        const application_type = [];
        const application_data = [];
        const most_case_title = [];
        const most_case_value = [];
        const months = Object.keys(res.data.list.session_analysis);
        const userMonths = Object.keys(res.data.list.users_by_browser_traffic);
        setAnalyticsMonthData(months);
        setAnalyticsData(res.data.list.session_analysis);
        res.data.list.number_of_users_by_nutrition_method.forEach(
          (app_type) => {
            user_app_type.push(app_type.item_title);
            user_app_data.push(app_type.item_value);
          }
        );
        res.data.list.number_of_users_by_application_type.forEach(
          (app_type) => {
            application_type.push(app_type.item_title);
            application_data.push(app_type.item_value);
          }
        );
        res.data.list.most_viewed_nlp_labels.forEach((app_type) => {
          most_case_title.push(app_type.item_title);
          most_case_value.push(app_type.item_value);
        });
        setUserTrafficData(res.data.list.users_by_browser_traffic);
        setUserAppType(user_app_data);
        setUserAppData(user_app_type);
        setAppType(application_type);
        setAppData(application_data);
        setMostCaseTitle(most_case_title);
        setMostCaseValue(most_case_value);
        setUserTrafficMonth(userMonths);
        setLoading(false);
      }
    } catch (error) {
      setLoading(true);
      console.error("Error fetching analytics data:", error);
    }
  };
  const getAnalyticsInitial = async () => {
    setLoading(true);
    try {
      const res = await getAnalyticsData("", "", "", "");
      if (res.success) {
        const user_app_type = [];
        const user_app_data = [];
        const application_type = [];
        const application_data = [];
        const most_case_title = [];
        const most_case_value = [];
        const months = Object.keys(res.data.list.session_analysis);
        const userMonths = Object.keys(res.data.list.users_by_browser_traffic);
        setAnalyticsMonthData(months);
        setAnalyticsData(res.data.list.session_analysis);
        res.data.list.number_of_users_by_nutrition_method.forEach(
          (app_type) => {
            user_app_type.push(app_type.item_title);
            user_app_data.push(app_type.item_value);
          }
        );
        res.data.list.number_of_users_by_application_type.forEach(
          (app_type) => {
            application_type.push(app_type.item_title);
            application_data.push(app_type.item_value);
          }
        );
        res.data.list.most_viewed_nlp_labels.forEach((app_type) => {
          most_case_title.push(app_type.item_title);
          most_case_value.push(app_type.item_value);
        });
        setUserTrafficData(res.data.list.users_by_browser_traffic);
        setUserAppType(user_app_data);
        setUserAppData(user_app_type);
        setAppType(application_type);
        setAppData(application_data);
        setMostCaseTitle(most_case_title);
        setMostCaseValue(most_case_value);
        setUserTrafficMonth(userMonths);
        setLoading(false);
      }
    } catch (error) {
      setLoading(true);
      console.error("Error fetching analytics data:", error);
    }
  };
  useEffect(() => {
    getAnalytics();
    getGeneraData();
  }, []);
  return (
    <div>
      {loading ? (
        <div className="settings-loader">
          <Lottie
            loop
            animationData={settingsAnimation}
            play
            style={{ width: 400, height: 400 }}
          />
        </div>
      ) : (
        <>
          <Header items={[{ label: "Analytics" }]} />
          <Card className="chart-card">
            <div style={{ padding: 20 }}>
              <div className="filter-div">
                <div className="users-input-div">
                  <Dropdown
                    className="dropdown"
                    placeholder="Select Nutrition Method"
                    optionLabel="title"
                    options={nutritionMethods}
                    value={selectedNutritionMethod}
                    onChange={(e) => setSelectedNutritionMethod(e.value)}
                    showClear
                  />
                  <Dropdown
                    className="dropdown"
                    placeholder="Select Nutrition Type"
                    optionLabel="title"
                    options={nutritionApplicationMethods}
                    value={selectedNutritionAppMethod}
                    onChange={(e) => setSelectedNutritionAppMethod(e.value)}
                    showClear
                  />
                  <Calendar
                    value={dates}
                    onChange={(e) => setDates(e.value)}
                    selectionMode="range"
                    readOnlyInput
                    dateFormat="dd/mm/yy"
                    placeholder="Select Start-End Date"
                    showButtonBar
                    className="dropdown"
                    showIcon
                  />
                </div>
                <div className="log-bring-list-div">
                  <Button
                    label="Search"
                    icon="pi pi-search"
                    ref={searchButtonRef}
                    iconPos="left"
                    onClick={() => getAnalytics()}
                  />
                  <div
                    className="clear-button"
                    onClick={() => {
                      setDates(null);
                      setSelectedNutritionMethod(null);
                      setSelectedNutritionAppMethod(null);
                      getAnalyticsInitial();
                    }}
                  >
                    Clear Filter
                  </div>
                </div>
              </div>
            </div>
          </Card>
          <ChartCombo month={analyticsMonthData} comboData={analyticsData} />
          <div className="grid">
            <div className="col">
              <ChartDoughnut
                title="Number of Users by Nutrition Method"
                data={userAppData}
                number={userAppType}
              />
            </div>
            <div className="col">
              <ChartDoughnut
                title="Number of Users by Application Type"
                data={appType}
                number={appData}
              />
            </div>
          </div>
          <ChartHorizontal
            month={userTrafficMonth}
            userTrafficData={userTrafficData}
            title="Users by Browser Traffic"
          />
          <div className="grid">
            <div className="col" style={{ width: "50%" }}>
              <ChartPie
                title="Most Viewed NLP Labels"
                data={mostCaseTitle}
                number={mostCaseValue}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default General;
