import React, { useEffect, useRef, useState } from "react";
import Header from "../../../components/header/Header";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import "./Validation-que.css";
import { Dropdown } from "primereact/dropdown";
import {
  getGeneralList,
  getMasterDataset,
  getValidationQueue,
  postAssignValidation,
  postExcludeValidation,
} from "../../../API/helper";
import { Toast } from "primereact/toast";
import { Message } from "primereact/message";
import { ConfirmDialog } from "primereact/confirmdialog";
import { InputText } from "primereact/inputtext";
import { get } from "axios";
import Lottie from "react-lottie-player";
import settingsAnimation from "../../../assets/settingsAnimation.json";
function ValidationQue(props) {
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [visible, setVisible] = useState(false);
  const [caseLabel, setCaseLabel] = useState(null);
  const [caseLabelOptions, setCaseLabelOptions] = useState(null);
  const [loading, setLoading] = useState(false);
  const [excludeVisible, setExcludeVisible] = useState(false);
  const [totalRecords, setTotalRecords] = useState("");
  const [validationData, setValidationData] = useState([]);
  const [rows, setRows] = useState(10);
  const [selectedUserStatus, setSelectedUserStatus] = useState(null);
  const [isFiltered, setIsFiltered] = useState(false);
  const [uniqueSentences,setUniqueSentences] =useState([])
  const userStatus = [
    { name: "Approved", code: "approved" },
    { name: "Not Approved", code: "not_approved" },
    { name: "Uncertain", code: "uncertain" },
  ];
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    limit: 2,
    page: 0,
    source: "",
  });
  const toast = useRef();
  const searchButtonRef = useRef();
  const handleOnPage = (event) => {
    setRows(event.rows);
    setLazyParams(event);
  };
  const areCasesSame = (myArray) => {
    const possibleItem = myArray[0]?.case_label;
    let areCasesSame = true;
    for (let i = 1; i < myArray.length; i++) {
      if (myArray[i]?.case_label !== possibleItem) {
        areCasesSame = false;
        break;
      }
    }
    return areCasesSame;
  };
  const getValidationQueueData = async () => {
    setLoading(true);
    const response = await getValidationQueue(
      lazyParams.page + 1,
      lazyParams.rows
    );
    const groupedSentences = [...new Set(response.data.list.map(item => item.sentence))];
    setUniqueSentences(groupedSentences.map((sentence, index) => ({
      name: sentence,
      key: sentence.slice(0, 3), // İlk üç harfi al
    })))
    setTotalRecords(response.data.paging.total);
    setValidationData(response.data.list);
    setLoading(false);
  };
  const getGeneraData = async () => {
    const generalData = await getGeneralList();
    if (generalData) {
      setCaseLabelOptions(generalData.data.list.nlp_labels);
    }
  };
  useEffect(() => {

    console.log(uniqueSentences)
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        searchButtonRef.current.click();
      }
    };
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const onCaseLabelChange = (e) => {

    setCaseLabel(e.value);
  };
  useEffect(() => {
    loadLazyData();
  }, [lazyParams]);
  const loadLazyData = () => {
    if (isFiltered) {
      onFilter();
    } else {
      getValidationQueueData();
    }

  };
  const caseLabelTemplate = (option) => {
    return (
      <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
        <span>
          <span style={{ fontWeight: "bold" }}>Label: </span>
          {option.title}
        </span>
        <span>
          <span style={{ fontWeight: "bold" }}>Description: </span>
          {option.description}
        </span>
      </div>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {rowData.status === "approved" ? (
          <span
            className="user-validated"
            style={{ backgroundColor: "green" }}
          />
        ) : rowData.status === "not_approved" ? (
          <div className="user-validated" style={{ backgroundColor: "red" }} />
        ) : (
          <span
            className="user-validated"
            style={{ backgroundColor: "gray" }}
          />
        )}
      </React.Fragment>
    );
  };
  const onFilter = () => {
    setLoading(true);
    getValidationQueue(lazyParams.page+1, lazyParams.rows, selectedUserStatus?.code).then((response) => {
      setTotalRecords(response.data.paging.total);
      const groupedSentences = [...new Set(response.data.list.map(item => item.sentence))];
     setUniqueSentences(groupedSentences.map((sentence, index) => ({
       name: sentence,
       key: sentence.slice(0, 3), // İlk üç harfi al
     })))
      setValidationData(response.data.list);
      setIsFiltered(true);
      setLoading(false);
    });
  };
  useEffect(() => {
    let selection;
    if (selectedProduct.length < 2 && selectedProduct.length > 0) {
      selection = caseLabelOptions.filter(
        (options) => options.title === selectedProduct[0].case_label
      );
      setCaseLabel(selection[0]);
    } else {
      setCaseLabel(null);
    }
  }, [selectedProduct]);
  const header = (
    <div className="validation-que-header">
      <div style={{ display: "flex", alignItems: "center" }}>
        ({selectedProduct?.length} selected)
      </div>
      <Button
        disabled={selectedProduct?.length < 1}
        label="Assign to a case label"
        onClick={() => {
          setVisible(true);
        }}
      />
      <Button
        disabled={selectedProduct?.length < 1}
        label="Exclude From Data"
        onClick={() => {
          setExcludeVisible(true);
        }}
      />
    </div>
  );
  const assignToCaseLabel = async () => {
    let tempArray = [];
    selectedProduct.forEach((item) => {
      tempArray.push({ id: item.id });
    });
    await postAssignValidation(tempArray, caseLabel.title).then((resp) => {
      if (resp.success) {
        getValidationQueueData();
        setVisible(false);
      }
    });
  };

  const excludeFromData = async () => {
    let tempArray = [];
    selectedProduct.forEach((item) => {
      tempArray.push({ id: item.id });
    });
    await postExcludeValidation(tempArray).then((resp) => {
      if (resp.success) {
        getValidationQueueData();
        setExcludeVisible(false);
      }
    });
  };

  const assignDialogHeader = (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        Assign {selectedProduct?.length} Sentences to a Case Label
      </div>
    </div>
  );
  const excludeDialogHeader = (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        Sentence Operation
      </div>
    </div>
  );
  useEffect(() => {
    getValidationQueueData();
    getGeneraData();
  }, []);
  return (
    <div>
      {loading ? (
        <div className="settings-loader">
          <Lottie
            loop
            animationData={settingsAnimation}
            play
            style={{ width: 400, height: 400 }}
          />
        </div>
      ) : (
        <div>
          <Header items={[{ label: "Validation Queue" }]} />
          <div className="masterDataSet">
            <Toast ref={toast}></Toast>
            <div className="validation-wrapper">
              <div className="validation-info">
                <div style={{ display: "flex" }}>
                  <span>
                    Sentences entered by users must be approved by the
                    administrator.
                  </span>
                </div>
                <div style={{ display: "flex" }}>
                  <div
                    className="user-validated"
                    style={{
                      backgroundColor: "green",
                      marginLeft: "5px",
                      marginRight: "5px",
                    }}
                  />
                  Green colour in the table means that it is approved by the
                  user.{" "}
                </div>
                <div style={{ display: "flex" }}>
                  <div
                    className="user-validated"
                    style={{
                      backgroundColor: "red",
                      marginLeft: "5px",
                      marginRight: "5px",
                    }}
                  />
                  Red colour in the table means that it is not approved by the
                  user.
                </div>
                <div style={{ display: "flex" }}>
                  <div
                    className="user-validated"
                    style={{
                      backgroundColor: "gray",
                      marginLeft: "5px",
                      marginRight: "5px",
                    }}
                  />
                  Grey colour in the table means that no action has been taken
                  by the user.{" "}
                </div>
              </div>
              <div className="validation-filter-div">
                <div className="input-div">
                  <Dropdown
                    value={selectedUserStatus}
                    onChange={(e) => setSelectedUserStatus(e.value)}
                    options={userStatus}
                    optionLabel="name"
                    placeholder="Select User Validated"
                    style={{ width: "40%" }}
                    className="w-full"
                  />
                 {/* <Dropdown
                      value={selectedUserStatus}
                      onChange={(e) => setSelectedUserStatus(e.value)}
                      options={uniqueSentences}
                      optionLabel="name"
                      placeholder="Select Sentence"
                      style={{ width: "40%" }}
                      className="w-full"
                  />*/}
                </div>
                <div className="bring-list-div">
                  <Button
                    label="Search"
                    ref={searchButtonRef}
                    icon="pi pi-search"
                    iconPos="left"
                    onClick={() => {
                      onFilter();
                    }}
                  />
                  <div
                    className="clear-button"
                    onClick={() => {
                      getValidationQueueData();
                      setSelectedUserStatus(null);
                      setLazyParams({
                        first: 0,
                        rows: 10,
                        limit: 2,
                        page: 0,
                        source: "",
                      })
                    }}
                  >
                    Clear Filter
                  </div>
                </div>
              </div>
            </div>

            <div className="masterBot">
              <DataTable
                value={validationData}
                paginator
                dataKey="id"
                rows={rows}
                lazy
                totalRecords={totalRecords}
                onPage={handleOnPage}
                rowsPerPageOptions={[5, 10, 25, 50]}
                first={lazyParams.first}
                selectionMode={"radiobutton"}
                multiple={true}
                header={header}
                selection={selectedProduct}
                onSelectionChange={(e) => setSelectedProduct(e.value)}
                tableStyle={{ minWidth: "50rem" }}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
              >
                <Column
                  selectionMode="multiple"
                  headerStyle={{ width: "3rem" }}
                ></Column>
                <Column
                  field="id"
                  header="ID"
                  style={{ width: "10%" }}
                  sortable
                ></Column>
                <Column
                  field="nutrition_method"
                  header="Method"
                  style={{ width: "10%" }}
                ></Column>
                <Column
                  field="nutrition_type"
                  header="Type"
                  style={{ width: "10%" }}
                ></Column>
                <Column
                  field="sentence"
                  header="Sentence"
                  sortable
                  style={{ width: "50%" }}
                ></Column>
                <Column
                  field="case_label"
                  header="Possible Case"
                  style={{ width: "15%" }}
                ></Column>
                <Column
                  header="User Validated"
                  body={actionBodyTemplate}
                  bodyStyle={{ paddingLeft: 60 }}
                  exportable={false}
                  style={{ minWidth: "11rem" }}
                ></Column>
              </DataTable>
              <Dialog
                header={excludeDialogHeader}
                visible={excludeVisible}
                onHide={() => setExcludeVisible(false)}
                style={{ width: "50vw" }}
                breakpoints={{ "960px": "75vw", "641px": "100vw" }}
              >
                <div
                  style={{ display: "flex", flexDirection: "column", gap: 5 }}
                >
                  {selectedProduct?.slice(0, 2).map((product) => {
                    return <span>{product.sentence}</span>;
                  })}
                  {selectedProduct?.length > 2 ? (
                    <span>{selectedProduct.length - 2} more...</span>
                  ) : (
                    ""
                  )}
                </div>
                <div style={{ fontWeight: "bold", paddingTop: 30 }}>
                  <span>
                    The sentences you selected above will be removed. Do you
                    confirm?
                  </span>
                </div>
                <div className="validation-dialog-buttons">
                  <Button
                    className="p-button-raised p-button-outlined"
                    onClick={() => setExcludeVisible(false)}
                  >
                    No
                  </Button>
                  <Button
                    onClick={() => {
                      excludeFromData();
                    }}
                    label="Yes"
                    text
                    raised
                  />
                </div>
              </Dialog>
              <Dialog
                header={assignDialogHeader}
                visible={visible}
                onHide={() => setVisible(false)}
                style={{ width: "50vw" }}
                breakpoints={{ "960px": "75vw", "641px": "100vw" }}
              >
                <div
                  style={{ display: "flex", flexDirection: "column", gap: 5 }}
                >
                  {selectedProduct?.slice(0, 2).map((product) => {
                    return <span>{product.sentence}</span>;
                  })}
                  {selectedProduct?.length > 2 ? (
                    <span>{selectedProduct.length - 2} more...</span>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 30,
                  }}
                >
                  <Dropdown
                    className="validation-dropdown"
                    value={caseLabel}
                    itemTemplate={caseLabelTemplate}
                    filter
                    options={caseLabelOptions}
                    placeholder="Select Case Label"
                    onChange={onCaseLabelChange}
                    optionLabel="title"
                    showClear
                  />
                </div>
                {areCasesSame(selectedProduct) ? (
                  ""
                ) : (
                  <div style={{ marginTop: "15px", width: "100%" }}>
                    <Message
                      style={{ width: "100%" }}
                      severity="warn"
                      text="The logs you selected belong to different case labels."
                    />
                  </div>
                )}
                <div className="validation-dialog-buttons">
                  <Button
                    className="p-button-raised p-button-outlined"
                    onClick={() => setVisible(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => assignToCaseLabel()}
                    label="Save Changes"
                    severity="info"
                  />
                </div>
              </Dialog>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ValidationQue;
