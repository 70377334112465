import React from 'react';
import './Header.css';
import { BreadCrumb } from 'primereact/breadcrumb';
import {ProgressBar} from "primereact/progressbar";

const home = {
  icon: 'pi pi-home',
  url: '/',
};

const Header = ({ items }) => {
  return (
    <div className="header-component">
      <BreadCrumb model={items} home={home} />
    </div>
  );
};

export default Header;
