import React, {useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {createNewPoll, editPoll, getPollsDetail} from "../../../API/helper";
import Header from "../../../components/header/Header";
import {Button} from "primereact/button";
import {Toast} from "primereact/toast";
import './PollsDetails.css'
import Lottie from "react-lottie-player";
import settingsAnimation from "../../../assets/settingsAnimation.json";
import {Steps} from "primereact/steps";
import PollsNutritionMethods from "../../../components/Polls-Nutrition-Methods/PollsNutritionMethods";
import PollsEdit from "../../../components/Polls-Details/PollsEdit";
import PollsQuestionDetails from "../../../components/Polls-Question-Details/PollsQuestionDetails";
import OverviewPoll from "../../../components/Overview&Create-Poll/OverviewPoll";
function PollsDetails(props) {
    const { state } = useLocation();
    const toast = useRef(null);
    const [visible, setVisible] = useState(false);
    const [selectedDates,setSelectedDates]=useState()
    const [dates, setDates] = useState(null);
    const [label,setLabel]=useState("")
    const [loading,setLoading]=useState(false)
    const [selectedPosition,setSelectedPosition]=useState(null)
    const [pollValue, setPollValue] = useState({question:""});
    const [activeIndex, setActiveIndex] = useState(0);
    const [overviewLabel,setOverViewLabel]=useState("")
    const [publishNow,setPublishNow]=useState(true)
    const navigate = useNavigate();
    const options = ['Active', 'Passive'];
    const [status, setStatus] = useState(options[1]);
    const [method, setMethod] = useState([]);
    const [type, setType] = useState([]);
    const [items, setItems] = useState([
        { id: 1, answer: '' },
        { id: 2, answer: '' },
    ]);
    useEffect(()=>{
        if(publishNow){
            setDates(null)
        }
    },[publishNow])

    const steps = [
        <div className="under-page"><PollsNutritionMethods activeItem={activeIndex} setActiveItem={setActiveIndex} method={method} setMethod={setMethod} type={type} setType={setType} state={state} setSelectedPosition={setSelectedPosition} selectedPosition={selectedPosition} dates={dates} setDates={setDates} pollValue={pollValue} setPollValue={setPollValue} setSelectedDates={setSelectedDates}/></div>,
        <div className="under-page"><PollsEdit activeItem={activeIndex} setActiveItem={setActiveIndex} dates={dates} setDates={setDates} selectedDates={selectedDates} publishNow={publishNow} setPublishNow={setPublishNow} selectedDates={selectedDates}/></div>,
        <div className="under-page"><PollsQuestionDetails activeItem={activeIndex} setActiveItem={setActiveIndex} state={state} items={items} setItems={setItems} pollValue={pollValue} setPollValue={setPollValue} dates={dates} setDates={setDates} selectedDates={selectedDates} publishNow={publishNow} setPublishNow={setPublishNow}/></div>,
        <div className="under-page"><OverviewPoll activeItem={activeIndex} setActiveItem={setActiveIndex} method={method} selectedPosition={selectedPosition} type={type} status={status} state={state} items={items} pollValue={pollValue} dates={dates} setStatus={setStatus} publishNow={publishNow}/></div>
    ]

    const getPolls = async ()=>{
        setLoading(true)
        await getPollsDetail(state.id).then((res)=>{
            let nutritionMethods=[]
            let nutritionTypes=[]
            let answers=[]
            setPollValue({...pollValue,question:res.data.list.detail.question,name:res.data.list.detail.name})
            nutritionMethods=res.data.list.detail?.methods
            nutritionTypes=res.data.list.detail?.types
            res.data.list.detail.poll_position ==="after" ? setSelectedPosition({ name: 'After', code: 'After' }):setSelectedPosition({ name: 'Before', code: 'Before' })
            answers = res.data.list.detail?.answers
            res.data.list.detail.active === 1 ? setStatus('Active'):setStatus('Passive')
            setMethod(nutritionMethods)
            setType(nutritionTypes)
            setItems(answers)
            setLoading(false)
        })
    }

    const createPoll = async () => {
        await createNewPoll(pollValue.name,method,type,selectedPosition,pollValue.question,items,status).then((response) =>{
                if(response.success){
                    toast.current.show({
                        severity: "success",
                        summary: "Successful",
                        detail: response.message,
                        life: 3000,
                    })
                    setTimeout(() => {
                        navigate("/polls")
                    }, 1000);
                }
                else{
                    toast.current.show({
                        severity: "error",
                        summary: "Error",
                        detail: response.message,
                        life: 3000,
                    })
                }
            }
        );
    };
    const editPollData = async (admin_id) => {
        await editPoll(admin_id,pollValue.name,method,type,selectedPosition,pollValue.question,items,status).then((response) =>{
                if(response.success){
                    toast.current.show({
                        severity: "success",
                        summary: "Successful",
                        detail: response.message,
                        life: 3000,
                    })
                    setTimeout(() => {
                        navigate("/polls")
                    }, 1000);
                }
                else{
                    toast.current.show({
                        severity: "error",
                        summary: "Error",
                        detail: response.message,
                        life: 3000,
                    })
                }
            }
        );
    };



    const onSubmit = ()=>{
        if(state?.create){
            createPoll()
        }else{
            editPollData(state?.id)
        }
    }
    useEffect(()=>{
        if(!state?.create){
            setLabel("Poll Details")
            setOverViewLabel("Edit Poll")
            getPolls()
        }else{
            setLabel("Create New Poll")
            setOverViewLabel("Create Poll")
        }
    },[])


    const pollsSteps = [
        {
            label: 'Nutrition Methods',
        },
        {
            label: 'Polls Schedule Planner',
        },
        {
            label: 'Polls Question Details',
        },
        {
            label: `Overview & ${overviewLabel}`,
        }
    ];
    return (
        <div>
            {loading ? (
                <div className="settings-loader">
                    <Lottie
                        loop
                        animationData={settingsAnimation}
                        play
                        style={{ width: 400, height: 400 }}
                    />
                </div>
            ) :  <div>
                <Header items={[{ label: 'Polls' , url: '/polls'},{ label: label }]} />
                <Toast ref={toast} />
                <div className="flow-wrapper">
                    <Toast ref={toast}></Toast>
                    <div className="steps-div">
                        <Steps model={pollsSteps} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={true} />
                    </div>
                    {steps.map((item,index)=>activeIndex===index ? item : <div></div>)}
                </div>
            </div>}
        </div>

    )
}

export default PollsDetails;