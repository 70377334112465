import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useRef, useState } from "react";
import "./CasesSentences.css";
import { useLocation } from "react-router-dom";
import Header from "../../../components/header/Header";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { Tag } from "primereact/tag";
import {
  addNLPCasesSentences,
  deleteNLPCasesSentences,
  getGeneralList,
  getNLPCasesSentences,
  postNlpUndo,
  updateNLPCasesSentences,
} from "../../../API/helper";
import Lottie from "react-lottie-player";
import settingsAnimation from "../../../assets/settingsAnimation.json";

const CasesSentences = () => {
  const { state } = useLocation();
  const [byContent, setByContent] = useState("");
  const [visible, setVisible] = useState(false);
  const [addVisible, setAddVisible] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [source, setSource] = useState(null);
  const [status, setStatus] = useState(null);
  const [statusOptions, setStatusOptions] = useState(null);
  const [sourceOptions, setSourceOptions] = useState(null);
  const [editSentence, setEditSentence] = useState("");
  const [addNewSentence, setAddNewSentence] = useState("");
  const [undoDialogVisible, setUndoDialogVisible] = useState(false);
  const [undoInfos, setUndoInfos] = useState(null);
  const [deleteSentenceID, setDeleteSentenceID] = useState("");
  const [sentenceListValue, setSentenceListValue] = useState();
  const [allTags, setAllTags] = useState([]);
  const [loading,setLoading]=useState(false)
  const [selectedSentence, setSelectedSentence] = useState({
    sentence: "",
    id: "",
  });
  const [totalRecords, setTotalRecords] = useState("");
  const [rows, setRows] = useState(10);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 100,
  });

  const getAllCaseSentencesList = async () => {
      setLoading(true)
    const caseList = await getNLPCasesSentences(
        state.rowData.caselabel,
        lazyParams.page + 1,
        lazyParams.rows,
        byContent,
        source ? source.source : "",
        status ? status : ""
    );
    setAllTags(caseList.data.flows);
    setSentenceListValue(caseList.data.sentences.list);
    setTotalRecords(caseList.data.sentences.paging.total);
    setLoading(false)
  };
    const getClearedAllCaseSentencesList = async () => {
        setLoading(true)
        const caseList = await getNLPCasesSentences(
            state.rowData.caselabel,
            lazyParams.page + 1,
            lazyParams.rows,
            byContent,
             "",
             ""
        );
        setAllTags(caseList.data.flows);
        setSentenceListValue(caseList.data.sentences.list);
        setTotalRecords(caseList.data.sentences.paging.total);
        setLoading(false)
    };
  const handleOnPage = (event) => {
    setRows(event.rows);
    setLazyParams(event);
  };
  useEffect(() => {
    getAllCaseSentencesList();
  }, []);
  const getGeneraData = async () => {
    const generalData = await getGeneralList();
    if (generalData) {
      setSourceOptions(generalData.data.list.nlp.case_label.source_list);
      setStatusOptions(generalData.data.list.change_log.actions);
    }
  };
  const onSourceChange = (e) => {
    setSource(e.value);
  };
  const onStatusChange = (e) => {
    setStatus(e.value);
  };

  const statusTemplate = (option) => {
    return (
        <div style={{}}>
          <Tag severity={option.severity} value={option.value}></Tag>
        </div>
    );
  };
  const loadLazyData = () => {
    getAllCaseSentencesList();
  };
  useEffect(() => {
    loadLazyData();
  }, [lazyParams]);
  const handleEdit = (rowData) => {
    let sentence = rowData.sentence;
    if (rowData.change_logs?.list.length > 0) {
      rowData.change_logs?.list.forEach((log) => {
        if (log.type === "sentence") {
          sentence = log.new_value;
        }
      });
    }
      setEditSentence(sentence)
    setSelectedSentence({
      sentence: sentence,
      id: rowData.id,
    });
  };
  useEffect(() => {
    getGeneraData();
  }, []);
  useEffect(() => {
    if (source === null && byContent === "") {
      getAllCaseSentencesList();
    }
  }, [source, byContent]);

  const sortedTags = allTags.sort((a, b) => {
    if (a.title < b.title) return -1;
    if (a.title > b.title) return 1;
    return 0;
  });
  const sentenceBody = (rowData) => {
    return(
        <React.Fragment>
            {rowData?.ref_sentence===rowData?.sentence ? <div
                style={{
                    textDecoration: rowData.deletion_status ? "line-through" : "none",
                }}
                className="clear-button-case-label"

            >
                {rowData.sentence}
            </div>: <div>
                <div
                    style={{
                        opacity: 0.5,
                        marginLeft: "10px",
                        textDecoration:"line-through"
                    }}
                >
                    {rowData?.ref_sentence}
                </div>
                <div
                    className="clear-button-case-label"
                    style={{
                        marginTop: 5,
                    }}
                >
                    {rowData?.sentence}
                </div>
            </div>}

    </React.Fragment>)
  };

  const sourceBody = (rowData) => {
    return (
        <React.Fragment>
          <div
              style={{
                textDecoration: rowData.deletion_status ? "line-through" : "none",
              }}
          >
            {rowData.source}
          </div>
        </React.Fragment>
    );
  };
  const tableTags = (rowData) => {
    let value = "";
    let severity = "";
    if (rowData?.change_status === 200) {
      value = "Deleted";
      severity = "danger";
    } else if (rowData?.change_status === 100) {
      value = "Updated";
      severity = "warning";
    } else if (rowData?.change_status === 300) {
      value = "Added";
      severity = "success";
    } else {
      return null;
    }

    return (
        <React.Fragment>
          <div>
            <Tag value={value} severity={severity}></Tag>
          </div>
        </React.Fragment>
    );
  };
  const newSentence = async () => {
    await addNLPCasesSentences(addNewSentence, state.rowData.caselabel);
    getAllCaseSentencesList();
  };
  const deleteSentence = async () => {
    await deleteNLPCasesSentences(state.rowData.caselabel, deleteSentenceID);
    getAllCaseSentencesList();
  };

  const updateSentence = async () => {
    await updateNLPCasesSentences(
        selectedSentence.id,
        editSentence
    );
    getAllCaseSentencesList();
  };

  const undoMaster = async () => {
      await updateNLPCasesSentences(
          undoInfos.id,
          undoInfos.ref_sentence
      );
      getAllCaseSentencesList();

  };
    const statusValueTemplate = (option) => {
        return (
            <div>
                <Tag severity={option.severity} value={option.value}></Tag>
            </div>
        );
    };
  const deleteBody = (rowData) => {

      return (
          <React.Fragment>
              <div style={{display: "flex", gap: "5px", justifyContent: "right"}}>
                  {rowData?.change_status!==0 && rowData?.change_status!==300 && <button
                      onClick={() => {
                          setUndoInfos(rowData);
                          setUndoDialogVisible(true);
                      }}
                      style={{
                          backgroundColor: "rgb(154 170 183)",
                          color: "white",
                          padding: "10px ",
                          border: "1px solid rgb(154 170 183)",
                          borderRadius: "7px",
                          cursor: "pointer",
                          fontSize: "1rem",
                      }}
                      className="p-button-raised pi pi-undo"
                  ></button>}
                  {rowData?.change_status!==300 && <button
                      onClick={() => {
                          handleEdit(rowData);
                          setVisible(true);

                      }}
                      style={{
                          backgroundColor: "#F59E0B",
                          color: "white",
                          padding: "10px ",
                          border: "1px solid #F59E0B",
                          borderRadius: "7px",
                          cursor: "pointer",
                          fontSize: "1rem",
                      }}
                      className="p-button-raised pi pi-pencil"
                  ></button>}
                  <button
                      onClick={() => {
                          setDeleteSentenceID(rowData.id);
                          setDialogVisible(true);
                      }}
                      className="p-button-raised  pi pi-trash"
                      style={{
                          padding: "10px ",
                          backgroundColor: "#ff7b5a",
                          color: "white",
                          border: "1px solid #ff7b5a",
                          borderRadius: "7px",
                          cursor: "pointer",
                          fontSize: "1rem",
                      }}
                  ></button>
              </div>
          </React.Fragment>
      );
  }

  return (
      <div>
          {loading?<div className="settings-loader">
                  <Lottie
                      loop
                      animationData={settingsAnimation}
                      play
                      style={{ width: 400, height: 400 }}
                  />
              </div>:
              <div>
                  <Header
                      items={[
                          { label: "Cases Labes", url: "/nlp/cases-labels" },
                          { label: state.rowData.caselabel },
                      ]}
                  />
                  <Dialog
                      header={"Undo Changes"}
                      visible={undoDialogVisible}
                      onHide={() => setUndoDialogVisible(false)}
                      style={{ width: "50vw" }}
                      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
                  >
                      <div style={{ fontWeight: "bold", marginTopTop: 10 }}>
                          <span>Are you sure you want to undo the changes made?</span>
                      </div>
                      <div className="validation-dialog-buttons">
                          <Button
                              className="p-button-raised p-button-outlined"
                              onClick={() => setUndoDialogVisible(false)}
                          >
                              No
                          </Button>
                          <Button
                              onClick={() => {
                                  undoMaster();
                                  setUndoDialogVisible(false);
                              }}
                              label="Yes"
                          />
                      </div>
                  </Dialog>
                  <Dialog
                      header={"Deleting Sentence"}
                      visible={dialogVisible}
                      onHide={() => setDialogVisible(false)}
                      style={{ width: "50vw" }}
                      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
                  >
                      <div style={{ fontWeight: "bold", marginTopTop: 10 }}>
          <span>
            You are about to delete the selected sentence. Do you confirm ?
          </span>
                      </div>
                      <div className="validation-dialog-buttons">
                          <Button
                              className="p-button-raised p-button-outlined"
                              onClick={() => setDialogVisible(false)}
                          >
                              No
                          </Button>
                          <Button
                              onClick={() => {
                                  setDialogVisible(false);
                                  deleteSentence();
                              }}
                              label="Yes"
                          />
                      </div>
                  </Dialog>
                  <div className="caseSentencesTop">
                      <div className="caseFlows">
                          <span style={{ fontWeight: "700" }}>Connected Flows</span>
                          <div className="caseTags">
                              {sortedTags.map((tag, index) => {
                                  if (tag.title[0] === "J") {
                                      return (
                                          <Tag
                                              key={`warning_${index}`}
                                              severity="warning"
                                              value={tag.title}
                                          ></Tag>
                                      );
                                  } else if (tag.title[0] === "N") {
                                      return (
                                          <Tag
                                              key={`success_${index}`}
                                              severity="success"
                                              value={tag.title}
                                          ></Tag>
                                      );
                                  } else {
                                      return <Tag key={`default_${index}`} value={tag.title}></Tag>;
                                  }
                              })}
                          </div>
                      </div>
                      <div className="addSentence">
                          <Button
                              onClick={() => {
                                  setAddVisible(true);
                              }}
                              label="ADD NEW SENTENCE"
                              severity="info"
                          />
                          <Dialog
                              header={`Add a new sentence for the case label - ${state.rowData.caselabel}`}
                              visible={addVisible}
                              onHide={() => setAddVisible(false)}
                              style={{ width: "50vw" }}
                              breakpoints={{ "960px": "75vw", "641px": "100vw" }}
                          >
                              <div className="newSentence">
              <span style={{ fontSize: "13px" }}>
                Please consider the context of your sentence. It should cover
                the scope below; This label is about if patients can shower with
                the PEG/NG tube
              </span>
                                  <InputTextarea
                                      id="description"
                                      style={{ width: "100%" }}
                                      onChange={(e) => setAddNewSentence(e.target.value)}
                                      rows={5}
                                      cols={30}
                                  />
                                  <div className="dialogButton">
                                      <Button
                                          className="p-button-raised p-button-outlined"
                                          onClick={() => setAddVisible(false)}
                                      >
                                          Cancel
                                      </Button>
                                      <Button
                                          onClick={(e) => {
                                              setAddVisible(false);
                                              newSentence(e);
                                          }}
                                          label="Save Changes"
                                          severity="info"
                                      />
                                  </div>
                              </div>
                          </Dialog>
                      </div>
                  </div>
                  <div className="caseDescription">
                      <span style={{ fontWeight: "700" }}>DESCRIPTION</span>
                      <span style={{ fontSize: "13px" }}>{state.rowData.description}</span>
                  </div>
                  <div className="caseSentences">
                      <div className="caseSentencesFilter">
                          <Dropdown
                              style={{ width: "15%" }}
                              value={status}
                              itemTemplate={statusTemplate}
                              options={statusOptions}
                              placeholder="Select Status"
                              onChange={onStatusChange}
                              optionLabel={statusValueTemplate}
                              showClear
                          />
                          <InputText
                              placeholder="Search by content"
                              style={{ width: "20%" }}
                              value={byContent}
                              onChange={(e) => setByContent(e.target.value)}
                          />
                          <Dropdown
                              className="cases-label-dropdown"
                              value={source}
                              options={sourceOptions}
                              placeholder="Select Source"
                              onChange={onSourceChange}
                              optionLabel="source"
                              showClear
                          />
                          <Button
                              label="Search"
                              onClick={() => {
                                  getAllCaseSentencesList();
                              }}
                          />
                          <div
                              className="clear-button"
                              onClick={() => {
                                  setByContent("");
                                  setSource(null);
                                  getClearedAllCaseSentencesList()
                              }}
                          >
                              Clear Filter
                          </div>
                      </div>
                      <div className="caseSentenceTable">
                          <DataTable
                              paginator
                              dataKey="id"
                              rows={rows}
                              lazy
                              selectionMode
                              totalRecords={totalRecords}
                              onPage={handleOnPage}
                              first={lazyParams.first}
                              value={sentenceListValue}
                              rowsPerPageOptions={[5, 10, 25, 50]}
                              tableStyle={{ minWidth: "50rem" }}
                              paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                              currentPageReportTemplate="{first} to {last} of {totalRecords}"
                          >
                              <Column field="id" header="ID" style={{ width: "10%" }}></Column>
                              <Column body={tableTags}></Column>
                              <Column
                                  field="sentence"
                                  body={sentenceBody}
                                  header="SENTENCE"
                                  style={{ width: "70%" }}
                              ></Column>
                              <Column
                                  field="created_by"
                                  header="Changed By"
                                  style={{ width: "20%" }}
                              ></Column>
                              <Column
                                  field="date"
                                  header="Change Date"
                                  style={{ width: "20%" }}
                              ></Column>
                              <Column
                                  field="source"
                                  body={sourceBody}
                                  header="SOURCE"
                                  style={{ width: "20%" }}
                              ></Column>

                              <Column
                                  body={deleteBody}
                                  style={{ width: "10%", textAlign: "right" }}
                              />
                          </DataTable>
                          <Dialog
                              header="SENTENCE"
                              visible={visible}
                              onHide={() => setVisible(false)}
                              style={{ width: "50vw" }}
                              breakpoints={{ "960px": "75vw", "641px": "100vw" }}
                          >
                              <div className="dialog">
              <span style={{ fontSize: "13px" }}>
                This label is about if patients can shower with the PEG/NG tube.
              </span>
                                  <InputTextarea
                                      id="description"
                                      style={{ width: "100%" }}
                                      value={editSentence}
                                      onChange={(e) => setEditSentence(e.target.value)}
                                      rows={5}
                                      cols={30}
                                  />
                                  <div className="dialogButton">
                                      <Button
                                          className="p-button-raised p-button-outlined"
                                          onClick={() => setVisible(false)}
                                      >
                                          Cancel
                                      </Button>
                                      <Button
                                          onClick={() => {
                                              setVisible(false);
                                              updateSentence();
                                          }}
                                          label="Save Changes"
                                          severity="info"
                                      />
                                  </div>
                              </div>
                          </Dialog>
                      </div>
                  </div>
              </div>}
      </div>

  );
};

export default CasesSentences;