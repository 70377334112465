import React, { memo } from 'react';
import { Handle } from 'reactflow';
import { Button } from 'primereact/button';
import RemoveIcon from '../../../../../assets/icons/remove.png'
import { useRef } from 'react';
import { useState } from 'react';
import {changeNodeText, updateFlowNode} from '../../../../../API/helper';

export default memo(({ data, isConnectable }) => {
    const inputFile = useRef(null);
    const [fileName, setFileName] = useState(null);
    const [tempThumbnail, setTempThumbnail] = useState('');
    const [loading, setLoading] = useState(false);
    const [selectSentence,setSelectSentence]=useState("")

    const handleSelectFile = (event) => {
        setLoading(true);
        console.log(event.target.files[0].name);
        setFileName(event.target.files[0].name);

        updateFlowNode(data.id, data.topic_id, '', 'video', event.target.files[0])
            .then((data) => {
                if (data.data.success) {
                    console.log('handleSelectFile/res', data);
                    console.log(data.data.data.action_thumbnail);
                    setTempThumbnail(data.data.data.action_thumbnail);
                }
            })
            .catch((err) => {
                console.log('handleSelectFile/ERROR', err);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const sendNewValue = async ()=>{
        if(selectSentence.length>3){
            changeNodeText(selectSentence,localStorage.getItem("lang_id"),localStorage.getItem("country_id"),data.id)
                .then((resp)=>{
                    if(resp.status){
                        data.onClickNextNode()
                    }
                })
        }else{
            data.onClickNextNode()
        }
    }
    return (
        <>
            <Handle
                type="target"
                position="left"
                style={{ background: '#555' }}
                onConnect={(params) => console.log('handle onConnect', params)}
                isConnectable={isConnectable}
            />

            <div className="node-header">
                <div className="node-label">Video</div>
            </div>

            <div>
                <div className="video-node-body">
                    {data.action_value ? (
                        <div
                            className="video-thumbnail"
                            style={{
                                backgroundImage: tempThumbnail
                                    ? `url(${tempThumbnail})`
                                    : `url(${
                                        tempThumbnail ? tempThumbnail : data.action_thumbnail
                                    })`,
                            }}
                        ></div>
                    ) : (
                        <>
                            <img
                                className="image-preview-image video-thumbnail"
                                src="https://martialartspasadenantc.com/wp-content/uploads/sites/185/2015/12/video-placeholder.png"
                                alt="node-preview"
                            />
                        </>
                    )}
                    <input
                        ref={inputFile}
                        type="file"
                        id="imgupload"
                        style={{ display: 'none' }}
                        onChange={handleSelectFile}
                        accept=".mp4, .mov, .avi"
                    />
                </div>
                <div className="translation-button-wrapper">
                    <Button label="Previous" severity="info" onClick={()=>{
                        data.onClickPreviousNode()
                    }}/>
                    <Button label={selectSentence?.length>3 ?"Save":"Skip"} severity="info" onClick={()=>{
                        sendNewValue()
                    }}/>
                </div>
            </div>
            <Handle
                type="source"
                position="right"
                id="b"
                isConnectable={isConnectable}
            />
        </>
    );
});
