let API_BASE_URL = "";
const DEV_MODE = process.env.REACT_APP_MODE;
const PRODUCT_URL = process.env.REACT_APP_PRODUCT_URL;
const DEV_URL = process.env.REACT_APP_STAGING_URL;
let token = "";
const getToken = () => {
  if (token === "") {
    token = localStorage.getItem("token");
    return token;
  } else {
    return token;
  }
};
const getURL = () => {
  if (DEV_MODE === "DEV") {
    console.log("APP STARTED WITH STAGING MODE");
    API_BASE_URL = DEV_URL;
  } else if (DEV_MODE === "PRODUCT") {
    console.log("APP STARTED WITH PRODUCT MODE");
    API_BASE_URL = PRODUCT_URL;
  }
};
getURL();
export const getUserList = async (userListData) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}/admin/v1/users/list?page=${userListData?.page}&limit=${userListData?.rows}`,
    requestOptions
  );
  const data = await response.json();
  if (!data.success) {
    localStorage.removeItem("token");
    localStorage.removeItem("login");
    window.location.reload();
  }
  return data;
};
export const getUserLogData = async (user_id,page,row) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}/admin/v1/chat_log/list?page=${page}&limit=${row}&user_id=${user_id}`,
    requestOptions
  );
  const data = await response.json();
  return data;
};
export const getFilteredUserList = async (
  user_id,
  last_seen_location,
  session_start_date,
  session_end_date,
  register_start_date,
  register_end_date,
  user_browser,
  page,
  row
) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}/admin/v1/users/list?` +
      new URLSearchParams({
        user_id,
        last_seen_location,
        session_start_date,
        session_end_date,
        register_start_date,
        register_end_date,
        user_browser,
        page,
        row,
      }),
    requestOptions
  );
  const data = await response.json();
  return data;
};
