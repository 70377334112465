import axios from "axios";
import FormData from "form-data";
let API_BASE_URL = "";
const DEV_MODE = process.env.REACT_APP_MODE;
const PRODUCT_URL = process.env.REACT_APP_PRODUCT_URL;
const DEV_URL = process.env.REACT_APP_STAGING_URL;

let token = "";
const getToken = () => {
  if (token === "") {
    token = localStorage.getItem("token");
    return token;
  } else {
    return token;
  }
};
const getURL = () => {
  if (DEV_MODE === "DEV") {
    console.log("APP STARTED WITH STAGING MODE");
    API_BASE_URL = DEV_URL;
  } else if (DEV_MODE === "PRODUCT") {
    console.log("APP STARTED WITH PRODUCT MODE");
    API_BASE_URL = PRODUCT_URL;
  }
};
getURL();

export const getFilteredFlowList = async (
  content,
  nutritionMethodID,
  nutritionAppMethodID,
  nlpLabel,
  page,
  row
) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}admin/v1/flow/list?` +
      new URLSearchParams({
        content: content,
        nutrition_method: nutritionMethodID,
        nutrition_type: nutritionAppMethodID,
        label: nlpLabel,
        page,
        limit: row,
      }),
    requestOptions
  );
  const data = await response.json();
  if (!data.success && data.message !== "Flows not found.") {
    localStorage.removeItem("token");
    localStorage.removeItem("login");
    window.location.reload();
  }
  return data;
};

export const method = async (query) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}admin/v1/datasets/list?` + new URLSearchParams(query),
    requestOptions
  );
  const data = await response.json();
  if (!data.success) {
    localStorage.removeItem("token");
    localStorage.removeItem("login");
    window.location.reload();
  }
  return data;
};

export const getDatasetList = async (page, row) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}admin/v1/datasets/list?` +
      new URLSearchParams({
        page,
        row,
      }),
    requestOptions
  );
  const data = await response.json();
  if (!data.success) {
    localStorage.removeItem("token");
    localStorage.removeItem("login");
    window.location.reload();
  }
  return data;
};

export const getFlowList = async (flowListData) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}/admin/v1/flow/list?page=${flowListData.page}&limit=${flowListData.limit}`,
    requestOptions
  );
  const data = await response.json();
  if (!data.success) {
    localStorage.removeItem("token");
    localStorage.removeItem("login");
    window.location.reload();
  }
  return data;
};
export const getCaseLabels = async (questions, nutritionID, nutritionApp) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  const formData = new FormData();
  formData.append("message", questions);
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData,
  };
  const response = await fetch(
    `${API_BASE_URL}admin/v1/flow/case_label/generator`,
    requestOptions
  );
  const data = await response.json();
  if (!data.success) {
    localStorage.removeItem("token");
    localStorage.removeItem("login");
    window.location.reload();
  }
  return data;
};
async function loadFonts(url, name) {
  var link = document.createElement("link");
  link.rel = "stylesheet";
  link.href = url;
  document.getElementsByTagName("head")[0].appendChild(link);
}
export const getGeneralList = async () => {
  const config = {
    headers: { authorization: `Bearer ${getToken()}` },
  };
  const res = await axios(`${API_BASE_URL}/admin/v1/general/list`, config);
  if (res.data.data.list.flow_options) {
    localStorage.setItem(
      "option_list",
      JSON.stringify(res.data.data.list.flow_options)
    );
    window.option_list = res.data.data.list.flow_options;
    window.nlp_labels = res.data.data.list.nlp_labels;
  }
  res.data.data.list.widget["font.list"].forEach((font) => {
    loadFonts(font.code, font.name);
  });
  window.font_list = res.data.data.list.widget["font.list"];
  return res.data;
};

export const addFlow = async (flowData) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);

  const formData = new FormData();
  formData.append("nutrition_method_id", flowData.nutritionMethodID);
  formData.append("nutrition_type_id", flowData.nutritionApplicationMethodID);
  formData.append("nlp_label_id", flowData.nlpLabelID);
  formData.append("message", flowData.message);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}admin/v1/flow/add`,
    requestOptions
  );
  const data = await response.json();
  if (!data.success) {
    localStorage.removeItem("token");
    localStorage.removeItem("login");
    window.location.reload();
  }
  return data;
};

export const getFlowDetailList = async (flow_topic_id) => {
  const config = {
    headers: { authorization: `Bearer ${getToken()}` },
  };
  const res = await axios(
    `${API_BASE_URL}/admin/v1/flow/detail/` + flow_topic_id,
    config
  );
  if (!res.data.success) {
    localStorage.removeItem("token");
    localStorage.removeItem("login");
    window.location.reload();
  }
  return res.data;
};
export const getFlowTranslationDetails = async (
  country_id,
  lang_id,
  flow_topic_id
) => {
  const config = {
    headers: { authorization: `Bearer ${getToken()}` },
  };
  const res = await axios(
    `${API_BASE_URL}/admin/v1/translations/flows/get/${country_id}/${lang_id}/${flow_topic_id}`,
    config
  );
  if (!res.data.success) {
    localStorage.removeItem("token");
    localStorage.removeItem("login");
    window.location.reload();
  }
  return res.data;
};
export const updateFlowNode = async (
  node_id,
  flow_topic_id,
  title,
  action,
  action_value
) => {
  var data = new FormData();
  data.append("id", node_id);
  data.append("flow_topic_id", flow_topic_id);
  data.append("title", title);
  data.append("action", action);
  if (action === "video" || action === "image")
    data.append("action_value", action_value, "video.mp4");
  if (action === "color") data.append("action_value", action_value);

  var config = {
    method: "post",
    url: `${API_BASE_URL}/admin/v1/flow/design/node/update`,
    headers: {
      Accept: "application/json",
      authorization: `Bearer ${getToken()}`,
    },
    data: data,
  };

  const res = await axios(config);
  return res;
};

export const createNewFlowNode = async (flow_topic_id, title, action, x, y) => {
  const data = new FormData();
  data.append("flow_topic_id", flow_topic_id);
  data.append("title", title);
  data.append("action", action);
  data.append("position", `{"x":${x},"y":${y}}`);
  data.append("position_absolute", `{"x":${x},"y":${y}}`);

  const config = {
    method: "post",
    url: `${API_BASE_URL}/admin/v1/flow/design/node/add`,
    headers: {
      Accept: "application/json",
      authorization: `Bearer ${token}`,
    },
    data: data,
  };
  const res = await axios(config);
  return res;
};

export const createNewOption = async (
  flow_topic_id,
  title,
  action,
  x,
  y,
  action_value
) => {
  const data = new FormData();
  data.append("flow_topic_id", flow_topic_id);
  data.append("title", title);
  data.append("action", action);
  data.append("position", `{"x":${x},"y":${y}}`);
  data.append("position_absolute", `{"x":${x},"y":${y}}`);

  const config = {
    method: "post",
    url: `${API_BASE_URL}/admin/v1/flow/design/node/add`,
    headers: {
      Accept: "application/json",
      authorization: `Bearer ${token}`,
    },
    data: data,
  };
  const res = await axios(config);
  return res;
};

export const updateNodePosition = async (
  flow_topic_id,
  flow_id,
  x,
  y,
  type = "flow",
  option_id
) => {
  var data = new FormData();
  data.append("flow_topic_id", parseInt(flow_topic_id));
  data.append("flow_id", flow_id);
  data.append("position", `{"x":${x},"y":${y}}`);
  data.append("position_absolute", `{"x":${x},"y":${y}}`);
  data.append("type", type);
  if (type === "option") data.append("option_id", option_id);

  var config = {
    method: "post",
    url: `${API_BASE_URL}/admin/v1/flow/design/node/position/update`,
    headers: {
      Accept: "application/json",
      authorization: `Bearer ${getToken()}`,
    },
    data: data,
  };

  const res = await axios(config);
  return res;
};

export const deleteFlowNode = async (flow_topic_id, flow_id) => {
  var data = new URLSearchParams();
  data.append("flow_topic_id", flow_topic_id);
  data.append("flow_id", flow_id);

  var config = {
    method: "delete",
    url: `${API_BASE_URL}/admin/v1/flow/design/node/delete`,
    headers: {
      Accept: "application/json",
      authorization: `Bearer ${token}`,
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data,
  };

  const res = await axios(config);
  return res;
};

export const addNodeRelation = async (
  flow_topic_id,
  input_option_flow_id,
  input_type,
  input_id,
  output_type,
  output_id,
  none_output
) => {
  console.log(input_id, "input id");
  console.log(input_option_flow_id, "inpt option flow id");
  var FormData = require("form-data");
  var data = new FormData();
  data.append("flow_topic_id", flow_topic_id);
  if (input_type === "option")
    data.append("input_option_flow_id", input_option_flow_id);
  data.append("input_type", input_type);
  data.append("input_id", isNaN(input_id) ? input_option_flow_id : input_id);
  data.append("output_type", output_type);
  data.append("output_id", output_id);
  data.append("none_output_answer_id", none_output);

  var config = {
    method: "post",
    url: `${API_BASE_URL}/admin/v1/flow/design/node/relational`,
    headers: {
      Accept: "application/json",
      authorization: `Bearer ${token}`,
    },
    data: data,
  };

  const res = await axios(config);
  return res;
};

export const deleteNodeRelation = async (
  flow_topic_id,
  type,
  flow_id,
  option_id,
  answer_flow_id
) => {
  var data = new URLSearchParams();
  data.append("flow_topic_id", flow_topic_id);
  data.append("type", type);
  data.append("flow_id", flow_id);
  data.append("option_id", option_id);
  data.append("answer_flow_id", answer_flow_id);

  var config = {
    method: "delete",
    url: `${API_BASE_URL}/admin/v1/flow/design/node/relational/delete`,
    headers: {
      Accept: "application/json",
      authorization: `Bearer ${token}`,
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data,
  };

  const res = await axios(config);
  return res;
};

export const changeOptionData = async (
  flow_topic_id,
  flow_id,
  old_option_id,
  new_option_id
) => {
  var FormData = require("form-data");
  var data = new FormData();
  data.append("flow_topic_id", flow_topic_id);
  data.append("flow_id", flow_id);
  data.append("old_option_id", old_option_id);
  data.append("new_option_id", new_option_id);

  var config = {
    method: "post",
    url: `${API_BASE_URL}/admin/v1/flow/design/node/option/update`,
    headers: {
      Accept: "application/json",
      authorization: `Bearer ${token}`,
    },
    data: data,
  };

  const res = await axios(config);
  return res;
};
export const changeOptionDataSplit = async (
  flow_topic_id,
  flow_id,
  new_option_id
) => {
  var FormData = require("form-data");
  var data = new FormData();
  data.append("flow_topic_id", flow_topic_id);
  data.append("flow_id", flow_id);
  data.append("old_option_id", flow_id);
  data.append("new_option_id", new_option_id);

  var config = {
    method: "post",
    url: `${API_BASE_URL}/admin/v1/flow/design/node/option/update`,
    headers: {
      Accept: "application/json",
      authorization: `Bearer ${token}`,
    },
    data: data,
  };

  const res = await axios(config);
  return res;
};

export const updateStatus = async (flow_id, status) => {
  const data = new FormData();
  data.append("flow_topic_id", flow_id);
  data.append("status", status);

  var config = {
    method: "post",
    url: `${API_BASE_URL}/admin/v1/flow/status`,
    headers: {
      Accept: "application/json",
      authorization: `Bearer ${getToken()}`,
    },
    data: data,
  };
  const res = await axios(config);

  return res;
};
export const deleteFlow = async (flow_id) => {
  const data = new URLSearchParams();
  data.append("flow_topic_id", flow_id);
  const config = {
    method: "delete",
    url: `${API_BASE_URL}/admin/v1/flow/delete`,
    headers: {
      Accept: "application/json",
      authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data,
  };

  const res = await axios(config);
  return res;
};
export const getInitialSettings = async () => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}/admin/v1/settings/api_keys/detail/1`,
    requestOptions
  );
  const data = await response.json();
  if (!data.success) {
    localStorage.removeItem("token");
    localStorage.removeItem("login");
    window.location.reload();
  }
  return data;
};
export const submitGreetingsSettings = async (settings) => {
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);

  var formdata = new FormData();
  formdata.append("api_id", "1");
  formdata.append("data", JSON.stringify(settings));

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}/admin/v1/settings/api_keys/update`,
    requestOptions
  );
  const data = await response.json();
  return data;
};
export const getNLPCaseLabelList = async (page, row, searchData) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}/admin/v1/nlp/case/list?` +
      new URLSearchParams({
        page,
        limit: row,
        search: searchData,
      }),
    requestOptions
  );
  const data = await response.json();
  if (!data.success) {
    localStorage.removeItem("token");
    localStorage.removeItem("login");
    window.location.reload();
  }
  return data;
};
export const getNLPCasesSentences = async (
  caseLabelValue,
  page,
  row,
  searchData,
  sourceData,
  changelog_action
) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}/admin/v1/nlp/case/detail/${caseLabelValue}?` +
      new URLSearchParams({
        page,
        limit: row,
        search: searchData,
        source: sourceData,
        status: changelog_action.toLowerCase(),
      }),
    requestOptions
  );
  const data = await response.json();
  return data;
};

export const addNLPCasesSentences = async (addNewSentence, caselabel) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);

  const data = new FormData();
  data.append("case", caselabel);
  data.append("dataset_id", 1);
  data.append("language_id", 1);
  data.append("sentence", addNewSentence);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: data,
    redirect: "follow",
  };

  const response = await fetch(
    `${API_BASE_URL}admin/v1/datasets/sentence/add`,
    requestOptions
  );
  const addData = await response.json();
  return addData;
};

export const deleteNLPCasesSentences = async (caselabel, deleteSentenceID) => {
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);

  var urlencoded = new URLSearchParams();
  urlencoded.append("case_label", caselabel);

  var requestOptions = {
    method: "DELETE",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
  };

  const response = await fetch(
    `${API_BASE_URL}/admin/v1/datasets/sentence/${deleteSentenceID}`,
    requestOptions
  );
  const deleteData = await response.json();
  return deleteData;
};

export const updateNLPCasesSentences = async (sentenceID, editSentence) => {
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);

  var formdata = new FormData();
  formdata.append("sentence", editSentence);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  const response = await fetch(
    `${API_BASE_URL}admin/v1/datasets/sentence/${sentenceID}`,
    requestOptions
  );
  const updateData = await response.json();
  return updateData;
};

export const getMasterDataset = async (
  caseLabelValue,
  page,
  row,
  searchData,
  sourceData,
  changelog_action
) => {
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}/admin/v1/nlp/master_dataset/list?` +
      new URLSearchParams({
        page,
        limit: row,
        search: searchData,
        source: sourceData,
        case_label: caseLabelValue,
        changelog_action: changelog_action,
      }),
    requestOptions
  );
  const data = await response.json();
  if (!data.success) {
    localStorage.removeItem("token");
    localStorage.removeItem("login");
    window.location.reload();
  }
  return data;
};
export const getValidationQueue = async (page, row, status) => {
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  if (status === undefined) {
    status = "";
  }
  const response = await fetch(
    `${API_BASE_URL}/admin/v1/nlp/validation_queue/list?` +
      new URLSearchParams({
        page,
        limit: row,
        status,
      }),
    requestOptions
  );
  const data = await response.json();
  if (!data.success) {
    localStorage.removeItem("token");
    localStorage.removeItem("login");
    window.location.reload();
  }
  return data;
};

export const updateMasterDataset = async (id, caselabel, editSentence) => {
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);

  var formdata = new FormData();
  formdata.append("case_label", caselabel);
  formdata.append("sentence", editSentence);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  const response = await fetch(
    `${API_BASE_URL}/admin/v1/nlp/master_dataset/update/${id}`,
    requestOptions
  );
  const updateData = await response.json();
  return updateData;
};

export const deleteMasterDataset = async (id) => {
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);

  var formdata = new FormData();

  var requestOptions = {
    method: "DELETE",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  fetch(`${API_BASE_URL}admin/v1/datasets/sentence/${id}`, requestOptions);
};

export const postAssignValidation = async (selectedProduct, caselabel) => {
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    data: selectedProduct,
    assign_case_label: caselabel,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}/admin/v1/nlp/validation_queue/assign`,
    requestOptions
  );
  const data = await response.json();
  return data;
};

export const postExcludeValidation = async (selectedProduct) => {
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    data: selectedProduct,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}/admin/v1/nlp/validation_queue/exclude`,
    requestOptions
  );
  const data = await response.json();
  return data;
};
export const postNlpUndo = async (id, action) => {
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);

  var formdata = new FormData();
  formdata.append("id", id);
  formdata.append("action", action);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  const response = await fetch(
    `${API_BASE_URL}/admin/v1/change_log/undo`,
    requestOptions
  );
  const data = await response.json();
  return data;
};
export const getAllTranslations = async (
  language_id,
  country_id,
  page,
  row
) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}/admin/v1/translations/flows/list/${language_id}/${country_id}?` +
      new URLSearchParams({
        page,
        limit: row,
      }),
    requestOptions
  );
  const data = await response.json();
  return data;
};

export const getWaitingTranslations = async (
  language_id,
  country_id,
  page,
  row
) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}/admin/v1/translations/flows/list/waiting/${language_id}/${country_id}?` +
      new URLSearchParams({
        page,
        limit: row,
      }),
    requestOptions
  );
  const data = await response.json();
  return data;
};
export const getWaitingTranslationsReview = async (
  language_id,
  country_id,
  page,
  row
) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}/admin/v1/translations/flows/review/list/waiting/${language_id}/${country_id}?` +
      new URLSearchParams({
        page,
        limit: row,
      }),
    requestOptions
  );
  const data = await response.json();
  return data;
};
export const getDoneTranslationsg = async (
  language_id,
  country_id,
  page,
  row
) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}/admin/v1/translations/flows/review/list/${language_id}/${country_id}?` +
      new URLSearchParams({
        page,
        limit: row,
      }),
    requestOptions
  );
  const data = await response.json();
  return data;
};
export const getInProgressTranslations = async (
  language_id,
  country_id,
  page,
  row
) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}/admin/v1/translations/flows/list/onProcess/${language_id}/${country_id}?` +
      new URLSearchParams({
        page,
        limit: row,
      }),
    requestOptions
  );
  const data = await response.json();
  return data;
};
export const getInProgressTranslationsReview = async (
  language_id,
  country_id,
  page,
  row
) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}/admin/v1/translations/flows/review/list/onProcess/${language_id}/${country_id}?` +
      new URLSearchParams({
        page,
        limit: row,
      }),
    requestOptions
  );
  const data = await response.json();
  return data;
};
export const getAllCountryLanguage = async () => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}/admin/v1/translations/country_lang`,
    requestOptions
  );
  const data = await response.json();
  return data;
};
export const startTrainJob = async (dataset_id) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}admin/v1/datasets/create-task`,
    requestOptions
  );
  const data = await response.json();
  return data;
};
export const changeNodeText = async (
  message,
  lang_id,
  country_id,
  flow_id,
  approve
) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  const formData = new FormData();
  formData.append("text", message);
  formData.append("approved", approve);
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData,
  };
  const response = await fetch(
    `${API_BASE_URL}/admin/v1/translations/flows/nodes/${lang_id}/${country_id}/${flow_id}`,
    requestOptions
  );
  const data = await response.json();
  return data;
};
export const forkDataset = async (ref_dataset_id) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  const formData = new FormData();
  formData.append("ref_dataset_id", ref_dataset_id);
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData,
  };
  const response = await fetch(
    `${API_BASE_URL}/admin/v1/datasets/fork`,
    requestOptions
  );
  const data = await response.json();
  return data;
};
export const selectActiveDataset = async (active_dataset_id) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  const formData = new FormData();
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData,
  };
  const response = await fetch(
    `${API_BASE_URL}/admin/v1/datasets/set_active_dataset/${active_dataset_id}`,
    requestOptions
  );
  const data = await response.json();
  return data;
};
export const selectDevelopmentDataset = async (active_dataset_id) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  const formData = new FormData();
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData,
  };
  const response = await fetch(
    `${API_BASE_URL}/admin/v1/datasets/set_dev_dataset/${active_dataset_id}`,
    requestOptions
  );
  const data = await response.json();
  return data;
};
export const getChangeLog = async () => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}admin/v1/datasets/changelog`,
    requestOptions
  );
  const data = await response.json();
  return data;
};
export const getChartData = async (id) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}admin/v1/datasets/case-label-chart`,
    requestOptions
  );
  const data = await response.json();
  return data;
};
export const getChartDataByID = async (id) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}admin/v1/datasets/case-label-chart/${id}`,
    requestOptions
  );
  const data = await response.json();
  return data;
};
export const getChangeLogData = async (id) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}admin/v1/datasets/get/${id}`,
    requestOptions
  );
  const data = await response.json();
  return data;
};
export const getAllAdmins = async (page, row) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}/admin/v1/admin/list?page=${page}&limit=${row}`,
    requestOptions
  );
  const data = await response.json();
  return data;
};
export const getPollList = async (page, row) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}admin/v1/polls/list?page=${page}&limit=${row}`,
    requestOptions
  );
  const data = await response.json();
  return data;
};
export const getAdminDetail = async (id) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}admin/v1/admin/detail/${id}`,
    requestOptions
  );
  const data = await response.json();
  return data;
};
export const getPollsDetail = async (id) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}admin/v1/polls/detail/${id}`,
    requestOptions
  );
  const data = await response.json();
  return data;
};
export const createAdmin = async (adminData, auth) => {
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  var formdata = new FormData();
  formdata.append("customer_id", "1");
  formdata.append("name", adminData.name);
  formdata.append("email", adminData.email);
  formdata.append("password", adminData.password);
  formdata.append("password_confirmation", adminData.password_confirmation);
  formdata.append("auth", auth);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}admin/v1/admin/add`,
    requestOptions
  );
  const data = await response.json();
  return data;
};
export const editAdmin = async (adminData, id, auth) => {
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);

  var formdata = new FormData();
  formdata.append("customer_id", "1");
  formdata.append("name", adminData.name);
  formdata.append("email", adminData.email);
  formdata.append("auth", auth);
  if (adminData?.password !== undefined) {
    formdata.append("password", adminData.password);
    formdata.append("password_confirmation", adminData.password_confirmation);
  }

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}admin/v1/admin/update/${id}`,
    requestOptions
  );
  const data = await response.json();
  return data;
};
export const getCountries = async (page, row) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}admin/v1/country/list?page=${page}&limit=${row}`,
    requestOptions
  );
  const data = await response.json();
  return data;
};
export const getCountriesDetail = async (id) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}admin/v1/country/detail/${id}`,
    requestOptions
  );
  const data = await response.json();
  return data;
};
export const createCountry = async (countryData) => {
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);

  var formdata = new FormData();
  formdata.append("code", countryData.code);
  formdata.append("title", countryData.title);
  formdata.append("locale", countryData.locale);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}admin/v1/country/add`,
    requestOptions
  );
  const data = await response.json();
  return data;
};
export const editCountry = async (countryData, id) => {
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);

  var formdata = new FormData();
  formdata.append("code", countryData.code);
  formdata.append("title", countryData.title);
  formdata.append("locale", countryData.locale);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}admin/v1/country/update/${id}`,
    requestOptions
  );
  const data = await response.json();
  return data;
};
export const deleteDataset = async (id) => {
  var config = {
    method: "delete",
    url: `${API_BASE_URL}admin/v1/datasets/${id}`,
    headers: {
      Accept: "application/json",
      authorization: `Bearer ${token}`,
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  const res = await axios(config);
  return res;
};
export const getLanguages = async (page, row) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}admin/v1/language/list?page=${page}&limit=${row}`,
    requestOptions
  );
  const data = await response.json();
  return data;
};
export const getLanguagesDetail = async (id) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}admin/v1/language/detail/${id}`,
    requestOptions
  );
  const data = await response.json();
  return data;
};
export const editLanguage = async (languageData, id) => {
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);

  var formdata = new FormData();
  formdata.append("code", languageData.code);
  formdata.append("title", languageData.title);
  formdata.append("nlp_url", languageData.nlp_url);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}admin/v1/language/update/${id}`,
    requestOptions
  );
  const data = await response.json();
  return data;
};
export const createLanguage = async (languageData) => {
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);

  var formdata = new FormData();
  formdata.append("code", languageData.code);
  formdata.append("title", languageData.title);
  formdata.append("nlp_url", languageData.nlp_url);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}admin/v1/language/add`,
    requestOptions
  );
  const data = await response.json();
  return data;
};
export const getAnalyticsData = async (
  nutritionMethodID,
  nutritionAppMethodID,
  start_date,
  end_date
) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}/admin/v1/analytics/list?` +
      new URLSearchParams({
        nutrition_method: nutritionMethodID,
        nutrition_type: nutritionAppMethodID,
        start_date,
        end_date,
      }),
    requestOptions
  );
  const data = await response.json();
  return data;
};
export const getAdminSlice = async () => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}/admin/v1/general/auth-admin-type`,
    requestOptions
  );
  const data = await response.json();

  if (!data.success) {
    localStorage.removeItem("token");
    localStorage.removeItem("login");
    window.location.reload();
  }
  return data;
};
export const createNewPoll = async (
  name,
  methods,
  types,
  position,
  question,
  answers,
  status,
  startDate,
  endDate,
  poll_type
) => {
  let selectedAnswers = [];
  answers.forEach((ans) => {
    selectedAnswers.push(ans.answer);
  });
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);

  var formdata = new FormData();
  formdata.append("name", name);
  formdata.append("methods", JSON.stringify(methods));
  formdata.append("types", JSON.stringify(types));
  formdata.append("poll_position", position.name);
  formdata.append("active", status === "Active" ? 1 : 0);
  formdata.append("question", question);
  formdata.append("answers", JSON.stringify(selectedAnswers));
  formdata.append("start_date", startDate);
  formdata.append("end_date", endDate);
  formdata.append("poll_type", poll_type);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}admin/v1/polls/add`,
    requestOptions
  );
  const data = await response.json();
  return data;
};
export const editPoll = async (
  id,
  name,
  position,
  question,
  answers,
  status,
  startDate,
  endDate,
  poll_type,
  types,
  methods
) => {
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);

  const tempType = [];
  const tempMethod = [];
  types.forEach((typ) => {
    tempType.push(typ.id);
  });
  methods.forEach((method) => {
    tempMethod.push(method.id);
  });
  var formdata = new FormData();
  formdata.append("name", name);
  formdata.append("poll_position", position.name);
  formdata.append("active", status === "Active" ? 1 : 0);
  formdata.append("question", question);
  formdata.append("answers", JSON.stringify(answers));
  formdata.append("start_date", startDate);
  formdata.append("end_date", endDate);
  formdata.append("methods", JSON.stringify(tempMethod));
  formdata.append("types", JSON.stringify(tempType));
  formdata.append("poll_type", poll_type);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}admin/v1/polls/update/${id}`,
    requestOptions
  );
  const data = await response.json();
  return data;
};
export const deletePollByID = async (id) => {
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);

  var requestOptions = {
    method: "DELETE",
    headers: myHeaders,
    redirect: "follow",
  };

  const response = await fetch(
    `${API_BASE_URL}admin/v1/polls/${id}`,
    requestOptions
  );
  const deleteData = await response.json();
  return deleteData;
};
export const getAuthTypeList = async (page, row) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}admin/v1/authtypes/list`,
    requestOptions
  );
  const data = await response.json();
  return data;
};
export const getPollsDetailAnswers = async (id, page, row) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}/admin/v1/polls/chart/${id}?page=${page}&limit=${row}`,
    requestOptions
  );
  const data = await response.json();

  return data;
};
export const getCustomer = async (page, row) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}admin/v1/customers/list?page=${page}&limit=${row}`,
    requestOptions
  );
  const data = await response.json();
  return data;
};
export const createCustomer = async (name) => {
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);

  var formdata = new FormData();
  formdata.append("name", name);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}admin/v1/customers/add`,
    requestOptions
  );
  const data = await response.json();
  return data;
};
export const getCustomerDetail = async (id) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}admin/v1/customers/detail/${id}`,
    requestOptions
  );
  const data = await response.json();
  return data;
};
export const editCustomers = async (countryData, id) => {
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);

  var formdata = new FormData();
  formdata.append("name", countryData.name);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}admin/v1/customers/update/${id}`,
    requestOptions
  );
  const data = await response.json();
  return data;
};
export const deleteCustomers = async (id) => {
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  var formdata = new FormData();
  formdata.append("", "");
  var requestOptions = {
    method: "DELETE",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}admin/v1/customers/${id}`,
    requestOptions
  );
  const data = await response.json();
  return data;
};
export const pollsAddCheck = async (
  methods,
  types,
  position,
  poll_type,
  start_date,
  end_date,
  state,
  lastCheck
) => {
  let editMethod = [];
  let editType = [];
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  var formdata = new FormData();
  if (poll_type === "schedule") {
    formdata.append("start_date", start_date);
    formdata.append("end_date", end_date);
  }
  if (state?.create === false) {
    methods.forEach((item) => {
      editMethod.push(item.id);
    });
    types.forEach((item) => {
      editType.push(item.id);
    });
    formdata.append("methods", JSON.stringify(editMethod));
    formdata.append("types", JSON.stringify(editType));
  } else {
    formdata.append("methods", JSON.stringify(methods));
    formdata.append("types", JSON.stringify(types));
  }
  formdata.append("poll_position", position.name);
  formdata.append("poll_type", poll_type);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}admin/v1/polls/add-check`,
    requestOptions
  );
  const data = await response.json();
  return data;
};
export const changePollStatus = async (id, status) => {
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);

  var formdata = new FormData();
  formdata.append("id", id);
  formdata.append("status", status);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}admin/v1/polls/status`,
    requestOptions
  );
  const data = await response.json();
  return data;
};
export const getWidgetNodes = async (flow_id, option_id) => {
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);

  var formdata = new FormData();
  formdata.append("flow_id", flow_id);
  formdata.append("option_id", option_id);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}admin/v1/flow/test-flow-widget`,
    requestOptions
  );
  const data = await response.json();
  return data;
};
export const getProductPollList = async (page, row) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}admin/v1/products/list?page=${page}&limit=${row}`,
    requestOptions
  );
  const data = await response.json();
  return data;
};
export const getCompanyList = async (page, row) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}admin/v1/products/company-list?page=${page}&limit=${row}`,
    requestOptions
  );
  const data = await response.json();
  return data;
};
export const createProductPoll = async (
  nutMethod,
  nutType,
  name,
  company,
  value,
  unit
) => {
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  var formdata = new FormData();
  if (typeof company === "string") {
    formdata.append("company_id", 0);
    formdata.append("company_title", company);
  } else {
    formdata.append("company_id", company.id);
    formdata.append("company_title", company.title);
  }
  formdata.append("methods", JSON.stringify(nutMethod));
  formdata.append("types", JSON.stringify(nutType));
  formdata.append("value", value);
  formdata.append("unit", unit);
  formdata.append("name", name);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}admin/v1/products/add`,
    requestOptions
  );
  const data = await response.json();
  return data;
};
export const deleteProductPollByID = async (id) => {
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);

  var requestOptions = {
    method: "DELETE",
    headers: myHeaders,
    redirect: "follow",
  };

  const response = await fetch(
    `${API_BASE_URL}admin/v1/products/${id}`,
    requestOptions
  );
  const deleteData = await response.json();
  return deleteData;
};
export const getWellBeingPollList = async (page, row) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}admin/v1/wellbeing/list?page=${page}&limit=${row}`,
    requestOptions
  );
  const data = await response.json();
  return data;
};
export const deleteWellBeingPollByID = async (id) => {
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);

  var requestOptions = {
    method: "DELETE",
    headers: myHeaders,
    redirect: "follow",
  };

  const response = await fetch(
    `${API_BASE_URL}admin/v1/wellbeing/${id}`,
    requestOptions
  );
  const deleteData = await response.json();
  return deleteData;
};
export const changeWellBeingPollStatus = async (id, status) => {
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);

  var formdata = new FormData();
  formdata.append("id", id);
  formdata.append("status", status);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}admin/v1/wellbeing/status`,
    requestOptions
  );
  const data = await response.json();
  return data;
};
export const createNewWellBeingPoll = async (questionArray) => {
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);

  var formdata = new FormData();
  formdata.append("questions", JSON.stringify(questionArray));

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}admin/v1/wellbeing/add`,
    requestOptions
  );
  const data = await response.json();
  return data;
};
export const getWellBeingPollDetail = async (id) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}admin/v1/wellbeing/detail/${id}`,
    requestOptions
  );
  const data = await response.json();
  return data;
};
export const updateWellBeingPoll = async (id, question, answers, status) => {
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);

  var formdata = new FormData();
  formdata.append("question", question);
  formdata.append("answers", JSON.stringify(answers));
  formdata.append("status", 1);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  const response = await fetch(
    `${API_BASE_URL}admin/v1/wellbeing/update/${id}`,
    requestOptions
  );
  const updateData = await response.json();
  return updateData;
};
export const getProductPollsDetail = async (id) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}admin/v1/products/detail/${id}`,
    requestOptions
  );
  const data = await response.json();
  return data;
};
export const editProductPoll = async (
  nutMethod,
  nutType,
  name,
  company,
  value,
  unit,
  id
) => {
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  var formdata = new FormData();
  if (typeof company === "string") {
    formdata.append("company_id", 0);
    formdata.append("company_title", company);
  } else {
    formdata.append("company_id", company.id);
    formdata.append("company_title", company.title);
  }
  formdata.append("methods", JSON.stringify(nutMethod));
  formdata.append("types", JSON.stringify(nutType));
  formdata.append("value", value);
  formdata.append("unit", unit);
  formdata.append("name", name);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}admin/v1/products/update/${id}`,
    requestOptions
  );
  const data = await response.json();
  return data;
};
export const getProductPollsDetailAnswers = async (id, page, row) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}/admin/v1/products/chart/${id}?page=${page}&limit=${row}`,
    requestOptions
  );
  const data = await response.json();

  return data;
};
export const getWellPollsDetailAnswers = async (id, page, row) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const response = await fetch(
    `${API_BASE_URL}/admin/v1/wellbeing/chart/${id}?page=${page}&limit=${row}`,
    requestOptions
  );
  const data = await response.json();

  return data;
};
