import React from 'react';
import { getBezierPath } from 'reactflow';

import './EdgeWithDeleteButton.css';

const foreignObjectSize = 40;

export default function EdgeWithDeleteButton({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  data,
}) {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      <foreignObject
        width={foreignObjectSize}
        height={foreignObjectSize}
        x={labelX - foreignObjectSize / 2}
        y={labelY - foreignObjectSize / 2}
        className="edgebutton-foreignobject"
        requiredExtensions="http://www.w3.org/1999/xhtml"
      >
        <div>
          <button
            className="edgebutton"
            onClick={(event) => data.onEdgeRemoveButtonClick(event, id)}
          >
            ×
          </button>
          {/* <div
            style={{
              fontSize: 10,
              position: 'absolute',
              zIndex: 30,
            }}
          >
            {id}
          </div> */}
        </div>
      </foreignObject>
    </>
  );
}
