import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getAllCountryLanguage } from "../../API/helper";
import Header from "../../components/header/Header";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ProgressBar } from "primereact/progressbar";
import "./Translations-Selections.css";
import { Button } from "primereact/button";
import Lottie from "react-lottie-player";
import settingsAnimation from "../../assets/settingsAnimation.json";
function TranslationsViewSelection(props) {
  const [products, setProducts] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(50);
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);
  const dt = useRef(null);
  const { state } = useLocation();
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 50,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: {
      name: { value: "", matchMode: "contains" },
      "country.name": { value: "", matchMode: "contains" },
      company: { value: "", matchMode: "contains" },
      "representative.name": { value: "", matchMode: "contains" },
    },
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (selectedProducts !== null) {
      localStorage.setItem("lang_id", selectedProducts?.lang_id);
      localStorage.setItem("country_id", selectedProducts?.country_id);
      localStorage.setItem("country_name", selectedProducts?.name);
      localStorage.setItem("translation_selection_id", selectedProducts?.id);
      navigate("/translations/translations-review");
    }
  }, [selectedProducts]);
  const getCountryLanguages = async () => {
    setLoading(true);
    await getAllCountryLanguage().then((res) => {
      let data;
      if (res.status) {
        data = res.list.map((item) => ({
          ...item,
          GlobalLanguage: "Global-English",
          value: 20 + Math.floor(Math.random() * 80),
        }));
      }
      setProducts(data);
      setLoading(false);
    });
  };

  useEffect(() => {
    getCountryLanguages();
  }, []);

  const header = (
    <div>
      <span>Translations Review</span>
    </div>
  );
  const handleOnPage = (event) => {
    setRows(event.rows);
    setLazyParams(event);
  };

  const valueTemplate = (value) => {
    return (
      <React.Fragment>
        <b>{value}</b>
      </React.Fragment>
    );
  };
  const progressBody = (rowData) => {
    let color;
    if (rowData.approved_total_as_ratio * 100 < 30) {
      color = "#EF4444";
    } else if (
      rowData.approved_total_as_ratio * 100 > 30 &&
      rowData.approved_total_as_ratio * 100 < 80
    ) {
      color = "#F59E0B";
    } else {
      color = "#22C55E";
    }
    return (
      <React.Fragment>
        <div style={{ position: "relative", flexDirection: "row" }}>
          <span
            style={{
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              zIndex: 999,
              top: 4,
              color: "black",
              fontWeight: "bold",
            }}
          >
            {(rowData.approved_total_as_ratio * 100).toString().substring(0, 4)}%
          </span>
          <ProgressBar
            value={rowData.approved_total_as_ratio * 100}
            color={color}
          ></ProgressBar>
        </div>
      </React.Fragment>
    );
  };
  return (
    <div>
      {loading ? (
        <div className="settings-loader">
          <Lottie
            loop
            animationData={settingsAnimation}
            play
            style={{ width: 400, height: 400 }}
          />
        </div>
      ) : (
        <div>
          <Header items={[{ label: "Language Selection" }]} />
          <div className="datatable-crud-demo">
            <Toast ref={toast} />
            <div style={{ padding: 20 }}>
              <DataTable
                ref={dt}
                header={header}
                value={products}
                selection={selectedProducts}
                selectionMode={"single"}
                onPage={handleOnPage}
                onSelectionChange={(e) => setSelectedProducts(e.value)}
                dataKey="id"
                rows={rows}
                responsiveLayout="scroll"
                style={{ width: "100%", border: "1px solid #dee2e6" }}
              >
                <Column
                  field="id"
                  header="ID"
                  style={{ minWidth: "5rem" }}
                ></Column>
                <Column
                  field="GlobalLanguage"
                  header="Source Language"
                ></Column>
                <Column field="name" header="Target Language"></Column>
                <Column
                  field="approved_total_as_text"
                  header="Review Left"
                ></Column>
                <Column
                  field="value"
                  header="Completion Rate"
                  headerStyle={{ display: "flex", justifyContent: "center" }}
                  body={progressBody}
                  style={{ minWidth: "6rem" }}
                ></Column>
              </DataTable>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
//{(x) =>{return (x.approved_total_as_ratio*100).toString()+"%"; }}
export default TranslationsViewSelection;
