import React, { useEffect, useRef, useState } from "react";
import {
  getCompanyList,
  getPollsDetail,
  getProductPollsDetail,
} from "../../../API/helper";
import { Toast } from "primereact/toast";
import Header from "../../../components/header/Header";
import "./ProductPollDetails.css";
import { useLocation, useNavigate } from "react-router-dom";
import ProductNutritionMethods from "../../../components/Product-Nutrition-Methods/ProductNutritionMethods";
import Lottie from "react-lottie-player";
import settingsAnimation from "../../../assets/settingsAnimation.json";
import { Steps } from "primereact/steps";
import ProductFeatureDetails from "../../../components/Product-Feature-Details/ProductFeatureDetails";
import OverViewProductPoll from "../../../components/Overview&Create-Product-Poll/OverViewProductPoll";
const ProductPollDetails = () => {
  const { state } = useLocation();
  const toast = useRef(null);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [filteredCompanies, setFilteredCompanies] = useState(null);
  const [selectedUnits, setSelectedUnits] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [method, setMethod] = useState([]);
  const [type, setType] = useState([]);
  const [productName, setProductName] = useState("");
  const [loading, setLoading] = useState(false);
  const pollsSteps = [
    {
      label: "Nutrition Methods",
    },
    {
      label: "Product Detail",
    },
    {
      label: "Overview & Create Product",
    },
  ];
  const units = [
    { name: "Gram", code: "gr" },
    { name: "Milliliter", code: "ml" },
  ];
  const navigate = useNavigate();
  const [productValue, setProductValue] = useState();
  const getPollsDetail = async () => {
    try {
      setLoading(true);
      const res = await getProductPollsDetail(state.id);
      const value = res.data?.list?.value.split(" ");
      const nutritionMethods = res.data.list?.methods;
      const nutritionTypes = res.data.list?.types;

      setSelectedCompany(res.data.list?.company);
      setProductName(res.data.list?.name);

      if (value) {
        setSelectedUnits(value[1]);
        setProductValue(parseInt(value[0]));
      }

      value.length > 0 && setMethod(nutritionMethods);
      setType(nutritionTypes);
      setLoading(false);
    } catch (error) {
      // Hata yönetimi burada yapılabilir
      console.error("Hata oluştu:", error);
    }
  };
  console.log(selectedUnits, "asdfasdf");
  console.log(productValue);

  useEffect(() => {
    if (!state.create) getPollsDetail();
  }, []);
  const steps = [
    <div className="under-page">
      <ProductNutritionMethods
        state={state}
        activeItem={activeIndex}
        setActiveItem={setActiveIndex}
        method={method}
        setMethod={setMethod}
        type={type}
        setType={setType}
      />
    </div>,
    <div className="under-page">
      <ProductFeatureDetails
        state={state}
        setProductName={setProductName}
        productName={productName}
        activeItem={activeIndex}
        setActiveItem={setActiveIndex}
        units={units}
        selectedUnits={selectedUnits}
        setSelectedUnits={setSelectedUnits}
        setFilteredCompanies={setFilteredCompanies}
        selectedCompany={selectedCompany}
        setSelectedCompany={setSelectedCompany}
        companies={companies}
        filteredCompanies={filteredCompanies}
        productValue={productValue}
        setProductValue={setProductValue}
      />
    </div>,
    <div className="under-page">
      <OverViewProductPoll
        state={state}
        activeItem={activeIndex}
        setActiveItem={setActiveIndex}
        method={method}
        type={type}
        productName={productName}
        productValue={productValue}
        companyName={selectedCompany}
        unit={selectedUnits}
      />
    </div>,
  ];

  const getPollProducts = async () => {
    await getCompanyList().then((resp) => {
      setCompanies(resp.data.list);
    });
  };

  useEffect(() => {
    getPollProducts();
  }, []);

  return (
    <div>
      {loading ? (
        <div className="settings-loader">
          <Lottie
            loop
            animationData={settingsAnimation}
            play
            style={{ width: 400, height: 400 }}
          />
        </div>
      ) : (
        <div>
          <Header
            items={[
              { label: "Product Polls", url: "/polls/product-polls" },
              { label: "Create Poll" },
            ]}
          />
          <Toast ref={toast} />
          <div className="flow-wrapper">
            <Toast ref={toast}></Toast>
            <div className="steps-div">
              <Steps
                model={pollsSteps}
                activeIndex={activeIndex}
                onSelect={(e) => setActiveIndex(e.index)}
                readOnly={true}
              />
            </div>
            {steps.map((item, index) =>
              activeIndex === index ? item : <div></div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductPollDetails;
