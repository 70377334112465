import React, { memo, useEffect, useState } from 'react';
import { Handle } from 'reactflow';
import { Dropdown } from 'primereact/dropdown';
import './OptionTranslationNode.css'
import {Button} from "primereact/button";

export default memo(({ data, isConnectable }) => {
    const optionList = window.option_list?.map((e) => {
        return {
            label: e.title.toLocaleUpperCase(),
            value: e.id,
        };
    });
    return (
        <>
            <Handle
                type="target"
                position="left"
                style={{ background: '#555' }}
                onConnect={(params) => console.log('handle onConnect', params)}
                isConnectable={isConnectable}
            />

            <div className="node-header">
                <div className="node-label">Option:</div>
            </div>
            <div className="node-body">
                <div style={{display:"flex"}}>
                    <Dropdown
                        disabled={true}
                        value={Number(data.action_value)}
                        options={optionList}
                        onChange={(e) =>
                            data.updateNodeData({
                                id: e.value,
                                action: 'option',
                                old_id: data.id,
                            })
                        }
                        filter
                        filterBy="label"
                        placeholder="Search"
                        panelClassName="option-dropdown-panel"
                    />
                </div>
                {/*<div className="translation-button-wrapper">
                    <Button label="Previous" severity="info" onClick={()=>{
                        data.onClickPreviousNode()
                    }}/>
                    <Button label="Skip" severity="info" onClick={()=>{data.onClickNextNode()
                    }}/>
                </div>*/}
            </div>
            <Handle
                type="source"
                position="right"
                id="b"
                style={{ background: '#555' }}
                isConnectable={isConnectable}
            />
        </>
    );
});
